import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import { parseISO, isPast } from 'date-fns';

import {
    edit,
    intentStatusColor, intentStatuses, responseStatus, shared, view,
} from '../config/constants';
import { getLotOrProject } from './response.service';
import shareIcon from '../styles/images/share-icon.png';

const getIntentStatusColor = (status) => ({
    [intentStatuses.unsuccessful]: intentStatusColor.red,
    [intentStatuses.successful]: intentStatusColor.green,
}[status || '']);

const getResponseLabel = (response) => (
    <p className='title'>{response.sharedQuestion && <img src={shareIcon} alt={shared} id='share-icon'></img>}{response.templateName}</p>
);

const getButtonLabel = (response, data) => (
    response.responseStatus === responseStatus.notStarted
            || isPast(parseISO(data.submissionEndDate))
            || data.bidStatus === responseStatus.bidSubmitted
            || data.projectStatus === responseStatus.cancelled
            || data.awardApproval === responseStatus.approved
        ? view : edit
);

const isButtonDisabled = (response, data, questionnaireAwaitingRefresh) => (
    questionnaireAwaitingRefresh.length > 0
        && !(response.responseStatus === responseStatus.notStarted
        || isPast(parseISO(data?.submissionEndDate))
        || data?.bidStatus === responseStatus.bidSubmitted
        || data?.projectStatus === responseStatus.cancelled));

const getResponses = (
    initialData, responses, questionnaireAwaitingRefresh, getResponsesMenu,
) => responses
    .reduce((acc, response) => acc.concat({
        key: response.responseID,
        label: getResponseLabel(response),
        menu: getResponsesMenu(initialData, response, questionnaireAwaitingRefresh),
    }), []);

const getLotsOrProjectsMenu = (_initialData, data) => {
    const menuOptions = [];
    if (data.intentStatus) {
        menuOptions.push({
            id: uuidv4(),
            type: 'statusLabel',
            color: getIntentStatusColor(data.intentStatus),
            labelTxt: data.intentStatus,
        });
    }
    return menuOptions;
};

const getLotsOrProjects = (
    initialData, projectsOrLots, questionnaireAwaitingRefresh, getResponsesMenu,
) => Object.entries(projectsOrLots)
    .reduce((acc, [key, value]) => acc.concat({
        key,
        openState: true,
        label: `${getLotOrProject(initialData.publishLevel)} ${value.order} : ${value.title}`,
        menu: getLotsOrProjectsMenu(initialData, value, true),
        nodes: getResponses(
            initialData, value.responses, questionnaireAwaitingRefresh, getResponsesMenu,
        ),
    }), []);


const prepareCurrentSubmissionsTree = (
    data, getResponsesMenu, questionnaireAwaitingRefresh = [],
) => getLotsOrProjects(
    data, data.projectsOrLots, questionnaireAwaitingRefresh, getResponsesMenu,
);


const getprojectsOrLotsStructure = (responses) => {
    let projectsOrLotsStructure = {};
    responses.forEach((response, index) => {
        projectsOrLotsStructure = {
            ...projectsOrLotsStructure,
            [response.lotID]: {
                bidStatus: responseStatus.bidSubmitted,
                order: index + 1,
                title: response.lotTitle,
                ...response.intentStatus && { intentStatus: response.intentStatus },
                responses: response.questionnaires
                    .map((questionnaire) => (
                        { ...questionnaire, templateName: questionnaire.responseName }
                    )),
            },
        };
    });
    return projectsOrLotsStructure;
};

const getTreeStructure = (data) => ({
    projectsOrLots: getprojectsOrLotsStructure(data.responses),
    publishLevel: data.publishLevel,
    awardApproval: data.responses[0].awardApproval,
});

export {
    prepareCurrentSubmissionsTree,
    getTreeStructure,
    isButtonDisabled,
    getButtonLabel,
    getIntentStatusColor,
};
