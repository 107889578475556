const getSteeringGroupRole = (userId, steeringInfo) => {
    const role = [];
    steeringInfo?.map((group) => {
        if (group.userID === userId) {
            role.push(group.userRole);
        }
        return null;
    });
    return role;
};

const helperFunctions = {
    getSteeringGroupRole,
};

export default helperFunctions;
