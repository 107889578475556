import React, { useState, useEffect } from 'react';
import propTypes from 'prop-types';
import SelectSearch, { fuzzySearch } from 'react-select-search/dist/cjs';
import DatePicker from 'react-date-picker';
import Modal from '../../components/Modal';
import LabelledInput, { LabelledInputTypeFile } from '../../components/LabelledInput';
import LabelledRadioButton from '../../components/LabelledRadioButton';
import Form from '../../components/Form';
import UploadItemContainer from '../UploadModal/UploadItemContainer';
import UploadSVG from '../../styles/images/upload.svg';
import Button from '../../components/Button';

const UploadContractModal = ({
    contractDetails,
    mode,
    documents,
    // questionnaire,
    handleSubmit,
    handleRemoveDocument,
    handleDownload,
    closeModal,
    uploadIdentifier,
    readOnly = false,
}) => {
    const [values, setValues] = useState({});
    const [audience, setAudience] = useState();
    const [allLots, setAllLots] = useState();
    const [lotList, setLotList] = useState();
    const [selectedLots, setSelectedLots] = useState([]);
    const [supplierList, setSupplierList] = useState([]);
    const [allSuppliers, setAllSuppliers] = useState();
    const [selectedSuppliers, setSelectedSuppliers] = useState([]);

    const buildSupplierInfo = (suppliers) => {
        const foundSuppliers = [];

        return suppliers?.map((supplier) => {
            if (supplier.lotID && supplier.suppliers) {
                return supplier.suppliers.map((lotSupplier) => {
                    if (!foundSuppliers.includes(lotSupplier.accountID)) {
                        foundSuppliers.push(lotSupplier.accountID);

                        return {
                            name: lotSupplier.account?.companyName,
                            value: lotSupplier.accountID,
                            ...lotSupplier,
                        };
                    }

                    return null;
                });
            }

            return {
                name: supplier.account?.companyName,
                value: supplier.accountID,
                ...supplier,
            };
        }).flat().filter(Boolean);
    };

    useEffect(() => {
        setSupplierList(buildSupplierInfo(contractDetails?.awardedSuppliers));

        if (contractDetails?.publishLevel === 'lot') {
            setLotList(contractDetails.lots.map((lot) => ({
                name: lot.title,
                value: lot.lotID,
                ...lot,
            })));
        } else {
            setAudience('suppliers');
        }
    }, [contractDetails]);

    const generateViewDocumentBody = () => (<UploadItemContainer
        documents={documents}
        handleRemoveDocument={handleRemoveDocument}
        handleDownload={handleDownload}
        readOnly={readOnly}
    />);

    const generateModalBody = () => <>
        {contractDetails.publishLevel === 'lot' && <div className='uploadContentDiv'><LabelledRadioButton
            id='uploadAudience'
            label='Is this document for lots or suppliers?'
            breakColumn={false}
            onChange={(event) => setAudience(event.target.value)}
            options={[{
                label: 'Lots',
                value: 'lots',
                id: 'uploadAudienceLots',
                name: 'lots',
                checked: audience === 'lots',
            }, {
                label: 'Suppliers',
                value: 'suppliers',
                id: 'uploadAudienceSuppliers',
                name: 'suppliers',
                checked: audience === 'suppliers',
            }]}
            renderAsRow={true} /></div>}
        {audience === 'lots'
        && <div className='uploadContentDiv'><LabelledRadioButton
            id='selectAllLots'
            label='Is this document for all lots or specific lots?'
            breakColumn={false}
            onChange={(event) => setAllLots(event.target.value)}
            options={[{
                label: 'All Lots',
                value: 'true',
                id: 'selectAllLots',
                name: 'allLots',
                checked: allLots === 'true',
            }, {
                label: 'Specific Lot(s)',
                value: 'false',
                id: 'selectSpecificLots',
                name: 'specificLots',
                checked: allLots === 'false',
            }]}
            renderAsRow={true} />
        </div>
        }
        {(audience === 'suppliers' || contractDetails.publishLevel === 'project')
        && <div className='uploadContentDiv'><LabelledRadioButton
            id='selectAllSuppliers'
            label='Is this document for all suppliers or specific suppliers?'
            breakColumn={false}
            onChange={(event) => setAllSuppliers(event.target.value)}
            options={[{
                label: 'All Suppliers',
                value: 'true',
                id: 'selectAllSuppliers',
                name: 'allSuppliers',
                checked: allSuppliers === 'true',
            }, {
                label: 'Specific Supplier(s)',
                value: 'false',
                id: 'selectSpecificSuppliers',
                name: 'specificSuppliers',
                checked: allSuppliers === 'false',
            }]}
            renderAsRow={true} />
        </div>
        }
        {audience === 'lots' && allLots === 'false'
        && <div className='uploadContentDiv'><p className='title selectSearchTitle'>Please select which lots you wish to upload a document to</p>
            <SelectSearch
                id='selectLotsUpload-SelectSearch'
                options={lotList || []}
                closeOnSelect={false}
                printOptions='on-focus'
                multiple
                search
                placeholder='Select Lot(s)'
                onChange={(_event, data) => setSelectedLots(data)}
                filterOptions={fuzzySearch}
                z-index='40'
                // value={interestSupplier.map((a) => a.name).toString() || ''}
            /></div>}
        {((audience === 'suppliers' && allSuppliers === 'false') || (contractDetails.publishLevel === 'project' && allSuppliers === 'false'))
        && <div className='uploadContentDiv'><p className='title selectSearchTitle'>Please select which supplier(s) you wish to upload a document to</p>
            <SelectSearch
                id='addSuppliersUpload-SelectSearch'
                options={supplierList || []}
                closeOnSelect={false}
                printOptions='on-focus'
                multiple
                search
                placeholder='Select Supplier(s)'
                onChange={(_event, data) => setSelectedSuppliers(data)}
                filterOptions={fuzzySearch}
                z-index='40'
                // value={interestSupplier.map((a) => a.name).toString() || ''}
            /></div>}
        <div className='uploadContentDiv'>
            <label className='title label-margin datePickerLabel'>Expiry date (optional)</label>
            <DatePicker className='customDateInput nmiDate-imput upload-expiry'
                id='expiryDate'
                dayPlaceholder='DD'
                monthPlaceholder='MM'
                yearPlaceholder='YYYY'
                format={'dd-MM-y'}
                calendarIcon={null}
                onChange={(date) => setValues({
                    ...values, expiryDate: date,
                })}
                value={values.expiryDate || ''}
                minDate={new Date()}
            />
        </div>
        { values.filename ? <div className='uploadedFile'>
            <p className='title-small'>{values.filename}</p>
            <Button
                id='removeDocument'
                label='Remove'
                size='small'
                variant='primary'
                handleClick={() => setValues({})}
            />
        </div>
            : <div className='fileUpload'>
                <img src={UploadSVG} alt='UploadSVG' />
                <LabelledInput id='fileUpload'
                    type={LabelledInputTypeFile}
                    breakColumn={true}
                    onChange={(e) => setValues({
                        ...values, fileUpload: e.target.files,
                    })}
                    placeholder='Browse Files'
                    multipleFiles={true}
                />
            </div>
        }
    </>;

    const handleDocumentSubmit = (e) => {
        e.preventDefault();
        const payload = {};
        if (contractDetails.publishLevel === 'project') payload.type = 'account';
        if (audience === 'lots') payload.type = 'lots';
        if (audience === 'suppliers') payload.type = 'account';

        payload[audience] = {};

        const docObjects = [];
        // const docObjects = values.map((doc) => ({ documentID: doc.documentID }));

        if (audience === 'lots') {
            if (allLots === 'true') {
                payload[audience].all = docObjects;
            } else {
                selectedLots.forEach((lot) => {
                    payload[audience][lot.lotID] = docObjects;
                });
            }
        }

        if (audience === 'suppliers') {
            if (allSuppliers === 'true') {
                payload[audience].all = docObjects;
            } else {
                selectedSuppliers.forEach((supplier) => {
                    payload[audience][supplier.accountID] = docObjects;
                });
            }
        }


        handleSubmit(e, values, payload);
    };

    return <>
        {(mode === 'add')
            ? <Form id='duForm' onSubmit={(e) => handleDocumentSubmit(e)}>
                <Modal
                    open={true}
                    id='documentUpload'
                    size='medium'
                    headerTitle={`Upload ${uploadIdentifier}`}
                    mainActionButtonTxt='Upload'
                    // handleMainActionBtnClick={
                    //     questionnaire ? (e) => handleSubmit(e, values) : () => null
                    // }
                    secondActionButton
                    secondActionButtonTxt='Cancel'
                    handleSecondaryActionBtnClick={closeModal}
                    closeModal={closeModal}
                    helpOption={false}
                    body={generateModalBody()}
                />
            </Form> : <Modal
                open={true}
                id='viewDocuments'
                size='medium'
                headerTitle={`View ${uploadIdentifier}`}
                secondActionButton={false}
                handleSecondaryActionBtnClick={closeModal}
                mainActionButtonTxt='false'
                closeModal={closeModal}
                helpOption={false}
                body={generateViewDocumentBody()}
                footer={false}
            />
        }
    </>;
};

UploadContractModal.propTypes = {
    contractDetails: propTypes.object.isRequired,
    mode: propTypes.string.isRequired,
    documents: propTypes.array.isRequired,
    questionnaire: propTypes.bool,
    handleRemoveDocument: propTypes.func.isRequired,
    handleDownload: propTypes.func.isRequired,
    handleSubmit: propTypes.func.isRequired,
    closeModal: propTypes.func.isRequired,
    uploadIdentifier: propTypes.string,
    readOnly: propTypes.bool,
};


export default UploadContractModal;
