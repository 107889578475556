import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import userManagementAPIs from '../../services/user-management.service';
import DashboardCard from '../../components/DashboardCard';
import Upload from '../../features/Upload';
import Button from '../../components/Button';
import Tag from '../../components/Tag';
import { LabelledInputTypeText } from '../../components/LabelledInput';
import LabelledTextArea from '../../components/LabelledTextArea';
import LabelledSelect from '../../components/LabelledSelect';
import Modal from '../../components/Modal';
import Form from '../../components/Form';
import Toast from '../../components/Alerts/Toast/Toast';
import SupplierAccordion from './SupplierAccordion';
import SupplierListComp from './SupplierListComp';
import Constants from './supplierConstants';
import Alert from '../../components/Alerts/Alert';

const SupplierOverview = () => {
    const { id } = useParams();
    const history = useHistory();
    const [supplierData, setSupplierData] = useState({});
    const [documents, setDocuments] = useState([]);
    const [riskModal, setRiskModal] = useState();
    // const [creditScoreModal, setCreditScoreModal] = useState();
    const [values, setValues] = useState({});

    const fetchSupplier = async (supplierId) => {
        try {
            const responseData = await userManagementAPIs.getSupplierDetails(supplierId);

            if (responseData.status === 200) {
                setSupplierData(responseData.data);
                // FIXME: Documents aren't the part of the payload in current
                // work package and has to be picked up in the upcoming one
                setDocuments(responseData.data?.additionalDocuments || []);
            }
        } catch (err) {
            Alert(Constants.messages.noData.title, Constants.messages.noData.message,
                null, true);
            alert();
        }
    };

    const generateCodeTags = (item) => {
        const TagList = [];
        item?.forEach((tag, index) => {
            const TagElement = <Tag key={`${tag.classificationID}-${index}`}
                id={`row-${tag.classificationID}-${index}`}
                tagTxt={`${tag.category} - ${tag.level}`}
                isDeletable={false}
                size='large' />;
            TagList.push(TagElement);
        });
        if (TagList.length === 0) {
            return <p className={'caption'}>{'No codes and categories found.'}</p>;
        }
        return <div className='sectionContentTags'>
            {TagList}
        </div>;
    };

    const generateRegionTags = (item) => {
        const TagList = [];
        item?.forEach((tag, index) => {
            const TagElement = <Tag key={`${tag.code}-${index}`}
                id={`row-${tag.code}-${index}`}
                tagTxt={`${tag.code} - ${tag.description}`}
                isDeletable={false}
                size='large' />;
            TagList.push(TagElement);
        });
        if (TagList.length === 0) {
            return <p className={'caption'}>{'No region(s) of supply found.'}</p>;
        }
        return <div className='sectionContentTags'>
            {TagList}
        </div>;
    };

    const supplierDetailsContent = () => <>
        <section className='itemBody'>
            {/* <p className='title-large sectionHeader keyInfo'>Key Information</p> */}
            <div className='sectionContent'>
                <div className='sectionRow'>
                    <p className='title sectionLabel'>{Constants.businessStructure}</p>
                    <p className='caption sectionValue'>{supplierData?.businessStructure || '-'}</p>
                </div>
                <div className='sectionRow'>
                    <p className='title sectionLabel'>{Constants.natureOfBusiness}</p>
                    <p className='caption sectionValue'>{supplierData?.natureOfBusiness || '-'}</p>
                </div>
                <div className='sectionRow'>
                    <p className='title sectionLabel'>{Constants.vatNumber}</p>
                    <p className='caption sectionValue'>{supplierData?.vatNumber || '-'}</p>
                </div>
                <div className='sectionRow'>
                    <p className='title sectionLabel'>{Constants.registrationNumber}</p>
                    <p className='caption sectionValue'>{supplierData?.registrationNumber || '-'}</p>
                </div>
                <div className='sectionRow'>
                    <p className='title sectionLabel'>{Constants.address}</p>
                    <p className='caption sectionValue'>{supplierData?.address ? Object.keys(supplierData?.address).sort().map((k) => supplierData?.address[k]).join(', ') : '-'}</p>
                </div>
                <div className='sectionRow'>
                    <p className='title sectionLabel'>{Constants.wardName}</p>
                    <p className='caption sectionValue'>{supplierData?.wardName || '-'}</p>
                </div>
                <div className='sectionRow'>
                    <p className='title sectionLabel'>{Constants.localityName}</p>
                    <p className='caption sectionValue'>{supplierData?.localityName || '-'}</p>
                </div>
                <div className='sectionRow'>
                    <p className='title sectionLabel'>{Constants.regionName}</p>
                    <p className='caption sectionValue'>{supplierData?.regionName || '-'}</p>
                </div>
                <div className='sectionRow'>
                    <p className='title sectionLabel'>{Constants.companySize}</p>
                    <p className='caption sectionValue'>{supplierData?.companySize || '-'}</p>
                </div>
            </div>
            <p className='title-large sectionHeader'>{Constants.codesCategories}</p>
            <div className='sectionContent'>
                {generateCodeTags(supplierData?.industryCodes)}
            </div>
            <p className='title-large sectionHeader'>{Constants.deliveryArea}</p>
            <div className='sectionContent'>
                {generateRegionTags(supplierData?.deliveryArea)}
            </div>
        </section>
    </>;

    useEffect(() => {
        fetchSupplier(id);
    }, [id]);

    const generateDropdownOptions = (key, option, defaultValue) => ((key > 0)
        ? <option key={`riskStatus-${key}`} value={option}>{option}</option>
        : <option key={`riskStatus-${key}`} value='' disabled>{defaultValue}</option>);

    const handleChange = (e) => {
        const { name } = e.target;
        const { value } = e.target;
        setValues({
            ...values,
            [name]: value,
        });
    };

    const generateRiskContent = () => <>
        <LabelledSelect
            id='status'
            label='Select Risk Status'
            required={true}
            breakColumn={true}
            onChange={(e) => handleChange(e)}
            value={values.status || ''}
            options={Constants.allowedRisks.map((option, idx) => generateDropdownOptions(idx, option, 'Select Risk Status'))}
        />
        <LabelledTextArea
            id='reason'
            type={LabelledInputTypeText}
            label='Risk Reason'
            breakColumn={true}
            onChange={(e) => handleChange(e)}
            value={values.reason || ''}
            currentCharCount={values.status ? values.status.length : 0}
            placeholder='Enter Risk Reason'
            required={false}
        />
    </>;

    // const generateCreditContent = () => <>
    //     <LabelledInputType
    //         id='creditScore'
    //         type={LabelledInputTypeText}
    //         label='Credit Score'
    //         breakColumn={true}
    //         readonly={false}
    //         onChange={(e) => handleChange(e)}
    //         value={values.creditScore || ''}
    //         placeholder='No Credit Score found'
    //         required={true}
    //     />
    // </>;

    const closeRiskModal = () => {
        setValues({
            ...values,
            status: '',
            reason: '',
        });
        setRiskModal(false);
    };

    // const closeCreditModal = () => {
    //     setValues({
    //         ...values,
    //     });
    //     setCreditScoreModal(false);
    // };

    const handleRisk = () => {
        setValues({
            ...values,
            status: supplierData?.riskStatus?.status || '',
            reason: supplierData?.riskStatus?.reason || '',
        });
        setRiskModal(true);
    };

    // const handleCreditScore = () => {
    //     setValues({
    //         ...values,
    //     });
    //     setCreditScoreModal(true);
    // };

    const handleRiskSubmit = async (e) => {
        e.preventDefault();
        try {
            const payLoad = {
                status: values.status,
                reason: values.reason,
            };
            const response = await userManagementAPIs
                .postSupplierRiskStatus(supplierData?.accountID, payLoad);
            if (response.status === 200) {
                Toast.fire({
                    icon: 'success',
                    titleText: 'Risk Status updated successfully.',
                });
                fetchSupplier(id);
            }
        } catch {
            Toast.fire({
                icon: 'error',
                titleText: 'Unable to update risk status.',
            });
        }
        closeRiskModal();
    };
    const getColorCodeForCreditScore = () => {
        const creditScore = supplierData?.creditScore?.creditRating;
        let creditScoreClass = 'default';
        if (creditScore) {
            creditScoreClass = Constants
                .creditScore[Object.keys(Constants.creditScore).filter((x) => x === creditScore)];
        }
        return creditScoreClass;
    };

    const supplierActionContent = () => (<div id={'supplierActionContainer'}>
        <Button
            id={'back'}
            size={'small'}
            label='Back'
            variant={'secondary'}
            handleClick={() => history.goBack()}
        />
        <Button
            id='riskStatusButton'
            type='button'
            variant={'tertiary'}
            additionalVariant={`riskStatus ${supplierData?.riskStatus?.status?.toLowerCase() === 'medium' ? 'amber' : supplierData?.riskStatus?.status?.toLowerCase() || 'default'}`}
            label={supplierData && supplierData?.riskStatus?.status ? `${supplierData.riskStatus.status} Risk` : Constants.riskStatus}
            icon={false}
            handleClick={handleRisk}
        />
        <Button
            id='creditScoreButton'
            type='button'
            variant={'tertiary'}
            additionalVariant={`creditScore ${supplierData?.creditScore?.creditRating ? getColorCodeForCreditScore() : 'default'}`}
            label={supplierData?.creditScore && supplierData?.creditScore !== undefined ? `Credit Score : ${supplierData?.creditScore?.creditRating || ''}` : 'No Credit Score' }
            icon={false}
        />
    </div>);

    const departmentDetailsContent = () => {
        const deptSection = supplierData?.departments
            && <div className='departmentContent'>
                <SupplierAccordion
                    id={'bdAccordion_department'}
                    dataSet={supplierData?.departments}
                    accordionState={false}
                    context={Constants.department}
                />
            </div>;
        return deptSection;
    };
    const regionalOfficeDetailsContent = () => (supplierData?.additionalAccounts
            && <div className='accountContent'>
                {/* <div id='description' className='sectionRow'>
                    <p className='title sectionLabel'>Headquarters: {supplierData?.accountName}</p>
                </div> */}
                <SupplierListComp dataSet={supplierData?.additionalAccounts}
                    context={Constants.regionalOffice} />
            </div>
    );
    const headquarterDetailsContent = () => (supplierData?.additionalAccounts
            && <div className='accountContent'>
                <SupplierListComp dataSet={supplierData?.additionalAccounts}
                    context={Constants.headquarter} />
            </div>
    );
    return (<section id='supplierOverView'>
        {<div className='componentsContainer'>
            <div className={'dashboardCard large'} id={'supplierActions'}>
                <main className='dashboardCardMain'>{supplierActionContent()}</main>
            </div>
        </div>}
        <div className='componentsContainer'>
            <DashboardCard
                id='requestOverviewCard'
                size='large'
                header={true}
                headerTitle={supplierData?.accountName || 'Supplier Name'}
                caption={''}
                actionBtn={false}
                headerStatus={false}
                icon={false}
                content={supplierDetailsContent()}
                variant={'primary'}
                secondaryActionBtn={false}
                footer={false}
            />
        </div>
        <div className='componentsContainer' id='supplierActionCard'>
            <DashboardCard
                id='actions'
                size='small'
                header={true}
                headerTitle='Actions'
                content={
                    <>
                        <Upload title='Documents' uploadedDocuments={documents} questionnaire={false} readOnly={true}/>
                        <div className='overview-body' >
                            <p className='title sectionLabel'>Selection Questionnaire</p>
                            <div className='buttonContainer'>
                                <Button id='actionBtnS'
                                    size='small'
                                    variant='secondary'
                                    label='View'
                                    handleClick={() => history.push(`/supplier/view/sq/${supplierData.accountID}`)}
                                />
                            </div>
                        </div>
                    </>}
            />
            <DashboardCard
                id='departmentsCard'
                size='medium'
                header={true}
                headerTitle={Constants.departmentAndUsers}
                content={departmentDetailsContent()}
            />
        </div>
        <div className='componentsContainer' id='regionalOfficeOrHeadquartersCard'>
            <DashboardCard
                id='regionalOfficeOrHeadquartersCard'
                size='large'
                header={true}
                headerTitle={supplierData?.isHQ ? Constants.regionalOfficeTitle
                    : Constants.headquartersTitle}
                content={supplierData && supplierData?.isHQ ? regionalOfficeDetailsContent()
                    : headquarterDetailsContent()}
            />
        </div>
        {riskModal
        && <Form onSubmit={(e) => handleRiskSubmit(e)}><Modal
            id='riskStatusModal'
            headerTitle='Select Risk Status'
            open={riskModal}
            size='medium'
            body={generateRiskContent()}
            footer={true}
            handleMainActionBtnClick = {() => null}
            mainActionButtonTxt={'Confirm'}
            secondActionButton={true}
            handleSecondaryActionBtnClick={closeRiskModal}
            secondActionButtonTxt={'Cancel'}
            helpOption={false}
            closeModal={closeRiskModal}
        /></Form>}
        {/* {creditScoreModal
        && <Modal
            id='creditScoreModal'
            headerTitle='Credit Score'
            open={creditScoreModal}
            size='medium'
            body={generateCreditContent()}
            footer={true}
            handleMainActionBtnClick = {closeCreditModal}
            mainActionButtonTxt={'Close'}
            secondActionButton={false}
            helpOption={false}
            closeModal={closeCreditModal}
        />} */}
    </section>);
};

export default SupplierOverview;
