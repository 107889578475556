import React, {
    useEffect, useState, useContext, useRef,
} from 'react';
import { useHistory, useParams } from 'react-router-dom';

import SelectSearch from 'react-select-search';
import BrowseData from '../../BrowseData';
import Constants from './constants';
import AuthContext from '../../../context/AuthContext';
import LabelledRadioButton from '../../../components/LabelledRadioButton';
import LabelledTextArea from '../../../components/LabelledTextArea';
import LabelledInput, { LabelledInputTypeText } from '../../../components/LabelledInput';
import projectManagementAPIs from '../../../services/project-management.service';
import projectService from '../../../services/project.service';
import Toast from '../../../components/Alerts/Toast/Toast';
import ToastConstants from '../../../components/Alerts/Toast/constants';

import Upload from '../../Upload';
import {
    accountTypes,
    registerInterestStatuses,
    CorrespondenceVisibility,
    CorrespondenceVisibilityLabel,
    CorrespondenceTypeName,
    resourceTypes,
} from '../../../config/constants';
import Modal from '../../../components/Modal';
import { cleanupDeletedDocument, mergeUploadedDocuments } from '../../../utils/uploadHelper';
import isSupplierRole from '../../../utils/isSupplierRole';
import helperFunctions from '../../../utils/helperFunctions';
import CorrespondenceRecipients from '../CorrespondenceRecipients';


const BrowseCorrespondence = () => {
    const authContext = useContext(AuthContext);
    const history = useHistory();
    const { resourceType, resourceId } = useParams();
    const [correspondence, setCorrespondence] = useState([]);
    const [createModal, setCreateModal] = useState(false);
    const [values, setValues] = useState({});
    const [registeredSuppliers, setRegisteredSuppliers] = useState([]);
    const [successfulSuppliers, setSuccessfulSuppliers] = useState([]);
    const [topicList, setTopicList] = useState();
    const projectRef = useRef();
    const contractRef = useRef();

    const sortByUnreadMessages = { sort: Constants.sortOptions[1].value };

    const fetchProjectSuppliers = async () => {
        const response = await projectManagementAPIs.getProject(resourceId);
        if (response.status === 200) {
            projectRef.current = response.data;
            const registeredSuppliersInCurrentPublishEvent = projectService
                .getRegisteredSuppliersFromCurrentPublishEvent(projectRef.current);

            setValues({
                ...values,
                correspondenceType: CorrespondenceVisibility.specificSuppliers,
            });

            setRegisteredSuppliers(registeredSuppliersInCurrentPublishEvent.map((supplier) => ({
                name: supplier.account?.companyName,
                value: supplier.accountID,
            })));

            setSuccessfulSuppliers(registeredSuppliersInCurrentPublishEvent
                .filter(
                    (supplier) => supplier.status === registerInterestStatuses.approved,
                ));
        }
    };

    const getTopicRecipients = (recipients) => (recipients?.reduce((acc, recipient) => `${acc}${recipient.account.companyName} `, '') || '');

    const addKeywordsAttributeToTopics = (topics) => topics.map((topic) => ({
        ...topic,
        keywords: getTopicRecipients(topic.recipients) + topic.senderAccount?.companyName,
        senderCompany: topic.senderAccount?.companyName,
    }));

    const getSortOptions = () => {
        const { sortOptions, lastMessageSortOptions } = Constants;
        if (!isSupplierRole(authContext?.user?.accountType)) {
            return [...sortOptions, ...lastMessageSortOptions];
        }
        return sortOptions;
    };

    const fetchContractSuppliers = async () => {
        const response = await projectManagementAPIs.getContract(resourceId);
        if (response.status === 200) {
            contractRef.current = response.data;
            const { awardedSuppliers } = contractRef.current;

            if (Array.isArray(awardedSuppliers)) {
                if (response.data.publishLevel === Constants.LOT) {
                    const allSuppliers = awardedSuppliers
                        .reduce((acc, lot) => acc.concat(lot.suppliers), []);
                    const suppliersMap = allSuppliers
                        .reduce((acc, supplier) => ({
                            ...acc,
                            [supplier.accountID]: supplier,
                        }), {});
                    const uniqueSuppliers = Object.values(suppliersMap);

                    setRegisteredSuppliers(uniqueSuppliers.map((supplier) => ({
                        name: supplier.account?.companyName,
                        value: supplier.accountID,
                    })));
                } else {
                    setRegisteredSuppliers(awardedSuppliers.map((supplier) => ({
                        name: supplier.account?.companyName,
                        value: supplier.accountID,
                    })));
                }
            }
        }
    };

    const fetchCorrespondences = async () => {
        try {
            const response = await projectManagementAPIs.browseCorrespondence(
                resourceId, resourceType,
            );

            if (response.status === 200) {
                const topicListMap = {};
                response.data.items.forEach((topic) => {
                    topicListMap[topic.topicID] = topic;
                });
                setTopicList(topicListMap);
                const topicsUpdated = addKeywordsAttributeToTopics(response.data.items);
                setCorrespondence(topicsUpdated);
            } else {
                Toast.fire({
                    icon: 'error',
                    titleText: 'Unable to retrieve information.',
                });
            }
        } catch (error) {
            Toast.fire({
                icon: 'error',
                titleText: 'Unable to retrieve information.',
            });
        }
    };

    const closeCreateModal = () => {
        setValues({});
        setCreateModal(false);
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setValues({
            ...values,
            [name]: value,
        });
    };

    const handleThreadDocumentUpload = (uploadedDocs) => {
        const mergedDocs = mergeUploadedDocuments(values.documents, uploadedDocs);

        setValues({
            ...values,
            documents: mergedDocs,
        });
    };

    const handleThreadDocumentDelete = (deletedDoc) => {
        const mergedDocs = cleanupDeletedDocument(values.documents, deletedDoc);

        setValues({
            ...values,
            documents: mergedDocs,
        });
    };

    const generateCreateContent = () => <>
        { authContext.user?.accountType?.toLowerCase() !== accountTypes.supplier
        && registeredSuppliers.length > 0
            && <><LabelledRadioButton
                id='correspondenceType'
                label={CorrespondenceVisibilityLabel.title}
                breakColumn={false}
                onChange={(event) => handleChange(event)}
                options={[
                    {
                        label: CorrespondenceVisibilityLabel.specificSuppliers,
                        value: CorrespondenceVisibility.specificSuppliers,
                        id: CorrespondenceVisibility.specificSuppliers,
                        name: CorrespondenceTypeName,
                        checked: values.correspondenceType
                            === CorrespondenceVisibility.specificSuppliers,
                        required: true,
                    },
                    {
                        label: CorrespondenceVisibilityLabel.allRegisteredSuppliers,
                        value: CorrespondenceVisibility.allRegisteredSuppliers,
                        id: CorrespondenceVisibility.allRegisteredSuppliers,
                        name: CorrespondenceTypeName,
                        checked: values.correspondenceType
                            === CorrespondenceVisibility.allRegisteredSuppliers,
                        required: true,
                    },
                    {
                        label: CorrespondenceVisibilityLabel.publicCorrespondence,
                        value: CorrespondenceVisibility.publicCorrespondence,
                        id: CorrespondenceVisibility.publicCorrespondence,
                        name: CorrespondenceTypeName,
                        checked: values.correspondenceType
                            === CorrespondenceVisibility.publicCorrespondence,
                        required: true,
                    },
                ]}
                renderAsRow={true} />
            {values.correspondenceType === CorrespondenceVisibility.specificSuppliers
                && <div className = 'labelled-select'>
                    <label className='form-label title selectSearchTitle'>Select supplier(s)</label>
                    <SelectSearch
                        id='recipientAccountIDs'
                        multiple={true}
                        printOptions={'on-focus'}
                        closeOnSelect={false}
                        options={registeredSuppliers}
                        value={values.recipientAccountIDs || []}
                        onChange={(selectedValues) => {
                            setValues({
                                ...values,
                                recipientAccountIDs: selectedValues,
                            });
                        }}
                        placeholder='Select Supplier(s)'
                        z-index='4'
                        emptyMessage='No supplier(s) available'
                    />
                </div>}
            </>}
        {
            values.correspondenceType === CorrespondenceVisibility.allRegisteredSuppliers
            && <CorrespondenceRecipients recipients={successfulSuppliers} />
        }
        <LabelledInput id='title'
            type={LabelledInputTypeText} label='Subject'
            breakColumn={true} onChange={(e) => handleChange(e)}
            value={values.title || ''}
            required={true}
            placeholder='Enter subject' />
        <LabelledTextArea
            id='correspondenceText'
            type={LabelledInputTypeText}
            label='Message'
            breakColumn={true}
            onChange={(e) => handleChange(e)}
            value={values.correspondenceText || ''}
            currentCharCount={values.correspondenceText ? values.correspondenceText.length : 0}
            placeholder='Enter message'
            required={true}
            maxCharCount={6000}
        />
        <div id='buttonContainer'>
            <p className='title' id='uploadTitle'>Attachments</p>
            <Upload
                title={''}
                allowMultiple={true}
                uploadCallback={(data) => handleThreadDocumentUpload(data)}
                deleteCallback={(data) => handleThreadDocumentDelete(data)}
                uploadedDocuments={values.documents || []}
                readOnly={false}
            />
        </div>
    </>;

    const getRecipientAccountIds = (correspondenceType) => {
        if (isSupplierRole(authContext?.user?.accountType)) {
            return [];
        }

        const recipients = {
            publicCorrespondence: [],
            allRegisteredSuppliers: successfulSuppliers.map((item) => item.accountID),
            specificSuppliers: values.recipientAccountIDs,
        };

        return recipients[correspondenceType];
    };

    const handleSubmit = async () => {
        const payload = {
            subject: values.title,
            message: values.correspondenceText,
            recipientAccountIds: getRecipientAccountIds(values.correspondenceType),
            documents: values.documents,
        };

        const response = await projectManagementAPIs.createCorrespondenceTopic(
            resourceId, resourceType, payload,
        );

        if (response.status === 201) {
            Toast.fire({
                icon: 'success',
                titleText: 'Correspondence created successfully',
            });
            closeCreateModal();
            fetchCorrespondences();
        } else {
            Toast.fire({
                icon: 'error',
                titleText: 'Error creating the Correspondence',
            });
        }
    };

    const handleExport = async () => {
        const response = await projectManagementAPIs.exportCorrespondence(resourceId);
        if (response.status === 200) {
            try {
                helperFunctions.exportFunctionCSV(response);
                Toast.fire({
                    icon: ToastConstants.success,
                    title: Constants.topicsSuccessfullyDownloaded,
                });
            } catch (error) {
                Toast.fire({
                    icon: ToastConstants.error,
                    titleText: Constants.topicsError,
                });
            }
        } else {
            Toast.fire({
                icon: ToastConstants.error,
                titleText: Constants.topicsError,
            });
        }
    };

    const handleAddNew = () => {
        setValues({
            correspondenceType: CorrespondenceVisibility.specificSuppliers,
        });
        setCreateModal(true);
    };

    useEffect(() => {
        fetchCorrespondences();
    }, [resourceType, resourceId]);

    useEffect(() => {
        if (!resourceType || !authContext?.user?.accountType) {
            return;
        }
        if (createModal && !isSupplierRole(authContext.user.accountType)) {
            if (resourceType === resourceTypes.contract && !contractRef.current) {
                fetchContractSuppliers();
            }
            if (resourceType === resourceTypes.project && !projectRef.current) {
                fetchProjectSuppliers();
            }
        }
    }, [createModal]);


    return <><BrowseData
        id='browseCorrespondence'
        context='correspondence'
        contextSingular='Correspondence'
        dataSet={correspondence}
        searchPlaceholder='Search for correspondence'
        searchFilterAttribute='title'
        sortOptions={getSortOptions()}
        searchSortFilter={sortByUnreadMessages}
        allowAddNew = {true}
        labelAddNew='Add'
        handleAddNew={handleAddNew}
        allowFilter={false}
        allowBackButton={true}
        allowExportButton={!isSupplierRole(authContext?.user?.accountType)}
        handleBack={() => history.goBack()}
        exportFunction={handleExport}
        topicList={topicList}
    />;
    {createModal
        && <Modal
            id='createStatusModal'
            headerTitle='Create New Correspondence'
            open={createModal}
            size='medium'
            body={generateCreateContent()}
            footer={true}
            handleMainActionBtnClick = {() => handleSubmit()}
            mainActionButtonTxt={'Confirm'}
            secondActionButton={true}
            handleSecondaryActionBtnClick={closeCreateModal}
            secondActionButtonTxt={'Cancel'}
            helpOption={false}
            closeModal={closeCreateModal}
        />}
    </>;
};

export default BrowseCorrespondence;
