import constants from './constants';

const getFilterPropertiesByFieldName = (fieldName) => {
    switch (fieldName.toLowerCase()) {
        case constants.filterFields.STATUS:
            return {
                filterType: constants.filterTypes.SELECT,
                label: 'Status',
                stateKey: 'status',
                dynamicOptions: false,
            };
        case constants.filterFields.CONTRACT_STATUS:
            return {
                filterType: constants.filterTypes.SELECT,
                label: 'Status',
                stateKey: 'contractStatus',
                dynamicOptions: false,
            };
        case constants.filterFields.RIGOUR_LEVEL:
            return {
                filterType: constants.filterTypes.SELECT,
                label: 'Rigour Level',
                stateKey: 'rigour',
                dynamicOptions: false,
            };
        case constants.filterFields.PROJECT_STATUS:
            return {
                filterType: constants.filterTypes.SELECT,
                label: 'Status',
                stateKey: 'projectStatus',
                dynamicOptions: false,
            };
        case constants.filterFields.WORKFLOWTYPE:
            return {
                filterType: constants.filterTypes.SELECT,
                label: 'Workflow type',
                stateKey: 'workflowType',
                dynamicOptions: false,
            };
        case constants.filterFields.EVENTTYPE:
            return {
                filterType: constants.filterTypes.SELECT,
                label: 'Event type',
                stateKey: 'eventType',
                dynamicOptions: false,
            };
        case constants.filterFields.STAGETYPE:
            return {
                filterType: constants.filterTypes.SELECT,
                label: 'Stage type',
                stateKey: 'stageCategory',
                dynamicOptions: false,
            };
        case constants.filterFields.RTM:
            return {
                filterType: constants.filterTypes.SELECT,
                label: 'Route to Market',
                stateKey: 'route',
                dynamicOptions: false,
            };
        case constants.filterFields.DEPARTMENTS:
            return {
                filterType: constants.filterTypes.SELECT,
                label: 'Department',
                stateKey: 'departmentID',
                dynamicOptions: true,
            };
        case constants.filterFields.AUTHORITY:
            return {
                filterType: constants.filterTypes.SELECT,
                label: 'Authority',
                stateKey: 'accountID',
                dynamicOptions: true,
            };
        case constants.filterFields.CREATED_BETWEEN:
            return {
                filterType: constants.filterTypes.RANGE,
                label: 'Created between',
                stateKey: 'createdOn',
            };
        case constants.filterFields.PRFS_STARTING_BETWEEN:
            return {
                filterType: constants.filterTypes.RANGE,
                label: 'Requests starting between',
                stateKey: 'requestStartRange',
            };
        case constants.filterFields.CONTRACTS_STARTING_BETWEEN:
            return {
                filterType: constants.filterTypes.RANGE,
                label: 'Contracts starting between',
                stateKey: 'contractStart',
            };
        case constants.filterFields.CONTRACTS_ENDING_BETWEEN:
            return {
                filterType: constants.filterTypes.RANGE,
                label: 'Contracts ending between',
                stateKey: 'contractEnd',
            };
        case constants.filterFields.CODESANDCATEGORIES:
            return {
                filterType: constants.filterTypes.CODESANDCATEGORIES,
                label: 'Codes and categories',
            };
        case constants.filterFields.DELIVERYAREA:
            return {
                filterType: constants.filterTypes.DELIVERYAREA,
                label: 'Delivery area',
            };
        case constants.filterFields.LOCALITY:
            return {
                filterType: constants.filterTypes.LOCALITY,
                label: constants.filterLabels.LOCALITY,
            };
        case constants.filterFields.QUESTIONNAIRETYPE:
            return {
                filterType: constants.filterTypes.SELECT,
                label: 'Questionnaire type',
                stateKey: 'templateType',
                dynamicOptions: false,
            };
        case constants.filterFields.QUESTIONNAIRE_STATUS:
            return {
                filterType: constants.filterTypes.SELECT,
                label: 'Status',
                stateKey: 'templateStatus',
                dynamicOptions: false,
            };
        case constants.filterFields.COMPANY_TYPE:
            return {
                filterType: constants.filterTypes.SELECT,
                label: 'Company type',
                stateKey: 'companyType',
                dynamicOptions: false,
            };
        case constants.filterFields.EXPRESSION_WINDOW_BETWEEN:
            return {
                filterType: constants.filterTypes.RANGE,
                label: 'Expression window between',
                stateKey: 'expressionDate',
            };
        case constants.filterFields.SUBMISSION_WINDOW_BETWEEN:
            return {
                filterType: constants.filterTypes.RANGE,
                label: 'Submission window between',
                stateKey: 'submissionBetween',
            };
        case constants.filterFields.COMPANY_SIZE_BETWEEN:
            return {
                filterType: constants.filterTypes.INPUT_RANGE,
                label: 'Company size between',
                stateKeyFrom: 'noOfEmployeesFrom',
                stateKeyTo: 'noOfEmployeesTo',
            };
        case constants.filterFields.POSTCODE:
            return {
                filterType: constants.filterTypes.INPUT,
                label: 'Postcode',
                stateKey: 'postcode',
            };
        case constants.filterFields.ESTIMATED_VALUE_BETWEEN:
            return {
                filterType: constants.filterTypes.INPUT_RANGE,
                label: 'Estimated Value between',
                stateKeyFrom: 'estimatedValueFrom',
                stateKeyTo: 'estimatedValueTo',
            };
        default:
            return {};
    }
};

const getSelectOptionsByField = (fieldName) => {
    switch (fieldName.toLowerCase()) {
        case constants.filterFields.STATUS:
            return [{
                label: '',
                value: '',
            },
            {
                label: 'Disabled',
                value: 'false',
            },
            {
                label: 'Active',
                value: 'true',
            }];
        case constants.filterFields.CONTRACT_STATUS:
            return [{
                label: '',
                value: '',
            },
            {
                label: 'Cancelled',
                value: 'cancelled',
            },
            {
                label: 'Active',
                value: 'active',
            }];
        case constants.filterFields.QUESTIONNAIRE_STATUS:
            return [{
                label: '',
                value: '',
            },
            {
                label: 'Draft',
                value: 'draft',
            },
            {
                label: 'Created',
                value: 'created',
            }];
        case constants.filterFields.RIGOUR_LEVEL:
            return [{
                label: '',
                value: '',
            },
            {
                label: 'Platinum',
                value: 'platinum',
            },
            {
                label: 'Gold',
                value: 'gold',
            },
            {
                label: 'Silver',
                value: 'silver',
            }];
        case constants.filterFields.PROJECT_STATUS:
            return [{
                label: '',
                value: '',
            },
            {
                label: 'Created',
                value: 'created',
            },
            {
                label: 'Open',
                value: 'open',
            }, {
                label: 'Cancelled',
                value: 'cancelled',
            }];
        case constants.filterFields.WORKFLOWTYPE:
            return [{
                label: '',
                value: '',
            },
            {
                label: 'Project Management',
                value: 'Project Management',
            },
            {
                label: 'Contract Management',
                value: 'Contract Management',
            }];
        case constants.filterFields.EVENTTYPE:
            return [{
                label: '',
                value: '',
            }, {
                label: 'Calendar Event',
                value: 'calendar',
            }, {
                label: 'Document Event',
                value: 'document',
            }, {
                label: 'Publish Event',
                value: 'publish',
            }, {
                label: 'Notice Event',
                value: 'notice',
            }, {
                label: 'Evaluation Event',
                value: 'evaluation',
            }, {
                label: 'Issue Award/Unsuccessful Notices Event',
                value: 'issueAwardUnsuccessfulNotices',
            },
            {
                label: 'Supplier Documentation',
                value: 'supplierDocumentation',
            },
            {
                label: 'Market Understanding',
                value: 'marketUnderstanding',
            },
            {
                label: 'Demand Management',
                value: 'demandManagement',
            },
            {
                label: 'Cost Management',
                value: 'costManagement',
            },
            {
                label: 'Performance Monitoring & Supplier Relations',
                value: 'performanceMonitoringSupplierRelations',
            },
            {
                label: 'Risk Management',
                value: 'riskManagement',
            },
            {
                label: 'Update Contract Management Plan',
                value: 'updateContractManagementPlan',
            },
            {
                label: 'Exit Strategy',
                value: 'exitStrategy',
            },
            {
                label: 'Extension Event',
                value: 'extension',
            },
            {
                label: 'Variation Event',
                value: 'variation',
            },
            {
                label: 'Renew Contract Event',
                value: 'renewContract',
            },
            ];
        case constants.filterFields.STAGETYPE:
            return [{
                label: '',
                value: '',
            },
            {
                label: 'pre-procurement',
                value: 'pre-procurement',
            },
            {
                label: 'e-tendering',
                value: 'e-tendering',
            },
            {
                label: 'contract-management',
                value: 'contract-management',
            }];
        case constants.filterFields.RTM:
            return [{
                label: '',
                value: '',
            }, {
                label: 'Not Selected',
                value: '_',
            }, {
                label: 'Open Procedure',
                value: 'openProcedure',
            }, {
                label: 'Restricted Procedure',
                value: 'restrictedProcedure',
            }, {
                label: 'Competitive Procedure with Negotiation',
                value: 'CPN',
            }, {
                label: 'Competitive Procedure with Dialogue',
                value: 'CPD',
            }, {
                label: 'Innovation Partnership',
                value: 'innovationPartnership',
            }, {
                label: 'Request for Quotation',
                value: 'requestQuotation',
            }, {
                label: 'Request for Quotation - Clever Quotes',
                value: 'requestQuotationCleverQuotes',
            }, {
                label: 'Under Threshold',
                value: 'underThreshold',
            }, {
                label: 'Direct Award',
                value: 'directAward',
            }, {
                label: 'Established Solution',
                value: 'establishedSolution',
            }];
        case constants.filterFields.AUTHORITY:
            // TODO: Waiting API integration
            return [{
                label: '',
                value: '',
            },
            {
                label: 'Disabled',
                value: 'true',
            },
            {
                label: 'Active',
                value: 'false',
            }];
        case constants.filterFields.QUESTIONNAIRETYPE:
            return [{
                label: '',
                value: '',
            },
            {
                label: 'Selection Questionnaire',
                value: 'selection',
            },
            {
                label: 'Tender Questionnaire',
                value: 'tender',
            },
            {
                label: 'Award Questionnaire',
                value: 'award',
            },
            {
                label: 'Market Consultation Questionnaire',
                value: 'marketConsultation',
            },
            {
                label: 'Additional Questionnaire',
                value: 'additional',
            }];
        case constants.filterFields.COMPANY_TYPE:
            return [{
                label: '',
                value: '',
            },
            {
                label: 'Public Limited Company',
                value: 'Public Limited Company',
            },
            {
                label: 'Private Limited Company',
                value: 'Private Limited Company',
            },
            {
                label: 'Sole Trader',
                value: 'Sole Trader',
            },
            {
                label: 'Charity',
                value: 'Charity',
            },
            {
                label: 'Other',
                value: 'Other',
            }];
        default:
            return {};
    }
};

export default {
    getFilterPropertiesByFieldName,
    getSelectOptionsByField,
};
