import React, { useState } from 'react';
import propTypes from 'prop-types';
import DatePicker from 'react-date-picker';
import Modal from '../../components/Modal';
import LabelledInput, { LabelledInputTypeFile } from '../../components/LabelledInput';
import Form from '../../components/Form';
import UploadSVG from '../../styles/images/upload.svg';
import Button from '../../components/Button';
import UploadItemContainer from './UploadItemContainer';

const UploadModal = ({
    mode,
    documents,
    questionnaire,
    handleSubmit,
    handleRemoveDocument,
    handleDownload,
    handleMultipleDownload,
    closeModal,
    uploadIdentifier,
    readOnly = false,
    showExpiryDate = true,
    allowMultiple = true,
}) => {
    const [values, setValues] = useState({});

    const generateViewDocumentBody = () => (<UploadItemContainer
        documents={documents}
        handleRemoveDocument={handleRemoveDocument}
        handleDownload={handleDownload}
        readOnly={readOnly}
    />);

    // const handleChange = (event) => {
    //     const { name } = event.target;
    //     const { value } = event.target;
    //     setValues({
    //         ...values,
    //         [name]: value,
    //     });
    // };
    return <>
        {(mode === 'add')
            ? <Form id='duForm' onSubmit={(e) => handleSubmit(e, values)}>
                <Modal
                    open={true}
                    id='documentUpload'
                    size='medium'
                    headerTitle={`Upload ${uploadIdentifier}`}
                    mainActionButtonTxt='Upload'
                    handleMainActionBtnClick={
                        questionnaire ? (e) => handleSubmit(e, values) : () => null
                    }
                    secondActionButton
                    secondActionButtonTxt='Cancel'
                    handleSecondaryActionBtnClick={closeModal}
                    closeModal={closeModal}
                    helpOption={false}
                    body={<>
                        {/* <LabelledInput id='documentName'
                            type={LabelledInputTypeText} label='Document Name'
                            breakColumn={true} onChange={(e) => handleChange(e)}
                            value={values.documentName || ''}
                            placeholder='Enter Name'
                            pattern='^[a-zA-Z0-9]+$'
                            title='File name cannot contain whitespaces or special characters.'
                        />
                        <LabelledInput id='description'
                            type={LabelledInputTypeText} label='Document Description'
                            breakColumn={true} onChange={(e) => handleChange(e)}
                            value={values.description || ''}
                            placeholder='Enter Description'
                        /> */}
                        {showExpiryDate && <><label className='title label-margin datePickerLabel'>Expiry date (optional)</label><DatePicker className='customDateInput nmiDate-imput upload-expiry'
                            id='expiryDate'
                            dayPlaceholder='DD'
                            monthPlaceholder='MM'
                            yearPlaceholder='YYYY'
                            format={'dd-MM-y'}
                            calendarIcon={null}
                            onChange={(date) => setValues({
                                ...values, expiryDate: date,
                            })}
                            value={values.expiryDate || ''}
                            minDate={new Date()} /></>}
                        {values.filename ? <div className='uploadedFile'>
                            <p className='title-small'>{values.filename}</p>
                            <Button
                                id='removeDocument'
                                label='Remove'
                                size='small'
                                variant='primary'
                                handleClick={() => setValues({})}
                            />
                        </div>
                            : <div className='fileUpload'>
                                <img src={UploadSVG} alt='UploadSVG' />
                                <LabelledInput id='fileUpload'
                                    type={LabelledInputTypeFile}
                                    breakColumn={true}
                                    onChange={(e) => setValues({
                                        ...values, fileUpload: e.target.files,
                                    })}
                                    placeholder='Browse Files'
                                    multipleFiles={true}
                                />
                            </div>
                        }
                    </>}
                />
            </Form> : <Modal
                open={true}
                id='viewDocuments'
                size='medium'
                headerTitle={`View ${uploadIdentifier}`}
                secondActionButton={false}
                handleSecondaryActionBtnClick={closeModal}
                mainActionButton={true}
                mainActionButtonTxt='Download All'
                closeModal={closeModal}
                helpOption={false}
                body={generateViewDocumentBody()}
                footer={allowMultiple && documents.length > 1}
                handleMainActionBtnClick={(e) => {
                    e.preventDefault();
                    handleMultipleDownload(documents);
                }}
            />
        }
    </>;
};

UploadModal.propTypes = {
    mode: propTypes.string.isRequired,
    documents: propTypes.array.isRequired,
    questionnaire: propTypes.bool,
    handleRemoveDocument: propTypes.func,
    handleDownload: propTypes.func.isRequired,
    handleMultipleDownload: propTypes.func.isRequired,
    handleSubmit: propTypes.func.isRequired,
    closeModal: propTypes.func.isRequired,
    uploadIdentifier: propTypes.string,
    readOnly: propTypes.bool,
    showExpiryDate: propTypes.bool,
    allowMultiple: propTypes.bool,
};


export default UploadModal;
