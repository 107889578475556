import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import jsxGenerateData from '../../utils/jsxGenerateData';
import generateData from './components/generateJsxData';
import Button from '../../components/Button';
import editIcon from '../../styles/images/edit.png';
import AuthContext from '../../context/AuthContext';
import MyProfileForm from '../../features/MyProfileForm';
import MyProfileSupplierForm from '../../features/MyProfileSupplierForm';
import userManagementAPIs from '../../services/user-management.service';
import UserRoles from '../../features/Header/constants';
import Toast from '../../components/Alerts/Toast/Toast';
import Modal from '../../components/Modal';
import rbac from '../../rbac';

const MyProfile = () => {
    const [values, setValues] = useState({});
    const [workflowModal, setWorkflowModal] = useState(false);
    const [data, setData] = useState({});
    const [edit, setEdit] = useState(false);
    const history = useHistory();
    const authContext = useContext(AuthContext);

    const retrieveUserData = async () => {
        let jsxData;
        try {
            const accountObj = await userManagementAPIs.getUserDetails(authContext.user.id);
            setValues(accountObj.data);
            jsxData = generateData(accountObj.data);
        } catch (error) {
            Toast.fire({
                icon: 'error',
                titleText: 'Unable to retrieve information.',
            });
        }
        return jsxData;
    };
    // const handlePrivilegeChange = (privilegeData) => {
    //     setValues({
    //         ...values,
    //         specialAccountPrivileges: privilegeData,
    //     });
    // };

    const handleChange = (event) => {
        const { name } = event.target;
        let { value } = event.target;
        if (name === 'noOfEmployees') {
            value = parseInt(value, 10);
        }
        setValues({
            ...values,
            organisationDetails: {
                ...values.organisationDetails,
                [name]: value,
            },
        });
    };

    const handleContactChange = (event) => {
        event.stopPropagation();
        const { name } = event.target;
        const { value } = event.target;
        setValues({
            ...values,
            userDetails: {
                ...values.userDetails,
                contactDetails: {
                    ...values.userDetails.contactDetails,
                    [name]: value,
                },
            },
        });
    };

    const handleOrganisationChange = (event) => {
        const { name } = event.target;
        const { value } = event.target;
        setValues({
            ...values,
            organisationDetails: {
                ...values.organisationDetails,
                [name]: value,
            },
        });
    };

    const handleAddressChange = (event) => {
        const { name } = event.target;
        const { value } = event.target;
        setValues({
            ...values,
            organisationDetails: {
                ...values.organisationDetails,
                address: {
                    ...values.organisationDetails.address,
                    [name]: value,
                },
            },
        });
    };

    // const onRadioChange = (event) => {
    //     const { target } = event;
    //     setValues({
    //         ...values,
    //         preferences: { notificationLevel: target.value },
    //     });
    // };

    const generateEditActions = () => (edit ? null
        : <div className='editBtn'>
            <Button id='actionBtn'
                type='submit'
                variant='secondary'
                additionalVariant='skinless'
                icon
                iconSrc={editIcon}
                label='Edit'
                handleClick={() => setEdit(true)} />
        </div>);

    const createForm = async () => {
        const jsxData = await retrieveUserData();
        setData(jsxData);
    };

    const backBtn = () => <Button id='backBtn'
        type='submit'
        variant='secondary'
        label='Back'
        handleClick={() => history.goBack()}/>;

    const workflowBtn = () => <>
        {!edit && <div className='editBtn'>
            {rbac.can(rbac.action.setDefaultWorkflow, [
                authContext.user.accountType.toLowerCase() + authContext?.user?.role,
            ]) && <Button id='actionBtn'
                type='submit'
                variant='secondary'
                label='Default Workflows'
                handleClick={() => setWorkflowModal(true)} />}
        </div>}
    </>;

    // We need a workflow button to render regardless! Maybe some filtering?
    // Create bespoke items for each one.
    const generateModalBody = () => <>
        <div className='mpWorkflowItem'>
            <div className='mpHeader'>
                <p className='title-large'>Direct Award</p>
                <p className='caption'>{values?.organisationDetails?.dAWorkflow?.workflowName || 'No Workflow Selected'}</p>
            </div>
            <div className='mpButtonContainer'>
                {values?.organisationDetails?.dAWorkflow
                    ? <>
                        <Button
                            id='viewButton'
                            variant='tertiary'
                            label='View Workflow'
                            // size='small'
                            handleClick={() => history.push(`./workflows/view/${values?.organisationDetails?.dAWorkflow?.workflowID}`)}
                        />
                        {rbac.can(rbac.action.setDefaultWorkflow, [
                            authContext.user.accountType.toLowerCase() + authContext?.user?.role,
                        ]) && <Button
                            id='viewButton'
                            variant='tertiary'
                            label='Select Workflow'
                            // size='small'
                            handleClick={() => history.push(`./workflows/browse/_/_/${values?.organisationDetails?.accountID}/directAward`)}
                        />}
                    </>
                    : <>
                        {rbac.can(rbac.action.setDefaultWorkflow, [
                            authContext.user.accountType.toLowerCase() + authContext?.user?.role,
                        ]) && <Button
                            id='viewButton'
                            variant='tertiary'
                            label='Select Workflow'
                            // size='small'
                            handleClick={() => history.push(`./workflows/browse/_/_/${values?.organisationDetails?.accountID}/directAward`)}
                        />}
                    </>
                }
            </div>
        </div>
        <div className='mpWorkflowItem'>
            <div className='mpHeader'>
                <p className='title-large'>Further Competition</p>
                <p className='caption'>{values?.organisationDetails?.fCWorkflow?.workflowName || 'No Workflow Selected'}</p>
            </div>
            <div className='mpButtonContainer'>
                {values?.organisationDetails?.fCWorkflow
                    ? <>
                        <Button
                            id='viewButton'
                            variant='tertiary'
                            label='View Workflow'
                            // size='small'
                            handleClick={() => history.push(`./workflows/view/${values?.organisationDetails?.fCWorkflow?.workflowID}`)}
                        />
                        {rbac.can(rbac.action.setDefaultWorkflow, [
                            authContext.user.accountType.toLowerCase() + authContext?.user?.role,
                        ]) && <Button
                            id='viewButton'
                            variant='tertiary'
                            label='Select Workflow'
                            // size='small'
                            handleClick={() => history.push(`./workflows/browse/_/_/${values?.organisationDetails?.accountID}/furtherComp`)}
                        />}
                    </>
                    : <>
                        {rbac.can(rbac.action.setDefaultWorkflow, [
                            authContext.user.accountType.toLowerCase() + authContext?.user?.role,
                        ]) && <Button
                            id='viewButton'
                            variant='tertiary'
                            label='Select Workflow'
                            // size='small'
                            handleClick={() => history.push(`./workflows/browse/_/_/${values?.organisationDetails?.accountID}/furtherComp`)}
                        />}
                    </>
                }
            </div>
        </div>
    </>;

    useEffect(() => {
        if (authContext.authenticated) {
            createForm();
        }
    }, [edit]);

    return <section id='supplierProfileData'>

        {data && Object.keys(data).length > 0 && jsxGenerateData('supplierProfile', data, null, null, edit, generateEditActions,
            values?.organisationDetails?.accountType?.toLowerCase() === UserRoles.SUPPLIER_ROLE
                ? <MyProfileSupplierForm
                    values={values}
                    handleCancel={() => setEdit(false)}
                    setValues={setValues}
                    handleChange={(e) => handleChange(e)}
                    handleContactChange={(e) => handleContactChange(e)}
                    // onRadioChange={(e) => onRadioChange(e)}
                    handleAddressChange={(e) => handleAddressChange(e)}
                />
                : <MyProfileForm
                    values={values}
                    handleCancel={() => setEdit(false)}
                    setValues={setValues}
                    handleChange={(e) => handleChange(e)}
                    // handlePrivilegeChange={handlePrivilegeChange}
                    handleContactChange={(e) => handleContactChange(e)}
                    handleOrganisationChange={(e) => handleOrganisationChange(e)}
                    // onRadioChange={(e) => onRadioChange(e)}
                    handleAddressChange={(e) => handleAddressChange(e)}
                />, false,
            values.organisationDetails.accountType.toLowerCase() !== UserRoles.SUPPLIER_ROLE
                ? workflowBtn : null,
            backBtn)
        }

        {workflowModal && <Modal
            id='workflowModal'
            headerTitle='Default Workflows'
            closeModal={() => setWorkflowModal(false)}
            body={generateModalBody()}
            footer={false}
            size='small'

        />}
    </section>;
};

export default MyProfile;
