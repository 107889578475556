import React, { useContext, useState } from 'react';
import PropTypes, { string } from 'prop-types';
import { Link } from 'react-router-dom';
// import { format } from 'date-fns';
import ScreenOverlay from '../../components/ScreenOverlay';
import SideDrawer from '../../components/SideDrawer';
import Button from '../../components/Button';
import closeIcon from '../../styles/images/closeButton.svg';
import userManagementAPIs from '../../services/user-management.service';
import Upload from '../Upload';
import { cleanupDeletedDocument, mergeUploadedDocuments, persistUploadedDocuments } from '../../utils/uploadHelper';
import helperFunctions from '../../utils/helperFunctions';
import AuthContext from '../../context/AuthContext';
import { accountTypes } from '../../config/constants';

// const onlineEventList = ['rebate', 'questionnaire', 'evaluation',
//     'e-form', 'issue award', 'unsuccessful notice', 'publish notice'];

// const onlineEventCheck = (event) => {
//     if (event) {
//         const check = event.toLowerCase();
//         return onlineEventList.includes(check);
//     }
//     return false;
// };

// const hasEventStarted = (eventStatus) => {
//     if (eventStatus && eventStatus === 'IN-PROGRESS') {
//         return true;
//     }

//     return false;
// };

// const isStageOne = (eventStatus) => {
//     if (eventStatus && eventStatus === 'STAGE1APPROVAL') {
//         return true;
//     }

//     return false;
// };

// const isStageTwo = (eventStatus) => {
//     if (eventStatus && eventStatus === 'STAGE2APPROVAL') {
//         return true;
//     }

//     return false;
// };

// const isEventCompleted = (eventStatus) => {
//     if (eventStatus && eventStatus === 'COMPLETED') {
//         return true;
//     }

//     return false;
// };


const checkIfCreator = (creator, userID) => {
    if (creator && creator === userID) {
        return true;
    }
    return false;
};

const formatAttendees = (attendees) => {
    string = '';
    attendees.forEach((attendee) => {
        string += attendee;
    });
    return string;
};


const ViewEventPanel = ({
    open,
    id,
    userID,
    // viewButton,
    viewEventData,
    closePanel,
    // footerBtnsDisabled,
    handleViewWorknotes,
    handleWorknotes,
}) => {
    const [passAttendees, changeAttendees] = useState([]);
    const [attendeeList, updateAttendeeList] = useState([]);

    const authContext = useContext(AuthContext);

    const getAttendees = async (attendees) => {
        if (attendees && (attendees !== attendeeList)) {
            const requests = [];
            const userList = [];
            const nameEnder = ', ';
            updateAttendeeList(attendees);
            attendees.forEach((user) => {
                requests.push(userManagementAPIs.getUserDetails(user));
            });
            Promise.all(requests).then((response) => {
                response.forEach((call) => {
                    if (call.data.contactDetails) {
                        userList.push(call.data.contactDetails.firstname.concat(' ', call.data.contactDetails.surname.concat(nameEnder)));
                    }
                });
            }).then(() => {
                changeAttendees(userList);
            });
        }
        return [];
    };
    getAttendees(viewEventData.attendees);

    // const completeEvent = async (event) => {
    //     event.preventDefault();
    //     const updatedEvent = {};
    //     if (viewEventData.stage1Approval) {
    //         updatedEvent.eventStatus = 'STAGE1APPROVAL';
    //         updatedEvent.completedDate = '';
    //     } else {
    //         updatedEvent.eventStatus = 'COMPLETED';
    //         updatedEvent.completedDate = format(new Date(), 'dd-MM-yyyy');
    //     }

    //     const updateRequest = {
    //         ...viewEventData,
    //         eventStatus: updatedEvent.eventStatus,
    //         completedDate: updatedEvent.completedDate,
    //     };

    //     const response = await userManagementAPIs.updateEvent(updateRequest);
    //     if (response.status === 200) {
    //         setTimeout(closePanel(), 5000);
    //     } else {
    //         alert('Unable to update event');
    //     }
    // };

    // const rejectEvent = async (event) => {
    //     event.preventDefault();
    //     const updateRequest = {
    //         ...viewEventData,
    //         eventStatus: 'IN-PROGRESS',
    //         completedDate: '',
    //     };
    //     const response = await userManagementAPIs.updateEvent(updateRequest);
    //     if (response.status === 200) {
    //         setTimeout(closePanel(), 5000);
    //     } else {
    //         alert('Unable to update event');
    //     }
    // };

    // const approveEvent = async (event) => {
    //     event.preventDefault();
    //     const status = viewEventData.eventStatus;
    //     const updatedEvent = {};
    //     if (status === 'STAGE1APPROVAL' && viewEventData.stage2Approval) {
    //         updatedEvent.eventStatus = 'STAGE2APPROVAL';
    //         updatedEvent.completedDate = '';
    //         alert('Stage 1 Approved');
    //     } else if (status === 'STAGE1APPROVAL' && !viewEventData.stage2Approval) {
    //         updatedEvent.eventStatus = 'COMPLETED';
    //         updatedEvent.completedDate = format(new Date(), 'dd-MM-yyyy');
    //         alert('Stage 1 Event Completed');
    //     }

    //     if (status === 'STAGE2APPROVAL') {
    //         updatedEvent.eventStatus = 'COMPLETED';
    //         updatedEvent.completedDate = format(new Date(), 'dd-MM-yyyy');
    //         alert('Stage 2 Event Completed');
    //     }
    //     const updateRequest = {
    //         ...viewEventData,
    //         eventStatus: updatedEvent.eventStatus,
    //         completedDate: updatedEvent.completedDate,
    //     };
    //     const response = await userManagementAPIs.updateEvent(updateRequest);
    //     if (response.status === 200) {
    //         setTimeout(closePanel(), 5000);
    //     } else {
    //         alert('Unable to update event');
    //     }
    // };

    // const cancelApproveEvent = async (event) => {
    //     event.preventDefault();
    //     const updatedEvent = {};
    //     updatedEvent.eventStatus = 'IN-PROGRESS';
    //     updatedEvent.completedDate = '';
    //     const updateRequest = {
    //         ...viewEventData,
    //         eventStatus: updatedEvent.eventStatus,
    //         completedDate: updatedEvent.completedDate,
    //     };
    //     const response = await userManagementAPIs.updateEvent(updateRequest);
    //     if (response.status === 200) {
    //         setTimeout(closePanel(), 5000);
    //     } else {
    //         alert('Unable to update event');
    //     }
    // };

    const uploadDocument = async (docs) => {
        const mergedDocs = mergeUploadedDocuments(viewEventData.documents, docs);

        const updateRequest = {
            ...viewEventData,
            documents: mergedDocs,
        };

        await persistUploadedDocuments('upload', () => userManagementAPIs.updateEvent(updateRequest));
    };

    const deleteDocument = async (doc) => {
        const mergedDocs = cleanupDeletedDocument(viewEventData.documents, doc);

        const updateRequest = {
            ...viewEventData,
            documents: mergedDocs,
        };

        await persistUploadedDocuments('delete', () => userManagementAPIs.updateEvent(updateRequest));
    };

    const getEventDetails = (type) => {
        let details = null;
        switch (type) {
            case 'CONTRACT':
                // eslint-disable-next-line no-case-declarations
                const contractId = viewEventData.context.filter((attr) => attr.contextType === 'contractID')[0].contextID;
                details = <>
                    <label className='title' id='vepContract'>More Info:</label>
                    <Link key={'contractLink'} className='caption' to={`${authContext.user?.accountType?.toLowerCase() === accountTypes.supplier
                        ? `/contracts/overview/${contractId}` : `/contracts/management/${contractId}` }`}>
                        {viewEventData.name}
                    </Link>
                </>;
                break;
            case 'PROJECT':
                // eslint-disable-next-line no-case-declarations
                const projectId = viewEventData.context.filter((attr) => attr.contextType === 'projectID')[0].contextID;
                details = <>
                    {authContext.user?.accountType?.toLowerCase() !== accountTypes.supplier
                    && <><label className='title' id='vepProject'>More Info:</label><Link key={'projectLink'} className='caption' to={`/projects/overview/${projectId}`}>
                        {viewEventData.name}
                    </Link></>}</>;
                break;
            default:
                details = <></>;
        }
        return details;
    };

    const getPrettyType = (_type) => {
        if (_type) return helperFunctions.camel2title(_type);
        return '';
    };

    return (<>
        { open && <ScreenOverlay handleClick={closePanel} /> }
        <SideDrawer open={open}
            id={id}
            openDirection='right'
            tabletsAndMobileOnly={false}>
            <header className='viewEventPanelHeader'>
                <p className='title-large'> {viewEventData.type ? `${getPrettyType(viewEventData.type.toLowerCase())} Details` : 'Details'}</p>
                <button
                    type='button'
                    className='closeBtn'
                    id={`close-${id}`}
                    onClick={closePanel}>
                    <img src={closeIcon} className='closeBtn-img'
                        alt='modal-close-button'></img>
                </button>
            </header>
            <main className='vepBody'>
                <div className='viewEventPanelTitle'>
                    <label className='title' id='vepTitle'>{viewEventData.title}</label>
                    {/* { footerBtnsDisabled
                        || <div className='viewEventPending title-small'>Pending</div> } */}
                </div>
                <p className='caption-small' id='vepDetails'>{viewEventData.description}</p>
                {/* <div className='viewEventPanelContent'>
                    <label className='title' id='vepStart'>Event Type:</label>
                    <p className='caption'>{viewEventData.eventType}</p>
                </div> */}
                <div className='viewEventPanelContent'>
                    <label className='title' id='vepStart'>Start Date:</label>
                    <p className='caption'>{viewEventData.startDate && viewEventData.startDate}</p>
                </div>
                {viewEventData.endDate && viewEventData.endDate !== 'Invalid Date'
                && <div className='viewEventPanelContent'>
                    <label className='title' id='vepEnd'>End Date:</label>
                    <p className='caption'>{viewEventData.endDate}</p>
                </div>
                }
                <div className='viewEventPanelContent'>
                    { // viewEventData.contractTitle
                        getEventDetails(viewEventData.type)
                    }
                    {/* <a className='caption'>
                        {viewEventData.contractTitle
                            ? viewEventData.contractTitle
                            : viewEventData.projectTitle}
                    </a> */}
                </div>
                {checkIfCreator(viewEventData.creator, userID) && <div>
                    <div className='viewEventPanelContent'>
                        {<label className='title' id='vepProject'>Attendees:</label>}
                        <p className='caption' id='attendees'>{formatAttendees(passAttendees)}</p>
                    </div>
                </div>}
                {/* {onlineEventCheck(viewEventData.eventType)
                && <div>
                    <div className='viewEventPanelDivider'></div>
                    {(viewEventData.eventType !== 'Rebate') && <div>
                        <div className='viewEventPanelContent'>
                            <label className='title'>View in Open</label>
                        </div>
                        <div className='viewEventPanelButtonGroup'>
                            <Button className='vePanelBtn'
                                id={`${id}-viewAdd`}
                                variant='secondary'
                                label='View'
                                handleClick={viewButton}
                            />
                        </div>
                    </div>}
                    {(viewEventData.eventType === 'Rebate') && <div>
                        <div className='viewEventPanelContent'>
                            <label className='title'>Rebate e-form</label>
                        </div>
                        <div className='viewEventPanelButtonGroup'>
                            <Button className='vePanelBtn'
                                id={`${id}-viewAdd`}
                                variant='primary'
                                label='View'
                                handleClick={toggleRebateModal}
                            />
                        </div>
                    </div>}
                </div>} */}
                {viewEventData.eventType === 'Meeting' && <div>
                    <div className='viewEventPanelDivider'></div>
                    <div className='viewEventPanelContent'>
                        <label className='title'>Worknotes</label>
                    </div>
                    <p className='caption' id='noOfWorknotes'>{ viewEventData.worknotes ? viewEventData.worknotes.length : 0 } worknotes added.</p>
                    <div className='viewEventPanelButtonGroup'>
                        <Button className='vePanelBtn'
                            id={`${id}-viewAdd`}
                            variant='secondary'
                            label='View'
                            handleClick={handleViewWorknotes}
                        />
                        <Button className='vePanelBtn'
                            id={`${id}-Add`}
                            variant='primary'
                            label='Add'
                            handleClick={handleWorknotes}
                        />
                    </div>
                    <div className='viewEventPanelDivider'></div>
                    <Upload
                        title={'Documents'}
                        uploadedDocuments={viewEventData.documents}
                        allowMultiple={true}
                        uploadCallback={uploadDocument}
                        deleteCallback={deleteDocument}
                    />
                </div>}
            </main>
            {/* <footer className='viewEventPanelFooter'>
                {hasEventStarted(viewEventData.eventStatus) && <Button className='completeEventBtn'
                    id={`${id}-completeEvent`}
                    variant={ 'primary' }
                    label={ 'Complete Event' }
                    handleClick={(e) => completeEvent(e)}
                />}
                {(isStageOne(viewEventData.eventStatus) || isStageTwo(viewEventData.eventStatus))
            && <div id={`${id}-checkApproveContainer`}>
                <Button className='rejectEventBtn'
                    id={`${id}-rejectEvent`}
                    variant={ 'secondary' }
                    label={ 'Reject' }
                    handleClick={(e) => rejectEvent(e)}
                    disabled={footerBtnsDisabled} />
                <Button className='approveEventBtn'
                    id={`${id}-approveEvent`}
                    variant={ 'primary' }
                    label={ 'Approve' }
                    handleClick={(e) => approveEvent(e)}
                    disabled={footerBtnsDisabled} />
            </div>}
                {isEventCompleted(viewEventData.eventStatus) && <Button className='approveEventBtn'
                    id={`${id}-approved`}
                    variant={ 'disabled'}
                    label={'Cancel Approval' }
                    handleClick={(e) => cancelApproveEvent(e)}
                />}
            </footer> */}
        </SideDrawer>
    </>);
};

ViewEventPanel.propTypes = {
    open: PropTypes.bool.isRequired,
    id: PropTypes.string.isRequired,
    userID: PropTypes.string,
    // viewButton: PropTypes.func.isRequired,
    viewEventData: PropTypes.object.isRequired,
    closePanel: PropTypes.func.isRequired,
    footerBtnsDisabled: PropTypes.bool.isRequired,
    handleViewWorknotes: PropTypes.func.isRequired,
    handleWorknotes: PropTypes.func.isRequired,
    handleCompleteEvent: PropTypes.func.isRequired,
};

export default ViewEventPanel;
