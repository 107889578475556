import instance from '../config/axios.instance';
import endpoints from '../config/endpoints';

const { projectManagement } = endpoints;

const projectManagementAPIs = {

    getProject: async (projectId) => {
        try {
            const endpoint = projectManagement.project;
            const response = await instance.get(`${endpoint}/overview/${projectId}`);
            return response;
        } catch (e) {
            return e.response || e;
        }
    },

    getOpportunity: async (opportunityId) => {
        try {
            const endpoint = projectManagement.opportunity;
            const response = await instance.get(`${endpoint}/overview/${opportunityId}`);
            return response;
        } catch (e) {
            return e.response || e;
        }
    },

    getPublicOpportunity: async (opportunityId) => {
        try {
            const endpoint = projectManagement.opportunity;
            const response = await instance.get(`${endpoint}/overview-public/${opportunityId}`);
            return response;
        } catch (e) {
            return e.response || e;
        }
    },

    unpublishOpportunity: async (projectId, eventId) => {
        try {
            const endpoint = projectManagement.opportunity;
            const response = await instance.put(`${endpoint}/manage/unpublish/${projectId}/${eventId}`);
            return response;
        } catch (e) {
            return e.response || e;
        }
    },

    getRenewal: async (projectId) => {
        try {
            const endpoint = projectManagement.getProject;
            const response = await instance.get(`${endpoint}/${projectId}/true`);
            return response;
        } catch (e) {
            return e.response || e;
        }
    },

    getCallOff: async (projectId, lotId) => {
        try {
            const endpoint = projectManagement.getProject;
            const response = await instance.get(`${endpoint}/${projectId}/true/${lotId}`);
            return response;
        } catch (e) {
            return e.response || e;
        }
    },

    getLotViaSolution: async (solutionID, lotID) => {
        try {
            const endpoint = projectManagement.solution;
            const response = await instance.get(`${endpoint}/view/lot/${solutionID}/${lotID}`);
            return response;
        } catch (e) {
            return e.response || e;
        }
    },

    getLotDetails: async (lotID) => {
        try {
            const endpoint = projectManagement.lot;
            const response = await instance.get(`${endpoint}/${lotID}`);
            return response;
        } catch (e) {
            return e.response || e;
        }
    },

    getPublicLotDetails: async (lotID) => {
        try {
            const endpoint = projectManagement.lot;
            const response = await instance.get(`${endpoint}/public/${lotID}`);
            return response;
        } catch (e) {
            return e.response || e;
        }
    },

    getPublicProject: async (projectId) => {
        try {
            const endpoint = projectManagement.getPublicProject;
            const response = instance.get(`${endpoint}/${projectId}`);
            return response;
        } catch (e) {
            return e.response || e;
        }
    },

    getPublicProjects: async () => {
        try {
            const endpoint = projectManagement.getPublicProjects;
            const response = instance.get(endpoint);
            return response;
        } catch (e) {
            return e.response || e;
        }
    },

    createProject: async (payLoad) => {
        try {
            const endpoint = projectManagement.createProjectRequest;
            const response = await instance.post(endpoint, payLoad);
            return response;
        } catch (e) {
            return e.response || e;
        }
    },

    cancelProject: async (projectID, payload) => {
        try {
            const endpoint = projectManagement.cancelProject;
            const response = await instance.put(`${endpoint}/${projectID}`, payload);
            return response;
        } catch (e) {
            return e.response || e;
        }
    },

    getAllByAccount: async (accountId) => {
        try {
            const endpoint = projectManagement.getAll;
            const response = await instance.get(`${endpoint}/${accountId}`);
            return response;
        } catch (e) {
            return e.response || e;
        }
    },

    getUserOpportunities: async (accountId) => {
        try {
            const endpoint = projectManagement.getAll;
            const response = await instance.get(`${endpoint}/interest/${accountId}`);
            return response;
        } catch (e) {
            return e.response || e;
        }
    },

    getProjectByState: async (projectState) => {
        try {
            const endpoint = projectManagement.getAllState;
            const response = await instance.get(`${endpoint}/${projectState}`);
            return response;
        } catch (e) {
            return e.response || e;
        }
    },

    getSuggestedOpportunities: async (body) => {
        try {
            const endpoint = projectManagement.getSuggestedOpportunities;
            const response = instance.get(endpoint, {
                params: {
                    ...(body.code ? { codes: body.code.join(',') } : {}),
                    ...(body.region ? { regions: body.region.join(',') } : {}),
                },
            });
            return response;
        } catch (e) {
            return e.response || e;
        }
    },

    getInterest: async (projectID, eventID) => {
        try {
            const endpoint = projectManagement.interest;
            const response = await instance.get(`${endpoint}/summary/${projectID}/${eventID}`);
            return response;
        } catch (e) {
            return e.response || e;
        }
    },

    getContracts: async (accountId) => {
        try {
            const endpoint = projectManagement.contract;
            const response = await instance.get(`${endpoint}/all/${accountId}`);
            return response;
        } catch (e) {
            return e.response || e;
        }
    },

    listContracts: async (tabSelected) => {
        try {
            const endpoint = `${projectManagement.contract}`;
            const response = await instance.get(`${endpoint}/browse/all/${tabSelected}`);
            return response;
        } catch (e) {
            return e.response || e;
        }
    },

    getContract: async (contractID) => {
        try {
            const endpoint = projectManagement.contract;
            const response = await instance.get(`${endpoint}/overview/${contractID}`);
            return response;
        } catch (e) {
            return e.response || e;
        }
    },

    manageContract: async (contractId) => {
        try {
            const endpoint = projectManagement.contract;
            const response = await instance.get(`${endpoint}/supplier/overview/${contractId}`);
            return response;
        } catch (e) {
            return e.response || e;
        }
    },

    getPublicContract: async (contractId) => {
        try {
            const endpoint = projectManagement.contract;
            const response = await instance.get(`${endpoint}/get/public/${contractId}`);
            return response;
        } catch (e) {
            return e.response || e;
        }
    },

    getPublicContracts: async () => {
        try {
            const endpoint = projectManagement.contract;
            const response = await instance.get(`${endpoint}/all/public/`);
            return response;
        } catch (e) {
            return e.response || e;
        }
    },

    createRenewedProject: async (contractID, payload) => {
        try {
            const endpoint = projectManagement.contract;
            const response = await instance.post(`${endpoint}/renewal/${contractID}`, payload);
            return response;
        } catch (e) {
            return e.response || e;
        }
    },

    exportPublicContracts: async () => {
        try {
            const endpoint = projectManagement.contract;
            const response = await instance.get(`${endpoint}/public/export`);
            return response;
        } catch (e) {
            return e.response || e;
        }
    },

    updateProject: async (projectId, payLoad) => {
        try {
            const endpoint = projectManagement.updateProject;
            const response = await instance.put(`${endpoint}/${projectId}`, payLoad);
            return response;
        } catch (e) {
            return e.response || e;
        }
    },

    registerInterest: async (opportunityID) => {
        try {
            const endpoint = projectManagement.interest;
            const response = await instance.put(`${endpoint}/${opportunityID}`);
            return response;
        } catch (e) {
            return e.response || e;
        }
    },

    registerSupplier: async (opportunityID, accountID, accountName) => {
        try {
            const endpoint = projectManagement.interest;
            const body = { accountID, accountName };
            const response = await instance.put(`${endpoint}/${opportunityID}`, body);
            return response;
        } catch (e) {
            return e.response || e;
        }
    },

    registerSupplierDirectAward: async (
        projectID, awardEventID, accountID, accountName, lotID = null,
    ) => {
        try {
            const endpoint = projectManagement.interestDirectAward;
            const body = { accountID, accountName };
            let url = `${endpoint}/${projectID}/${awardEventID}`;

            if (lotID) {
                url = `${url}/${lotID}`;
            }
            const response = await instance.put(url, body);
            return response;
        } catch (e) {
            return e.response || e;
        }
    },

    withdrawInterest: async (opportunityID, payload) => {
        try {
            const endpoint = projectManagement.interest;
            const response = await instance.put(`${endpoint}/withdraw/${opportunityID}`, payload);
            return response;
        } catch (e) {
            return e.response || e;
        }
    },

    updateInterest: async (projectId, payLoad) => {
        try {
            const endpoint = projectManagement.updateInterest;
            const response = await instance.put(`${endpoint}/${projectId}`, payLoad);
            return response;
        } catch (e) {
            return e.response || e;
        }
    },

    createFAQ: async (projectId, payLoad) => {
        try {
            const endpoint = projectManagement.project;
            const response = await instance.put(`${endpoint}/create/FAQ/${projectId}`, payLoad);
            return response;
        } catch (e) {
            return e.response || e;
        }
    },

    updateFAQ: async (projectId, faqID, payLoad) => {
        try {
            const endpoint = projectManagement.project;
            const response = await instance.put(`${endpoint}/update/FAQ/${projectId}/${faqID}`, payLoad);
            return response;
        } catch (e) {
            return e.response || e;
        }
    },

    updateLot: async (lotID, payLoad) => {
        try {
            const endpoint = projectManagement.lot;
            const response = await instance.put(`${endpoint}/${lotID}`, payLoad);
            return response;
        } catch (e) {
            return e.response || e;
        }
    },

    assignQuestionnaire: async (projectId, eventId, payLoad) => {
        try {
            const endpoint = projectManagement.questionnaire;
            const response = await instance.post(`${endpoint}/assign/${projectId}/${eventId}`, payLoad);
            return response;
        } catch (e) {
            return e.response || e;
        }
    },

    getAssignedQuestionnaire: async (projectId, eventId) => {
        try {
            const endpoint = projectManagement.questionnaire;
            const response = await instance.get(`${endpoint}/get/${projectId}/${eventId}`);
            return response;
        } catch (e) {
            return e.response || e;
        }
    },

    editAssignedQuestionnaire: async (responseID, payload) => {
        try {
            const endpoint = projectManagement.questionnaire;
            const response = await instance.put(`${endpoint}/update/${responseID}`, payload);
            return response;
        } catch (e) {
            return e.response || e;
        }
    },

    editAssignedQuestionnaireLots: async (responseID, payload) => {
        try {
            const endpoint = projectManagement.questionnaire;
            const response = await instance.put(`${endpoint}/lots/update/${responseID}`, payload);
            return response;
        } catch (e) {
            return e.response || e;
        }
    },

    removeAssignedQuestionnaire: async (responseID, payload) => {
        try {
            const endpoint = projectManagement.questionnaire;
            const response = await instance.delete(`${endpoint}/delete/${responseID}`, payload);
            return response;
        } catch (e) {
            return e.response || e;
        }
    },

    updateEvent: async (projectId, eventID, payLoad) => {
        try {
            const endpoint = projectManagement.getWorkflow;
            const response = await instance.put(`${endpoint}/update/event/status/${projectId}/${eventID}`, payLoad);
            return response;
        } catch (e) {
            return e.response || e;
        }
    },

    updateCompleteEventContract: async (projectId, eventID, payLoad) => {
        try {
            const endpoint = projectManagement.getWorkflow;
            const response = await instance.put(`${endpoint}/update/contract/event/status/${projectId}/${eventID}`, payLoad);
            return response;
        } catch (e) {
            return e.response || e;
        }
    },

    escalateEvent: async (projectId, eventID, workflowId, payLoad) => {
        try {
            const endpoint = projectManagement.getWorkflow;
            const response = await instance.put(`${endpoint}/update/event/escalate/${projectId}/${eventID}/${workflowId}`, payLoad);
            return response;
        } catch (e) {
            return e.response || e;
        }
    },

    uploadEventDocument: async (projectId, eventID, payLoad) => {
        try {
            const endpoint = projectManagement.getWorkflow;
            const response = await instance.put(`${endpoint}/update/event/documents/${projectId}/${eventID}`, payLoad);
            return response;
        } catch (e) {
            return e.response || e;
        }
    },

    deleteEventDocument: async (projectId, eventID, documentId) => {
        try {
            const endpoint = projectManagement.getWorkflow;
            const response = await instance.put(`${endpoint}/update/event/documents/remove/${projectId}/${eventID}/${documentId}`);
            return response;
        } catch (e) {
            return e.response || e;
        }
    },

    addEventWorknote: async (projectId, eventID, payLoad) => {
        try {
            const endpoint = projectManagement.getWorkflow;
            const response = await instance.put(`${endpoint}/update/event/worknote/${projectId}/${eventID}`, payLoad);
            return response;
        } catch (e) {
            return e.response || e;
        }
    },

    uploadProjectDocument: async (projectId, payLoad) => {
        try {
            const endpoint = projectManagement.project;
            const response = await instance.put(`${endpoint}/document/upload/${projectId}`, payLoad);
            return response;
        } catch (e) {
            return e.response || e;
        }
    },

    deleteProjectDocument: async (projectId, documentId) => {
        try {
            const endpoint = projectManagement.project;
            const response = await instance.put(`${endpoint}/document/delete/${projectId}/${documentId}`);
            return response;
        } catch (e) {
            return e.response || e;
        }
    },

    addProjectWorknote: async (projectId, payLoad) => {
        try {
            const endpoint = projectManagement.project;
            const response = await instance.put(`${endpoint}/worknote/${projectId}`, payLoad);
            return response;
        } catch (e) {
            return e.response || e;
        }
    },

    createContract: async (payload, projectId) => {
        try {
            const endpoint = projectManagement.contract;
            const finalEndpoint = projectId != null ? `${endpoint}/create/${projectId}` : `${endpoint}/create`;
            const response = await instance.post(finalEndpoint, payload);
            return response;
        } catch (e) {
            return e.response || e;
        }
    },


    updateContract: async (payload, contractId) => {
        try {
            const endpoint = projectManagement.contract;
            const response = await instance.put(`${endpoint}/update/${contractId}`, payload);
            return response;
        } catch (e) {
            return e.response || e;
        }
    },

    createVariation: async (contractId) => {
        try {
            const endpoint = projectManagement.contract;
            const response = await instance.put(`${endpoint}/variation/${contractId}`);
            return response;
        } catch (e) {
            return e.response || e;
        }
    },

    createContractVariation: async (contractId, payload) => {
        try {
            const endpoint = projectManagement.contract;
            const response = await instance.put(`${endpoint}/variation/${contractId}`, payload);
            return response;
        } catch (e) {
            return e.response || e;
        }
    },

    getAllProjectsByIDandType: async (accountId, type) => {
        try {
            const endpoint = projectManagement.getAllType;
            const response = await instance.get(`${endpoint}/${accountId}/${type}`);
            return response;
        } catch (e) {
            return e.response || e;
        }
    },

    getResponse: async (responseID) => {
        try {
            const endpoint = projectManagement.getResponse;
            const response = await instance.get(`${endpoint}/${responseID}`);
            return response;
        } catch (e) {
            return e.response || e;
        }
    },

    getSubmission: async (projectId, userID, summary) => {
        try {
            const endpoint = projectManagement.getSubmission;
            const response = await instance.get(`${endpoint}/${projectId}/${userID}/${summary}`);
            return response;
        } catch (e) {
            return e.response || e;
        }
    },

    createLot: async (payLoad) => {
        try {
            const endpoint = projectManagement.lot;
            const response = await instance.post(`${endpoint}/create`, payLoad);
            return response;
        } catch (e) {
            return e.response || e;
        }
    },

    deleteLot: async (projectId, lotID) => {
        try {
            const endpoint = projectManagement.lot;
            const response = await instance.delete(`${endpoint}/${lotID}/${projectId}`);
            return response;
        } catch (e) {
            return e.response || e;
        }
    },

    browseLots: async (projectId, filterOptions) => {
        try {
            const endpoint = projectManagement.lot;
            const response = await instance.get(`${endpoint}/browse/all/${projectId}`,
                {
                    params: {
                        ...filterOptions,
                    },
                });
            return response;
        } catch (e) {
            return e.response || e;
        }
    },

    reorderLots: async (projectID, payload) => {
        try {
            const endpoint = projectManagement.lot;
            const response = await instance.put(`${endpoint}/reorderLots/${projectID}`, payload);
            return response;
        } catch (e) {
            return e.response || e;
        }
    },

    createWorkflow: async (payLoad) => {
        try {
            const endpoint = projectManagement.createWorkflow;
            const response = await instance.post(endpoint, payLoad);
            return response;
        } catch (e) {
            return e.response || e;
        }
    },

    getWorkflow: async (workflowId) => {
        try {
            const endpoint = `${projectManagement.getWorkflow}/${workflowId}`;
            const response = await instance.get(endpoint);
            return response;
        } catch (e) {
            return e.response || e;
        }
    },

    getAllWorkflowsByUser: async (userId) => {
        try {
            const endpoint = projectManagement.getAllWorkflowsByUser;
            const response = await instance.get(`${endpoint}/${userId}`);
            return response;
        } catch (e) {
            return e.response || e;
        }
    },

    getAllWorkflowsByAccount: async (tabSelected) => {
        try {
            const endpoint = projectManagement.getAllWorkflowsByAccount;
            const response = await instance.get(`${endpoint}/${tabSelected}`);
            return response;
        } catch (e) {
            return e.response || e;
        }
    },

    updateWorkflow: async (workflowId, payLoad) => {
        try {
            const endpoint = `${projectManagement.updateWorkflow}/${workflowId}`;
            const response = await instance.put(endpoint, payLoad);
            return response;
        } catch (e) {
            return e.response || e;
        }
    },

    updateProjectWorkflow: async (workflowId, projectId, payLoad) => {
        try {
            const endpoint = `${projectManagement.updateWorkflow}/update/projectWorkflow/${projectId}/${workflowId}`;
            const response = await instance.put(endpoint, payLoad);
            return response;
        } catch (e) {
            return e.response || e;
        }
    },

    updateAssign: async (pId, payLoad) => {
        try {
            const endpoint = `${projectManagement.updateAssign}/${pId}`;
            const response = await instance.put(endpoint, payLoad);
            return response;
        } catch (e) {
            return e.response || e;
        }
    },

    updateSteeringGroup: async (pId, payLoad) => {
        try {
            const endpoint = `${projectManagement.project}/steeringGroup/${pId}`;
            const response = await instance.put(endpoint, payLoad);
            return response;
        } catch (e) {
            return e.response || e;
        }
    },

    updateContractSteeringGroup: async (pId, payLoad) => {
        try {
            const endpoint = `${projectManagement.contract}/steeringGroup/${pId}`;
            const response = await instance.put(endpoint, payLoad);
            return response;
        } catch (e) {
            return e.response || e;
        }
    },

    applyExtension: async (projectId) => {
        try {
            const endpoint = `${projectManagement.applyExtension}/${projectId}`;
            const response = await instance.put(endpoint);
            return response;
        } catch (e) {
            return e.response || e;
        }
    },

    publishEvent: async (payLoad) => {
        try {
            const endpoint = `${projectManagement.opportunity}`;
            const response = await instance.post(`${endpoint}/publish-event`, payLoad);
            return response;
        } catch (e) {
            return e.response || e;
        }
    },

    editDates: async (payLoad, projectId, eventId) => {
        try {
            const endpoint = `${projectManagement.opportunity}`;
            const response = await instance.put(`${endpoint}/manage/expression-submission-date/${projectId}/${eventId}`, payLoad);
            return response;
        } catch (e) {
            return e.response || e;
        }
    },


    updateResponse: async (responseID, payload) => {
        try {
            const endpoint = `${projectManagement.updateResponse}`;
            const response = await instance.put(`${endpoint}/${responseID}`, payload);
            return response;
        } catch (e) {
            return e.response || e;
        }
    },


    updateResponseStatus: async (responseID, payLoad) => {
        try {
            const endpoint = `${projectManagement.response}`;
            const response = await instance.put(`${endpoint}/status/${responseID}`, payLoad);
            return response;
        } catch (e) {
            return e.response || e;
        }
    },

    submitResponses: async (projectId, eventID, payLoad) => {
        try {
            const endpoint = `${projectManagement.response}`;
            const response = await instance.put(`${endpoint}/update/all/${projectId}/${eventID}`, payLoad);
            return response;
        } catch (e) {
            return e.response || e;
        }
    },

    answerResponse: async (responseID, actionType, payLoad) => {
        try {
            const endpoint = `${projectManagement.response}`;
            const response = await instance.put(`${endpoint}/answer/${actionType}/${responseID}`, payLoad);
            return response;
        } catch (e) {
            return e.response || e;
        }
    },

    extendContract: async (contractID) => {
        try {
            const endpoint = `${projectManagement.contract}`;
            const response = await instance.put(`${endpoint}/extension/${contractID}`);
            return response;
        } catch (e) {
            return e.response || e;
        }
    },

    cancelContract: async (contractID, payLoad) => {
        try {
            const endpoint = `${projectManagement.contract}`;
            const response = await instance.put(`${endpoint}/cancel/${contractID}`, payLoad);
            return response;
        } catch (e) {
            return e.response || e;
        }
    },

    updateTenderSubmission: async (action, projectId, userID) => {
        try {
            const endpoint = `${projectManagement.updateTenderSubmission}`;
            const response = await instance.put(`${endpoint}/${action}/${projectId}/${userID}`);
            return response;
        } catch (e) {
            return e.response || e;
        }
    },

    getAllSubmission: async (projectId, summary) => {
        try {
            const endpoint = `${projectManagement.getAllSubmission}`;
            const response = await instance.get(`${endpoint}/${projectId}/${summary}`);
            return response;
        } catch (e) {
            return e.response || e;
        }
    },

    updateAllResponses: async (projectId, payLoad) => {
        try {
            const endpoint = `${projectManagement.updateTenderSubmission}`;
            const response = await instance.put(`${endpoint}/${projectId}`, payLoad);
            return response;
        } catch (e) {
            return e.response || e;
        }
    },

    updateResponsesOnQuestionnaireChange: async (projectId, eventId, payLoad) => {
        try {
            const endpoint = `${projectManagement.updateResponsesOnQuestionnaireChange}`;
            const response = await instance.put(`${endpoint}/${projectId}/${eventId}`, payLoad);
            return response;
        } catch (e) {
            return e.response || e;
        }
    },

    createAward: async (payLoad) => {
        try {
            const endpoint = `${projectManagement.createAward}`;
            const response = await instance.post(`${endpoint}`, payLoad);
            return response;
        } catch (e) {
            return e.response || e;
        }
    },

    createNotice: async (projectId, eventId, payLoad) => {
        try {
            const endpoint = `${projectManagement.createNotice}`;
            const response = await instance.post(`${endpoint}/${projectId}/${eventId}`, payLoad);
            return response;
        } catch (e) {
            return e.response || e;
        }
    },

    publishNotice: async (projectId, eventId, payLoad) => {
        try {
            const endpoint = `${projectManagement.publishNotice}`;
            const response = await instance.post(`${endpoint}/publish/${projectId}/${eventId}`, payLoad);
            return response;
        } catch (e) {
            return e.response || e;
        }
    },

    publishFTSNotice: async (projectId, eventId, payLoad) => {
        try {
            const endpoint = `${projectManagement.publishFTSNotice}`;
            const response = await instance.post(`${endpoint}/${projectId}/${eventId}`, payLoad);
            return response;
        } catch (e) {
            return e.response || e;
        }
    },

    getNotice: async (projectId, eventId) => {
        try {
            const endpoint = `${projectManagement.getNotice}`;
            const response = await instance.get(`${endpoint}/${projectId}/${eventId}`);
            return response;
        } catch (e) {
            return e.response || e;
        }
    },

    updateAwardResponseById: async (responseId, payLoad) => {
        try {
            const endpoint = `${projectManagement.updateResponseById}`;
            const response = await instance.put(`${endpoint}/award/${responseId}`, payLoad);
            return response;
        } catch (e) {
            return e.response || e;
        }
    },

    updateAwardsResponseByProjectId: async (projectId, payLoad) => {
        try {
            const endpoint = `${projectManagement.updateAllAwards}`;
            const response = await instance.put(`${endpoint}/${projectId}`, payLoad);
            return response;
        } catch (e) {
            return e.response || e;
        }
    },

    getAllAwarded: async (projectId) => {
        try {
            const endpoint = `${projectManagement.getAllAwarded}`;
            const response = await instance.get(`${endpoint}/${projectId}`);
            return response;
        } catch (e) {
            return e.response || e;
        }
    },

    uploadIntentAwardLetter: async (type, projectId, eventID, accountID, payLoad) => {
        try {
            const endpoint = `${projectManagement.award}`;
            const response = await instance.put(`${endpoint}/docs/update/${type}/${projectId}/${eventID}/${accountID}`, payLoad);
            return response;
        } catch (e) {
            return e.response || e;
        }
    },

    deleteIntentAwardLetter: async (type, projectId, eventID, accountID, documentID) => {
        try {
            const endpoint = `${projectManagement.award}`;
            const response = await instance.put(`${endpoint}/docs/delete/${projectId}/${eventID}/${accountID}/${documentID}`);
            return response;
        } catch (e) {
            return e.response || e;
        }
    },

    createPRF: async (payLoad) => {
        try {
            const endpoint = `${projectManagement.prf}`;
            const response = await instance.post(`${endpoint}/create`, payLoad);
            return response;
        } catch (e) {
            return e.response || e;
        }
    },

    editPRF: async (prfId, payLoad) => {
        try {
            const endpoint = `${projectManagement.prf}`;
            const response = await instance.put(`${endpoint}/${prfId}`, payLoad);
            return response;
        } catch (e) {
            return e.response || e;
        }
    },

    getPRF: async (prfId) => {
        try {
            const endpoint = `${projectManagement.prf}`;
            const response = await instance.get(`${endpoint}/${prfId}`);
            return response;
        } catch (e) {
            return e.response || e;
        }
    },

    rejectPRF: async (prfId, payLoad) => {
        try {
            const endpoint = `${projectManagement.prf}`;
            const response = await instance.put(`${endpoint}/reject/${prfId}`, payLoad);
            return response;
        } catch (e) {
            return e.response || e;
        }
    },

    cancelPRF: async (prfId, payLoad) => {
        try {
            const endpoint = `${projectManagement.prf}`;
            const response = await instance.put(`${endpoint}/cancel/${prfId}`, payLoad);
            return response;
        } catch (e) {
            return e.response || e;
        }
    },

    listPRF: async () => {
        try {
            const endpoint = `${projectManagement.prf}`;
            const response = await instance.get(`${endpoint}/get/list`);
            return response;
        } catch (e) {
            return e.response || e;
        }
    },

    createEvent: async (payLoad) => {
        try {
            const endpoint = `${projectManagement.event}`;
            const response = await instance.post(`${endpoint}/create`, payLoad);
            return response;
        } catch (e) {
            return e.response || e;
        }
    },

    listEvents: async () => {
        try {
            const endpoint = `${projectManagement.event}`;
            const response = await instance.get(`${endpoint}/list/all`);
            return response;
        } catch (e) {
            return e.response || e;
        }
    },

    updateLibraryEvent: async (eventId, payLoad) => {
        try {
            const endpoint = `${projectManagement.event}`;
            const response = await instance.put(`${endpoint}/${eventId}`, payLoad);
            return response;
        } catch (e) {
            return e.response || e;
        }
    },

    getEvent: async (eventId) => {
        try {
            const endpoint = `${projectManagement.event}`;
            const response = await instance.get(`${endpoint}/${eventId}`);
            return response;
        } catch (e) {
            return e.response || e;
        }
    },

    createStage: async (payLoad) => {
        try {
            const endpoint = `${projectManagement.stage}`;
            const response = await instance.post(`${endpoint}/create`, payLoad);
            return response;
        } catch (e) {
            return e.response || e;
        }
    },

    getStage: async (stageId) => {
        try {
            const endpoint = `${projectManagement.stage}`;
            const response = await instance.get(`${endpoint}/${stageId}`);
            return response;
        } catch (e) {
            return e.response || e;
        }
    },

    updateStage: async (stageId, payLoad) => {
        try {
            const endpoint = `${projectManagement.stage}`;
            const response = await instance.put(`${endpoint}/edit/${stageId}`, payLoad);
            return response;
        } catch (e) {
            return e.response || e;
        }
    },

    deleteStage: async (stageId) => {
        try {
            const endpoint = `${projectManagement.stage}`;
            const response = await instance.delete(`${endpoint}/${stageId}`);
            return response;
        } catch (e) {
            return e.response || e;
        }
    },

    listStages: async () => {
        try {
            const endpoint = `${projectManagement.stage}`;
            const response = await instance.get(`${endpoint}`);
            return response;
        } catch (e) {
            return e.response || e;
        }
    },

    enableWorkflow: async (workflowId, payLoad) => {
        try {
            const endpoint = `${projectManagement.updateWorkflow}`;
            const response = await instance.put(`${endpoint}/enable/${workflowId}`, payLoad);
            return response;
        } catch (e) {
            return e.response || e;
        }
    },

    listProjects: async (tabSelected) => {
        try {
            const endpoint = `${projectManagement.project}`;
            const response = await instance.get(`${endpoint}/browse/all/${tabSelected}`);
            return response;
        } catch (e) {
            return e.response || e;
        }
    },

    listOpportunities: async (tabSelected) => {
        try {
            const endpoint = `${projectManagement.opportunity}`;
            const response = await instance.get(`${endpoint}/browse/all/${tabSelected}`);
            return response;
        } catch (e) {
            return e.response || e;
        }
    },

    listPublicOpportunities: async () => {
        try {
            const endpoint = `${projectManagement.opportunity}`;
            const response = await instance.get(`${endpoint}/browse/all-public`);
            return response;
        } catch (e) {
            return e.response || e;
        }
    },

    updateRisk: async (projectID, payload) => {
        try {
            const endpoint = `${projectManagement.project}`;
            const response = await instance.put(`${endpoint}/risk/${projectID}`, payload);
            return response;
        } catch (e) {
            return e.response || e;
        }
    },

    assignWorkflowToProject: async (projectId, workflowId) => {
        try {
            const endpoint = `${projectManagement.assignWorkflowToProject}`;
            const response = await instance.put(`${endpoint}/${projectId}/${workflowId}`);
            return response;
        } catch (e) {
            return e.response || e;
        }
    },

    createCorrespondenceTopic: async (resourceId, resource, payload) => {
        try {
            const endpoint = `${projectManagement.correspondence}`;
            const response = await instance.post(`${endpoint}/${resource}/${resourceId}/correspondence/topic/create`, payload);
            return response;
        } catch (e) {
            return e.response || e;
        }
    },

    getTopic: async (topicId, resourceId, resource) => {
        try {
            const endpoint = `${projectManagement.correspondence}`;
            const response = await instance.get(`${endpoint}/${resource}/${resourceId}/correspondence/topic/${topicId}`);
            return response;
        } catch (e) {
            return e.response || e;
        }
    },

    removeProjectWorkflow: async (workflowID) => {
        try {
            const endpoint = `${projectManagement.updateWorkflow}`;
            const response = await instance.put(`${endpoint}/remove/${workflowID}`);
            return response;
        } catch (e) {
            return e.response || e;
        }
    },

    getAnswerSummary: async (projectID, eventID, exportData = false) => {
        try {
            const endpoint = `${projectManagement.response}`;
            const finalEndpoint = !exportData ? `${endpoint}/answer/summary/${projectID}/${eventID}` : `${endpoint}/answer/summary/${projectID}/${eventID}/${exportData}`;
            const response = await instance.get(finalEndpoint);
            return response;
        } catch (e) {
            return e.response || e;
        }
    },

    getDPSAnswerSummary: async (projectID, eventID, exportData = false) => {
        try {
            const endpoint = `${projectManagement.response}`;
            const finalEndpoint = !exportData ? `${endpoint}/dps/answer/summary/${projectID}/${eventID}` : `${endpoint}/dps/answer/summary/${projectID}/${eventID}/${exportData}`;
            const response = await instance.get(finalEndpoint);
            return response;
        } catch (e) {
            return e.response || e;
        }
    },

    refreshAnswers: async (projectID, eventID) => {
        try {
            const endpoint = `${projectManagement.response}`;
            const response = await instance.put(`${endpoint}/answer/refresh/${projectID}/${eventID}`);
            return response;
        } catch (e) {
            return e.response || e;
        }
    },

    assignLotsToOpportunity: async (projectID, eventID, payload) => {
        try {
            const endpoint = `${projectManagement.response}`;
            const response = await instance.put(`${endpoint}/assignLots/${projectID}/${eventID}`, payload);
            return response;
        } catch (e) {
            return e.response || e;
        }
    },

    dpsAssignLotsToOpportunity: async (projectID, eventID, payload) => {
        try {
            const endpoint = `${projectManagement.response}`;
            const response = await instance.post(`${endpoint}/dps/assignLots/${projectID}/${eventID}`, payload);
            return response;
        } catch (e) {
            return e.response || e;
        }
    },

    dpsEditLotsToOpportunity: async (projectID, eventID, payload) => {
        try {
            const endpoint = `${projectManagement.response}`;
            const response = await instance.put(`${endpoint}/dps/assignLots/${projectID}/${eventID}`, payload);
            return response;
        } catch (e) {
            return e.response || e;
        }
    },

    browseCorrespondence: async (resourceId, resource) => {
        try {
            const endpoint = `${projectManagement.correspondence}`;
            const response = await instance.get(`${endpoint}/${resource}/${resourceId}/correspondence/topic/browse/all`);
            return response;
        } catch (e) {
            return e.response || e;
        }
    },

    approveOrRejectSupplier: async (projectID, eventID, payload) => {
        try {
            const endpoint = `${projectManagement.opportunity}/manage/approval/${projectID}/${eventID}`;
            const response = await instance.put(endpoint, payload);
            return response;
        } catch (e) {
            return e.response || e;
        }
    },

    browseThreads: async (resourceId, resource, topicId) => {
        try {
            const endpoint = `${projectManagement.correspondence}`;
            const response = await instance.get(`${endpoint}/${resource}/${resourceId}/correspondence/topic/${topicId}/thread/all`);
            return response;
        } catch (e) {
            return e.response || e;
        }
    },

    removeSupplierFromOpportunity: async (opportunityID, accountID) => {
        try {
            const endpoint = `${projectManagement.opportunity}/remove/${opportunityID}/${accountID}`;
            const response = await instance.put(endpoint);
            return response;
        } catch (e) {
            return e.response || e;
        }
    },

    createThread: async (resourceId, resource, topicId, payload) => {
        try {
            const endpoint = `${projectManagement.correspondence}`;
            const response = await instance.post(`${endpoint}/${resource}/${resourceId}/correspondence/topic/${topicId}/thread/create`, payload);
            return response;
        } catch (e) {
            return e.response || e;
        }
    },

    updateThread: async (resourceId, resource, topicId, threadId, payload) => {
        try {
            const endpoint = `${projectManagement.correspondence}`;
            const response = await instance.put(`${endpoint}/${resource}/${resourceId}/correspondence/topic/${topicId}/thread/update/${threadId}`, payload);
            return response;
        } catch (e) {
            return e.response || e;
        }
    },

    browseThreadMessages: async (resourceId, resource, topicId, threadId) => {
        try {
            const endpoint = `${projectManagement.correspondence}`;
            const response = await instance.get(`${endpoint}/${resource}/${resourceId}/correspondence/topic/${topicId}/thread/${threadId}/message/all`);
            return response;
        } catch (e) {
            return e.response || e;
        }
    },

    createThreadMessage: async (resourceId, resource, topicId, threadId, payload) => {
        try {
            const endpoint = `${projectManagement.correspondence}`;
            const response = await instance.post(`${endpoint}/${resource}/${resourceId}/correspondence/topic/${topicId}/thread/${threadId}/message/create`, payload);
            return response;
        } catch (e) {
            return e.response || e;
        }
    },

    getUnreadCorrespondence: async (resourceId, resourceType) => {
        try {
            const endpoint = projectManagement.unreadCorrespondence;
            const response = await instance.get(`${endpoint}/${resourceType}/${resourceId}`);
            return response;
        } catch (e) {
            return e.response || e;
        }
    },

    getEvaluationSummary: async (projectId, eventId, exportData = false) => {
        try {
            const endpoint = `${projectManagement.evaluation}`;
            const finalEndpoint = !exportData ? `${endpoint}/summary/${projectId}/${eventId}` : `${endpoint}/summary/${projectId}/${eventId}/${exportData}`;
            const response = await instance.get(finalEndpoint);
            return response;
        } catch (e) {
            return e.response || e;
        }
    },

    getDPSEvaluationSummary: async (projectId, eventId, exportData = false) => {
        try {
            const endpoint = `${projectManagement.evaluation}`;
            const finalEndpoint = !exportData ? `${endpoint}/dps/summary/${projectId}/${eventId}` : `${endpoint}/dps/summary/${projectId}/${eventId}/${exportData}`;
            const response = await instance.get(finalEndpoint);
            return response;
        } catch (e) {
            return e.response || e;
        }
    },

    openAllSeals: async (projectId, eventId) => {
        try {
            const endpoint = `${projectManagement.evaluation}`;
            const response = await instance.put(`${endpoint}/release/${projectId}/${eventId}`);
            return response;
        } catch (e) {
            return e.response || e;
        }
    },

    openSeal: async (projectId, eventId, accountId) => {
        try {
            const endpoint = `${projectManagement.evaluation}`;
            const response = await instance.put(`${endpoint}/release/${projectId}/${eventId}/${accountId}`);
            return response;
        } catch (e) {
            return e.response || e;
        }
    },

    startEvaluation: async (responseId) => {
        try {
            const endpoint = `${projectManagement.evaluation}`;
            const response = await instance.put(`${endpoint}/evaluate/${responseId}`);
            return response;
        } catch (e) {
            return e.response || e;
        }
    },

    saveEvaluation: async (responseId, payload) => {
        try {
            const endpoint = `${projectManagement.evaluation}`;
            const response = await instance.put(`${endpoint}/update/save/${responseId}`, payload);
            return response;
        } catch (e) {
            return e.response || e;
        }
    },

    submitEvaluation: async (responseId, payload) => {
        try {
            const endpoint = `${projectManagement.evaluation}`;
            const response = await instance.put(`${endpoint}/update/submit/${responseId}`, payload);
            return response;
        } catch (e) {
            return e.response || e;
        }
    },

    submitAllForApproval: async (projectId, eventId, accountId) => {
        try {
            const endpoint = `${projectManagement.evaluation}`;
            const response = accountId
                ? await instance.put(`${endpoint}/submit/${projectId}/${eventId}/${accountId}`)
                : await instance.put(`${endpoint}/submit/${projectId}/${eventId}`);
            return response;
        } catch (e) {
            return e.response || e;
        }
    },

    approveEvaluations: async (projectId, eventId, payload, accountId) => {
        try {
            const endpoint = `${projectManagement.evaluation}`;
            const response = accountId
                ? await instance.put(`${endpoint}/approval/${projectId}/${eventId}/${accountId}`, payload)
                : await instance.put(`${endpoint}/approval/${projectId}/${eventId}`, payload);
            return response;
        } catch (e) {
            return e.response || e;
        }
    },

    disqualifySupplier: async (payload) => {
        try {
            const endpoint = `${projectManagement.evaluation}`;
            const response = await instance.put(`${endpoint}/disqualify`, payload);
            return response;
        } catch (e) {
            return e.response || e;
        }
    },

    getAwardSummary: async (projectId, eventId) => {
        try {
            const endpoint = `${projectManagement.award}`;
            const response = await instance.get(`${endpoint}/summary/${projectId}/${eventId}`);
            return response;
        } catch (e) {
            return e.response || e;
        }
    },

    submitForApprovalAward: async (projectId, eventId, accountId) => {
        try {
            const endpoint = `${projectManagement.award}`;
            const response = accountId
                ? await instance.put(`${endpoint}/submit/${projectId}/${eventId}/${accountId}`)
                : await instance.put(`${endpoint}/submit/${projectId}/${eventId}`);
            return response;
        } catch (e) {
            return e.response || e;
        }
    },

    approveAward: async (projectId, eventId, payload, accountId) => {
        try {
            const endpoint = `${projectManagement.award}`;
            const response = accountId
                ? await instance.put(`${endpoint}/approval/${projectId}/${eventId}/${accountId}`, payload)
                : await instance.put(`${endpoint}/approval/${projectId}/${eventId}`, payload);
            return response;
        } catch (e) {
            return e.response || e;
        }
    },

    updateIntentByProject: async (projectId, eventId, accountId, payload) => {
        try {
            const endpoint = `${projectManagement.award}`;
            const response = await instance.put(`${endpoint}/update/${projectId}/${eventId}/${accountId}`, payload);
            return response;
        } catch (e) {
            return e.response || e;
        }
    },

    updateIntentByLot: async (projectId, eventId, accountId, lotId, payload) => {
        try {
            const endpoint = `${projectManagement.award}`;
            const response = await instance.put(`${endpoint}/update/${projectId}/${eventId}/${accountId}/${lotId}`, payload);
            return response;
        } catch (e) {
            return e.response || e;
        }
    },

    sendIntentLetters: async (projectId, eventId, payload) => {
        try {
            const endpoint = `${projectManagement.award}`;
            const response = await instance.put(`${endpoint}/send/intent/${projectId}/${eventId}`, payload);
            return response;
        } catch (e) {
            return e.response || e;
        }
    },

    uploadDocumentForContract: async (contractId, payload) => {
        try {
            const endpoint = `${projectManagement.contract}`;
            const response = await instance.put(`${endpoint}/document/upload/${contractId}`, payload);
            return response;
        } catch (e) {
            return e.response || e;
        }
    },

    updateContractDocs: async (contractId, payload) => {
        try {
            const endpoint = `${projectManagement.contract}`;
            const response = await instance.put(`${endpoint}/contractDocs/${contractId}`, payload);
            return response;
        } catch (e) {
            return e.response || e;
        }
    },

    deleteContractDoc: async (contractId, documentId) => {
        try {
            const endpoint = `${projectManagement.contract}`;
            const response = await instance.delete(`${endpoint}/contractDocs/${contractId}/${documentId}`, { documentID: documentId });
            return response;
        } catch (e) {
            return e.response || e;
        }
    },

    sendAwardLetters: async (projectId, eventId, accountId) => {
        try {
            const endpoint = `${projectManagement.award}`;
            const response = (accountId && accountId !== '')
                ? await instance.put(`${endpoint}/send/award/${projectId}/${eventId}/${accountId}`)
                : await instance.put(`${endpoint}/send/award/${projectId}/${eventId}`);
            return response;
        } catch (e) {
            return e.response || e;
        }
    },

    deleteDocumentForContract: async (contractId, documentId) => {
        try {
            const endpoint = `${projectManagement.contract}`;
            const response = await instance.put(`${endpoint}/document/delete/${contractId}/${documentId}`);
            return response;
        } catch (e) {
            return e.response || e;
        }
    },

    updateWorknoteForContract: async (contractId, payload) => {
        try {
            const endpoint = `${projectManagement.contract}`;
            const response = await instance.put(`${endpoint}/worknote/${contractId}`, payload);
            return response;
        } catch (e) {
            return e.response || e;
        }
    },

    updateStandstillTimer: async (projectId, eventId, payload) => {
        try {
            const endpoint = `${projectManagement.award}`;
            const response = await instance.put(`${endpoint}/standstill/update/${projectId}/${eventId}`, payload);
            return response;
        } catch (e) {
            return e.response || e;
        }
    },

    getSolution: async (solutionId) => {
        try {
            const endpoint = `${projectManagement.solution}`;
            const response = await instance.get(`${endpoint}/overview/${solutionId}`);
            return response;
        } catch (e) {
            return e.response || e;
        }
    },

    listSolutions: async () => {
        try {
            const endpoint = `${projectManagement.solution}`;
            const response = await instance.get(`${endpoint}/browse/all`);
            return response;
        } catch (e) {
            return e.response || e;
        }
    },

    updateShareSolution: async (payload) => {
        try {
            const endpoint = `${projectManagement.solution}`;
            const response = await instance.put(`${endpoint}/update`, payload);
            return response;
        } catch (e) {
            return e.response || e;
        }
    },

    createCallOff: async (payload) => {
        try {
            const endpoint = `${projectManagement.project}`;
            const response = await instance.post(`${endpoint}/calloff/create`, payload);
            return response;
        } catch (e) {
            return e.response || e;
        }
    },

    browseCallOff: async () => {
        try {
            const endpoint = `${projectManagement.project}`;
            const response = await instance.get(`${endpoint}/calloff-browse`);
            return response;
        } catch (e) {
            return e.response || e;
        }
    },

    registerInterestSuppliers: async (opportunityID, body) => {
        try {
            const endpoint = `${projectManagement.interest}`;
            const response = await instance.put(`${endpoint}/clever-quotes/${opportunityID}`, body);
            return response;
        } catch (e) {
            return e.response || e;
        }
    },

    viewFindTenderServiceNotice: async (projectID, eventID) => {
        try {
            const endpoint = `${projectManagement.viewFindTenderServiceNotice}`;
            const response = await instance.get(`${endpoint}/${projectID}/${eventID}`, { responseType: 'blob' });
            return response;
        } catch (e) {
            return e.response || e;
        }
    },

    getSuccessfulSuppliers: async (projectID, eventID) => {
        try {
            const endpoint = `${projectManagement.response}`;
            const response = await instance.get(`${endpoint}/successful-suppliers/${projectID}/${eventID}`);
            return response;
        } catch (e) {
            return e.response || e;
        }
    },

    createSolutionAccessRequest: async (payload) => {
        try {
            return await instance.post(projectManagement.solutionAccessRequestURL, payload);
        } catch (e) {
            return e.response || e;
        }
    },

    getSolutionAccessRequest: async (solutionID, departmentID) => {
        try {
            return await instance.get(
                `${projectManagement.solutionAccessRequestURL}/${solutionID}/${departmentID}`,
            );
        } catch (e) {
            return e.response || e;
        }
    },

    fetchSolutionAccessRequests: async (solutionID) => {
        try {
            return await instance.get(
                `${projectManagement.solutionAccessRequestsURL}/${solutionID}`,
            );
        } catch (e) {
            return e.response || e;
        }
    },

    updateSolutionAccessRequest:
        async (solutionID, departmentID, userID, contractReference, requestStatus) => {
            const payload = {
                solutionID, departmentID, userID, contractReference, requestStatus,
            };

            try {
                return await instance.put(projectManagement.solutionAccessRequestURL, payload);
            } catch (e) {
                return e.response || e;
            }
        },

    exportCorrespondence: async (projectID) => {
        try {
            const endpoint = `${projectManagement.exportCorrespondenceURL}/${projectID}/correspondence/topic/export/all`;
            const response = await instance.get(`${endpoint}`);
            return response;
        } catch (e) {
            return e.response || e;
        }
    },

    getResponsesHistory: async (projectID, eventID) => {
        try {
            const endpoint = `${projectManagement.getResponsesHistoryURL}/${projectID}/${eventID}`;
            const response = await instance.get(`${endpoint}`);
            return response;
        } catch (e) {
            return e.response || e;
        }
    },
};
export default projectManagementAPIs;
