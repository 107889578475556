import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import Form from '../../components/Form';
import WhoAreYou from './components/WhoAreYou';
import CompanyType from './components/CompanyType';
import CannotOpenAccount from './components/CannotOpenAccount';
import CompanySearch from './components/CompanySearch';
import CompanySearchResults from './components/CompanySearchResults';
import actions from './slice/supplier-reg.action';
import { companyTypes } from '../../config/constants';

const SupplierRegistration = ({ showRegModal, closeModal }) => {
    const [currentStep, setCurrentStep] = useState(1);
    const [searchResults, setSearchResults] = useState({});
    const history = useHistory();
    const dispatch = useDispatch();

    const verifyCHSearch = (data) => {
        const result = companyTypes.filter((company) => company.name === data);
        return result[0]?.companySearch || false;
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        return null;
    };

    const skip = async () => {
        closeModal();
        history.push('/registration/company-details/enter');
    };

    /**
     * Take the user to next steps in journey.
     * Journey steps are mapped as follows:
     * Step 1: Who are you?
     * Step 2: Information
     * Step 3: Company Type
     * Step 4: Company Search
     * Step 5: Company Search Results
     * @param {*} event
     * @param {*} data
     * @returns
     */
    const next = async (event, data) => {
        event.preventDefault();
        /**
         * STEP 1: Who are you?
         * Branch the user to company type or information
         */
        if (currentStep === 1) {
            dispatch(actions.resetRegForm());
            const userType = data.filter((el) => el.checked)[0];
            dispatch(actions.setUserType({ userType: userType.value }));
            if (userType.value === 'Supplier') {
                setCurrentStep(3);
            } else {
                setCurrentStep(2);
            }
        }

        /**
         * STEP 2: Information Modal
         * User cannot open an account online
         */
        if (currentStep === 2) {
            closeModal();
            history.push('/');
        }

        /**
         * STEP 3: Company Type Modal
         * Branch the user to comapny search results modal
         */
        if (currentStep === 3) {
            dispatch(actions.setCompanyType({ companyType: data }));
            if (verifyCHSearch(data) === true) {
                setCurrentStep(4);
            } else {
                skip();
            }
            // if (data === 'Public Limited Company' || data === 'Private Limited Company') {
            //     setCurrentStep(4);
            // } else {
            //     skip();
            // }
        }

        /**
         * STEP 4: Company Search
         * User performs a company search
         */
        if (currentStep === 4) {
            setSearchResults({
                ...data,
            });
            setCurrentStep(5);
        }

        /**
         * STEP 4: Company search results
         * User agrees with the search results
         */
        if (currentStep === 5) {
            dispatch(actions.setCompanyDetails({
                accountName: data.name,
                businessStructure: data.category,
                natureOfBusiness: data.sicCodes
                    ? data.sicCodes[0]?.code
                    : data.categoryCodes[0]?.code,
                vatNumber: '',
                registrationNumber: data.companyNumber,
                noOfEmployees: '',
                addressLine1: data.address?.addressLine1,
                addressLine2: data.address?.addressLine2,
                city: data.address?.postTown,
                county: data.address?.county,
                postcode: data.address?.postcode || data.address?.postCode,
                country: data.address?.country || 'United Kingdom',
            }));
            closeModal();
            history.push('/registration/company-details/review');
        }
        return null;
    };

    return (<>
        {(showRegModal) && <Form id='reg-form' onSubmit={(event) => handleSubmit(event)}>
            <WhoAreYou currentStep={currentStep} onSubmit={next} closeModal={closeModal} />
            <CompanyType currentStep={currentStep} onSubmit={next} closeModal={closeModal} />
            <CannotOpenAccount currentStep={currentStep} onSubmit={next} closeModal={closeModal} />
            <CompanySearch
                currentStep={currentStep}
                onSubmit={next}
                skipStep={skip}
                closeModal={closeModal} />
            <CompanySearchResults
                currentStep={currentStep}
                results={searchResults}
                onSubmit={next}
                skipStep={skip}
                closeModal={closeModal} />
        </Form>
        }
    </>
    );
};

SupplierRegistration.propTypes = {
    showRegModal: PropTypes.bool,
    closeModal: PropTypes.func,
};

export default SupplierRegistration;
