/* eslint-disable no-empty */
import React, { useEffect, useState, useContext } from 'react';
import { useHistory, useParams, Link } from 'react-router-dom';
// import { saveAs } from 'file-saver';
import PropTypes from 'prop-types';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useSelector, useDispatch } from 'react-redux';
import { format, parseISO } from 'date-fns';
import SearchBar from '../SearchBar';
import SortDropdown from '../SortDropdown';
import Button from '../../components/Button';
import filterIcon from '../../styles/images/filterIcon.svg';
import addIcon from '../../styles/images/addIconWhite.svg';
import shareIcon from '../../styles/images/share-icon.png';
import Accordion from '../Accordion';
import helperFunctions from '../../utils/helperFunctions';
import Tabs from '../../components/Tabs';
import ShowMoreShowLess from '../../components/SeeMoreSeeLess';
import BrowseFilterPanel from '../BrowseFilterPanel';
import documentManagementAPIs from '../../services/document-management.service';
import projectManagementAPIs from '../../services/project-management.service';
import AuthContext from '../../context/AuthContext';
import Toast from '../../components/Alerts/Toast/Toast';
import actions from '../../screens/EditProjectWorkflow/slice/workflows.actions';
import {
    workflowEventTypes, routeToMarket, questionnaireTypes, allSteeringRoles, RICH_TEXT,
    evaluationPanel,
} from '../../config/constants';
// import fileReader from '../../utils/fileReader';
import BrowsePageHelper from '../../utils/browsePageHelper';
import {
    handleDownload,
} from '../../utils/uploadHelper';
import Constants from './constants';
import { browseDataContext } from '../ActivityLog/BrowseActivity/constants';
import isSupplierRole from '../../utils/isSupplierRole';
import isNepoRole from '../../utils/isNepoRole';
import isAdminRole from '../../utils/isAdminRole';
import isLARole from '../../utils/isLARole';
import { BUYER_STATUS } from '../Correspondence/ViewCorrespondence/constants';
import { getDescriptionBlock } from '../DocumentBuilder/helpers';
import projectService from '../../services/project.service';

const BrowseData = ({
    id,
    context,
    exportFunction,
    contextSingular,
    dataSet,
    searchPlaceholder,
    sortOptions,
    tabOptions,
    handleTabSelected,
    action,
    newSpend,
    newRebate,
    allowFilter = true,
    allowAddNew = false,
    labelAddNew,
    handleAddNew,
    handleView,
    handleEdit,
    allowEdit = true,
    allowSort = true,
    defaultTab = null,
    allowBackButton = false,
    allowExportButton = false,
    handleBack,
    searchSortFilter = {},
    selectedTab,
    topicList,
}) => {
    const history = useHistory();
    const authContext = useContext(AuthContext);
    const [filteredData, setFilteredData] = useState([]);
    const [filterPanelOpen, setFilterPanelOpen] = useState(false);
    const [eventsSelected, setEventsSelected] = useState([]);
    const [searchSortFilterData, setSearchSortFilterData] = useState(searchSortFilter);
    const [lastPosition, setLastPosition] = useState(20);
    const {
        documentBuilderState,
    } = useSelector((state) => ({
        documentBuilderState: state.WorkflowsReducer.documentBuilderState,
    }));
    const dispatch = useDispatch();
    const {
        workflowID,
        projectID,
        routeToMarketParam,
        type,
        questionnaireID,
        templateType,
        resourceName,
        responseID,
        eventID,
        accountID,
        opportunityID,
        callOffMechanism,
        contractID,
        resourceType,
        resourceId,
        lotID,
    } = useParams();

    const perPage = 20;

    const handleSearchSortFilter = (data) => {
        const updatedState = {
            ...searchSortFilterData,
            ...data,
            filter: {
                ...searchSortFilterData.filter,
                ...data.filter,
                ...searchSortFilter.filter,
            },
        };

        // This is a temporary fix to fix browse workflows.
        // I don't know why it does not filter normally.
        if (data?.filter?.route) {
            updatedState.filter.route = data.filter.route;
        }

        if (data.filter && Object.keys(data.filter).length === 0) {
            updatedState.filter = {};
        }

        const filteredSet = BrowsePageHelper.filterData(updatedState, dataSet);

        setSearchSortFilterData({
            ...updatedState,
        });
        setFilteredData(filteredSet.slice(0, perPage));
        setLastPosition(perPage);
    };

    const loadMoreData = () => {
        const filteredSet = BrowsePageHelper.filterData(searchSortFilterData, dataSet);
        setFilteredData(filteredSet.slice(0, lastPosition + perPage));
        setLastPosition(lastPosition + perPage);
    };

    // const handleDocumentDownload = async (documentID, filename) => {
    //     try {
    //         // const response = await documentManagementAPIs.getSignedUrl(documentID);
    //         const response = await documentManagementAPIs.getByDocumentID(documentID);
    //         if (response.status === 200) {
    //             try {
    //                 saveAs(response.data, filename);
    //                 // await downloadUrl(response.data, filename);
    //             } catch (error) {
    //                 Toast.fire({
    //                     icon: 'error',
    //                     title: 'Unable to download document.',
    //                 });
    //             }
    //         } else {
    //             const { data } = response;
    //             const file = await fileReader(data);
    //             const { ErrorCode } = JSON.parse(file);
    //             if (ErrorCode === '8000') {
    //                 Toast.fire({
    //                     icon: 'error',
    //                     titleText: 'Uploaded document is in process, please try after sometime.',
    //                 });
    //             } else {
    //                 Toast.fire({
    //                     icon: 'error',
    //                     titleText: 'Unable to download document.',
    //                 });
    //             }
    //         }
    //     } catch (error) {
    //         Toast.fire({
    //             icon: 'error',
    //             title: 'Unable to download document.',
    //         });
    //     }
    // };

    const uploadSpend = (event) => {
        event.preventDefault();
        newSpend();
    };

    const uploadRebate = (event) => {
        event.preventDefault();
        newRebate();
    };

    const throttle = (callbackFn, limit) => {
        let wait = false;
        return () => {
            if (!wait) {
                callbackFn.call();
                wait = true;
                setTimeout(() => {
                    wait = false;
                }, limit);
            }
        };
    };

    const getPrettyEventType = (eType) => workflowEventTypes.filter(
        (eventType) => eventType.value === eType,
    )[0]?.label;

    const getPrettyQuestionnaireType = (qType) => questionnaireTypes.filter(
        (eventType) => eventType.value === qType,
    )[0]?.label;

    const getPrettySteeringGroupRole = (roleId) => allSteeringRoles
        .filter((steeringRole) => (steeringRole.role === roleId))[0]?.name;

    const getPrettyRTM = (rtm) => routeToMarket
        .filter((route) => route.id === rtm)[0]?.name || 'Not Selected';

    const handleScroll = () => {
        const header = document.getElementById('bdContentHeader');
        const sticky = header?.offsetTop;
        if (window.pageYOffset > sticky) {
            header?.classList?.add('sticky');
        } else {
            header?.classList?.remove('sticky');
        }
    };

    useEffect(() => {
        const throttledScrollHandler = throttle(handleScroll, 10);
        window.addEventListener('scroll', throttledScrollHandler);
        return () => {
            window.removeEventListener('scroll', throttledScrollHandler);
        };
    }, []);

    useEffect(() => {
        const updatedState = {
            ...searchSortFilterData,
            ...searchSortFilter,
            filter: {
                ...searchSortFilterData.filter,
                ...searchSortFilter.filter,
            },
        };
        const filteredSet = BrowsePageHelper.filterData(updatedState, dataSet);
        setFilteredData(filteredSet.slice(0, perPage));
        setSearchSortFilterData(updatedState);
    }, [dataSet]);

    const toggleFilterPanel = () => setFilterPanelOpen(!filterPanelOpen);

    const generatePrettyDate = (date) => {
        try {
            const parsedISO = parseISO(date);
            if (parsedISO !== 'Invalid Date') {
                return `${format(parsedISO, 'dd-MM-yyyy')}`;
            }
        } catch (e) {
            return date;
        }
        return date;
    };

    const generateAccordionSectionHeader = (data) => {
        switch (context.toLowerCase()) {
            case 'opportunities':
                return <p className='title'>{data.opportunityName}</p>;
            case 'contractsregister':
                return <>
                    <p className='headerTitle title'>{data.title}</p>
                    <div className='bdAccordionSectionHeaderAddon'>
                        <p className='label body title'>Award date:</p>
                        <p className='value caption'>{generatePrettyDate(data.awardDate)}</p>
                    </div>
                </>;
            case 'suppliers':
                return <p className='title'>{data.accountName}</p>;
            case 'projects':
                return <>
                    <p className='headerTitle title'>{data.title}</p>
                    {(data.stages && data.stages.length > 0) && <div className='bdAccordionSectionHeaderAddon projects'>
                        <p className='label projects body'>Stage {data.currentStage} of {Object.keys(data.stages).length}</p>
                    </div>}
                    {data.projectStatus === 'cancelled' && <div className='bdAccordionSectionHeaderAddon cancelled'>
                        <label className={'label projects body'}>Cancelled</label>
                    </div>}
                </>;
            case 'requests':
                return <>
                    <p className='title'>{data.title}</p>
                    {data.projectStatus === 'cancelled' && <div className='bdAccordionSectionHeaderAddon cancelled'>
                        <label className={'label projects body'}>Cancelled</label>
                    </div>}
                </>;
            case 'workflows':
                return <>
                    {/* <p className='title bdWorkflowName'>{data.workflowName}</p>
                    {data.decision === 'disabled'
                    && <div className='bdAccordionSectionHeaderAddon cancelled'>
                        <label className={'label projects body'}>Disabled</label>
                    </div>} */}
                    <div className='headerTitleContent'>
                        {data.accessGroups?.length > 0 || data.isPublic ? <img src={shareIcon} alt={'shared'} className='share-icon'></img> : <></>}
                        <p className='title'>{data.workflowName}</p>
                    </div>
                    <div className='bdAccordionSectionHeaderAddon'>
                        {data.decision === 'disabled' && <label className='status-label red title'>Disabled</label>}
                        <label className='status-label title primary'>{getPrettyRTM(data.route)}</label>
                    </div>
                    {/* <div className='bdAccordionSectionHeaderAddon'>
                        <label className='title headerTag active'>{data.workflowType}</label>
                    </div> */}
                </>;
            case 'solutions':
                return <>
                    <p className='title'>{data.contractTitle}</p>
                    {data.solutionStatus === 'cancelled' && <div className='bdAccordionSectionHeaderAddon cancelled'>
                        <label className={'label projects body'}>Cancelled</label>
                    </div>}
                </>;
            case 'spends':
                return <>
                    <p className='title'>{data.filename.split('_')[0]}</p>
                </>;
            case 'rebates':
                return <>
                    <p className='title'>{data.filename.split('_')[0]}</p>
                </>;
            case 'questionnairesselect':
                return <>
                    <p className='title'>{data.templateName}</p>
                    <div className='bdAccordionSectionHeaderAddon'>
                        <label className='title headerTag active'>{getPrettyQuestionnaireType(data.templateType)}</label>
                    </div>
                </>;
            case 'questionnaires':
                return <>
                    <div className='headerTitleContent'>
                        {data.accessGroups?.length > 0 || data.isPublic ? <img src={shareIcon} alt={'shared'} className='share-icon'></img> : <></>}
                        <p className='title'>{data.templateName}</p>
                    </div>
                    <div className='bdAccordionSectionHeaderAddon'>
                        {data?.templateStatus === 'draft' ? <label className='status-label red title'>Draft</label>
                            : data?.isDisabled && <label className='status-label red title'>Disabled</label>}
                        <label className='status-label title primary'>{getPrettyQuestionnaireType(data.templateType)}</label>
                    </div>
                </>;
            case 'sections':
                return <>
                    <p className='title'>{data.title}</p>
                </>;
            case 'contracts':
                return <>
                    <p className='headerTitle title'>{data.title}</p>
                    {data.contractStatus && data.contractStatus === 'cancelled'
                        ? <div className='bdAccordionSectionHeaderAddon cancelled'>
                            <p className='label projects body'>Cancelled</p>
                        </div>
                        : <></>}
                </>;
            case 'events':
                return <>
                    <p className='title bdWorkflowName'>{data.eventName}</p>
                </>;
            case 'stages':
                return <>
                    <p className='title bdWorkflowName'>{data.stageName}</p>
                </>;
            case 'correspondence':
                return <>
                    <div className='neworUnread_accordion_header'>
                        {data.lastUnreadMessageSystemTimestamp && <p className='newOrUnreadData tag'> </p>}
                        <p className={`title ${data.lastUnreadMessageSystemTimestamp && 'newOrUnreadTitleMargin'}`}>{data.subject}</p>
                    </div>
                </>;
            case 'calloff': return <>
                <p className='headerTitle title'>{data.title}</p>
                {data.projectStatus === 'cancelled' && <div className='bdAccordionSectionHeaderAddon cancelled'>
                    <label className={'label projects body'}>Cancelled</label>
                </div>}
            </>;
            case browseDataContext:
                return <p className='title'>{data.message}</p>;
            default:
                return <></>;
        }
    };

    const selectSupplier = async (supplier, rtm) => {
        const response = projectService.isRTMDirectAward(rtm)
            ? await projectManagementAPIs.registerSupplierDirectAward(
                projectID, eventID, supplier.supplierID, supplier.accountName, lotID,
            )
            : await projectManagementAPIs.registerSupplier(
                opportunityID, supplier.supplierID, supplier.accountName,
            );

        if (response.status === 200) {
            Toast.fire({
                icon: 'success',
                title: 'Successfully added supplier.',
            });
            history.goBack();
        } else if (response.data && response.data.ErrorCode === '9000') {
            Toast.fire({
                icon: 'error',
                title: 'Supplier already registered.',
            });
        } else {
            Toast.fire({
                icon: 'error',
                title: 'Failed to add supplier.',
            });
        }
    };

    const selectEvents = (event) => {
        if (eventsSelected.indexOf(event) === -1) {
            setEventsSelected((events) => [
                ...events,
                event,
            ]);
        } else {
            setEventsSelected(eventsSelected.filter((e) => e !== event));
        }
    };

    /**
     * TODO: This will have to be caliberated for all event types
     * @param {} event
     * @returns
     */
    const prepareDocumentBuilderState = (event) => ({
        ...event,
        eventID: undefined,
        createdOn: undefined,
        systemTimestamp: undefined,
        accountID: undefined,
        departmentID: undefined,
        userID: undefined,
    });

    const prepareDocumentBuilderStateForStage = (stage) => ({
        name: stage.stageName,
        category: stage.category,
        sectionItems: stage.events.map((event) => prepareDocumentBuilderState(event)),
    });

    const prepareDocumentBuilderStateForSection = (section) => ({
        // This will need to handle questions too.
        ...section,
        title: section.title,
        sectionItems: section.questions.map((question) => ({
            ...question,
        })),
    });

    const fetchSection = async (sectionID) => {
        const response = await documentManagementAPIs.getSection(sectionID);
        return prepareDocumentBuilderStateForSection(response.data);
    };

    const viewSection = (sectionID) => {
        if (responseID) {
            history.push(`/${context.toLowerCase()}/view/${sectionID}/import/${templateType || '_'}/${questionnaireID}/${projectID}/${eventID}/${responseID}`);
        } else {
            history.push(`/${context.toLowerCase()}/view/${sectionID}/import/${templateType || '_'}/${questionnaireID || '_'}`);
        }
    };

    const selectSection = (sectionID) => {
        fetchSection(sectionID)
            .then((results) => {
                const currentState = documentBuilderState;
                if (!currentState.sections) {
                    currentState.sections = [];
                }
                currentState.sections.push(results);
                dispatch(actions.setBuilderState(currentState));
                if (responseID) {
                    history.push(`/questionnaires/edit/${questionnaireID}/${projectID}/${eventID}/imported/${responseID}`);
                } else if (questionnaireID) {
                    history.push(`/questionnaires/edit/${questionnaireID}/_/_/imported`);
                } else {
                    history.push(`/questionnaires/draft/${type}/_/_/imported`);
                }
                // history.goBack();
            })
            .catch(() => {
                Toast.fire({
                    icon: 'error',
                    title: 'Unable to import section.',
                });
            });
    };

    const fetchStage = async (stageID) => {
        const data = await projectManagementAPIs.getStage(stageID);
        return prepareDocumentBuilderStateForStage(data.data);
    };

    const selectStage = (stageID) => {
        fetchStage(stageID)
            .then((results) => {
                const currentState = documentBuilderState;
                if (!currentState.sections) {
                    currentState.sections = [];
                }
                currentState.sections.push(results);
                dispatch(actions.setBuilderState(currentState));
                if (contractID === '_' && projectID && projectID !== '_' && workflowID && workflowID !== '_') {
                    history.push(`/workflows/project/edit/${projectID}/${workflowID}/imported`);
                } else if (contractID && contractID !== '_' && projectID && projectID !== '_' && workflowID && workflowID !== '_') {
                    history.push(`/workflows/contract/edit/${projectID}/${workflowID}/${contractID}/imported`);
                } else if (resourceName && resourceName !== '_' && projectID && projectID !== '_') {
                    history.push(`/workflows/${resourceName}/${projectID}/${routeToMarketParam}/create/imported`);
                } else if (workflowID && workflowID !== '_') {
                    history.push(`/workflows/edit/${workflowID}/imported`);
                } else {
                    history.push(`/workflows/_/_/${routeToMarketParam}/create/imported`);
                }
            })
            .catch(() => {
                Toast.fire({
                    icon: 'error',
                    title: 'Unable to import stage.',
                });
            });
    };

    const formattedContext = (cntxt) => {
        switch (cntxt) {
            case 'contractsRegister':
                return 'awarded contracts';
            case 'calloff':
                return 'Call Off';
            case 'rebates':
                return 'rebate';
            default:
                return cntxt.toLowerCase();
        }
    };

    const formattedSuppliersName = (data) => {
        const unique = [];
        if (data?.isPublic && data?.awardedSuppliers?.length > 0) {
            data?.awardedSuppliers?.reduce((previousValue, currentValue) => previousValue
                .concat(currentValue), [])
                .map((supplier) => (unique
                    .filter((previousValue) => previousValue.account?.companyName
                    === supplier.account?.companyName)
                    .length > 0 || supplier.supplierStatus === 'inactive' ? null
                    : unique.push(supplier.account?.companyName)));
        } else {
            data?.awardedSuppliersName?.reduce((previousValue, currentValue) => previousValue
                .concat(currentValue), [])
                .map((supplier) => (unique
                    .filter((previousValue) => previousValue === supplier)
                    .length > 0 || supplier.supplierStatus === 'inactive' ? null
                    : unique.push(supplier)));
        }
        return unique.length > 0 ? unique.join(', ') : '-';
    };

    const formatMessage = (data) => {
        if (data.length > 525) {
            return `${data.substring(0, 525)}...`;
        }
        return data;
    };

    const getRecipientsName = (recipients) => {
        const recipientsNames = recipients
            .map((recipient) => recipient.account.companyName);
        const recipientsNamesJoined = recipientsNames.join(', ');
        return recipientsNamesJoined;
    };

    const getCorrespondenceLinkURL = (originalTopicID) => {
        if (isSupplierRole(topicList[originalTopicID]?.senderAccount?.accountType)) {
            return `/${resourceType}/correspondence/overview/${resourceId}/${originalTopicID}/single`;
        }
        return `/${resourceType}/correspondence/overview/${resourceId}/${originalTopicID}`;
    };


    const generateAccordionSectionContent = (data) => {
        switch (context.toLowerCase()) {
            case 'opportunities':
                return <>
                    <div className='bdAccordionSectionContentRow'>
                        <div className='bdAccordionSectionContentData'><p className='label body'>Buyer: </p><p className='value caption'>
                            {data?.account?.companyName || '-'}</p></div>
                        <div className='bdAccordionSectionContentData'><p className='label body'>Expression of interest start date: </p><p className='value caption'>
                            {data?.expressionWindowStartDate ? generatePrettyDate(data.expressionWindowStartDate) : '-'}</p></div>
                    </div>
                    <div className='bdAccordionSectionContentRow'>
                        <div className='bdAccordionSectionContentData'><p className='label body'>Estimated value: </p><p className='value caption'>
                            {helperFunctions.formatCurrency(data.estimatedValue) !== '' ? helperFunctions.formatCurrency(data.estimatedValue) : '-'}</p></div>
                        <div className='bdAccordionSectionContentData'><p className='label body'>Expression of interest end date: </p><p className='value caption'>
                            {data?.expressionWindowEndDate ? generatePrettyDate(data.expressionWindowEndDate) : '-'}</p></div>
                    </div>
                    <div className='bdAccordionSectionContentRow'>
                        <div className='bdAccordionSectionContentData'><p className='label body'>Event: </p><p className='value caption'>
                            {data?.eventName || '-' }</p></div>
                        <div className='bdAccordionSectionContentData'><p className='label body'>Submission end date: </p><p className='value caption'>
                            {data?.submissionEndDate ? generatePrettyDate(data.submissionEndDate) : '-'}</p></div>
                    </div>
                    <div className='bdAccordianSectionButton'>
                        <Button
                            id={`${context.toLowerCase()}Btn-${data.id}`}
                            variant='tertiary'
                            label={`View ${contextSingular}`}
                            handleClick={
                                authContext.authenticated === true ? () => history.push(`/${context.toLowerCase()}/overview/${data.opportunityID}`)
                                    : () => history.push(`/${context.toLowerCase()}/overview/public/${data.opportunityID}`)
                            } />
                    </div>
                </>;
            case 'contractsregister':
                return <>
                    <div className='bdAccordionSectionContentRow'>
                        <div className='bdAccordionSectionContentData'><p className='label body'>Buyer: </p><p className='value caption'>
                            {data?.account?.companyName || '-'}</p></div>
                        <div className='bdAccordionSectionContentData'><p className='label body'>Start date: </p><p className='value caption'>
                            {generatePrettyDate(data?.contractStart) || '-'}</p></div>
                    </div>
                    <div className='bdAccordionSectionContentRow'>
                        <div className='bdAccordionSectionContentData width70'><p className='label body'>Awarded supplier(s): </p><p className='value caption'>
                            {data ? <ShowMoreShowLess
                                inputText={formattedSuppliersName(data)}
                                inputTextCharacterLimit={30} /> : '-'}</p>
                        </div>
                        <div className='bdAccordionSectionContentData'><p className='label body'>End date: </p><p className='value caption'>
                            {generatePrettyDate(data?.contractEnd) || '-'}</p></div>
                    </div>
                    <div className='bdAccordionSectionContentRow'>
                        <div className='bdAccordionSectionContentData'><p className='label body'>Estimated value: </p><p className='value caption'>
                            {helperFunctions.formatCurrency(data.awardValue) !== '' ? helperFunctions.formatCurrency(data.awardValue) : '-'}</p></div>
                        {/* <div className='bdAccordionSectionContentData'>
                        <p className='label body'>Extension date: </p>
                        <p className='value caption'>{data.extensionDate}</p>
                        </div> */}
                    </div>
                    <div className='bdAccordianSectionButton'>
                        <Button
                            id={`contractBtn-${data.contractID}`}
                            variant='tertiary'
                            label='View Awarded Contract'
                            handleClick={() => history.push(`/contracts-register/overview/${data.contractID}`)} />
                    </div>
                </>;

            case 'suppliers':
                return <>
                    <div className='bdAccordionSectionContentRow'>
                        <div className='bdAccordionSectionContentColumn'>
                            <div className='bdAccordionSectionContentData'>
                                <p className='label body'>{Constants.companySize}</p>
                                <p className='value caption'>{data?.noOfEmployees || '-'}</p>
                            </div>
                        </div>
                        <div className='bdAccordionSectionContentColumn'>
                            <div className='bdAccordionSectionContentData'>
                                <p className='label body'>{Constants.postcode}</p>
                                <p className='value caption'>{data?.postcode || '-'}</p>
                            </div>
                            <div className='bdAccordionSectionContentData'>
                                <p className='label body'>{Constants.locality}</p>
                                <p className='value caption'>{data?.localityName || '-'}</p>
                            </div>
                        </div>
                    </div>
                    <div className='bdAccordianSectionButton'>
                        <Button
                            id={`${context.toLowerCase()}Btn-${data.id}`}
                            variant='tertiary'
                            label={`View ${contextSingular}`}
                            handleClick={() => history.push(`/${contextSingular.toLowerCase()}/overview/${data.supplierID}`)} />
                        {opportunityID
                        && <div className='bdAccordianSectionButton'>
                            <Button
                                id={`add${context.toLowerCase()}Btn-${data.id}`}
                                variant='tertiary'
                                label='Add Supplier'
                                handleClick={() => selectSupplier(data, routeToMarketParam)} />
                        </div>}
                    </div>
                </>;
            case 'projects':
                return <>
                    <div className='bdAccordionSectionContentRow'>
                        <div className='bdAccordionSectionContentData'><p className='label body'>Description: </p><p className='value caption'>
                            {data?.description || '-'}</p></div>
                        {selectedTab === 'nepo' && <div className='bdAccordionSectionContentData'><p className='label body'>Buyer: </p><p className='value caption'>
                            {data?.account?.companyName || '-'}</p></div>}
                    </div>
                    <div className='bdAccordionSectionContentRow'>
                        <div className='bdAccordionSectionContentData'><p className='label body'>Contract Start Date: </p><p className='value caption'>
                            {data?.contractStart || '-'}</p></div>
                        <div className='bdAccordionSectionContentData'><p className='label body'>Contract End Date: </p><p className='value caption'>
                            {data?.contractEnd || '-'}</p></div>
                    </div>
                    <div className='bdAccordianSectionButton'>
                        <Button
                            id={`${context.toLowerCase()}Btn-${data.projectID}`}
                            variant='tertiary'
                            label={`View ${contextSingular}`}
                            handleClick={() => history.push(`/${context.toLowerCase()}/overview/${data.projectID}`)} />
                    </div>
                </>;
            case 'requests':
                return <>
                    <div className='bdAccordionSectionContentRow'>
                        <div className='bdAccordionSectionContentData'>
                            <p className='label body'>Description: </p>
                            <p className='value caption'>{data?.description || '-'}</p>
                        </div>
                    </div>
                    <div className='bdAccordionSectionContentRow'>
                        <div className='bdAccordionSectionContentData'><p className='label body'>Request start date: </p><p className='value caption'>
                            {data?.contractStart || '-'}</p></div>
                        <div className='bdAccordionSectionContentData'><p className='label body'>Request duration: </p><p className='value caption'>
                            {data?.contractDuration || '-'}</p></div>
                    </div>
                    <div className='bdAccordianSectionButton'>
                        <Button
                            id={`${context.toLowerCase()}Btn-${data.projectID}`}
                            variant='tertiary'
                            label={`View ${contextSingular}`}
                            handleClick={() => history.push(`/procurement-${context.toLowerCase()}/overview/${data.projectID}`)} />
                    </div>
                </>;
            case 'workflows': {
                return <>
                    <div className='bdAccordionSectionContentRow'>
                        <div className='bdAccordionSectionContentData'>
                            <p className='label body'>Description: </p>
                            <p className='value caption'>{data?.workflowDescription || '-'}</p>
                        </div>
                    </div>
                    {action.type === 'view' ? <div className='bdAccordianSectionButton'>
                        <Button
                            id={`${context.toLowerCase()}Btn-${data.workflowID}`}
                            variant='tertiary'
                            label={`View ${contextSingular}`}
                            handleClick={() => history.push(`/${context.toLowerCase()}/view/${data.workflowID}`)} />
                    </div>
                        : <div className='bdAccordianSectionButton'>
                            <Button
                                id={`${context.toLowerCase()}Btn-${data.id}`}
                                variant='tertiary'
                                label={`View ${contextSingular}`}
                                handleClick={accountID
                                    ? () => history.push(`/${context.toLowerCase()}/view/${data.workflowID}/${projectID}/import/${accountID}/${callOffMechanism}`)
                                    : () => history.push(`/${context.toLowerCase()}/view/${data.workflowID}/${projectID}/import`)
                                } />
                            <Button
                                id={`${context.toLowerCase()}Btn-${data.workflowID}`}
                                variant='tertiary'
                                label={`Select ${contextSingular}`}
                                handleClick={() => action.cb(data.workflowID) } />
                        </div>
                    }
                </>;
            }
            case 'solutions':
                return <>
                    <div className='bdAccordionSectionContentRow'>
                        <div className='bdAccordionSectionContentData'><p className='label body'>Solution by: </p><p className='value caption'>
                            {data?.account?.companyName || '-'}</p></div>
                        <div className='bdAccordionSectionContentData'><p className='label body'>Start date: </p><p className='value caption'>
                            {data?.contractStart || '-'}</p></div>
                    </div>
                    <div className='bdAccordionSectionContentRow'>
                        <div className='bdAccordionSectionContentData'><p className='label body'>Project Type: </p><p className='value caption'>{
                            data?.projectDescribes?.length > 0
                                ? data?.projectDescribes.map((role, i) => {
                                    if (i + 1 === data?.projectDescribes.length) {
                                        return role ? `${helperFunctions.camel2title(role)}` : '-';
                                    }
                                    return role ? `${helperFunctions.camel2title(role)}, ` : '-';
                                })
                                : '-'}</p></div>
                        <div className='bdAccordionSectionContentData'><p className='label body'>Current end date: </p><p className='value caption'>
                            {data?.contractEnd || '-'}</p></div>
                    </div>
                    <div className='bdAccordianSectionButton'>
                        <Button
                            id={`${context.toLowerCase()}Btn-${data.id}`}
                            variant='tertiary'
                            label={`View ${contextSingular}`}
                            handleClick={() => history.push(`/${context.toLowerCase()}/overview/${data.solutionID}`)} />
                    </div>
                </>;
            case 'spends':
                return <>
                    <div className='bdAccordionSectionContentRow'>
                        <div className='bdAccordionSectionContentData'><p className='label body'>Upload date: </p><p className='value caption'>
                            {data?.lastModified || '-'}</p></div>
                    </div>
                    <div className='bdAccordianSectionButton'>
                        <Button
                            id={`${context.toLowerCase()}Btn-${data.documentID}`}
                            variant='tertiary'
                            label={'Download'}
                            handleClick={
                                () => handleDownload(data)
                            } />
                    </div>
                </>;
            case 'rebates':
                return <>
                    <div className='bdAccordionSectionContentRow'>
                        <div className='bdAccordionSectionContentData'><p className='label body'>Upload date: </p><p className='value caption'>
                            {data?.lastModified || '-'}</p></div>
                    </div>
                    <div className='bdAccordianSectionButton'>
                        <Button
                            id={`${context.toLowerCase()}Btn-${data.documentID}`}
                            variant='tertiary'
                            label={'Download'}
                            handleClick={
                                () => handleDownload(data)
                            } />
                    </div>
                </>;
            case 'questionnairesselect':
                return <>
                    <div className='bdAccordionSectionContentRow'>
                        <div className='bdAccordionSectionContentData'>
                            <p className='label body'>Description: </p>
                            { getDescriptionBlock(data.description || '-', RICH_TEXT.QUESTIONNAIRE_DESCRIPTION) }
                        </div>
                    </div>
                    <div className='bdAccordionSectionContentRow'>
                        <div className='bdAccordionSectionContentData'><p className='label body'>Date created: </p><p className='value caption'>
                            {data?.createdOn ? generatePrettyDate(data.createdOn) : '-'}</p></div>
                    </div>
                    <div className='bdAccordianSectionButton'>
                        <Button
                            id={`${context.toLowerCase()}Btn-${data.templateID}`}
                            variant='tertiary'
                            label={`View ${contextSingular}`}
                            handleClick={() => history.push(`/questionnaires/view/${data.templateID}`)}
                        />
                        <Button
                            id={`${context.toLowerCase()}Btn-${data.templateID}`}
                            variant='tertiary'
                            label={`Select ${contextSingular}`}
                            handleClick={() => action.cb(data) } />
                    </div>
                </>;
            case 'questionnaires':
                return <>
                    <div className='bdAccordionSectionContentRow'>
                        <div className='bdAccordionSectionContentData'>
                            <p className='label body'>Description: </p>
                            { getDescriptionBlock(data.description || '-', RICH_TEXT.QUESTIONNAIRE_DESCRIPTION) }
                        </div>
                    </div>
                    <div className='bdAccordionSectionContentRow'>
                        <div className='bdAccordionSectionContentData'><p className='label body'>Type: </p><p className='value caption'>
                            {data?.templateType ? getPrettyQuestionnaireType(data.templateType) : '-'}</p>
                        </div>
                        <div className='bdAccordionSectionContentData'><p className='label body'>Date created: </p><p className='value caption'>
                            {data?.createdOn ? generatePrettyDate(data.createdOn) : '-'}</p></div>
                    </div>
                    {/* TODO enable EVALUATION PANEL after the release
                     {data.evaluationPanel
                         && <div className='bdAccordionSectionContentRow'>
                             <div className='bdAccordionSectionContentData'>
                                 <p className='label body'>{evaluationPanel}:</p>
                                 <p className='value caption'>
                                     {helperFunctions
                                         .getEvaluationPanelLabel(data.evaluationPanel)}</p>
                             </div>
                         </div>
                    } */}
                    <div className='bdAccordianSectionButton'>
                        <Button
                            id={`${context.toLowerCase()}Btn-${data.id}`}
                            variant='tertiary'
                            label={`View ${contextSingular}`}
                            handleClick={() => history.push(`/${context.toLowerCase()}/view/${data.templateID}`)} />
                    </div>
                </>;
            case 'sections':
                return <>
                    <div className='bdAccordionSectionContentRow'>
                        <div className='bdAccordionSectionContentData'><p className='label body'>Description: </p><p className='value caption'>
                            { getDescriptionBlock(data?.description || '-', 'section-description') }</p></div>
                    </div>
                    {action.type === 'view' ? <div className='bdAccordianSectionButton'>
                        <Button
                            id={`${context.toLowerCase()}Btn-${data.id}`}
                            variant='tertiary'
                            label={`View ${contextSingular}`}
                            handleClick={() => history.push(`/${context.toLowerCase()}/view/${data.sectionID}`)}
                        />
                    </div>
                        : <div className='bdAccordianSectionButton'>
                            <Button
                                id={`${context.toLowerCase()}Btn-${data.id}`}
                                variant='tertiary'
                                label={`View ${contextSingular}`}
                                handleClick={() => viewSection(data.sectionID)} />
                            <Button
                                id={`${context.toLowerCase()}Btn-${data.sectionID}`}
                                variant= {'tertiary'}
                                label={'Import Section'}
                                handleClick={() => selectSection(data.sectionID) } />
                        </div>
                    }
                </>;
            case 'contracts':
                return <>
                    <div className='bdAccordionSectionContentRow'>
                        <div className='bdAccordionSectionContentData'><p className='label body'>Description: </p><p className='value caption'>
                            {data?.description || '-'}</p></div>
                    </div>
                    <div className='bdAccordionSectionContentRow'>
                        <div className='bdAccordionSectionContentData'><p className='label body'>Contract start date: </p><p className='value caption'>
                            {data?.contractStart || '-'}</p></div>
                        <div className='bdAccordionSectionContentData'><p className='label body'>Contract end date: </p><p className='value caption'>
                            {data?.contractEnd || '-'}</p></div>
                    </div>
                    <div className='bdAccordianSectionButton'>
                        <Button
                            id={`${context.toLowerCase()}Btn-${data.contractID}`}
                            variant='tertiary'
                            label={`View ${contextSingular}`}
                            handleClick={
                                () => (isSupplierRole(authContext?.user?.accountType)
                                    ? history.push(`/${context.toLowerCase()}/overview/${data.contractID}`)
                                    : history.push(`/${context.toLowerCase()}/management/${data.contractID}`)
                                )
                            }/>
                    </div>
                </>;
            case 'events': {
                return <>
                    <div className='bdAccordionSectionContentRow'>
                        <div className='bdAccordionSectionContentData'>
                            <p className='label body'>Description: </p>
                            <p className='value caption'>{data?.description || '-'}</p>
                        </div>
                        <div className='bdAccordionSectionContentData'>
                            <p className='label body'>Date created: </p>
                            <p className='value caption'>{data?.createdOn ? generatePrettyDate(data.createdOn) : '-'}</p>
                        </div>
                    </div>
                    <div className='bdAccordionSectionContentRow'>
                        <div className='bdAccordionSectionContentData'>
                            <p className='label body'>Type: </p>
                            <p className='value caption'>{data?.eventType ? getPrettyEventType(data.eventType) : '-'}</p>
                        </div>
                        <div className='bdAccordionSectionContentData'>
                            <p className='label body'>Role responsible: </p>
                            <p className='value caption'>{data?.rolesResponsible ? getPrettySteeringGroupRole(data.rolesResponsible) : '-'}</p>
                        </div>
                    </div>
                    {action.type === 'view'
                        ? <div className='bdAccordianSectionButton'>
                            <Button
                                id={`${context.toLowerCase()}Btn-${data.eventID}-view`}
                                variant='tertiary'
                                label={`View ${contextSingular}`}
                                handleClick={() => handleView(data.eventID)}
                            />
                            {allowEdit && <Button
                                id={`${context.toLowerCase()}Btn-${data.eventID}-edit`}
                                variant='tertiary'
                                label={`Edit ${contextSingular}`}
                                handleClick={() => handleEdit(data.eventID)}
                            />}
                        </div>
                        : <div className='bdAccordianSectionButton'>
                            <Button
                                id={`${context.toLowerCase()}Btn-${data.eventID}`}
                                variant= {eventsSelected.indexOf(data.eventID) > -1 ? 'primary' : 'tertiary'}
                                additionalVariant = {'selected-event'}
                                label={eventsSelected.indexOf(data.eventID) > -1 ? 'Selected' : 'Select Event'}
                                handleClick={() => selectEvents(data.eventID) } />
                            <Button
                                id={`${context.toLowerCase()}Btn-${data.eventID}-view`}
                                variant='tertiary'
                                label={`View ${contextSingular}`}
                                handleClick={() => handleView(data.eventID)}
                            />
                        </div>
                    }
                </>;
            }
            case 'stages': {
                return <>
                    <div className='bdAccordionSectionContentRow'>
                        <div className='bdAccordionSectionContentData'>
                            <p className='label body'>Route to market: </p>
                            <p className='value caption'>{data?.routeToMarket ? getPrettyRTM(data.routeToMarket) : '-'}</p>
                        </div>
                        <div className='bdAccordionSectionContentData'>
                            <p className='label body'>Date created: </p>
                            <p className='value caption'>{data.createdOn ? generatePrettyDate(data.createdOn) : '-'}</p>
                        </div>
                    </div>
                    <div className='bdAccordionSectionContentRow'>
                        <div className='bdAccordionSectionContentData'>
                            <p className='label body'>Number of events: </p>
                            <p className='value caption'>{data?.events?.length || '-'}</p>
                        </div>
                    </div>
                    {action.type === 'view'
                        ? <div className='bdAccordianSectionButton'>
                            <Button
                                id={`${context.toLowerCase()}Btn-${data.stageID}-view`}
                                variant='tertiary'
                                label={`View ${contextSingular}`}
                                handleClick={() => history.push(`/${context.toLowerCase()}/view/${data.stageID}`)}
                            />
                            <Button
                                id={`${context.toLowerCase()}Btn-${data.stageID}-edit`}
                                variant='tertiary'
                                label={`Edit ${contextSingular}`}
                                handleClick={() => history.push(`/${context.toLowerCase()}/edit/${data.stageID}`)}
                            />
                        </div>
                        : <div className='bdAccordianSectionButton'>
                            <Button
                                id={`${context.toLowerCase()}Btn-${data.stageID}`}
                                variant= {'tertiary'}
                                label={'Select Stage'}
                                handleClick={() => selectStage(data.stageID) }
                            />
                            <Button
                                id={`${context.toLowerCase()}Btn-${data.stageID}-view`}
                                variant='tertiary'
                                label={`View ${contextSingular}`}
                                handleClick={() => history.push(`/${context.toLowerCase()}/view/${data.stageID}`)}
                            />
                        </div>
                    }
                </>;
            }
            case 'correspondence':
                return <>
                    <div className='bdAccordionSectionContentRow'>
                        <div className='bdAccordionSectionContentData'>
                            <p className='value caption line-break'>
                                {data?.message ? formatMessage(data.message) : '-'}</p>
                        </div>
                    </div>
                    <div className='bdAccordionSectionContentRow'>
                        <div className='bdAccordionSectionContentData'><p className='label body'>{Constants.createdBy}</p><p className='value caption'>
                            {data?.senderAccount?.companyName || '-'}</p></div>
                        <div className='bdAccordionSectionContentData'><p className='label body'>{Constants.createdOn}</p><p className='value caption'>
                            {data?.createdOn ? helperFunctions.formatPrettyDateTime(data.createdOn) : '-'}</p></div>
                    </div>
                    {!isSupplierRole(authContext?.user?.accountType)
                    && <>
                        <div className='bdAccordionSectionContentRow'>
                            <div className='bdAccordionSectionContentData'><p className='label body'>{Constants.sentTo}</p><p className='value caption'>
                                {data.recipientAccountIDs?.length > 0 ? <ShowMoreShowLess
                                    inputText={getRecipientsName(data.recipients)}
                                    inputTextCharacterLimit={30} /> : Constants.all}</p>
                            </div>
                        </div>
                        {data.originalTopicID
                        && <div className='bdAccordionSectionContentRow'>
                            <div className='bdAccordionSectionContentData'><p className='label body'>{Constants.originalTopic}</p>
                                <Link className='value caption' key={data.id} to={
                                    getCorrespondenceLinkURL(data.originalTopicID)
                                }>
                                    {Constants.originalTopicLinkTitle}
                                </Link></div>
                        </div>}
                    </>
                    }
                    {!isSupplierRole(authContext?.user?.accountType)
                                        && data?.senderAccountID === authContext.user.accountId
                        ? <Button
                            id={`${context.toLowerCase()}Btn-${data.correspondenceID}`}
                            variant='tertiary'
                            label={`View ${contextSingular}`}
                            handleClick={() => history.push(`/${resourceType}/correspondence/overview/${resourceId}/${data.topicID}`)} />
                        : <Button
                            id={`${context.toLowerCase()}Btn-${data.correspondenceID}`}
                            variant='tertiary'
                            label={`View ${contextSingular}`}
                            handleClick={() => history.push(`/${resourceType}/correspondence/overview/${resourceId}/${data.topicID}/single`)} />
                    }

                </>;
            case 'calloff':
                return <>
                    <div className='bdAccordionSectionContentRow'>
                        <div className='bdAccordionSectionContentData'><p className='label body'>Description: </p><p className='value caption'>
                            {data?.description || '-'}</p></div>
                    </div>
                    <div className='bdAccordionSectionContentRow'>
                        <div className='bdAccordionSectionContentData'><p className='label body'>Contract Start Date: </p><p className='value caption'>
                            {data?.contractStart || '-'}</p></div>
                        <div className='bdAccordionSectionContentData'><p className='label body'>Contract End Date: </p><p className='value caption'>
                            {data?.contractEnd || '-'}</p></div>
                    </div>
                    <div className='bdAccordianSectionButton'>
                        <Button
                            id={`${context.toLowerCase()}Btn-${data.projectID}`}
                            variant='tertiary'
                            label={`View ${formattedContext(contextSingular)}`}
                            handleClick={() => history.push(`/${context.toLowerCase()}/overview/${data.projectID}`)} />
                    </div>
                </>;
            case browseDataContext:
                return <>
                    <div className='bdAccordionSectionContentRow'>
                        <div className='bdAccordionSectionContentData'><p className='label body'>{Constants.createdBy}</p><p className='value caption'>
                            {`${data?.username}, ${data?.departmentName}, ${data?.accountName}` || '-'}</p></div>
                        <div className='bdAccordionSectionContentData'><p className='label body'>{Constants.createdOn}</p><p className='value caption'>
                            {data?.systemTimestamp ? helperFunctions.formatPrettyDateTime(data.systemTimestamp) : '-'}</p></div>
                    </div>
                </>;
            default:
                return <></>;
        }
    };

    const getBuyerStatus = (topic) => {
        if (!isSupplierRole(authContext?.user?.accountType)) {
            if (topic?.buyerStatus) {
                return topic.buyerStatus;
            }

            if (!topic.hasThreads && isSupplierRole(topic.senderAccount?.accountType)) {
                if (topic.newOrUnread) {
                    return BUYER_STATUS.UNREAD.value;
                }
                return BUYER_STATUS.ACTION_NEEDED.value;
            }
        }
        return undefined;
    };

    const generateAccordionData = () => {
        const elements = [];
        if (filteredData) {
            filteredData.forEach((data, idx) => {
                elements.push({
                    sectionId: `bdAccordionSection-id-${idx}`,
                    header: generateAccordionSectionHeader(data),
                    content: generateAccordionSectionContent(data),
                    buyerStatus: getBuyerStatus(data),
                });
            });
        }

        return elements;
    };

    const generateFilterPanel = () => <BrowseFilterPanel
        open={filterPanelOpen}
        closePanel={toggleFilterPanel}
        handleFilterCallback={handleSearchSortFilter}
        context={isSupplierRole(authContext?.user?.accountType)
            && context.toLowerCase() === 'contracts' ? 'contracts_for_supplier' : context}
        searchSortFilterData={searchSortFilterData}
    />;

    const getTabs = () => {
        let rbacTabs = tabOptions;
        if (!(isNepoRole(authContext?.user?.accountType)
            && isAdminRole(authContext))) {
            rbacTabs = tabOptions?.filter((option) => option !== 'All Organisations');
        }
        if (rbacTabs) {
            return <Tabs
                data={rbacTabs}
                handleClick={(event) => handleTabSelected(event)}
                defaultTab={defaultTab} />;
        }
        return <></>;
    };

    const fetchEvent = async (event) => {
        const data = await projectManagementAPIs.getEvent(event);
        return prepareDocumentBuilderState(data.data);
    };

    const importEvents = () => {
        Promise.all(eventsSelected.map((event) => fetchEvent(event)))
            .then((results) => {
                const currentState = documentBuilderState;
                if (!currentState.sections[action.stage].sectionItems) {
                    currentState.sections[action.stage].sectionItems = [];
                }
                currentState.sections[action.stage].sectionItems.push(...results);
                dispatch(actions.setBuilderState(currentState));

                if (contractID === '_' && projectID && projectID !== '_' && workflowID && workflowID !== '_') {
                    history.push(`/workflows/project/edit/${projectID}/${workflowID}/imported`);
                } else if (contractID && contractID !== '_' && projectID && projectID !== '_' && workflowID && workflowID !== '_') {
                    history.push(`/workflows/contract/edit/${projectID}/${workflowID}/${contractID}/imported`);
                } else if (resourceName && resourceName !== '_' && projectID && projectID !== '_') {
                    history.push(`/workflows/${resourceName}/${projectID}/${routeToMarketParam}/create/imported`);
                } else if (workflowID && workflowID !== '_') {
                    history.push(`/workflows/edit/${workflowID}/imported`);
                } else {
                    history.push(`/workflows/_/_/${routeToMarketParam}/create/imported`);
                }

                // if (workflowID) {
                //     history.push(`/workflows/edit/${workflowID}/imported`);
                // } else {
                //     history.push(`/workflows/${routeToMarketParam}/create/imported`);
                // }
            })
            .catch(() => {
                Toast.fire({
                    icon: 'error',
                    title: 'Unable to import event.',
                });
            });
    };

    const getHeaderForSelectPage = () => {
        switch (context.toLowerCase()) {
            case 'events': {
                return <>
                    {action.type === 'view' ? <></>
                        : <>

                            {
                                <div className={'dashboardCard large'} id={'projectActions'}>
                                    <div className={'projectActionContainer'}>
                                        <div className={'cancelButtonContainer'}>
                                            <p className='title import-title'>{`Import events to ${action.stageName}`}</p>
                                            <div>
                                                <p className='caption'>{`${eventsSelected.length} events selected`}</p>
                                            </div>
                                        </div>
                                        <Button
                                            id={`${context.toLowerCase()}Btn-back`}
                                            variant= 'secondary'
                                            label={'Back'}
                                            handleClick={() => {
                                                if (contractID === '_' && projectID && projectID !== '_' && workflowID && workflowID !== '_') {
                                                    history.push(`/workflows/project/edit/${projectID}/${workflowID}/imported`);
                                                } else if (contractID && contractID !== '_' && projectID && projectID !== '_' && workflowID && workflowID !== '_') {
                                                    history.push(`/workflows/contract/edit/${projectID}/${workflowID}/${contractID}/imported`);
                                                } else if (resourceName && resourceName !== '_' && projectID && projectID !== '_') {
                                                    history.push(`/workflows/${resourceName}/${projectID}/${routeToMarketParam}/create/imported`);
                                                } else if (workflowID && workflowID !== '_') {
                                                    history.push(`/workflows/edit/${workflowID}/imported`);
                                                } else {
                                                    history.push(`/workflows/_/_/${routeToMarketParam}/create/imported`);
                                                }
                                            } } />
                                        <Button
                                            id={`${context.toLowerCase()}Btn`}
                                            additionalVariant = {'primaryToolbarBtn'}
                                            variant= 'primary'
                                            label={'Import Event(s)'}
                                            handleClick={() => importEvents() } />
                                    </div>
                                </div>
                            }
                        </>
                    }
                </>;
            }
            case 'stages': {
                return <>
                    {action.type === 'view' ? <></>
                        : <>

                            {
                                <div className={'dashboardCard large'} id={'projectActions'}>
                                    <div className={'projectActionContainer'}>
                                        <div className={'cancelButtonContainer'}>
                                            <p className='title import-title'>{`Import stage to ${action.workflowName}`}</p>
                                        </div>
                                        <Button
                                            id={`${context.toLowerCase()}Btn-back`}
                                            variant= 'secondary'
                                            label={'Back'}
                                            handleClick={() => {
                                                if (contractID === '_' && projectID && projectID !== '_' && workflowID && workflowID !== '_') {
                                                    history.push(`/workflows/project/edit/${projectID}/${workflowID}/imported`);
                                                } else if (contractID && contractID !== '_' && projectID && projectID !== '_' && workflowID && workflowID !== '_') {
                                                    history.push(`/workflows/contract/edit/${projectID}/${workflowID}/${contractID}/imported`);
                                                } else if (resourceName && resourceName !== '_' && projectID && projectID !== '_') {
                                                    history.push(`/workflows/${resourceName}/${projectID}/${routeToMarketParam}/create/imported`);
                                                } else if (workflowID && workflowID !== '_') {
                                                    history.push(`/workflows/edit/${workflowID}/imported`);
                                                } else {
                                                    history.push(`/workflows/_/_/${routeToMarketParam}/create/imported`);
                                                }
                                            } } />
                                    </div>
                                </div>
                            }
                        </>
                    }
                </>;
            }
            case 'sections': {
                return <>
                    {action.type === 'view' ? <></>
                        : <>
                            {
                                <div className={'dashboardCard large'} id={'projectActions'}>
                                    <div className={'projectActionContainer'}>
                                        <div className={'cancelButtonContainer'}>
                                            {/* Do we have a questionnaire id for a new one? */}
                                            <p className='title import-title'>{'Import section to questionnaire'}</p>
                                        </div>
                                        <Button
                                            id={`${context.toLowerCase()}Btn-back`}
                                            variant= 'secondary'
                                            label={'Back'}
                                            handleClick={() => {
                                                if (responseID) {
                                                    history.push(`/questionnaires/edit/${questionnaireID}/${projectID}/${eventID}/imported/${responseID}`);
                                                } else if (questionnaireID) {
                                                    history.push(`/questionnaires/edit/${questionnaireID}/_/_/imported`);
                                                } else {
                                                    history.push(`/questionnaires/draft/${type}/_/_/imported`);
                                                }
                                            }} />
                                    </div>
                                </div>
                            }
                        </>
                    }
                </>;
            }
            default:
                return <></>;
        }
    };

    const getContentBody = () => {
        if (filteredData.length > 0) {
            return <Accordion id='bdAccordion' isSupplierRole={isSupplierRole(authContext?.user?.accountType)} data={generateAccordionData()} open={true}/>;
        }
        if (context.toLowerCase() === 'spends') {
            return <p className='caption'>{`No ${contextSingular} files have been uploaded yet.`}</p>;
        }
        if (context.toLowerCase() === Constants.selectQuestionnaires) {
            return <p className='caption'>{ Constants.noResults }</p>;
        }
        return <p className='caption'>{`No ${formattedContext(context)} currently available.`}</p>;
    };

    return <>
        <div className='bdContentSectionWrapper'>
            {getHeaderForSelectPage()}
            <section className='bdContentSection' id={id}>
                {getTabs()}
                <div className='bdContentHeader' id='bdContentHeader'>
                    <div className='searchBarContainer'>
                        <SearchBar
                            id={`${context.toLowerCase()}SearchBar`}
                            placeholder={searchPlaceholder}
                            handleSearchCallback={handleSearchSortFilter}
                        />
                    </div>
                    <div className='sortFilterContainer'>
                        {(allowExportButton
                        || (context === 'contractsRegister' && (isNepoRole(authContext?.user?.accountType) || isLARole(authContext))))
                        && <Button
                            id={`${context.toLowerCase()}ExportButton`}
                            label={'Export'}
                            handleClick={() => exportFunction()}
                        />}
                        {allowSort && <SortDropdown
                            id={`${context.toLowerCase()}SortDropdown`}
                            options={sortOptions}
                            handleSortCallback={handleSearchSortFilter}
                        />}
                        {allowBackButton && <Button
                            id={`${context.toLowerCase()}backButton`}
                            label='Back'
                            variant='secondary'
                            handleClick={() => handleBack()}
                        />}
                        {allowFilter && <Button
                            id={`${context.toLowerCase()}FilterButton`}
                            label='Filter'
                            icon={true}
                            iconSrc={filterIcon}
                            handleClick={() => toggleFilterPanel()}
                        />}
                        {allowAddNew && <Button
                            id={'addNewButton'}
                            label={labelAddNew}
                            icon={true}
                            iconSrc={addIcon}
                            handleClick={() => handleAddNew()}
                            variant='primary'
                        />}
                        {(context.toLowerCase() === 'spends') && <Button
                            id={`${context.toLowerCase()}Btn-upload`}
                            label={'Upload Spend'}
                            variant='primary'
                            handleClick={(e) => uploadSpend(e)}
                        />}
                        {(context.toLowerCase() === 'rebates') && <Button
                            id={`${context.toLowerCase()}Btn-upload`}
                            label={'Upload Rebate'}
                            variant='primary'
                            handleClick={(e) => uploadRebate(e)}
                        />}
                    </div>
                </div>
                <div className='bdContentBody' aria-live='assertive'>
                    {filteredData && <InfiniteScroll
                        dataLength={filteredData.length}
                        next={loadMoreData}
                        hasMore={true}
                        endMessage={
                            <p className='title' id='endMessage'>
                                <b>You have seen it all.</b>
                            </p>
                        }
                    >
                        {getContentBody()}
                    </InfiniteScroll> }
                </div>
                {filterPanelOpen && generateFilterPanel()}
            </section ></div>
    </>;
};

BrowseData.propTypes = {
    id: PropTypes.string.isRequired,
    context: PropTypes.string.isRequired,
    exportFunction: PropTypes.func,
    action: PropTypes.object,
    newSpend: PropTypes.func,
    newRebate: PropTypes.func,
    contextSingular: PropTypes.string.isRequired,
    dataSet: PropTypes.array.isRequired,
    searchPlaceholder: PropTypes.string.isRequired,
    searchFilterAttribute: PropTypes.string.isRequired,
    sortOptions: PropTypes.array.isRequired,
    tabOptions: PropTypes.array,
    handleTabSelected: PropTypes.func,
    allowFilter: PropTypes.bool,
    allowAddNew: PropTypes.bool,
    labelAddNew: PropTypes.string,
    handleAddNew: PropTypes.func,
    handleView: PropTypes.func,
    handleEdit: PropTypes.func,
    allowEdit: PropTypes.bool,
    allowSort: PropTypes.bool,
    defaultTab: PropTypes.number,
    allowBackButton: PropTypes.bool,
    allowExportButton: PropTypes.bool,
    handleBack: PropTypes.func,
    searchSortFilter: PropTypes.object,
    selectedTab: PropTypes.string,
    topicList: PropTypes.array,
};

export default BrowseData;
