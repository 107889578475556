import {
    responseStatus, responseStatuses, responseStatusColour, dpsLabels, publishAtLevel,
} from '../config/constants';

const responseStatusValues = {
    colors: [
        responseStatuses[0].colour,
        responseStatuses[1].colour,
        responseStatuses[2].colour,
        responseStatuses[2].colour],
    labels: [
        responseStatuses[0].label,
        responseStatuses[1].label,
        responseStatuses[2].label,
        responseStatuses[2].label],
};

const getResponseStatusValues = (status, values) => ({
    [responseStatus.notStarted]: values[0],
    [responseStatus.inProgress]: values[1],
    [responseStatus.submitted]: values[2],
    [responseStatus.bidSubmitted]: values[3],
}[status || '']);


const getResponseStatusColor = (status, requiresUpdate, awaitingSupplierUpdate) => {
    if (requiresUpdate || awaitingSupplierUpdate) return responseStatusColour.amber;
    return getResponseStatusValues(status, responseStatusValues.colors);
};

const getResponseStatusLabel = (status, requiresUpdate, awaitingSupplierUpdate) => {
    if (requiresUpdate || awaitingSupplierUpdate) return responseStatus.updated;
    return getResponseStatusValues(status, responseStatusValues.labels);
};

const getLotOrProject = (publishLevel) => (publishLevel === publishAtLevel.lot
    ? dpsLabels.lotLabel : dpsLabels.projectLabel);

export {
    getResponseStatusColor,
    getResponseStatusLabel,
    getLotOrProject,
};
