import React, {
    useEffect, useState, useContext,
} from 'react';

import path from 'path';

import BrowseData from '../../features/BrowseData';
import Constants from './constants';
import documentManagementAPIs from '../../services/document-management.service';
import AuthContext from '../../context/AuthContext';
import UploadModal from '../../features/UploadModal';
import Toast from '../../components/Alerts/Toast/Toast';

import {
    handleDocumentSubmit,
    handleRemoveDocument,
    handleDownload,
    persistUploadedDocuments,
    mergeUploadedDocuments,
} from '../../utils/uploadHelper';

const BrowseRebates = () => {
    const [rebates, setRebates] = useState([]);
    const [documents, setDocuments] = useState([]);
    const [upload, setUpload] = useState(false);
    const [viewDocuments, setViewDocuments] = useState(false);

    const authContext = useContext(AuthContext);

    const fetchData = async () => {
        try {
            const responseData = await documentManagementAPIs.getDocumentByType('Rebate');
            if (responseData.status === 200) {
                setRebates(responseData.data.items);
            } else {
                Toast.fire({
                    icon: 'error',
                    titleText: 'Unable to retrieve information.',
                });
            }
        } catch (error) {
            Toast.fire({
                icon: 'error',
                titleText: 'Unable to retrieve information.',
            });
        }
    };

    useEffect(() => {
        fetchData({}, true);
    }, []);

    const handleUploadSubmit = async (e, document) => (
        handleDocumentSubmit(e, document, authContext, {
            documentInfo: 'REBATE',
            uploadCallback: async (docs) => {
                await persistUploadedDocuments(
                    'upload',
                    () => {
                        const mergedDocs = mergeUploadedDocuments(documents, docs);
                        setDocuments(mergedDocs);
                        return true;
                    },
                    () => {
                        setUpload(false);
                        fetchData();
                    },
                );
            },
            validationCallback: async (metadata) => {
                const allowedFileTypes = ['.xlsx', '.xls'];
                if (!metadata.every((file) => (
                    allowedFileTypes.includes(path.extname(file.filename))
                ))) {
                    Toast.fire({
                        icon: 'error',
                        titleText: 'Unable to upload document - File format must be .xlsx or .xls',
                    });
                    return false;
                }

                return true;
            },
        })
    );

    const handleUploadRemove = async (document) => (
        handleRemoveDocument(documents, document, setDocuments, {})
    );

    return <>
        <BrowseData
            id='browseRebates'
            context='rebates'
            contextSingular='rebate'
            dataSet={rebates}
            searchPlaceholder='Search for a rebate'
            numOfRowsPerPage={20}
            searchFilterAttribute='filename'
            sortOptions={Constants.sortOptions}
            newRebate={() => {
                setUpload(true);
            }}
            allowFilter={false}
        />
        {upload && <>
            <UploadModal
                mode='add'
                documents={documents}
                handleSubmit={handleUploadSubmit}
                handleRemoveDocument={handleUploadRemove}
                handleDownload={handleDownload}
                uploadIdentifier={'Rebate'}
                closeModal={() => setUpload(false)}/>
        </>}
        {viewDocuments && <>
            <UploadModal
                mode='view'
                documents={documents}
                setDocuments={setDocuments}
                handleSubmit={handleUploadSubmit}
                handleRemoveDocument={handleUploadRemove}
                handleDownload={handleDownload}
                uploadIdentifier={'Rebate'}
                closeModal={() => setViewDocuments(false)}/>
        </>}
    </>;
};

export default BrowseRebates;
