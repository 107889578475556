import React, { useState, useEffect, useContext } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { parseISO, isPast, isAfter } from 'date-fns';

import DashboardCard from '../../../components/DashboardCard';
import Button from '../../../components/Button';
import Modal from '../../../components/Modal';
import LabelledInput, { LabelledInputTypeText } from '../../../components/LabelledInput';
import LabelledTextArea from '../../../components/LabelledTextArea';
import LabelledSelect from '../../../components/LabelledSelect';
import Form from '../../../components/Form';
import rbac from '../../../rbac';
// import ProgressBar from '../../../features/ProgressBar';
import Accordion from '../../../features/Accordion';
import WorknotesModal from '../../../features/WorknotesModal';
import Upload from '../../../features/Upload';
import SteeringGroup from '../../ContractOverview/components/SteeringGroup';
import { allSteeringRoles, activityLogRoute, resourceTypes } from '../../../config/constants';
import projectManagementAPIs from '../../../services/project-management.service';
import addEllipseIcon from '../../../styles/images/addEllipseIcon.svg';
import editIcon from '../../../styles/images/edit.png';
import generateProjectDataChildren from '../utils';
import LotCard from './LotCard';
import WorkFlowEvents from './WorkFlowEvents';
import Constants from './constants';
import AuthContext from '../../../context/AuthContext';
import StatusLabel from '../../../components/StatusLabel';
import Confirm from '../../../components/Alerts/Confirm/Confirm';
import Toast from '../../../components/Alerts/Toast/Toast';
import GuidanceBanner from '../../../components/GuidanceBanner';
import publishIcon from '../../../styles/images/publishIcon.svg';
import helperFunctions from '../../../utils/helperFunctions';
import { mergeUploadedDocuments, persistUploadedDocuments } from '../../../utils/uploadHelper';

const ProjectOverviewContent = () => {
    const authContext = useContext(AuthContext);
    const [projectDetails, setProjectDetails] = useState({});
    const [unreadCorrespondence, setUnreadCorrespondence] = useState(0);

    const [values, setValues] = useState({});
    const [publishedEvent, setPublishedEvent] = useState({});
    const [showFAQ, setShowFAQ] = useState(false);
    const [hasEvents, setHasEvents] = useState(false);
    const [cancelModal, setCancelModal] = useState(false);
    const [cancelLabel, setCancelLabel] = useState('Cancel Project');
    const [showWorknotes, setShowWorkNote] = useState(false);
    const [workNoteMode, setWorkNoteMode] = useState('');
    const [escalation, setEscalation] = useState({});
    const [showEscalation, setShowEscalation] = useState(false);
    const [riskModal, setRiskModal] = useState();
    const [escalationResolvedModal, setEscalationResolvedModal] = useState();
    const history = useHistory();
    const { projectId } = useParams();

    const userSteeringRoles = rbac.util.getSteeringGroupRole(
        authContext.user.id,
        projectDetails.steeringInfo,
    );

    const getEvent = (projectData, eventId) => (
        projectData.workflow?.stages?.map((stage) => (
            stage?.events?.find((event) => event.eventID === eventId)
        )).flat()
    );

    const getPublishedEvent = (projectData) => {
        let publishEvent = {};
        projectData.workflow?.stages?.forEach((stage) => {
            stage?.events?.forEach((event) => {
                if (event.eventType === 'publish' && event.isPublished === true) {
                    publishEvent = {
                        ...event,
                    };
                }
            });
        });
        return publishEvent;
    };

    const fetchUnreadData = async (resourceType, resourceId) => {
        let unreadCount = 0;
        const response = await projectManagementAPIs.getUnreadCorrespondence(
            resourceId, resourceType,
        );
        if (response.status === 200) {
            response.data.map((threads) => {
                response.data.unreadCount = 0;
                if (threads.unreadThreads.length > 0) {
                    threads.unreadThreads.map((messages) => {
                        if (messages.unreadMessages.length > 0) {
                            response.data.unreadCount += messages.unreadMessages.length;
                        } else {
                            response.data.unreadCount += 1;
                        }
                        return null;
                    });
                } else {
                    response.data.unreadCount += 1;
                }
                unreadCount += response.data.unreadCount;
                return null;
            });
            setUnreadCorrespondence(unreadCount);
        }
    };

    const fetchProject = async () => {
        const responseData = await projectManagementAPIs.getProject(projectId);
        if (responseData.status === 200 && Object.keys(responseData.data).length > 0) {
            setProjectDetails(responseData.data);
            if (responseData.data.projectStatus === Constants.cancelled) {
                setCancelLabel(responseData.data.projectStatus);
            }
            setHasEvents(() => (responseData.data.workflow
                ? Object.values(responseData.data.workflow).length > 0 : false));
            setPublishedEvent(getPublishedEvent(responseData.data));
        } else {
            Toast.fire({
                icon: 'error',
                titleText: 'Unable to retrieve information.',
            });
        }
    };

    useEffect(() => {
        fetchProject();
        fetchUnreadData('project', projectId);
    }, [cancelLabel, projectId]);

    const groupByRole = (steeringInfo, getUserID) => {
        const rekeyedArray = [];
        steeringInfo.forEach((a) => {
            rekeyedArray[a.userRole] = rekeyedArray[a.userRole] || [];
            let name = [];
            if (a.userRole !== 'supplier' && !getUserID) {
                name = a?.username;
            } else if (a.userRole !== 'supplier' && getUserID) {
                name = a.userID;
            } else {
                name = a?.account?.companyName;
            }
            rekeyedArray[a.userRole].push(name);
        });

        return Object.keys(rekeyedArray).map((k) => ({
            userRole: k,
            users: rekeyedArray[k],
        }));
    };

    const handleViewContract = (e) => {
        e.preventDefault();
        return history.push(`/contracts/management/${projectDetails?.parentContractID}`);
    };

    const projectContent = () => (
        generateProjectDataChildren(projectDetails, handleViewContract)
    );

    const handleChange = (e) => {
        const { name } = e.target;
        const { value } = e.target;
        setValues({
            ...values,
            [name]: value,
        });
    };

    const pluralizeText = (count, singular) => (
        `${count || 0} ${helperFunctions.pluralizeText(count || 0, singular)} added`
    );

    const noLotContent = () => <div id='noLot'>
        <p className='caption' id='lotText'>No Lots added for this project.</p>
    </div>;

    const lotsContent = () => ((projectDetails?.lots?.length > 0)
        ? <div id='lotContainer'>
            {projectDetails.lots.sort((a, b) => a.lotOrderNo - b.lotOrderNo).map((lot, idx) => {
                const codesAndCategories = lot?.industryInfo?.map((code) => `${code.category} - ${code.level}`);
                const deliveryAreas = lot?.deliveryAreaInfo?.map((deliveryArea) => `${deliveryArea.code} - ${deliveryArea.description}`);

                return <LotCard
                    key={`lot-${idx}`}
                    id={`lots-id-${idx}`}
                    header
                    actionBtn={true}
                    caption={`Lot #${lot.lotOrderNo}`}
                    headerTitle={lot.title}
                    content={<>
                        <div className='contentItem'>
                            <>
                                <p className='caption-small projectHeader'>Codes and Categories:</p>
                                <p className='title-small projectTitle'>{codesAndCategories?.length > 0
                                    ? codesAndCategories.map((role, i) => {
                                        if (i + 1 === codesAndCategories.length) {
                                            return `${helperFunctions.camel2title(role)}`;
                                        }
                                        return `${helperFunctions.camel2title(role)}, `;
                                    })
                                    : '-'}</p>
                            </>
                            <>
                                <p className='caption-small projectHeader'>Delivery Area:</p>
                                <p className='title-small projectTitle'>{deliveryAreas?.length > 0
                                    ? deliveryAreas.map((role, i) => {
                                        if (i + 1 === deliveryAreas.length) {
                                            return `${helperFunctions.camel2title(role)}`;
                                        }
                                        return `${helperFunctions.camel2title(role)}, `;
                                    })
                                    : '-'}</p>
                            </>
                            <>
                                <p className='caption-small projectHeader'>Estimated Value:</p>
                                <p className='title-small projectTitle'>{lot.estimatedValue
                                    ? helperFunctions.formatCurrency(lot.estimatedValue) : '-'}</p>
                            </>
                        </div>
                    </>
                    }
                    actionBtnText='View Lot'
                    handlerActionBtn={() => { history.push(`/projects/overview/${projectId}/lot/view/${lot.lotID}`); }}
                />;
            })}
        </div>
        : <div id='noLot'>
            <p className='caption' id='lotText'>No Lots added for this project.</p>
        </div>);

    const handleEscalateResolve = async (e) => {
        e.preventDefault();
        const payLoad = {
            escalationAction: 'resolve',
            reason: values.resolvedReason,
            escalationID: escalation.escalationID,
        };
        try {
            const response = await projectManagementAPIs.escalateEvent(
                projectId, escalation.eventID, projectDetails?.workflow?.workflowID, payLoad,
            );
            if (response.status === 200) {
                setValues({
                    ...values,
                    resolvedReason: '',
                });
                fetchProject(projectId);
                setEscalationResolvedModal(false);
                Toast.fire({
                    icon: 'success',
                    titleText: 'Escalation resolved successfully.',
                });
            } else {
                Toast.fire({
                    icon: 'error',
                    titleText: 'Unable to resolve escalation.',
                });
            }
        } catch {
            Toast.fire({
                icon: 'error',
                titleText: 'Unable to resolve escalation.',
            });
        }
    };

    const handleEscalateReason = async (reason, eventId, closeModal) => {
        const payLoad = {
            reason,
            escalationAction: 'escalate',
        };
        try {
            const response = await projectManagementAPIs
                .escalateEvent(projectId, eventId, projectDetails?.workflow?.workflowID, payLoad);
            if (response.status === 200) {
                closeModal();
                fetchProject(projectId);
                Toast.fire({
                    icon: 'success',
                    titleText: 'Event escalated successfully.',
                });
            } else {
                Toast.fire({
                    icon: 'error',
                    titleText: 'Unable to escalate event.',
                });
            }
        } catch (error) {
            Toast.fire({
                icon: 'error',
                titleText: 'Unable to escalate event.',
            });
        }
    };

    const handleUnpublishOpportunity = async (eventId) => {
        try {
            const response = await projectManagementAPIs
                .unpublishOpportunity(projectId, eventId);
            if (response.status === 200) {
                Toast.fire({
                    icon: 'success',
                    titleText: 'Opportunity unpublished successfully.',
                });
                fetchProject(projectId);
            } else {
                Toast.fire({
                    icon: 'error',
                    titleText: 'Unable to unpublish opportunity.',
                });
            }
        } catch (error) {
            Toast.fire({
                icon: 'error',
                titleText: 'Unable to unpublish opportunity.',
            });
        }
    };

    const handleUpdateEvent = async ({ eventId, action }) => {
        if (action === 'refresh') {
            fetchProject(projectId);
            return;
        }

        if (action === 'unpublish') {
            handleUnpublishOpportunity(eventId);
            return;
        }

        const payLoad = {
            action,
        };
        try {
            const response = await projectManagementAPIs
                .updateEvent(projectId, eventId, payLoad);
            if (response.status === 200) {
                Toast.fire({
                    icon: 'success',
                    titleText: 'Event updated successfully.',
                });
                fetchProject(projectId);
            } else {
                Toast.fire({
                    icon: 'error',
                    titleText: 'Unable to update event.',
                });
            }
        } catch (error) {
            Toast.fire({
                icon: 'error',
                titleText: 'Unable to update event.',
            });
        }
    };

    const closeRiskModal = () => {
        setValues({
            ...values,
            riskStatus: '',
            riskStatusReason: '',
        });
        setRiskModal(false);
    };

    const closeFaqModal = () => {
        setShowFAQ(false);
        setValues({
            ...values,
            question: '',
            answer: '',
            faqID: '',
        });
    };

    const handleFaq = async (event) => {
        event.preventDefault();

        let newFAQ = {
            question: values.question,
            answer: values.answer,
        };

        try {
            if (values.faqID !== undefined && values.faqID !== '') {
                const response = await projectManagementAPIs.updateFAQ(
                    projectId, values.faqID, newFAQ,
                );
                newFAQ = {
                    ...newFAQ,
                    faqID: values.faqID,
                };
                if (response.status === 200) {
                    Toast.fire({
                        icon: 'success',
                        titleText: 'FAQ updated successfully.',
                    });
                    fetchProject(projectId);
                } else {
                    Toast.fire({
                        icon: 'error',
                        titleText: 'Unable to update FAQ.',
                    });
                }
            } else {
                const response = await projectManagementAPIs.createFAQ(projectId, newFAQ);
                if (response.status === 200) {
                    Toast.fire({
                        icon: 'success',
                        titleText: 'FAQ added successfully.',
                    });
                    fetchProject(projectId);
                } else {
                    Toast.fire({
                        icon: 'error',
                        titleText: 'Unable to add FAQ.',
                    });
                }
            }
        } catch (e) {
            Toast.fire({
                icon: 'error',
                titleText: `Unable to ${(values.faqID !== undefined && values.faqID !== '') ? 'update' : 'add'} FAQ.`,
            });
        }
        closeFaqModal();
    };

    const handleDeleteFaq = async (data) => {
        try {
            await projectManagementAPIs.updateFAQ(projectId, data.faqID, {
                isActive: data.isActive,
            });
            fetchProject(projectId);
            Toast.fire({
                icon: 'success',
                titleText: `FAQ ${data.isActive ? 'enabled' : 'disabled'} successfully.`,
            });
        } catch (e) {
            Toast.fire({
                icon: 'error',
                titleText: 'Unable to disable FAQ.',
            });
        }
        closeFaqModal();
    };

    const generateFAQModal = () => <>
        <LabelledInput id='question'
            type={LabelledInputTypeText} label='Question'
            breakColumn={true} onChange={(e) => handleChange(e)}
            value={values.question || ''}
            placeholder='Enter question'
            required={true} />
        <LabelledTextArea id='answer'
            label='Answer'
            breakColumn={true} onChange={(e) => handleChange(e)}
            value={values.answer || ''}
            currentCharCount={values.answer ? values.answer.length : 0}
            placeholder='Enter your answer'
            maxCharCount='3000'
            required={true} />
    </>;

    const generateFaqSectionContent = (data) => <div className='row'>
        <div className='faqItem'>
            <p className='caption faqAnswer'>{data.answer}</p>
            {rbac.can(rbac.action.editFAQ, userSteeringRoles)
            && projectDetails?.projectStatus !== Constants.cancelled
            && <div className='faqButtonContainer'>
                <Button
                    id='edit'
                    type='button'
                    variant='primary'
                    label='Edit'
                    size='small'
                    disabled={false}
                    icon={false}
                    iconSrc={editIcon}
                    handleClick={() => {
                        setShowFAQ(true);
                        setValues({
                            ...values,
                            question: data.question,
                            answer: data.answer,
                            faqID: data.faqID,
                            faqIsActive: data.isActive,
                        });
                    }}
                />
                {data.isActive
                    ? <Button
                        id='delete'
                        type='button'
                        variant = 'primary'
                        additionalVariant = 'red'
                        size='small'
                        label='Disable'
                        disabled={false}
                        icon = {false}
                        handleClick={(e) => {
                            e.preventDefault();
                            Confirm(handleDeleteFaq, {
                                subTitle: 'Are you sure you want to disable?',
                            }, {
                                faqID: data.faqID,
                                isActive: false,
                            });
                        }}
                    />
                    : <Button
                        id='activate'
                        type='button'
                        variant = 'primary'
                        additionalVariant = 'red'
                        size='small'
                        label='Restore'
                        disabled={false}
                        icon = {false}
                        handleClick={(e) => {
                            e.preventDefault();
                            Confirm(handleDeleteFaq, {
                                subTitle: 'Are you sure you want to restore?',
                            }, {
                                faqID: data.faqID,
                                isActive: true,
                            });
                        }}
                    />}
            </div>}
        </div>
    </div>;

    const generateFaqSectionHeader = (data, idx) => <>
        <p className='title'>{data.question}</p>
        {!data.isActive && <StatusLabel
            id={`inactiveFAQLabel-${idx}`}
            labelTxt='Inactive'
            color={'red'}
        />}
    </>;

    const generateFaqAccordion = (eventData) => {
        const elements = eventData && eventData
            .map((data, idx) => ({
                sectionId: `wEAccordionSection-${idx}`,
                header: generateFaqSectionHeader(data, idx),
                content: generateFaqSectionContent(data),
            }));
        return elements;
    };

    const generateFaqContent = (data) => <Accordion
        id='cwAccordion'
        data={generateFaqAccordion(data) || []}
    />;

    const generateCancelModal = () => <>
        <LabelledTextArea id='cancel'
            label='Enter your reason for cancelling this project.'
            subLabel='This is internal and only visible within your organisation.'
            breakColumn={true} onChange={(e) => handleChange(e)}
            value={values.cancel || ''}
            currentCharCount={values.cancel ? values.cancel.length : 0}
            placeholder='Enter reason'
            required={true} />

        <LabelledTextArea id='cancelExternal'
            label='Public statement for cancelling this project.'
            subLabel='This is a public statement and is visible to suppliers.'
            breakColumn={true} onChange={(e) => handleChange(e)}
            value={values.cancelExternal || ''}
            currentCharCount={values.cancelExternal ? values.cancelExternal.length : 0}
            placeholder='Enter reason'
            required={false}
        />
    </>;

    const closeCancelModal = () => {
        setCancelModal(false);
        setValues({
            ...values,
            cancel: '',
            cancelExternal: '',
        });
    };

    const handleCancellation = async (event) => {
        event.preventDefault();
        try {
            await projectManagementAPIs.cancelProject(projectId, {
                internalReason: values.cancel,
                externalReason: values.cancelExternal,
            });
            Toast.fire({
                icon: 'success',
                titleText: 'Project cancelled successfully.',
            });
            setValues({
                ...values,
                cancel: '',
                cancelExternal: '',
            });
            fetchProject(projectId);
            closeCancelModal();
        } catch (e) {
            Toast.fire({
                icon: 'error',
                titleText: 'Unable to cancel project.',
            });
        }
    };

    const handleEventUploadDocument = async (docs, eventId) => {
        const projectEvent = getEvent(projectDetails, eventId);

        // This is a temporary fix to remedy issues with uploads to later stages. Needs refactoring.
        const filteredEvent = projectEvent.filter((project) => project !== undefined)[0];

        if (filteredEvent) {
            const mergedDocs = mergeUploadedDocuments(filteredEvent.additionalDocuments, docs);

            const payload = {
                additionalDocuments: mergedDocs,
            };

            await persistUploadedDocuments(
                'upload',
                () => projectManagementAPIs.uploadEventDocument(projectId, eventId, payload),
                () => fetchProject(projectId),
            );
        }
    };

    const handleEventDeleteDocument = async (eventId, documentId) => {
        await persistUploadedDocuments(
            'delete',
            () => projectManagementAPIs.deleteEventDocument(projectId, eventId, documentId),
            () => fetchProject(projectId),
        );
    };

    const handleAddWorkNotes = async (eventId, worknote, closeModal) => {
        const payLoad = {
            worknote,
        };
        try {
            const response = await projectManagementAPIs
                .addEventWorknote(projectId, eventId, payLoad);
            if (response.status === 200) {
                closeModal();
                fetchProject(projectId);
                Toast.fire({
                    icon: 'success',
                    titleText: 'Worknote added successfully.',
                });
            } else {
                Toast.fire({
                    icon: 'error',
                    titleText: 'Unable to add worknote.',
                });
            }
        } catch (error) {
            Toast.fire({
                icon: 'error',
                titleText: 'Unable to add worknote.',
            });
        }
    };

    const handlePublishProject = async (publishProjectData, eventId, closeModal, type = 'default') => {
        const {
            interestStartDate, interestStartTime, interestEndDate, interestEndTime,
            submissionStartDate, submissionStartTime, submissionEndDate, submissionEndTime,
        } = publishProjectData;
        interestStartDate.setHours(interestStartTime.split(':')[0]);
        interestStartDate.setMinutes(interestStartTime.split(':')[1]);
        interestEndDate.setHours(interestEndTime.split(':')[0]);
        interestEndDate.setMinutes(interestEndTime.split(':')[1]);
        submissionStartDate.setHours(submissionStartTime.split(':')[0]);
        submissionStartDate.setMinutes(submissionStartTime.split(':')[1]);
        submissionEndDate.setHours(submissionEndTime.split(':')[0]);
        submissionEndDate.setMinutes(submissionEndTime.split(':')[1]);

        if (isAfter(publishProjectData.interestEndDate, publishProjectData.submissionEndDate)) {
            Toast.fire({
                icon: 'error',
                titleText: 'Unable to publish project as expression end date should be shorter or same as submission end Date',
            });
        } else {
            const payLoad = {
                expressionWindowStartDate: interestStartDate.toISOString(),
                expressionWindowEndDate: interestEndDate.toISOString(),
                submissionStartDate: submissionStartDate.toISOString(),
                submissionEndDate: submissionEndDate.toISOString(),
                projectID: projectId,
                eventID: eventId,
            };

            try {
                let response = null;
                let success = false;
                if (type === 'edit') {
                    delete payLoad.projectID;
                    delete payLoad.eventID;
                    response = await projectManagementAPIs.editDates(payLoad, projectId, eventId);
                    if (response.status === 200 || response.status === 201) {
                        success = true;
                    }
                } else {
                    response = await projectManagementAPIs.publishEvent(payLoad);
                    if (response.status === 201) {
                        success = true;
                    }
                }
                if (success) {
                    closeModal();
                    fetchProject(projectId);
                    Toast.fire({
                        icon: 'success',
                        titleText: type === 'edit' ? 'Opportunity updated successfully' : 'Project published successfully',
                    });
                } else {
                    Toast.fire({
                        icon: 'error',
                        titleText: type === 'edit' ? 'Unable to update opportunity.' : `Unable to publish project ${response.data.Message}`,
                    });
                }
            } catch (resError) {
                Toast.fire({
                    icon: 'error',
                    titleText: 'Unable to publish project.',
                });
            }
        }
    };

    const handleRisk = () => {
        if (rbac.can(rbac.action.setProjectRisk, userSteeringRoles)) {
            setValues({
                ...values,
                riskStatus: projectDetails?.riskStatus?.projectRiskStatus || '',
                riskStatusReason: projectDetails?.riskStatus?.reason || '',
            });
            setRiskModal(true);
        }
    };


    const handleAddAdmin = async () => {
        if (rbac.can('editSteeringGroup', userSteeringRoles)) {
            Toast.fire({
                icon: 'error',
                titleText: 'User is already a procurement officer in the steering group.',
            });
            return;
        }

        const assignees = projectDetails.steeringGroup;
        if (assignees.filter((id) => id === authContext.user.id).length === 0) {
            assignees.push(authContext.user.id);
        }
        const steeringInfo = projectDetails.steeringInfo.filter((user) => user.userRole.toLowerCase() !== 'supplier').map((user) => ({
            userRole: user.userRole,
            accountID: user.accountID,
            departmentID: user.departmentID,
            userID: user.userID,
        }));
        steeringInfo.push({
            userRole: 'po',
            accountID: authContext.user.accountId,
            departmentID: authContext.user.details.departmentID,
            userID: authContext.user.id,
        });
        const payload = {
            assignees,
            steeringInfo,
        };

        const response = await projectManagementAPIs.updateSteeringGroup(projectId, payload);
        if (response.status === 200) {
            Toast.fire({
                icon: 'success',
                titleText: 'Successfully added to steering group.',
            });
            fetchProject(projectId);
        } else {
            Toast.fire({
                icon: 'error',
                titleText: 'Unable to add user to steering group.',
            });
        }
    };

    const addAdminToProject = () => {
        Confirm(handleAddAdmin, {
            subTitle: 'Add yourself as a procurement officer to this project? You can remove this at any time by visiting the edit steering group page.',
        });
    };

    const projectActionContent = () => (<div id={'projectActionContainer'}>
        <div className='actionBtnContainer'>
            {projectDetails.projectType === 'calloff'
                ? <Button
                    id='solutionOverviewButton'
                    type='button'
                    variant={'primary'}
                    label={'View Solution'}
                    disabled={projectDetails?.projectStatus === Constants.cancelled}
                    icon={false}
                    handleClick={() => history.push(`/solutions/overview/${projectDetails.parentSolutionID}`)}
                />
                : <Button
                    id='riskStatusButton'
                    type='button'
                    variant={'tertiary'}
                    additionalVariant={`riskStatus ${projectDetails?.riskStatus?.projectRiskStatus?.toLowerCase() === 'medium' ? 'amber' : projectDetails?.riskStatus?.projectRiskStatus?.toLowerCase() || 'default'}`}
                    label={projectDetails && projectDetails.riskStatus?.projectRiskStatus ? `${projectDetails.riskStatus.projectRiskStatus} Risk` : 'Risk Status'}
                    disabled={projectDetails?.projectStatus === Constants.cancelled}
                    icon={false}
                    handleClick={handleRisk}
                />}
        </div>
        <div className={'cancelButtonContainer'}>
            {(rbac.can(rbac.action.cancelProject, userSteeringRoles)
            && projectDetails?.projectStatus !== Constants.cancelled)
            && (projectDetails?.steeringInfo?.filter((role) => role.userRole === 'supplier').length === 0 || projectDetails.projectType === 'calloff')
            && <Button
                id='cancelButton'
                type='button'
                variant='secondary'
                additionalVariant='cancel'
                label={'Cancel Project'}
                disabled={projectDetails?.projectStatus === Constants.cancelled}
                icon={false}
                handleClick={() => setCancelModal(true)}
            />}
            {projectDetails?.projectStatus === Constants.cancelled
            && <StatusLabel
                id={'projectCancelledLabel'}
                labelTxt={helperFunctions.camel2title(projectDetails?.projectStatus)}
                color={'red'}
            />}
        </div>
    </div>);

    const getPrettySteeringGroup = (eType) => allSteeringRoles.filter(
        (eventType) => eventType.role === eType,
    )[0]?.name;

    const projectGuidanceContent = () => (<div id={'projectGuidanceContainer'}>
        {projectDetails?.missingSteeringGroup?.length > 0
            ? <div className={'dashboardCard large'} id={'missingSteeringGroup'}>
                <main className='dashboardCardMain'>
                    <div id={'projectGuidanceContainer'}>
                        <GuidanceBanner
                            id='missingSteeringGroup'
                            type={'warning'}
                            headerText='Workflow requires users assigned to the following steering group roles'
                            // bannerText1='Users are required to make progress in workflow'
                            bannerText2={projectDetails?.missingSteeringGroup?.length
                                ? projectDetails?.missingSteeringGroup.map((role, i) => {
                                    if (i + 1 === projectDetails?.missingSteeringGroup.length) {
                                        return `${getPrettySteeringGroup(role)}`;
                                    }
                                    return `${getPrettySteeringGroup(role)}, `;
                                })
                                : '-'}
                            buttonArr={[{
                                id: 'editSteeringGroup',
                                label: 'Edit Steering Group',
                                variant: 'primary skinless white',
                                action: () => {
                                    history.push(`/steering-group/select/${projectId}`);
                                },
                                allowAction: rbac.can(
                                    rbac.action.editSteeringGroup, userSteeringRoles,
                                )
                                && projectDetails?.projectStatus !== Constants.cancelled,
                            }]}
                        />
                    </div>
                </main>
            </div>
            : <></>}
        {publishedEvent?.isPublished
        && <div className={'dashboardCard large'} id={'publishEventGuidance'}>
            <main className='dashboardCardMain'>
                <div id={'projectGuidanceContainer'}>
                    <GuidanceBanner
                        id='publishEventGuidance'
                        headerText={<><img src={publishIcon} alt={''} className='publish-icon'></img> {publishedEvent.eventName}</>}
                        bannerText1= {isPast(parseISO(publishedEvent.submissionEndDate)) ? 'Submission deadline is elapsed' : 'Opportunity is visible to suppliers'}
                        bannerText2={`Submission End Date: ${helperFunctions.formatPrettyDateTime(publishedEvent.submissionEndDate)}`}
                        buttonArr={[
                        //     {
                        //     id: 'manageSuppliersBtn',
                        //     label: 'Manage Suppliers',
                        //     variant: 'primary skinless white',
                        //     action: () => {
                        //         history.push(`/suppliers/manage/${projectId}/
                        // ${publishedEvent.eventID}`);
                        //     },
                        //     allowAction: () => null,
                        // },
                        // {
                        //     id: 'viewResponsesBtn',
                        //     label: 'View Responses',
                        //     variant: 'primary skinless white',
                        //     action: () => {
                        //         history.push(`/evaluation/summary/${projectId}/
                        // ${publishedEvent.eventID}`);
                        //     },
                        //     allowAction: isPast(parseISO(publishedEvent.submissionEndDate)),
                        // }
                        ]}
                    />
                </div>
            </main>
        </div>}
    </div>);

    const generateDropdownOptions = (key, option, defaultValue) => ((key > 0)
        ? <option key={`riskStatus-${key}`} value={option}>{option}</option>
        : <option key={`riskStatus-${key}`} value='' disabled>{defaultValue}</option>);

    const uploadProjectDocuments = async (docs) => {
        const mergedDocs = mergeUploadedDocuments(projectDetails.additionalDocuments, docs);

        const payload = {
            additionalDocuments: mergedDocs,
        };

        await persistUploadedDocuments(
            'upload',
            () => projectManagementAPIs.uploadProjectDocument(projectId, payload),
            () => fetchProject(projectId),
        );
    };

    const deleteProjectDocument = async (doc) => {
        await persistUploadedDocuments(
            'delete',
            () => projectManagementAPIs.deleteProjectDocument(projectId, doc.documentID),
            () => fetchProject(projectId),
        );
    };

    const generateContract = () => {
        if (projectDetails?.contractInfo?.length > 0) {
            return projectDetails.contractInfo.map((contractData, index) => (
                <div key={`contract-${index}`} className={'contractItem'}>
                    <p className={'title'}>{contractData.contractName}</p>
                    <Button
                        id={`cBtn-${index}`}
                        label='View'
                        size='small'
                        handleClick={() => history.push(`/contracts/management/${contractData.contractID}`)}
                    />
                </div>
            ));
        }
        return <p className='caption'>No contract drafted for this project.</p>;
    };

    const handleRiskSubmit = async (e) => {
        e.preventDefault();
        const payLoad = {
            riskStatus: {
                projectRiskStatus: values.riskStatus,
                reason: values.riskStatusReason,
            },
        };
        try {
            const response = await projectManagementAPIs.updateRisk(projectId, payLoad);
            if (response.status === 200) {
                Toast.fire({
                    icon: 'success',
                    titleText: 'Risk Status updated successfully.',
                });
                fetchProject(projectId);
            }
        } catch {
            Toast.fire({
                icon: 'error',
                titleText: 'Unable to update risk status.',
            });
        }
        closeRiskModal();
    };

    const generateRiskContent = () => <>
        <LabelledSelect
            id='riskStatus'
            label='Select Risk Status'
            required={true}
            breakColumn={true}
            onChange={(e) => handleChange(e)}
            value={values.riskStatus || ''}
            options={Constants.allowedRiskColors
                .map((option, idx) => generateDropdownOptions(idx, option, 'Select Risk Status'))}
        />
        <LabelledTextArea
            id='riskStatusReason'
            type={LabelledInputTypeText}
            label='Risk Reason'
            breakColumn={true}
            onChange={(e) => handleChange(e)}
            value={values.riskStatusReason || ''}
            currentCharCount={values.riskStatusReason ? values.riskStatusReason.length : 0}
            placeholder='Enter Risk Reason'
            required={true}
        />
    </>;

    const handleAddActionWorkNotes = async (data, worknote) => {
        const payLoad = {
            worknote,
        };
        try {
            const response = await projectManagementAPIs
                .addProjectWorknote(projectId, payLoad);
            if (response.status === 200) {
                fetchProject(projectId);
                Toast.fire({
                    icon: 'success',
                    titleText: 'Worknote added successfully.',
                });
            } else {
                Toast.fire({
                    icon: 'error',
                    titleText: 'Unable to add worknote.',
                });
            }
        } catch (error) {
            Toast.fire({
                icon: 'error',
                titleText: 'Unable to add worknote.',
            });
        }
    };

    const viewWorkNotes = () => {
        setWorkNoteMode('view');
        setShowWorkNote(true);
    };

    const addWorkNotes = () => {
        setWorkNoteMode('add');
        setShowWorkNote(true);
    };

    const generateEscalations = () => ((projectDetails?.eventsEscalations?.length > 0)
        ? <>
            {projectDetails.eventsEscalations.map((escalate, index) => <div key={`esc-${index}`} className={'escalationItem'}>
                <p className={'title'}>{escalate.eventName}</p>
                <div id={'escalationBtnContainer'}>
                    {(rbac.can(rbac.action.resolveEscalation,
                        userSteeringRoles))
                    && projectDetails?.projectStatus !== Constants.cancelled
                    && <Button
                        id={`tBtn-${index}`}
                        label={escalate.escalationStatus === 'closed' ? 'Closed' : 'Resolve'}
                        size='small'
                        variant='secondary'
                        disabled={(escalate.escalationStatus === 'closed')}
                        handleClick={() => {
                            setEscalationResolvedModal(true);
                            setEscalation({
                                ...escalate,
                            });
                        }}
                    />}
                    <Button
                        id={`tBtn-${index}`}
                        label='View'
                        size='small'
                        handleClick={() => {
                            setShowEscalation(true);
                            setEscalation({
                                ...escalate,
                            });
                        }}
                    />
                </div>
            </div>)}
        </>
        : <div id='noEscalations'>
            <p className='caption'>No escalations.</p>
        </div>);

    const generateActivityLogContent = () => ((projectDetails?.activityLog?.length > 0)
        ? <>
            {projectDetails.activityLog.map((log, index) => <div key={`log-${index}`} className={'logItem'}>
                <p className={'headerTitle title'}>{log.message}</p>
                <p className={'caption'}>{log.username}, {log.departmentName}</p>
                <p className={'caption small'}>{helperFunctions.formatPrettyDateTime(log.systemTimestamp)}</p>
            </div>)}
        </>
        : <div id='noActivityLog'>
            <p className='caption'>No logs.</p>
        </div>);

    // const handleRemoveWorkflow = async () => {
    //     try {
    //         const response = await projectManagementAPIs
    //             .removeProjectWorkflow(projectDetails?.workflow?.workflowID);
    //         if (response.status === 200) {
    //             fetchProject(projectId);
    //             Toast.fire({
    //                 icon: 'success',
    //                 titleText: 'Workflow removed successfully.',
    //             });
    //         } else if (response.status === 8000) {
    //             fetchProject(projectId);
    //             Toast.fire({
    //                 icon: 'error',
    //                 titleText: 'Unable to remove the workflow as some events have been started.',
    //             });
    //         } else {
    //             Toast.fire({
    //                 icon: 'error',
    //                 titleText: 'Unable remove workflow.',
    //             });
    //         }
    //     } catch (error) {
    //         Toast.fire({
    //             icon: 'error',
    //             titleText: 'Unable remove workflow.',
    //         });
    //     }
    // };

    return (<div id='projectOverview'>
        {/* {hasEvents && <ProgressBar
            options={stageNames.length > 0 ? stageNames : []}
            activeStep={activeProgressStage || 0}
            context={'pastCurrentUpcoming'}
            id='pastCurrentUpcomingProgressBar'
        />} */}
        {projectDetails.projectStatus === Constants.cancelled
        && <div className='projectOverviewContainer'>
            <div className={'dashboardCard large'} id={'prfActions'}>
                <main className='dashboardCardMain'>
                    {projectDetails.statusReasons.map((status, index) => (
                        <div key={`procumentDetails-approvalHistory${index}`}>
                            <p className='title-xLarge' id='error'>Cancelled</p>
                            {/* <p className='caption'>
                            {procurementReqStatues.cancelled ? 'Cancelled by' : 'Rejected by'}
                            {procumentDetails.username} on {status.actionedOn
                            && helperFunctions.formatPrettyDate(status.actionedOn)}</p> */}
                            <p className='caption'><span className='title' id='labelText'>Reason :</span> {status.internalReason}</p>
                        </div>
                    ))}
                </main>
            </div>
        </div>}
        {<div className='projectOverviewContainer'>
            {projectGuidanceContent()}
        </div>}
        {<div className='projectOverviewContainer'>
            <div className={'dashboardCard large'} id={'projectActions'}>
                <main className='dashboardCardMain'>{projectActionContent()}</main>
            </div>
        </div>}
        <div className='projectOverviewContainer'>
            <DashboardCard
                id='projectDetails'
                size='large'
                header={true}
                headerTitle={projectDetails.title}
                caption={`Project # ${projectDetails.projectReference ? projectDetails.projectReference : '-'}`}
                actionBtn={
                    (!projectDetails.projectState
                        || projectDetails.projectState?.toLowerCase() === 'project')
                    && rbac.can(rbac.action.editProject, userSteeringRoles)
                    && projectDetails?.projectStatus !== Constants.cancelled}
                actionBtnText={'Edit Project Details'}
                variant={'secondary'}
                additionalVariant={'skinless'}
                handlerActionBtn={() => history.push(`/project/edit/${projectId}`)}
                content={projectDetails && projectContent()}
                footer={false}
                icon={true}
                iconSrc={editIcon}
            />
        </div>
        <div className='projectOverviewWorkflowContainer'>
            <div className='leftContainer'>
                <SteeringGroup
                    id='steeringGroup'
                    headerTitle='Steering Group'
                    actionBtn={((projectDetails?.projectStatus !== Constants.cancelled)
                        && (rbac.can(rbac.action.editSteeringGroup, userSteeringRoles))) || ((projectDetails?.projectStatus !== Constants.cancelled) && authContext?.user?.role === 'admin')}
                    actionBtnText={rbac.can(rbac.action.editSteeringGroup, userSteeringRoles) ? 'Edit' : 'Join Project'}
                    handlerActionBtn={rbac.can(rbac.action.editSteeringGroup, userSteeringRoles)
                        ? () => history.push(`/steering-group/select/${projectId}`)
                        : () => addAdminToProject()
                    }
                    iconSrc={editIcon}
                    icon={rbac.can(rbac.action.editSteeringGroup, userSteeringRoles) && true}
                    variant={rbac.can(rbac.action.editSteeringGroup, userSteeringRoles) ? 'secondary' : 'primary'}
                    additionalVariant={rbac.can(rbac.action.editSteeringGroup, userSteeringRoles) && 'skinless editSteeringGroup'}
                    data={projectDetails?.steeringInfo?.length > 0
                        ? groupByRole(projectDetails.steeringInfo, false)
                        : []}
                />
                {hasEvents
                && <DashboardCard
                    id='actions'
                    size='small'
                    header={true}
                    headerTitle='Actions'
                    content={<div id='projectActionContent'>
                        <div id='correspondenceContent'>
                            <p className='title sectionLabel'>Correspondence</p>
                            <p className='caption'>
                                {`${unreadCorrespondence} unread correspondence available`}
                            </p>
                            <div id='buttonContainer'>
                                <Button id='actionViewCorrespondence'
                                    size='small'
                                    variant='secondary'
                                    label='View'
                                    handleClick={() => history.push(`/project/correspondence/${projectId}`)} />
                            </div>
                        </div>
                        <div id='worknoteContent'>
                            <p className='title sectionLabel' id='worknotes'>Worknotes</p>
                            <p className='caption'>
                                {pluralizeText(projectDetails?.worknotes?.length, 'worknote')}
                            </p>
                            <div className='buttonContainer'>
                                <Button id='actionBtnS'
                                    size='small'
                                    variant='secondary'
                                    label='View'
                                    handleClick={viewWorkNotes} />
                                {rbac.can(rbac.action.addNotes, userSteeringRoles)
                                && projectDetails?.projectStatus !== Constants.cancelled
                                && <Button id='actionBtn'
                                    size='small'
                                    variant='primary'
                                    label='Add'
                                    handleClick={addWorkNotes} />}
                            </div>
                        </div>
                        <Upload
                            title='Documents'
                            allowMultiple={true}
                            uploadIdentifier='document'
                            uploadCallback={uploadProjectDocuments}
                            deleteCallback={deleteProjectDocument}
                            uploadedDocuments={projectDetails.additionalDocuments}
                            readOnly={projectDetails?.projectStatus === Constants.cancelled}
                        />
                    </div>}
                />}
                {hasEvents
                && <DashboardCard
                    id='contractDocument'
                    size='small'
                    header={true}
                    headerTitle={'Contract'}
                    caption={''}
                    actionBtn={rbac.can(rbac.action.addContract, userSteeringRoles)
                                && projectDetails?.projectStatus !== Constants.cancelled
                                && !projectDetails?.contractInfo }
                    actionBtnText={'Add'}
                    handlerActionBtn={() => {
                        history.push(`/projects/overview/contract/create/${projectId}`);
                    }}
                    variant={'secondary'}
                    additionalVariant={'skinless'}
                    icon={true}
                    iconSrc={addEllipseIcon}
                    content={generateContract()}
                    footer={false} />
                }
            </div>
            <WorkFlowEvents
                eventDetails={projectDetails.workflow}
                workflowId={projectDetails?.workflowID}
                handleUpdateEvent={handleUpdateEvent}
                handleEscalateEvent={handleEscalateReason}
                handlePublishEvent={handlePublishProject}
                handleUploadDocument={handleEventUploadDocument}
                handleDeleteDocument={handleEventDeleteDocument}
                handleAddWorkNotes={handleAddWorkNotes}
                showSelectWorkflow={!hasEvents}
                canSelectWorkflow={rbac.can(rbac.action.selectWorkflow, userSteeringRoles)
                    && projectDetails?.projectStatus !== Constants.cancelled}
                canEditWorkflow={(projectDetails?.projectStatus !== Constants.cancelled)
                    && (rbac.can(rbac.action.editWorkflow,
                        [authContext?.user?.accountType?.toUpperCase() + authContext?.user?.role])
                        || (rbac.can(rbac.action.editWorkflow, userSteeringRoles)))}
                handelSelectWorkflow={() => {
                    if (projectDetails.route) {
                        history.push(`/workflows/browse/project/${projectId}?type=${projectDetails.route}`);
                    } else {
                        history.push(`/workflows/browse/project/${projectId}`);
                    }
                }}
                handleEditWorkflow={() => {
                    history.push(`/workflows/project/edit/${projectId}/${projectDetails.workflowID}`);
                }}
                userSteeringRoles={userSteeringRoles}
                routeToMarket={projectDetails.route}
                callOffMechanism={projectDetails.callOffMechanism}
                contractType={projectDetails.projectDescribes}
                readOnly={projectDetails?.projectStatus === Constants.cancelled}
                projectType={projectDetails.projectType}
            />
        </div>
        {projectDetails.projectType !== 'calloff'
        && <div className='projectOverviewContainer'>
            <DashboardCard
                id='lots'
                size='large'
                header={true}
                headerTitle={'Lots'}
                caption={''}
                content={(projectDetails.lots && projectDetails.lots.length)
                    ? <div id='lotContainerMain'>{lotsContent()}</div>
                    : noLotContent()
                }
                footer={false}
                actionBtn={rbac.can(rbac.action.addLots, userSteeringRoles)
                        && (!projectDetails.projectState
                            || projectDetails.projectState?.toLowerCase() === 'project')
                        && projectDetails?.projectStatus !== Constants.cancelled}
                actionBtnText={'Add Lot'}
                handlerActionBtn={() => {
                    history.push(`/projects/overview/${projectId}/lot/create`);
                }}
                variant={'secondary'}
                additionalVariant={'skinless'}
                icon={true}
                iconSrc={addEllipseIcon}
                secondaryActionBtn={rbac.can(rbac.action.addLots, userSteeringRoles)
                        && (!projectDetails.projectState
                            || projectDetails.projectState?.toLowerCase() === 'project')}
                secondaryActionBtnText={'Manage Lot(s)'}
                handleSecondaryActionBtn={() => {
                    history.push(`/projects/overview/${projectId}/lot/browse`);
                }}
                secondaryVariant={'secondary'}
                secondaryAdditionalVariant={'skinless'}
                secondaryIcon={true}
                secondaryIconSrc={addEllipseIcon}
            />
        </div>}
        <div className='projectOverviewContainer'>
            <DashboardCard
                id='projectEscalations'
                size='small'
                header={true}
                headerTitle={'Project Escalations'}
                caption={'Escalations raised against workflow events for approvers to review and resolve.'}
                content={generateEscalations()}
                footer={false}
            />
            <DashboardCard
                id='faqEvents'
                size='medium'
                header={true}
                headerTitle={'Frequently Asked Questions'}
                caption={''}
                actionBtn={rbac.can(rbac.action.addFAQ, userSteeringRoles)
                    && projectDetails?.projectStatus !== Constants.cancelled}
                actionBtnText={'Add'}
                handlerActionBtn={() => {
                    setShowFAQ(true);
                    setValues({
                        ...values,
                        faqIsActive: true,
                    });
                }}
                content={projectDetails ? generateFaqContent(projectDetails.faq) : <p className='caption'>Unable to get FAQ details.Please try again later</p>}
                footer={false}
                icon={true}
                iconSrc={addEllipseIcon}
                variant={'secondary'}
                additionalVariant={'skinless'} />
        </div>
        <div className='projectOverviewContainer'>
            <DashboardCard
                id='projectActivityLog'
                size='small'
                header={true}
                headerTitle={'Activity Log'}
                caption={''}
                actionBtn={true}
                actionBtnText={Constants.actionButtonText.view}
                handlerActionBtn={() => {
                    history.push(`/${resourceTypes.project}/${activityLogRoute}/${projectId}`);
                }}
                content={generateActivityLogContent()}
                footer={false}
            />
        </div>
        {showFAQ && <Form onSubmit={handleFaq}> <Modal
            id='faqModal'
            open={true}
            size='medium'
            headerTitle='Add FAQ'
            body={generateFAQModal()}
            footer={true}
            mainActionButtonTxt={values.faqID !== undefined && values.faqID !== '' ? 'Update' : 'Add'}
            secondActionButtonTxt='Cancel'
            secondActionButton={true}
            handleMainActionBtnClick={() => null}
            handleSecondaryActionBtnClick={closeFaqModal}
            helpOption={false}
            closeModal={closeFaqModal} /></Form>}
        {cancelModal && <Form onSubmit={handleCancellation}><Modal
            id='cancelModal'
            open={true}
            size='medium'
            headerTitle='Cancellation Reason'
            body={generateCancelModal()}
            footer={true}
            mainActionButtonTxt='Submit'
            secondActionButtonTxt='Cancel'
            secondActionButton={true}
            handleMainActionBtnClick={() => null}
            handleSecondaryActionBtnClick={closeCancelModal}
            helpOption={false}
            closeModal={closeCancelModal} /></Form>}
        {showWorknotes
            && <WorknotesModal
                closeModal={() => setShowWorkNote(false)}
                mode={workNoteMode}
                worknotesInput={projectDetails?.worknotes?.length > 0
                    ? projectDetails.worknotes
                    : []}
                handleCallback={handleAddActionWorkNotes}
            />}
        {showEscalation
        && <Modal
            id='escalationModal'
            headerTitle={'Escalation Reason'}
            open={showEscalation}
            size='small'
            body={<section>
                <div className='escalationReasonItem'>
                    <p className='title-small escalationTitle'>{`Event escalated by ${escalation.username} on ${helperFunctions.formatPrettyDate(escalation.escalatedOn)}`}</p>
                    <p className='caption-small escalationReason'>{escalation.escalationReason}</p>
                </div>

                {escalation.escalationStatus === 'closed'
                    ? <div className='escalationReasonItem'>
                        <p className='title-small escalationTitle'>
                            {`Event escalation resolved by ${escalation.resolvedByUsername} on ${helperFunctions.formatPrettyDate(escalation.resolvedOn)}`}</p>
                        <p className='caption-small escalationReason'>{escalation.resolvedReason}</p>
                    </div>
                    : <></>}
            </section>}
            mainActionButton={false}
            footer={false}
            helpOption={false}
            closeModal={() => {
                setShowEscalation(false);
            }}
        />}
        {escalationResolvedModal
        && <Form onSubmit={(e) => handleEscalateResolve(e)}>
            <Modal
                id='escalationResolvedModal'
                headerTitle='Resolve Escalation'
                open={true}
                size='medium'
                body={<>
                    <LabelledTextArea
                        id='resolvedReason'
                        type={LabelledInputTypeText}
                        label='Resolved Reason'
                        breakColumn={true}
                        onChange={(e) => handleChange(e)}
                        value={values.resolvedReason || ''}
                        currentCharCount={values.resolvedReason ? values.resolvedReason.length : 0}
                        placeholder='Enter escalation resolved reason'
                        required={true}
                    />
                </>}
                footer={true}
                handleMainActionBtnClick = {() => null}
                mainActionButtonTxt={'Confirm'}
                secondActionButton={true}
                handleSecondaryActionBtnClick={() => {
                    setValues({
                        ...values,
                        resolvedReason: '',
                    });
                    setEscalationResolvedModal(false);
                }}
                secondActionButtonTxt={'Cancel'}
                helpOption={false}
                closeModal={() => {
                    setValues({
                        ...values,
                        resolvedReason: '',
                    });
                    setEscalationResolvedModal(false);
                }}
            />
        </Form>}
        {riskModal
        && <Form onSubmit={(e) => handleRiskSubmit(e)}><Modal
            id='riskStatusModal'
            headerTitle='Select Risk Status'
            open={riskModal}
            size='medium'
            body={generateRiskContent()}
            footer={true}
            handleMainActionBtnClick = {() => null}
            mainActionButtonTxt={'Confirm'}
            secondActionButton={true}
            handleSecondaryActionBtnClick={closeRiskModal}
            secondActionButtonTxt={'Cancel'}
            helpOption={false}
            closeModal={closeRiskModal}
        /></Form>}
    </div>
    );
};

export default ProjectOverviewContent;
