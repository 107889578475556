/* eslint-disable max-len */
/* eslint-disable no-restricted-syntax */
import { isValid, parse } from 'date-fns';
import format from 'date-fns/format';

const filterToQueryParam = (key, value) => {
    switch (key) {
        case 'createdDate':
            return value?.length === 2 ? {
                createdFrom: (value[0]) ? format(value[0], 'dd-MM-yyyy') : '',
                createdTo: (value[1]) ? format(value[1], 'dd-MM-yyyy') : '',
            } : null;
        case 'expressionDate':
            return value?.length === 2 ? {
                expressionFrom: (value[0]) ? format(value[0], 'dd-MM-yyyy') : '',
                expressionTo: (value[1]) ? format(value[1], 'dd-MM-yyyy') : '',
            } : null;
        case 'submissionBetween':
            return value?.length === 2 ? {
                submissionFrom: (value[0]) ? format(value[0], 'dd-MM-yyyy') : '',
                submissionTo: (value[1]) ? format(value[1], 'dd-MM-yyyy') : '',
            } : null;
        case 'contractStart':
            return value?.length === 2 ? {
                contractStart: (value[0]) ? format(value[0], 'dd-MM-yyyy') : '',
                contractStartTo: (value[1]) ? format(value[1], 'dd-MM-yyyy') : '',
            } : null;
        case 'endDate':
            return value?.length === 2 ? {
                contractEnd: (value[0]) ? format(value[0], 'dd-MM-yyyy') : '',
                contractEndTo: (value[1]) ? format(value[1], 'dd-MM-yyyy') : '',
            } : null;
        case 'startKey':
            return value === 'N/A' ? null : {
                [key]: value,
            };
        case 'industryCodes':
        case 'deliveryArea':
        case 'localities':
            return value?.length > 0 ? {
                [key]: value.join(),
            } : null;
        case 'industryInfo':
        case 'deliveryAreaInfo':
        case 'localityInfo':
            return null;
        default:
            return value?.length > 0 ? {
                [key]: value,
            } : null;
    }
};

const sortToQueryParam = (sortString) => {
    const sortProps = sortString.split('-');
    const key = sortProps[0];
    const type = sortProps[1];
    switch (key) {
        // use the switch to control key names
        case 'systemTimestamp':
            return {
                orderType: type === 'descending' ? 'desc' : 'asc',
                orderBy: 'createdFrom',
            };
        default:
            return {
                orderType: type === 'descending' ? 'desc' : 'asc',
                orderBy: key,
            };
    }
};

const prepareBrowsePageQueryParams = (searchSortFilterData, startKey) => {
    let sortObject = {};
    let queryParams = {
        search: searchSortFilterData.search || '',
        startKey: startKey && startKey !== 'N/A' ? startKey : '',
    };
    if (searchSortFilterData.sort) {
        sortObject = sortToQueryParam(searchSortFilterData.sort);
        queryParams = {
            ...queryParams,
            ...sortObject,
        };
    }
    if (searchSortFilterData.filter) {
        Object.keys(searchSortFilterData.filter).map((key) => {
            const queryObject = filterToQueryParam(key, searchSortFilterData.filter[key]);
            queryParams = {
                ...queryParams,
                ...queryObject,
            };
            return null;
        });
    }
    return queryParams;
};

const applyFilterOnData = (key, value, dataItem) => {
    switch (key) {
        case 'createdOn':
            return Date.parse(dataItem.createdOn) >= Date.parse(value[0])
                && Date.parse(dataItem.createdOn) <= Date.parse(value[1]);
        case 'contractStart':
            return Date.parse(parse(dataItem.contractStart, 'dd-MM-yyyy', new Date())) >= Date.parse(value[0])
                && Date.parse(parse(dataItem.contractStart, 'dd-MM-yyyy', new Date())) <= Date.parse(value[1]);
        case 'contractEnd':
            return Date.parse(parse(dataItem.contractEnd, 'dd-MM-yyyy', new Date())) >= Date.parse(value[0])
                && Date.parse(parse(dataItem.contractEnd, 'dd-MM-yyyy', new Date())) <= Date.parse(value[1]);
        case 'expressionDate':
            return Date.parse(value[0]) >= Date.parse(dataItem.expressionWindowStartDate)
                && Date.parse(value[0]) <= Date.parse(dataItem.expressionWindowEndDate)
                && Date.parse(value[1]) >= Date.parse(dataItem.expressionWindowStartDate)
                && Date.parse(value[1]) <= Date.parse(dataItem.expressionWindowEndDate);
        case 'submissionBetween':
            return Date.parse(value[0]) >= Date.parse(dataItem.submissionStartDate)
                && Date.parse(value[0]) <= Date.parse(dataItem.submissionEndDate)
                && Date.parse(value[1]) >= Date.parse(dataItem.submissionStartDate)
                && Date.parse(value[1]) <= Date.parse(dataItem.submissionEndDate);
        case 'industryCodes':
            return dataItem?.industryCodes?.some((code) => value.includes(code));
        case 'deliveryArea':
            return dataItem?.deliveryCode?.some((code) => value.includes(code));
        case 'localities':
            return value.includes(dataItem?.localityName);
        case 'noOfEmployeesFrom':
            return parseInt(dataItem?.noOfEmployees, 10) >= parseInt(value, 10);
        case 'noOfEmployeesTo':
            return parseInt(dataItem?.noOfEmployees, 10) <= parseInt(value, 10);
        case 'estimatedValueFrom':
            return parseInt(dataItem?.estimatedValue, 10) >= parseInt(value, 10);
        case 'estimatedValueTo':
            return parseInt(dataItem?.estimatedValue, 10) <= parseInt(value, 10);
        case 'status':
            return dataItem[key] === value || dataItem[key]?.toString() === value;
        default:
            if (Array.isArray(value)) {
                return value?.includes(dataItem[key]);
            }
            return dataItem[key] === value;
    }
};

const applySearchAndFilter = (filterData, originalDataSet) => {
    const { filter, search } = filterData;
    if (filter) {
        Object.keys(filter).forEach((key) => {
            if (filter[key] === undefined || filter[key] === '' || filter[key]?.length === 0) {
                delete filter[key];
            }
        });
    }

    const filteredDataSet = originalDataSet.filter((data) => {
        let filterMatch = true;
        if (filter) {
            for (const [key, value] of Object.entries(filter)) {
                filterMatch = filterMatch && applyFilterOnData(key, value, data);
            }
        }
        if (filterMatch === true && search && search !== '') {
            const searchAttributes = Object.keys(data).filter(
                (key) => key.toLowerCase().includes('name')
                    || key.toLowerCase().includes('description')
                    || key.toLocaleLowerCase().includes('title')
                    || key.toLocaleLowerCase().includes('subject')
                    || key.toLocaleLowerCase().includes('keywords')
                    || key.toLocaleLowerCase().includes('message'),
            );
            let filterSearch = false;
            for (const attribute of searchAttributes) {
                if (typeof data[attribute] === 'string' && data[attribute]?.toLowerCase().includes(search?.toLowerCase())) {
                    filterSearch = true;
                    break;
                }
            }
            filterMatch = filterMatch && filterSearch;
        }
        return filterMatch;
    });

    return filteredDataSet;
};

const applySort = (filterData, originalDataSet) => {
    const { sort } = filterData;
    if (sort && sort.length > 0) {
        const sortAttribute = sort.split('-')[0];
        const sortOrder = sort.split('-')[1];
        const dataType = sort.split('-')[2];

        originalDataSet.sort((a, b) => {
            if (a[sortAttribute] === undefined) {
                return 1;
            }

            if (b[sortAttribute] === undefined) {
                return -1;
            }
            switch (dataType) {
                case 'date':
                    if (isValid(parse(a[sortAttribute], 'dd-MM-yyyy', new Date())) && isValid(parse(b[sortAttribute], 'dd-MM-yyyy', new Date()))) {
                        const dateA = parse(a[sortAttribute], 'dd-MM-yyyy', new Date());
                        const dateB = parse(b[sortAttribute], 'dd-MM-yyyy', new Date());
                        return (sortOrder === 'ascending')
                            ? dateA - dateB
                            : dateB - dateA;
                    }
                    return 0;
                case 'number':
                    if (sortOrder === 'ascending') {
                        return parseInt(a[sortAttribute], 10) - parseInt(b[sortAttribute], 10);
                    }
                    return parseInt(b[sortAttribute], 10) - parseInt(a[sortAttribute], 10);
                case 'dateTime':
                case 'string':
                    if (sortOrder === 'ascending') {
                        return (a[sortAttribute]?.toLowerCase() > b[sortAttribute]?.toLowerCase()) ? 1 : -1;
                    }
                    return (a[sortAttribute]?.toLowerCase() < b[sortAttribute]?.toLowerCase()) ? 1 : -1;
                default:
                    return 0;
            }
        });
    }

    return originalDataSet;
};

const filterData = (searchSortFilterData, originalDataSet) => {
    let filteredDataSet = applySearchAndFilter(searchSortFilterData, originalDataSet);
    filteredDataSet = applySort(searchSortFilterData, filteredDataSet);
    return filteredDataSet;
};

const helper = {
    prepareBrowsePageQueryParams,
    filterData,
};

export default helper;
