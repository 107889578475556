import React, { useEffect, useContext, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import DocumentBuilder from '../../features/DocumentBuilder';
import projectManagementAPIs from '../../services/project-management.service';
import helperFunctions from '../../utils/helperFunctions';
import Toast from '../../components/Alerts/Toast/Toast';
import AuthContext from '../../context/AuthContext';
import { accountTypes } from '../../config/constants';

const ViewEvaluatedQuestionnaire = () => {
    const history = useHistory();
    const { responseID } = useParams();
    const [type, setType] = useState('');
    const [initialState, setInitialState] = useState({});
    const authContext = useContext(AuthContext);

    useEffect(() => {
        const fetchData = async () => {
            if (authContext.user.accountType !== accountTypes.supplier) {
                const responseData = await projectManagementAPIs.getResponse(responseID);
                // responseData = initialData;
                if (responseData.status === 200) {
                    const preparedData = helperFunctions
                        .prepareDocumentBuilderState('questionnaire', responseData.data, responseID);
                    setInitialState(preparedData);
                    setType(preparedData.details.type);
                } else {
                    Toast.fire({
                        icon: 'error',
                        titleText: 'Unable to retrieve information.',
                    });
                }
            } else {
                Toast.fire({
                    icon: 'error',
                    titleText: 'You do not have permission to view this content.',
                });
                history.push('/dashboard');
            }
        };
        // TODO: if (templateId && authContext.user.isAuthenticated) {
        if (responseID) {
            fetchData();
        }
    }, [responseID]);

    return Object.keys(initialState).length > 0 ? <DocumentBuilder
        documentMode={'viewevaluation'}
        context='Questionnaire'
        type={type}
        initialDocumentState={initialState}
        sectionLabel='Section'
        allowSectionReordering={false}
        allowSectionItemsReordering={false}
        sectionDetailsUserList={[]}
    /> : <></>;
};

export default ViewEvaluatedQuestionnaire;
