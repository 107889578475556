import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import DashboardCard from '../../../../components/DashboardCard';
import Button from '../../../../components/Button';
import editIcon from '../../../../styles/images/edit.png';
import Events from './components/Events';

const WorkFlowEvents = ({
    eventDetails,
    workflowId,
    handleEditWorkflow,
    handleUpdateEvent,
    handleEscalateEvent,
    handlePublishEvent,
    handleUploadDocument,
    handleDeleteDocument,
    handleAddWorkNotes,
    showSelectWorkflow = false,
    canEditWorkflow = false,
    canSelectWorkflow = false,
    handelSelectWorkflow,
    userSteeringRoles,
    routeToMarket,
    callOffMechanism,
    contractType,
    projectType,
    readOnly,
}) => {
    const [events, setEvents] = useState([]);
    useEffect(() => {
        const allEvents = [];
        eventDetails?.stages?.map((stage) => {
            allEvents.push(...stage.events);
            return null;
        });
        setEvents(allEvents);
    }, [eventDetails]);

    const generateEventsSection = () => (eventDetails
        ? <Events
            events={events}
            projectId={eventDetails.projectID}
            workflowId={workflowId}
            handleUpdateEvent={handleUpdateEvent}
            handleEscalateEvent={handleEscalateEvent}
            handlePublishEvent={handlePublishEvent}
            handleUploadDocument={handleUploadDocument}
            handleDeleteDocument={handleDeleteDocument}
            handleAddWorkNotes={handleAddWorkNotes}
            userSteeringRoles={userSteeringRoles}
            routeToMarket={routeToMarket}
            callOffMechanism={callOffMechanism}
            contractType={contractType}
            projectType={projectType}
            readOnly={readOnly}
        />
        : <div className='noWorkflowSelected'>
            <p className='title'>No workflow selected for this project.</p>
            {showSelectWorkflow
            && canSelectWorkflow
            && <Button
                id={'selectWorkflowBtn'}
                type={'button'}
                label={'Select Workflow'}
                handleClick={handelSelectWorkflow}
            />}
        </div>);

    return (
        <DashboardCard
            id={showSelectWorkflow ? 'noWorkflowEvent' : 'workflowEvent'}
            size='medium'
            header={true}
            headerTitle={'Workflow'}
            caption={eventDetails?.workflowName}
            actionBtn={canEditWorkflow && !showSelectWorkflow}
            actionBtnText={canEditWorkflow ? 'Edit Workflow' : ''}
            handlerActionBtn={canEditWorkflow ? handleEditWorkflow : () => null }
            variant={canEditWorkflow ? 'secondary' : ''}
            additionalVariant={canEditWorkflow ? 'skinless' : ''}
            content={generateEventsSection()}
            footer={false}
            icon={canEditWorkflow}
            iconSrc={canEditWorkflow ? editIcon : ''}
        />
    );
};

WorkFlowEvents.propTypes = {
    eventDetails: PropTypes.object,
    workflowId: PropTypes.string,
    handleEditWorkflow: PropTypes.func,
    handleUpdateEvent: PropTypes.func,
    handlePublishEvent: PropTypes.func,
    handleEscalateEvent: PropTypes.func,
    handleUploadDocument: PropTypes.func,
    handleDeleteDocument: PropTypes.func,
    handleAddWorkNotes: PropTypes.func,
    showSelectWorkflow: PropTypes.bool,
    canEditWorkflow: PropTypes.bool,
    canSelectWorkflow: PropTypes.bool,
    handelSelectWorkflow: PropTypes.func,
    userSteeringRoles: PropTypes.array,
    routeToMarket: PropTypes.string,
    callOffMechanism: PropTypes.string,
    contractType: PropTypes.array,
    projectType: PropTypes.string,
    readOnly: PropTypes.bool,
};

export default WorkFlowEvents;
