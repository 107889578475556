import React, { useEffect, useState } from 'react';
import BrowseData from '../../features/BrowseData';
import projectManagementAPIs from '../../services/project-management.service';
import Toast from '../../components/Alerts/Toast/Toast';

const BrowseCallOffs = () => {
    const [callOff, setCallOff] = useState([]);

    const fetchData = async () => {
        try {
            const responseData = await projectManagementAPIs.browseCallOff();
            if (responseData.status === 200) {
                setCallOff(responseData.data.items);
            } else {
                Toast.fire({
                    icon: 'error',
                    titleText: 'Unable to retrieve information.',
                });
            }
        } catch (error) {
            Toast.fire({
                icon: 'error',
                titleText: 'Unable to retrieve information.',
            });
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    return <BrowseData
        id='browseCallOff'
        context='calloff'
        contextSingular='calloff'
        dataSet={callOff}
        searchPlaceholder='Search for a Call Off'
        numOfRowsPerPage={15}
        action={{
            type: 'view',
        }}
        allowAddNew={false}
        allowFilter={false}
        allowSort={false}
    />;
};

export default BrowseCallOffs;
