const Constants = {
    regionalOffice: 'regionaloffice',
    headquarter: 'headquarter',
    department: 'department',
    allowedRisks: [null, 'Low', 'Medium', 'High', 'Critical'],
    riskStatus: 'Risk Status',
    regionalOfficeTitle: 'Regional Offices',
    headquartersTitle: 'Headquarters',
    departmentAndUsers: 'Departments & Users',
    deliveryArea: 'Delivery Area ',
    codesCategories: 'Codes and Categories ',
    companySize: 'Number of employees: ',
    regionName: 'Region:',
    localityName: 'Locality:',
    wardName: 'Ward:',
    address: 'Address:',
    registrationNumber: 'Registration number:',
    vatNumber: 'VAT Number:',
    natureOfBusiness: 'Nature of business (SIC):',
    businessStructure: 'Company type:',

    messages: {
        noData: {
            title: 'No Data Found!',
            message: 'No suppliers data available now. Please try again later',
        },
    },
    creditScore: {
        A: 'lowest',
        B: 'low',
        C: 'amber',
        D: 'high',
        E: 'highest',
    },
};

export default Constants;
