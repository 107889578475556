import React, {
    useEffect, useState, useContext,
} from 'react';
import PropTypes from 'prop-types';
import { useHistory, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';

import CreatePasswordFormBody from './components/CreatePassword';
import CreatePasswordSuccessBody from './components/CreatePasswordSuccess';
import Constants from './constants';
import LoginFormBody from './components/LoginForm';
import InvalidCredentialsErrorDialog from './components/ErrorDialog';
import Modal from '../../components/Modal';
import Form from '../../components/Form';
import AuthContext from '../../context/AuthContext';
import SubmitEmail from './components/ForgotPassword/SubmitEmail';
import EmailSuccessMessage from './components/ForgotPassword/EmailSuccessMessage';
import VerifyCode from './components/ForgotPassword/VerifyCode';
import VerifyYourAccount from './components/Verification/VerifyYourAccount';
import VerificationSuccess from './components/Verification/VerificationSuccess';
import SelectionQuestionnairePrompt from './components/SelectionQuestionnairePrompt';
import authenticationAPIs from '../../services/user-management.service';
import documentManagementAPIs from '../../services/document-management.service';
import Toast from '../../components/Alerts/Toast/Toast';
// import Alert from '../../components/Alerts/Alert';
import UserDisabled from './components/UserDisabled';
import isProdEnvironemnt from '../../utils/isProdEnvironment';

/**
 * This component is a controller for logging in the user,
 * creating a password for the user,
 * handling password forgot scenario,
 * triggering registration,
 * handling account verification whilst registering a supplier.
 *
 * @param {*} showLoginModal
 * @param {*} closeModal
 * @param {*} toggleRegModal
 * @param {*} isVerificationJourney - Changes the default view the controller loads into the modal.
 * @returns
 */
const Login = ({
    showLoginModal,
    closeModal,
    toggleRegModal,
    isVerificationJourney = false,
    loginFormMode,
}) => {
    const authContext = useContext(AuthContext);
    const history = useHistory();

    const {
        confirmationCode,
    } = useParams();

    const [loginFormData, setLoginFormData] = useState({
        email: '', password: '',
    });

    const getLoginFormMode = (formMode) => Constants.nepoLoginFormModes.filter(
        (el) => el.modalBodyMode === formMode,
    )[0];

    const { accountDetails } = useSelector((state) => ({
        accountDetails: state.SupplierRegReducer.accountDetails,
    }));

    let login = !isVerificationJourney
        ? getLoginFormMode(Constants.LOGIN_FORM)
        : getLoginFormMode(Constants.VERIFY_YOUR_ACCOUNT);

    login = loginFormMode ? getLoginFormMode(loginFormMode) : login;
    useEffect(() => {
        if (isVerificationJourney) {
            setLoginFormData({
                ...loginFormData,
                email: accountDetails.email,
            });
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const [nepoLoginState, setNepoLoginState] = useState(login);
    const [verificationError, setVerificationError] = useState(false);

    const updateModalState = (formData, newState) => {
        setLoginFormData(formData);
        setNepoLoginState(newState);
    };

    const renderForgotPassword = () => {
        updateModalState({ email: '' }, getLoginFormMode(Constants.FP_SUBMIT_EMAIL_FORM));
    };

    // const renderVerifyCode = () => {
    //  updateModalState({ email: loginFormData.email, verifyCode: '' },
    // getLoginFormMode(Constants.FP_VERIFY_CODE_FORM));
    // };

    const renderCreatePasswordForm = (journey) => {
        updateModalState({
            newPassword: '',
            verifyPassword: '',
            verifyCode: loginFormData.verifyCode || '',
            email: loginFormData.email || '',
        }, getLoginFormMode(journey));
    };

    const handleAuthSuccessJourney = (authRes) => {
        if (authRes.data.ChallengeName) {
            renderCreatePasswordForm(Constants.CREATE_PASSWORD_FORM);
        } else {
            closeModal();
            history.push('/dashboard');
        }
    };

    const renderLoginInvalCredMessage = () => {
        updateModalState({}, getLoginFormMode(Constants.INVALID_LOGIN_MESSAGE_MESSAGE));
    };

    const handleAuthFailJourney = (authRes) => {
        if (authRes.networkError) {
            Toast.fire({
                icon: 'error',
                title: Constants.networkError,
            });
        } else if (!isVerificationJourney) {
            renderLoginInvalCredMessage();
        } else {
            setVerificationError(true);
        }
    };

    const renderCreatePasswordSuccess = (journey) => {
        updateModalState({}, getLoginFormMode(journey));
    };

    // const renderLogin = () => {
    //     updateModalState({
    //         email: '', password: '',
    //     }, getLoginFormMode(Constants.LOGIN_FORM));
    // };

    const isValidEmail = (email) => email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);
    const regexValidPwd = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{12,}$/;
    const isPasswordAppropriate = (newPassword) => newPassword.match(regexValidPwd);

    const handleLogin = async () => {
        if (loginFormData.email && loginFormData.password
            && isValidEmail(loginFormData.email)) {
            const authRes = await authContext.login(loginFormData.email, loginFormData.password);
            if (authRes.actionSuccess) {
                handleAuthSuccessJourney(authRes);
                setVerificationError(false);
            } else {
                handleAuthFailJourney(authRes);
            }
        }
    };

    const verifySelectionQuestionnaire = async () => {
        try {
            const response = await documentManagementAPIs.getDefaultTemplate();
            if (response.status === 200) {
                if (response.data[0].templateType === 'defaultSelectionResponse') {
                    return true;
                }
                return false;
            }
        } catch (err) {
            return Toast.fire({
                icon: 'error',
                titleText: 'Unable to retrieve information.',
            });
        }
        return false;
    };

    const handleCreatePassword = async () => {
        if (loginFormData.newPassword === loginFormData.verifyPassword
            && isPasswordAppropriate(loginFormData.newPassword)) {
            const authRes = await authContext.createPassword(loginFormData.newPassword);
            if (authRes.actionSuccess) {
                if (authRes?.data?.organisationDetails?.accountType.toLowerCase() === 'supplier'
                && authRes?.data?.userDetails?.userRole.toLowerCase() === 'admin') {
                    // Check if the selection questionnaire is already submitted.
                    const sqCheck = await verifySelectionQuestionnaire();
                    // Check if the admin user is not in a pending regional office.
                    if (sqCheck === false && authRes?.data?.organisationDetails.verified !== 'pending') {
                        renderCreatePasswordSuccess(Constants.SELECTION_QUESTIONNAIRE);
                    } else {
                        renderCreatePasswordSuccess(Constants.CREATE_PASSWORD_SUCCESS_MESSAGE);
                    }
                } else {
                    renderCreatePasswordSuccess(Constants.CREATE_PASSWORD_SUCCESS_MESSAGE);
                }
            } else if (!authRes.actionSuccess
                    && authRes?.data?.ErrorCode === '4000'
                    && authRes?.data?.Message?.includes('User is disabled.')) {
                Toast.fire({
                    icon: 'error',
                    title: Constants.disabledUserMessage,
                });
            } else {
                Toast.fire({
                    icon: 'error',
                    title: Constants.invalPasswordOrAttempt,
                });
            }
        } else {
            Toast.fire({
                icon: 'error',
                title: Constants.invalPassword,
            });
        }
    };

    const handleCreatePasswordSuccess = async () => {
        history.push('/dashboard');
    };

    const handleVerificationSuccess = async () => {
        if (authContext?.user?.accountType?.toLowerCase() === 'supplier'
            && authContext?.user?.role.toLowerCase() === 'admin') {
            // closeModal();
            updateModalState({}, getLoginFormMode(Constants.SELECTION_QUESTIONNAIRE));
            // history.push('/registration/codes-and-categories');
        } else {
            closeModal();
            history.push('/dashboard');
        }
    };

    const handleResetPassword = async () => {
        if (loginFormData.newPassword === loginFormData.verifyPassword
            && isPasswordAppropriate(loginFormData.newPassword)) {
            const authRes = await authContext.resetPassword(loginFormData.email,
                loginFormData.newPassword, confirmationCode);
            if (authRes.actionSuccess) {
                renderCreatePasswordSuccess(Constants.FP_RESET_PASSWORD_SUCCESS_MESSAGE);
            } else if (!authRes.actionSuccess
                && authRes?.data?.ErrorCode === '4000'
                && authRes?.data?.Message?.includes('Invalid verification code')) {
                Toast.fire({
                    icon: 'error',
                    title: Constants.invalCode,
                });
            } else {
                Toast.fire({
                    icon: 'error',
                    title: Constants.invalPasswordOrAttempt,
                });
            }
        } else {
            Toast.fire({
                icon: 'error',
                title: Constants.invalPassword,
            });
        }
    };

    const handleInitiateReset = async () => {
        if (loginFormData.email
            && isValidEmail(loginFormData.email)) {
            const authRes = await authContext.getVerificationCode(loginFormData.email);
            if (authRes.actionSuccess) {
                renderCreatePasswordSuccess(Constants.FP_SUBMIT_EMAIL_SUCCESS_MESSAGE);
            } else if (authRes.status === 500 && authRes.data['An error occurred: ']
                === Constants.passwordResetAPIError) {
                Toast.fire({
                    icon: 'error',
                    title: Constants.passwordResetWithoutCreate,
                });
            } else {
                Toast.fire({
                    icon: 'error',
                    title: Constants.passwordResetError,
                });
            }
        } else {
            Toast.fire({
                icon: 'error',
                title: Constants.invalEmail,
            });
        }
    };

    const handleRegisterSuccess = async () => {
        closeModal();
        history.push('/login');
    };

    const handleVerifyCodeForm = async () => {
        if (loginFormData.email
            && loginFormData.verifyCode) {
            renderCreatePasswordForm(Constants.FP_RESET_PASSWORD_FORM);
        } else {
            Toast.fire({
                icon: 'error',
                title: Constants.invalEmail,
            });
        }
    };

    const handleInput = (event) => {
        const attribute = event.target.name;
        const { value } = event.target;
        setLoginFormData({
            ...loginFormData,
            [attribute]: value,
        });
    };

    const handleResendVerificationCode = (e) => {
        e.preventDefault();
        handleInitiateReset();
    };

    const handleResendPassword = async (e) => {
        e.preventDefault();
        const res = await authenticationAPIs.resendTempPassword(loginFormData.email);
        if (res.status === 200) {
            Toast.fire({
                icon: 'success',
                title: 'Password has been sent again successfully.',
            });
        } else {
            Toast.fire({
                icon: 'error',
                title: 'Unable to resend the password.',
            });
        }
    };

    const routeToDashboard = () => {
        history.push('/dashboard');
    };

    const routeToSelectionQuestionnaire = () => {
        history.push('/supplier/edit/sq');
    };

    const handleSecondaryActions = (modalBodyMode) => {
        let callbackAction = null;
        switch (modalBodyMode) {
            case Constants.FP_VERIFY_CODE_FORM:
                callbackAction = handleResendVerificationCode;
                break;
            case Constants.VERIFY_YOUR_ACCOUNT:
                callbackAction = handleResendPassword;
                break;
            case Constants.SELECTION_QUESTIONNAIRE:
                callbackAction = routeToDashboard;
                break;
            default: return null;
        }
        return callbackAction;
    };

    const getModalBodyModes = (formMode) => {
        const modalBodyModes = [
            {
                modeData: getLoginFormMode(Constants.LOGIN_FORM),
                body: <LoginFormBody formData={loginFormData} handleInput={handleInput}
                    toggleForgotPasswordModal={renderForgotPassword} />,
                submitAction: handleLogin,
            },
            {
                modeData: getLoginFormMode(Constants.CREATE_PASSWORD_FORM),
                body: <CreatePasswordFormBody formData={loginFormData}
                    handleInput={handleInput} isVerificationJourney={isVerificationJourney} />,
                submitAction: handleCreatePassword,
            },
            {
                modeData: getLoginFormMode(Constants.CREATE_PASSWORD_SUCCESS_MESSAGE),
                body: <CreatePasswordSuccessBody bodyMessage={Constants.passwordCreateSuccess} />,
                submitAction: handleCreatePasswordSuccess,
            },
            {
                modeData: getLoginFormMode(Constants.VERIFICATION_SUCCESS_MESSAGE),
                body: <VerificationSuccess />,
                submitAction: handleVerificationSuccess,
            },
            {
                modeData: getLoginFormMode(Constants.INVALID_LOGIN_MESSAGE_MESSAGE),
                body: <InvalidCredentialsErrorDialog closeModal={closeModal}
                    toggleRegModal={toggleRegModal} />,
                submitAction: closeModal,
            },
            {
                modeData: getLoginFormMode(Constants.FP_SUBMIT_EMAIL_FORM),
                body: <SubmitEmail formData={loginFormData}
                    handleInput={handleInput} />,
                submitAction: handleInitiateReset,
            },
            {
                modeData: getLoginFormMode(Constants.FP_SUBMIT_EMAIL_SUCCESS_MESSAGE),
                body: <EmailSuccessMessage/>,
                submitAction: closeModal,
            },
            {
                modeData: getLoginFormMode(Constants.FP_VERIFY_CODE_FORM),
                body: <VerifyCode formData={loginFormData}
                    handleInput={handleInput} />,
                submitAction: handleVerifyCodeForm,
            },
            {
                modeData: getLoginFormMode(Constants.FP_RESET_PASSWORD_FORM),
                body: <CreatePasswordFormBody formData={loginFormData}
                    handleInput={handleInput} />,
                submitAction: handleResetPassword,
            },
            {
                modeData: getLoginFormMode(Constants.FP_RESET_PASSWORD_SUCCESS_MESSAGE),
                body: <CreatePasswordSuccessBody bodyMessage={Constants.passwordResetSuccess} />,
                submitAction: closeModal,
            },
            {
                modeData: getLoginFormMode(Constants.VERIFY_YOUR_ACCOUNT),
                body: <VerifyYourAccount formData={loginFormData}
                    handleInput={handleInput} verificationError={verificationError} />,
                submitAction: handleRegisterSuccess,
            },
            {
                modeData: getLoginFormMode(Constants.USER_DISABLED),
                body: <UserDisabled />,
                submitAction: closeModal,
            }, {
                modeData: getLoginFormMode(Constants.SELECTION_QUESTIONNAIRE),
                body: !isProdEnvironemnt() ? <SelectionQuestionnairePrompt/>
                    : <CreatePasswordSuccessBody bodyMessage={Constants.passwordResetSuccess}/>,
                submitAction: !isProdEnvironemnt() ? routeToSelectionQuestionnaire : closeModal,
            },
        ];
        return modalBodyModes.filter((el) => el.modeData.modalBodyMode
            === formMode)[0];
    };

    const handleSubmit = async (event, forFormMode) => {
        event.preventDefault();
        getModalBodyModes(forFormMode).submitAction();
    };
    return (<>
        {(showLoginModal)
            && <Form id='login-form' onSubmit={(event) => handleSubmit(event, nepoLoginState.modalBodyMode)}>
                <Modal
                    open={true}
                    closeModal={closeModal}
                    size={nepoLoginState.modalSize}
                    headerTitle={nepoLoginState.headerTitle}
                    secondActionButton={nepoLoginState.secondActionButton}
                    secondActionButtonTxt={nepoLoginState.secondActionButtonTxt}
                    handleSecondaryActionBtnClick=
                        {handleSecondaryActions(nepoLoginState.modalBodyMode)}
                    handleMainActionBtnClick={() => null}
                    mainActionButtonTxt={nepoLoginState.mainBtnTxt}
                    closeButton={false}
                    body={getModalBodyModes(nepoLoginState.modalBodyMode).body}
                    helpOption={nepoLoginState.showHelpOption}
                    helpTxt='New to Open?'
                    helpBtnTxt='Create an Account'
                    handleHelpActionBtnClick={() => { closeModal(); toggleRegModal(); }}
                    allowCloseButton={nepoLoginState.allowCloseButton}
                />
            </Form>
        }
    </>
    );
};

Login.propTypes = {
    showLoginModal: PropTypes.bool,
    closeModal: PropTypes.func,
    toggleRegModal: PropTypes.func,
    isVerificationJourney: PropTypes.bool,
    loginFormMode: PropTypes.string,
};

export default Login;
