const projectManagement = {
    getProject: `${process.env.MS_PROJECT_HOST || process.env.MS_GATEWAY_HOST}/project/overview`,
    getPublicProject: `${process.env.MS_PROJECT_HOST || process.env.MS_GATEWAY_HOST}/project/public/get`,
    getPublicProjects: `${process.env.MS_PROJECT_HOST || process.env.MS_GATEWAY_HOST}/project/public`,
    createProjectRequest: `${process.env.MS_PROJECT_HOST || process.env.MS_GATEWAY_HOST}/project/create`,
    cancelProject: `${process.env.MS_PROJECT_HOST || process.env.MS_GATEWAY_HOST}/project/cancel`,
    getAll: `${process.env.MS_PROJECT_HOST || process.env.MS_GATEWAY_HOST}/project/all`,
    getAllState: `${process.env.MS_PROJECT_HOST || process.env.MS_GATEWAY_HOST}/project/getAll/state`,
    getSuggestedOpportunities: `${process.env.MS_PROJECT_HOST || process.env.MS_GATEWAY_HOST}/project/suggested/get`,
    updateProject: `${process.env.MS_PROJECT_HOST || process.env.MS_GATEWAY_HOST}/project/update`,
    updateInterest: `${process.env.MS_PROJECT_HOST || process.env.MS_GATEWAY_HOST}/project/update/interest`,
    updateFAQ: `${process.env.MS_PROJECT_HOST || process.env.MS_GATEWAY_HOST}/project/update/FAQ`,
    updateLot: `${process.env.MS_PROJECT_HOST || process.env.MS_GATEWAY_HOST}/project/update/lot`,
    assignQuestionnaire: `${process.env.MS_PROJECT_HOST || process.env.MS_GATEWAY_HOST}/tender/questionnaire/assign`,
    getAssignedQuestionnaire: `${process.env.MS_PROJECT_HOST || process.env.MS_GATEWAY_HOST}/tender/questionnaire/get`,
    updateEvent: `${process.env.MS_PROJECT_HOST || process.env.MS_GATEWAY_HOST}/project/update/event`,
    getAllType: `${process.env.MS_PROJECT_HOST || process.env.MS_GATEWAY_HOST}/project/get/all/type`,
    getResponse: `${process.env.MS_PROJECT_HOST || process.env.MS_GATEWAY_HOST}/response/get`,
    getSubmission: `${process.env.MS_PROJECT_HOST || process.env.MS_GATEWAY_HOST}/response/get/tenders`,
    createLot: `${process.env.MS_PROJECT_HOST || process.env.MS_GATEWAY_HOST}/project/create/lot`,
    deleteLot: `${process.env.MS_PROJECT_HOST || process.env.MS_GATEWAY_HOST}/project/delete/lot`,
    createWorkflow: `${process.env.MS_PROJECT_HOST || process.env.MS_GATEWAY_HOST}/workflow/create`,
    getWorkflow: `${process.env.MS_PROJECT_HOST || process.env.MS_GATEWAY_HOST}/workflow`,
    getAllWorkflowsByUser: `${process.env.MS_PROJECT_HOST || process.env.MS_GATEWAY_HOST}/workflow/getAllUser`,
    getAllWorkflowsByAccount: `${process.env.MS_PROJECT_HOST || process.env.MS_GATEWAY_HOST}/workflow/browse/all`,
    updateWorkflow: `${process.env.MS_PROJECT_HOST || process.env.MS_GATEWAY_HOST}/workflow`,
    updateAssign: `${process.env.MS_PROJECT_HOST || process.env.MS_GATEWAY_HOST}/project/assign`,
    applyExtension: `${process.env.MS_PROJECT_HOST || process.env.MS_GATEWAY_HOST}/project/applyExtension`,
    assignWorkflowToProject: `${process.env.MS_PROJECT_HOST || process.env.MS_GATEWAY_HOST}/workflow/assign`,
    updateResponse: `${process.env.MS_PROJECT_HOST || process.env.MS_GATEWAY_HOST}/response/update`,
    getAllSubmission: `${process.env.MS_PROJECT_HOST || process.env.MS_GATEWAY_HOST}/response/getAll/tenders`,
    updateTenderSubmission: `${process.env.MS_PROJECT_HOST || process.env.MS_GATEWAY_HOST}/response/update/all/tenders`,
    updateResponsesOnQuestionnaireChange: `${process.env.MS_PROJECT_HOST || process.env.MS_GATEWAY_HOST}/response/update/all/tenders/available`,
    createAward: `${process.env.MS_PROJECT_HOST || process.env.MS_GATEWAY_HOST}/response/create/award`,
    updateAllAwards: `${process.env.MS_PROJECT_HOST || process.env.MS_GATEWAY_HOST}/response/updateAll/award`,
    getAllAwarded: `${process.env.MS_PROJECT_HOST || process.env.MS_GATEWAY_HOST}/response/getAll/awarded`,
    createNotice: `${process.env.MS_PROJECT_HOST || process.env.MS_GATEWAY_HOST}/publish/draftNotice/create`,
    getNotice: `${process.env.MS_PROJECT_HOST || process.env.MS_GATEWAY_HOST}/publish/viewNotice`,
    publishNotice: `${process.env.MS_PROJECT_HOST || process.env.MS_GATEWAY_HOST}/publish/draftNotice`,
    publishFTSNotice: `${process.env.MS_PROJECT_HOST || process.env.MS_GATEWAY_HOST}/publish/publishNotice/findTender`,
    prf: `${process.env.MS_PROJECT_HOST || process.env.MS_GATEWAY_HOST}/project/prf`,
    event: `${process.env.MS_PROJECT_HOST || process.env.MS_GATEWAY_HOST}/event`,
    stage: `${process.env.MS_PROJECT_HOST || process.env.MS_GATEWAY_HOST}/stage`,
    project: `${process.env.MS_PROJECT_HOST || process.env.MS_GATEWAY_HOST}/project`,
    lot: `${process.env.MS_PROJECT_HOST || process.env.MS_GATEWAY_HOST}/lots`,
    correspondence: `${process.env.MS_PROJECT_HOST || process.env.MS_GATEWAY_HOST}/resource`,
    unreadCorrespondence: `${process.env.MS_PROJECT_HOST || process.env.MS_GATEWAY_HOST}/project/get-correspondence-unread-data`,
    questionnaire: `${process.env.MS_PROJECT_HOST || process.env.MS_GATEWAY_HOST}/tender/questionnaire`,
    opportunity: `${process.env.MS_PROJECT_HOST || process.env.MS_GATEWAY_HOST}/opportunity`,
    interest: `${process.env.MS_PROJECT_HOST || process.env.MS_GATEWAY_HOST}/register-interest`,
    interestDirectAward: `${process.env.MS_PROJECT_HOST || process.env.MS_GATEWAY_HOST}/register-interest/direct-award`,
    response: `${process.env.MS_PROJECT_HOST || process.env.MS_GATEWAY_HOST}/response`,
    evaluation: `${process.env.MS_PROJECT_HOST || process.env.MS_GATEWAY_HOST}/evaluation`,
    award: `${process.env.MS_PROJECT_HOST || process.env.MS_GATEWAY_HOST}/award`,
    contract: `${process.env.MS_PROJECT_HOST || process.env.MS_GATEWAY_HOST}/contract`,
    updateContractDocs: `${process.env.MS_PROJECT_HOST || process.env.MS_GATEWAY_HOST}/contract/docs`,
    solution: `${process.env.MS_PROJECT_HOST || process.env.MS_GATEWAY_HOST}/solution`,
    solutionAccessRequestURL: `${process.env.MS_PROJECT_HOST || process.env.MS_GATEWAY_HOST}/solution/access-request`,
    solutionAccessRequestsURL: `${process.env.MS_PROJECT_HOST || process.env.MS_GATEWAY_HOST}/solution/access-requests`,
    viewFindTenderServiceNotice: `${process.env.MS_PROJECT_HOST || process.env.MS_GATEWAY_HOST}/publish/renderNotice/findTender`,
    exportCorrespondenceURL: `${process.env.MS_PROJECT_HOST || process.env.MS_GATEWAY_HOST}/resource/project`,
    getResponsesHistoryURL: `${process.env.MS_PROJECT_HOST || process.env.MS_GATEWAY_HOST}/response/submissions/history`,
    getResponseVersion: `${process.env.MS_PROJECT_HOST || process.env.MS_GATEWAY_HOST}/response/version/get`,
};

export default projectManagement;
