import React, { useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import Modal from '../../../components/Modal';
import LabelledTextArea from '../../../components/LabelledTextArea';
import Button from '../../../components/Button';
import StatusLabel from '../../../components/StatusLabel';
import { evaluationStatuses, questionnaireTypes } from '../../../config/constants';
import rbac from '../../../rbac';
import AuthContext from '../../../context/AuthContext';
import projectManagementAPIs from '../../../services/project-management.service';
import Toast from '../../../components/Alerts/Toast/Toast';
import { evaluationButtons } from '../constants';
import helperFunctions from '../../../utils/helperFunctions';

const SectionContent = ({
    data,
    showStatusLabel = false,
    showButton = false,
    projectDetails,
    isLotLevel = false,
    isDPS = false,
    handleStatusUpdate,
    handleDisqualifySupplier,
    readOnly,
}) => {
    const [values, setValues] = useState();
    const [disqualifyModal, setDisqualifyModal] = useState(false);
    const [reasonModal, setReasonModal] = useState(false);
    const history = useHistory();
    const authContext = useContext(AuthContext);
    const sectionLength = data?.responses?.length - 1;
    const userSteeringRole = rbac.util.getSteeringGroupRole(
        authContext.user.id,
        projectDetails?.steeringInfo,
    );

    const getPrettyQuestionnaireType = (qType) => questionnaireTypes.filter(
        (eventType) => eventType.value === qType,
    )[0]?.label;

    const handleEvaluate = async (responseId) => {
        try {
            const response = await projectManagementAPIs.startEvaluation(responseId);
            if (response.status === 200) {
                history.push(`/questionnaires/evaluate/${responseId}`);
            } else {
                Toast.fire({
                    icon: 'error',
                    titleText: 'Unable to start evaluation.',
                });
            }
        } catch (error) {
            Toast.fire({
                icon: 'error',
                titleText: 'Unable to start evaluation.',
            });
        }
    };

    const handleViewQuestionnaireButton = (responseID) => {
        // if (readOnly) {
        //     history.push(`/questionnaires/answer/view/${responseID}`);
        // } else {
        //     history.push(`/questionnaires/evaluate/view/${responseID}`);
        // }
        history.push(`/questionnaires/evaluate/view/${responseID}`);
        // TODO: Answer view - hide answer button for buyer
    };

    const generateButtons = (response, idx) => {
        switch (response.evaluationStatus || response.legalStatus) {
            case evaluationStatuses.released:
                return <>
                    {response.supplierDisqualified === true
                    && <Button
                        id={`viewReason-Btn-${idx}`}
                        label={'View Reason'}
                        additionalVariant={'small'}
                        handleClick={() => setReasonModal(true)}
                    />}
                    {rbac.can(rbac.action.evaluateQuestionnaire, userSteeringRole) && !readOnly
                        ? <Button
                            id={`${response.submissionStatus}-Btn-${idx}`}
                            label={'Evaluate'}
                            additionalVariant={'small'}
                            handleClick={() => handleEvaluate(response.responseID)}
                        />
                        : rbac.can(rbac.action.viewEvaluateQuestionnaire, userSteeringRole)
                        && <Button
                            id={`${response.submissionStatus}-Btn-${idx}`}
                            label={'View'}
                            additionalVariant={'small'}
                            handleClick={() => handleViewQuestionnaireButton(response.responseID) }
                        />
                    }
                </>;
            case evaluationStatuses.notStarted:
                return <>
                    {response.supplierDisqualified === true
                    && <Button
                        id={`viewReason-Btn-${idx}`}
                        label={'View Reason'}
                        additionalVariant={'small'}
                        handleClick={() => setReasonModal(true)}
                    />}
                    {rbac.can(rbac.action.evaluateQuestionnaire, userSteeringRole) && !readOnly
                        ? <Button
                            id={`${response.submissionStatus}-Btn-${idx}`}
                            label={'Evaluate'}
                            additionalVariant={'small'}
                            handleClick={() => handleEvaluate(response.responseID)}
                        />
                        : rbac.can(rbac.action.viewEvaluateQuestionnaire, userSteeringRole)
                        && <Button
                            id={`${response.submissionStatus}-Btn-${idx}`}
                            label={'View'}
                            additionalVariant={'small'}
                            handleClick={() => handleViewQuestionnaireButton(response.responseID) }
                        />
                    }
                </>;
            case evaluationStatuses.underEvaluation:
                return <>
                    {response.supplierDisqualified === true
                    && <Button
                        id={`viewReason-Btn-${idx}`}
                        label={'View Reason'}
                        additionalVariant={'small'}
                        handleClick={() => setReasonModal(true)}
                    />}
                    {rbac.can(rbac.action.evaluateQuestionnaire, userSteeringRole) && !readOnly
                        ? <Button
                            id={`${response.submissionStatus}-Btn-${idx}`}
                            label={'Resume'}
                            additionalVariant={'small'}
                            handleClick={() => history.push(`/questionnaires/evaluate/${response.responseID}`) }
                        />
                        : rbac.can(rbac.action.viewEvaluateQuestionnaire, userSteeringRole)
                        && <Button
                            id={`${response.submissionStatus}-Btn-${idx}`}
                            label={'View'}
                            additionalVariant={'small'}
                            handleClick={() => handleViewQuestionnaireButton(response.responseID) }
                        />
                    }
                </>;
            case evaluationStatuses.evaluationComplete:
                return <>
                    {response.supplierDisqualified === true
                    && <Button
                        id={`viewReason-Btn-${idx}`}
                        label={'View Reason'}
                        additionalVariant={'small'}
                        handleClick={() => setReasonModal(true)}
                    />}
                    {rbac.can(rbac.action.viewEvaluateQuestionnaire, userSteeringRole)
                    && <Button
                        id={`${response.submissionStatus}-Btn-${idx}`}
                        label={'View'}
                        additionalVariant={'small'}
                        handleClick={() => handleViewQuestionnaireButton(response.responseID) }
                    />}
                    {/* {isDPS
                    && !isLotLevel
                    && rbac.can(rbac.action.submitEvaluationsForApproval, userSteeringRole)
                    && evaluationButtons.getSubmitForApprovalButton(
                        handleStatusUpdate,
                        data.accountID,
                    )} */}
                </>;
            case evaluationStatuses.awaitingApproval:
                return <>
                    {response.supplierDisqualified === true
                    && <Button
                        id={`viewReason-Btn-${idx}`}
                        label={'View Reason'}
                        additionalVariant={'small'}
                        handleClick={() => setReasonModal(true)}
                    />}
                    {rbac.can(rbac.action.viewEvaluateQuestionnaire, userSteeringRole)
                    && <Button
                        id={`${response.submissionStatus}-Btn-${idx}`}
                        label={'View'}
                        additionalVariant={'small'}
                        handleClick={() => handleViewQuestionnaireButton(response.responseID) }
                    />}
                    {/* {isDPS
                    && !isLotLevel
                    && rbac.can(rbac.action.approveStage1, userSteeringRole)
                    && <>
                        {evaluationButtons.getStage1RejectButton(
                            handleStatusUpdate,
                            data.accountID,
                            'Reject',
                        )}
                        {evaluationButtons.getStage1ApproveButton(
                            handleStatusUpdate,
                            data.accountID,
                            'Approve',
                        )}
                    </>} */}
                </>;
            case evaluationStatuses.stage1Approved:
                return <>
                    {response.supplierDisqualified === true
                    && <Button
                        id={`viewReason-Btn-${idx}`}
                        label={'View Reason'}
                        additionalVariant={'small'}
                        handleClick={() => setReasonModal(true)}
                    />}
                    {rbac.can(rbac.action.viewEvaluateQuestionnaire, userSteeringRole)
                    && <Button
                        id={`${response.submissionStatus}-Btn-${idx}`}
                        label={'View'}
                        additionalVariant={'small'}
                        handleClick={() => handleViewQuestionnaireButton(response.responseID) }
                    />}
                    {/* {isDPS
                    && !isLotLevel
                    && rbac.can(rbac.action.approveEvaluationStage2, userSteeringRole)
                    && <>
                        {evaluationButtons.getStage2RejectButton(
                            handleStatusUpdate,
                            data.accountID,
                            'Reject',
                        )}
                        {evaluationButtons.getStage2ApproveButton(
                            handleStatusUpdate,
                            data.accountID,
                            'Approve',
                        )}
                    </>} */}
                </>;
            case evaluationStatuses.approved:
                return <>
                    {response.supplierDisqualified === true
                    && <Button
                        id={`viewReason-Btn-${idx}`}
                        label={'View Reason'}
                        additionalVariant={'small'}
                        handleClick={() => setReasonModal(true)}
                    />}
                    {rbac.can(rbac.action.viewEvaluateQuestionnaire, userSteeringRole)
                    && <Button
                        id={`${response.submissionStatus}-Btn-${idx}`}
                        label={'View'}
                        additionalVariant={'small'}
                        handleClick={() => handleViewQuestionnaireButton(response.responseID) }
                    />
                    }
                </>;
            default:
                return <></>;
        }
    };

    const generateDPSButtons = () => {
        switch (data?.responses[0].evaluationStatus || data?.responses[0].legalStatus) {
            case evaluationStatuses.evaluationComplete:
                return <>
                    {rbac.can(rbac.action.submitEvaluationsForApproval, userSteeringRole)
                     && helperFunctions.isSubmitForApprovalAvailable(data?.responses)
                    && <div className='sbAccordionSectionContentRow'>
                        <div className='sbAccordionSectionContentAddon'>
                            {evaluationButtons.getSubmitForApprovalButton(
                                handleStatusUpdate,
                                data.accountID,
                            )}
                        </div>
                    </div>}
                </>;
            case evaluationStatuses.awaitingApproval:
                return <>
                    {rbac.can(rbac.action.approveStage1, userSteeringRole)
                    && <div className='sbAccordionSectionContentRow'>
                        <div className='sbAccordionSectionContentAddon'>
                            {evaluationButtons.getStage1RejectButton(
                                handleStatusUpdate,
                                data.accountID,
                                'Reject',
                            )}
                            {evaluationButtons.getStage1ApproveButton(
                                handleStatusUpdate,
                                data.accountID,
                                'Approve',
                            )}
                        </div>
                    </div>}
                </>;
            case evaluationStatuses.stage1Approved:
                return <>
                    {rbac.can(rbac.action.approveStage2, userSteeringRole)
                    && <div className='sbAccordionSectionContentRow'>
                        <div className='sbAccordionSectionContentAddon'>
                            {evaluationButtons.getStage2RejectButton(
                                handleStatusUpdate,
                                data.accountID,
                                'Reject',
                            )}
                            {evaluationButtons.getStage2ApproveButton(
                                handleStatusUpdate,
                                data.accountID,
                                'Approve',
                            )}
                        </div>
                    </div>}
                </>;
            case evaluationStatuses.released:
                return <>
                    {rbac.can(rbac.action.submitEvaluationsForApproval, userSteeringRole)
                    && <div className='sbAccordionSectionContentRow'>
                        <div className='sbAccordionSectionContentAddon'>
                            {evaluationButtons.getDisqualifySupplierButton(
                                () => setDisqualifyModal(true),
                            )}
                        </div>
                    </div>}
                </>;
            case evaluationStatuses.notStarted:
                return <>
                    {rbac.can(rbac.action.disqualifySuppliers, userSteeringRole)
                    && <div className='sbAccordionSectionContentRow'>
                        <div className='sbAccordionSectionContentAddon'>
                            {evaluationButtons.getDisqualifySupplierButton(
                                () => setDisqualifyModal(true),
                            )}
                        </div>
                    </div>}
                </>;
            case evaluationStatuses.underEvaluation:
                return <>
                    {rbac.can(rbac.action.disqualifySuppliers, userSteeringRole)
                    && <div className='sbAccordionSectionContentRow'>
                        <div className='sbAccordionSectionContentAddon'>
                            {evaluationButtons.getDisqualifySupplierButton(
                                () => setDisqualifyModal(true),
                            )}
                        </div>
                    </div>}
                </>;
            case evaluationStatuses.approved:
            default:
                return <></>;
        }
    };

    const generateLabel = (item, idx) => {
        const disqualified = item.supplierDisqualified
            ? evaluationStatuses.disqualified : undefined;
        switch (disqualified || item.evaluationStatus) {
            case evaluationStatuses.disqualified:
                return <>
                    <div className='statuslabel label'>
                        <StatusLabel
                            id={`label-${idx}`}
                            color={'red'}
                            labelTxt={'Disqualified'}
                        />
                    </div>
                </>;
            case evaluationStatuses.evaluationComplete:
            case evaluationStatuses.awaitingApproval:
            case evaluationStatuses.stage1Approved:
            case evaluationStatuses.approved:
                return <>
                    {item?.weightage !== undefined
                    && <p className='label'>{`Score: ${item.score}/${item.weightage}`}</p>}
                    {item?.passFailResult
                    && <p className='label'>{`Pass/ Fail Result: ${helperFunctions.camel2title(item.passFailResult)}`}</p>}
                    <div className='statuslabel label'>
                        <StatusLabel
                            id={`label-${idx}`}
                            color={'green'}
                            labelTxt={'Evaluation Complete'}
                        />
                    </div>
                </>;
            default: return <></>;
        }
    };

    const handleChange = (event) => {
        const { name } = event.target;
        const { value } = event.target;
        setValues({
            ...values,
            [name]: value,
        });
    };

    const generateModalBody = () => <>
        <div className='disqualifySuppliersModal'>
            <p className='body'>You are disqualifying the following supplier from this opportunity. If they have multiple submissions, they will be marked as unsuccessful in all of them.</p>
            <div className='disqualifyItem'>
                <p className='label caption supplierTitle'>{`Supplier: ${data?.account?.companyName}`}</p>
                <p className = 'label caption'>Reason for disqualification:</p>
                <LabelledTextArea
                    id={'internalReason'}
                    placeholder='Enter reason'
                    onChange={(e) => handleChange(e)}
                    value={values?.internalReason}
                />
            </div>
        </div>
    </>;

    const generateReasonModalBody = () => <>
        <div className='disqualifySuppliersModal'>
            <p className='body'>{data?.disqualificationReason?.internalReason}</p>
        </div>
    </>;

    return (<>
        {data?.responses?.map(
            (item, idx) => (
                <div className='sbAccordionSectionContentRow' key={idx}>
                    <div className= {`sbAccordionSectionContentData ${isLotLevel && 'lotLevelAdditionalPadding'}`} >
                        <p className='label title'>{item.responseName}</p>
                        <p className='label body'>{item.responseType ? getPrettyQuestionnaireType(item.responseType) : ''}</p>
                    </div>
                    <div className='sbAccordionSectionContentAddon'>
                        {!readOnly && showStatusLabel && generateLabel(item, idx)}
                        {showButton && generateButtons(item, idx)}
                    </div>
                    {sectionLength > idx && <div className='splitter' />}
                </div>
            ),
        )}
        {!readOnly && isDPS && generateDPSButtons()}
        {disqualifyModal
        && <Modal
            open={true}
            closeModal={() => {
                setValues();
                setDisqualifyModal(false);
            }}
            size='medium'
            headerTitle='Disqualify Supplier(s)'
            handleMainActionBtnClick={() => {
                handleDisqualifySupplier({
                    supplierAccountID: data.accountID,
                    internalReason: values.internalReason,
                });
                setValues();
                setDisqualifyModal(false);
            }}
            mainActionButtonTxt='Confirm'
            closeButton={false}
            body={generateModalBody()}
            helpOption={false}
            secondActionButton={true}
            secondActionButtonTxt='Cancel'
            handleSecondaryActionBtnClick={() => {
                setValues();
                setDisqualifyModal(false);
            }}
        />}
        {reasonModal
        && <Modal
            open={true}
            closeModal={() => {
                setReasonModal(false);
            }}
            footer={false}
            size='small'
            headerTitle='Disqualification Reason'
            closeButton={true}
            body={generateReasonModalBody()}
            helpOption={false}
            secondActionButton={false}
        />}
    </>);
};

SectionContent.propTypes = {
    data: PropTypes.object.isRequired,
    showStatusLabel: PropTypes.bool,
    showButton: PropTypes.bool,
    projectDetails: PropTypes.object.isRequired,
    isLotLevel: PropTypes.bool,
    isDPS: PropTypes.bool,
    handleStatusUpdate: PropTypes.func,
    handleDisqualifySupplier: PropTypes.func,
    readOnly: PropTypes.bool,
};

export default SectionContent;
