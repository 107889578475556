const documentManagement = {
    getAllByAccount: `${process.env.MS_DOCUMENT_HOST || process.env.MS_GATEWAY_HOST}/template/list`,
    getByDocumentID: `${process.env.MS_DOCUMENT_HOST || process.env.MS_GATEWAY_HOST}/document/get`,
    getSignedUrl: `${process.env.MS_DOCUMENT_HOST || process.env.MS_GATEWAY_HOST}/document/getSignedUrl`,
    getAllByDocumentID: `${process.env.MS_DOCUMENT_HOST || process.env.MS_GATEWAY_HOST}/document/get/files`,
    getDocumentMetadata: `${process.env.MS_DOCUMENT_HOST || process.env.MS_GATEWAY_HOST}/document/get/metadata`,
    getAllByDocumentIDInSubfolders: `${process.env.MS_DOCUMENT_HOST || process.env.MS_GATEWAY_HOST}/document/get/zip/subfolders`,
    // getDocumentByType:
    // `${process.env.MS_DOCUMENT_HOST || process.env.MS_GATEWAY_HOST}/document/listFiles`,
    getDocumentByType: `${process.env.MS_DOCUMENT_HOST || process.env.MS_GATEWAY_HOST}/document/browse/all`,
    deleteDocument: `${process.env.MS_DOCUMENT_HOST || process.env.MS_GATEWAY_HOST}/document/delete`,
    template: `${process.env.MS_DOCUMENT_HOST || process.env.MS_GATEWAY_HOST}/template`,
    getSection: `${process.env.MS_DOCUMENT_HOST || process.env.MS_GATEWAY_HOST}/section`,
    createSection: `${process.env.MS_DOCUMENT_HOST || process.env.MS_GATEWAY_HOST}/section/create`,
    listSections: `${process.env.MS_DOCUMENT_HOST || process.env.MS_GATEWAY_HOST}/section/browse/all`,
};

export default documentManagement;
