const Constants = {
    sortOptions: [
        null,
        { label: 'First name (ascending)', value: 'firstname-ascending' },
        { label: 'First name (descending)', value: 'firstname-descending' },
        { label: 'Last name (ascending)', value: 'surname-ascending' },
        { label: 'Last name (descending)', value: 'surname-descending' },
    ],
};

export default Constants;
