import React from 'react';
import { parseISO, isPast } from 'date-fns';
import { v4 as uuidv4 } from 'uuid';

import SeeMoreSeeLess from '../../components/SeeMoreSeeLess';
import Toast from '../../components/Alerts/Toast/Toast';
import projectManagementAPIs from '../../services/project-management.service';

import Button from '../../components/Button';
import dpsConstants from './dpsConstants';
import {
    responseStatus,
    view,
    viewQuestionnaireUrl,
    intentStatuses,
    intentStatusColor,
    opportunityAwardSummaryUrl,
    viewOutcome,
} from '../../config/constants';
import { getButtonLabel, isButtonDisabled } from '../../services/current-submissions.service';
import { getResponseStatusColor, getResponseStatusLabel } from '../../services/response.service';
import { getAwardStatusLabel, getAwardStatusColor } from '../../services/award.service';
import StatusLabel from '../../components/StatusLabel';
import Constants from '../../components/TreeViewer/constants';

const getBannerText = (status, deadline, withAward = false) => {
    if (deadline) return dpsConstants.viewResponse;
    if (withAward || status === '') return dpsConstants.submitAnswers;
    return ({
        [dpsConstants.readyToSubmit]: dpsConstants.readyToSubmitText,
        [dpsConstants.responseSubmitted]: dpsConstants.responseSubmittedText,
        [dpsConstants.actionNeeded]: dpsConstants.submitAnswers,
    }[status || '']);
};

const getBannerColour = (status, deadline) => {
    if (deadline) return null;
    return ({
        [dpsConstants.readyToSubmit]: dpsConstants.red,
        [dpsConstants.responseSubmitted]: dpsConstants.green,
    }[status || null]);
};

const getLotStatusPill = (
    lotID, mapIntentStatuses,
) => mapIntentStatuses?.[lotID] && <StatusLabel
    id={lotID}
    color={mapIntentStatuses[lotID] === intentStatuses.successful
        ? intentStatusColor.green : intentStatusColor.red}
    labelTxt={mapIntentStatuses[lotID]}
/>;

const getListQuestionnairesToRefresh = (questionnaireAwaitingRefresh) => (
    questionnaireAwaitingRefresh?.map((el) => <>
        <li>{el.lotTitle} {el.templateName}</li>
        <li style={{ fontStyle: 'italic' }}>{dpsConstants.ammendmentReason}{el.versionReason}</li>
    </>)
);

const getTextQuestionnaires = (questionnaireAwaitingRefresh) => (
    <>
        <p>{dpsConstants.refreshBannerText2}</p>
        <p>{dpsConstants.refreshBannerText3}</p>
        <p>{dpsConstants.refreshBannerText4}</p>
        <p>{dpsConstants.refreshBannerText5}</p>
        <ul>
            <SeeMoreSeeLess inputText={getListQuestionnairesToRefresh(questionnaireAwaitingRefresh)}
                inputTextCharacterLimit={0} />
        </ul>
    </>
);

const getPreviousResponsesMenu = (status, responseId) => ([{
    id: uuidv4(),
    type: 'statusLabel',
    color: getResponseStatusColor(status),
    labelTxt: getResponseStatusLabel(status),
},
{
    id: uuidv4(),
    type: 'button',
    size: 'regular',
    variant: 'primary',
    additionalVariant: 'btnSize',
    label: view,
    onClick: `${viewQuestionnaireUrl}${responseId}`,
},
]);

const getPreviousLotMenu = (lot) => (lot.intentStatus ? [{
    id: uuidv4(),
    type: 'statusLabel',
    color: getAwardStatusColor(lot.intentStatus),
    labelTxt: getAwardStatusLabel(lot.intentStatus),
}] : []);

const getInitialPage = (currentResponses, setSelectLotsModal) => (
    <div id='answerSummaryNoLots'>
        <div className='title'>{dpsConstants.selectLotsLabel}</div>
        <div id='lotButton'>
            {!isPast(parseISO(currentResponses?.submissionEndDate))
                && currentResponses?.projectStatus !== responseStatus.cancelled
                && <Button
                    id='primary-action-btn'
                    type='button'
                    size='regular'
                    variant='primary'
                    label={dpsConstants.selectLots}
                    handleClick={() => setSelectLotsModal(true)}
                />
            }
        </div>
    </div>
);

// ASSIGN LOTS FUNCTIONALITY

const handleLotSubmit = async (
    lotList, selectedLots, withAward, projectId, eventId,
    getAnswerSummaryDPSResponse, setSelectLotsModal, getResponsesHistory,
) => {
    try {
        let response;
        if (!selectedLots.length && withAward) {
            response = await projectManagementAPIs.dpsAssignLotsToOpportunity(projectId,
                eventId, {
                    lots: lotList,
                });
            getResponsesHistory();
        } else {
            response = await projectManagementAPIs.dpsEditLotsToOpportunity(projectId,
                eventId, {
                    lots: lotList,
                });
        }

        if (response.status === 200) {
            getAnswerSummaryDPSResponse(projectId, eventId);
            Toast.fire({
                icon: dpsConstants.successIcon,
                titleText: dpsConstants.lotSubmitSuccess,
            });
            setSelectLotsModal(false);
        } else {
            Toast.fire({
                icon: dpsConstants.errorIcon,
                titleText: dpsConstants.lotSelectError,
            });
        }
    } catch (err) {
        Toast.fire({
            icon: dpsConstants.errorIcon,
            titleText: dpsConstants.lotSelectError,
        });
    }
};

// BANNER HELPERS

const handleWithdrawAll = async (projectId, eventId, getAnswerSummaryDPSResponse) => {
    try {
        const response = await projectManagementAPIs.submitResponses(projectId, eventId, {
            action: dpsConstants.withdrawAction,
        });
        if (response.status === 200) {
            Toast.fire({
                icon: dpsConstants.successIcon,
                titleText: dpsConstants.answerWithdrawSuccess,
            });
            getAnswerSummaryDPSResponse(projectId, eventId);
        } else {
            Toast.fire({
                icon: dpsConstants.errorIcon,
                titleText: dpsConstants.answerWithdrawError,
            });
        }
    } catch (err) {
        Toast.fire({
            icon: dpsConstants.errorIcon,
            titleText: dpsConstants.lotSelectError,
        });
    }
};

const handleSubmitAll = async (projectId, eventId, getAnswerSummaryDPSResponse) => {
    try {
        const response = await projectManagementAPIs.submitResponses(projectId, eventId, {
            action: dpsConstants.submitAction,
        });
        if (response.status === 200) {
            Toast.fire({
                icon: dpsConstants.successIcon,
                titleText: dpsConstants.answerSubmitSuccess,
            });
            getAnswerSummaryDPSResponse(projectId, eventId);
        } else {
            Toast.fire({
                icon: dpsConstants.errorIcon,
                titleText: dpsConstants.answerSubmitError,
            });
        }
    } catch (err) {
        Toast.fire({
            icon: dpsConstants.errorIcon,
            titleText: dpsConstants.lotSelectError,
        });
    }
};

const refreshAnswers = async (projectId, eventId, getAnswerSummaryDPSResponse) => {
    try {
        const response = await projectManagementAPIs.refreshAnswers(projectId, eventId);
        if (response.status === 200) {
            getAnswerSummaryDPSResponse();
            Toast.fire({
                icon: dpsConstants.successIcon,
                titleText: dpsConstants.answerRefreshSuccess,
            });
        } else {
            Toast.fire({
                icon: dpsConstants.errorIcon,
                titleText: dpsConstants.answerRefreshError,
            });
        }
    } catch (err) {
        Toast.fire({
            icon: dpsConstants.errorIcon,
            titleText: dpsConstants.lotSelectError,
        });
    }
};

const getCurrentAction = (responseArray, questionnaireAwaitingRefresh, submissionEndDate) => {
    const isSubmissionDeadline = isPast(parseISO(submissionEndDate));
    if (responseArray.includes(responseStatus.bidSubmitted)) return dpsConstants.responseSubmitted;
    if (responseArray.length === 1 && responseArray.includes('submitted')) return dpsConstants.readyToSubmit;
    if (isSubmissionDeadline && !responseArray
        .includes(responseStatus.bidSubmitted)) return dpsConstants.responseNotSubmitted;
    if (responseArray.includes('inProgress')
    || responseArray.includes('notStarted')
    || (questionnaireAwaitingRefresh.length > 0
        && !isSubmissionDeadline)) return dpsConstants.actionNeeded;
    return '';
};

const getSubmissionStatusFromProjectOrLots = (data, questionnaireAwaitingRefresh) => {
    if (!data) {
        return '';
    }
    let allResponsesStatuses;
    if (data?.publishLevel === dpsConstants.projectPublishLevel) {
        allResponsesStatuses = [
            ...new Set(data.responses?.map((response) => response.responseStatus))];
    } else {
        const { projectsOrLots } = data;

        if (!projectsOrLots) {
            return '';
        }

        const responsesStatuses = Object.entries(projectsOrLots)?.reduce((acc, [key]) => {
            const singleLotArray = [
                ...new Set(projectsOrLots[key]?.responses
                    ?.map((response) => response.responseStatus))];
            acc.push(...singleLotArray);
            return acc;
        }, []);

        allResponsesStatuses = [...new Set(responsesStatuses)];
    }

    return getCurrentAction(
        allResponsesStatuses, questionnaireAwaitingRefresh, data.submissionEndDate,
    );
};

const isSubmitAllButtonDisabled = (isSubmissionDeadline, data) => (
    isSubmissionDeadline
    || data.bidStatus === responseStatus.bidSubmitted
    || data.projectStatus === responseStatus.cancelled
);

const isWidthdrawAllButtonDisabled = (isSubmissionDeadline, data) => (
    isSubmissionDeadline
    || data.bidStatus !== responseStatus.bidSubmitted
    || data.projectStatus === responseStatus.cancelled
);

const getAwaitingRefreshResponses = (projectsOrLots) => {
    if (!projectsOrLots) return [];
    const allResponses = Object.entries(projectsOrLots)
        .reduce((acc, projectOrLot) => acc.concat(projectOrLot[1].responses), []);
    return allResponses.filter((response) => response.requiresUpdate);
};

const getResponsesMenu = (initialData, response, questionnaireAwaitingRefresh) => [
    {
        id: uuidv4(),
        type: 'statusLabel',
        color: getResponseStatusColor(response.responseStatus,
            response.requiresUpdate,
            response.awaitingSupplierUpdate),
        labelTxt: getResponseStatusLabel(response.responseStatus,
            response.requiresUpdate,
            response.awaitingSupplierUpdate),
    },
    {
        id: uuidv4(),
        type: 'button',
        size: 'regular',
        variant: 'primary',
        additionalVariant: 'btnSize',
        disabled: isButtonDisabled(response, initialData, questionnaireAwaitingRefresh),
        label: getButtonLabel(response, initialData),
        onClick: `${viewQuestionnaireUrl}${response.responseID}`,
    },
];

const getPreviousSubmissionsMenu = (projectId, eventId) => [{
    id: uuidv4(),
    type: Constants.MENU_OPTIONS.LINK,
    className: 'secondary button link medium title',
    labelTxt: viewOutcome,
    href: `${opportunityAwardSummaryUrl}/${projectId}/${eventId}`,
}];

export {
    getBannerText,
    getBannerColour,
    getTextQuestionnaires,
    getSubmissionStatusFromProjectOrLots,
    getInitialPage,
    handleLotSubmit,
    getAwaitingRefreshResponses,
    isSubmitAllButtonDisabled,
    isWidthdrawAllButtonDisabled,
    handleWithdrawAll,
    handleSubmitAll,
    refreshAnswers,
    getLotStatusPill,
    getResponsesMenu,
    getPreviousResponsesMenu,
    getPreviousLotMenu,
    getPreviousSubmissionsMenu,
};
