import {
    workflowEvents,
    eventStatuses,
    workflowRestrictionConstants,
    callOffMechanismValues,
    projectTypes,
    publishAtLevel,
} from '../config/constants';

function getCurrentPublishEvent(workflowStages) {
    const events = workflowStages.reduce((acc, stage) => acc.concat(stage.events), []);
    return events?.findLast((event) => event.eventType === workflowEvents.publish
    && event.eventStatus !== eventStatuses.pending
    && event.isPublished === true);
}

function getRegisteredSuppliersFromCurrentPublishEvent(project) {
    const publishEvent = getCurrentPublishEvent(project.workflow?.stages);
    return publishEvent?.registerInterest || [];
}

function isProjectOrCallOffDirectAward({ route, callOffMechanism, projectType }) {
    return (projectType === projectTypes.project
            && this.isRTMDirectAward(route))
        || (projectType === projectTypes.callOff
            && callOffMechanism === callOffMechanismValues.directAward);
}

function isCallOffDirectAward({ callOffMechanism, projectType }) {
    return projectType === projectTypes.callOff
            && callOffMechanism === callOffMechanismValues.directAward;
}

function isCallOffFurtherCompetition({ callOffMechanism, projectType }) {
    return projectType === projectTypes.callOff
            && callOffMechanism === callOffMechanismValues.furtherComp;
}

function isRTMDirectAward(route) {
    return [
        workflowRestrictionConstants.routeToMarket_directAward,
        workflowRestrictionConstants.establishedSolution,
    ].includes(route);
}
function getPublishLevel(workflowRecord) {
    return getCurrentPublishEvent(workflowRecord.stages)?.publishProject
        ? publishAtLevel.project
        : publishAtLevel.lot;
}

export default {
    getRegisteredSuppliersFromCurrentPublishEvent,
    isProjectOrCallOffDirectAward,
    isCallOffDirectAward,
    isCallOffFurtherCompetition,
    isRTMDirectAward,
    getPublishLevel,
};
