import React, {
    useState,
} from 'react';

import coverImage from '../../styles/images/ICT-Services_Transparent BKGRD.gif';
import Card from '../../components/Card';
import Button from '../../components/Button';
import Constants from './constants';
import SupplierRegistration from '../../features/SupplierRegistration';

const generateUserCards = () => {
    const { userCardsData } = Constants;
    const elements = [];
    userCardsData.forEach((data, idx) => {
        elements.push(<Card
            key={`hpCard-${idx}`}
            id={data.id}
            size={'large'}
            header={false}
            footer={false}
            image={true}
            imageSrc={data.image}
            content={<>
                <h3 id={`${data.id}-title`}>{data.title}</h3>
                <p className='body' id={`${data.id}-description`}>{data.description}</p>
            </>}
        />);
    });
    return elements;
};

const Homepage = () => {
    const [showRegModal, setShowRegModal] = useState(false);
    const toggleRegModal = () => {
        setShowRegModal(!showRegModal);
    };
    document.title = 'Home - Open | Developed by Nepo';

    return <><main id='hpMainContent'>
        <section id='hpWelcomeContainer'>
            <div id='welcomeMsgContainer'>
                <h1>Welcome to Open</h1>
                <p>The complete technology solution for <span className='bold'>your</span> e-Procurement value chain.</p>
                <Button id='getStartedBtn'
                    label='Get Started'
                    handleClick={toggleRegModal} />
            </div>
            <div id='welcomeImgContainer'>
                <img src={coverImage} alt='main-cover'></img>
            </div>
        </section>
        <section id='hpInfoContainer'>
            <h2 id='portalUseQuestionText'>What is Open?</h2>
            <div id='hpInfoCards'>
                {generateUserCards()}
            </div>
        </section>
    </main>
    {showRegModal && <SupplierRegistration showRegModal={showRegModal}
        closeModal={toggleRegModal} />}
    </>;
};

export default Homepage;
