import React, {
    useState, useEffect, useContext,
} from 'react';
import { useHistory } from 'react-router-dom';
import { format } from 'date-fns';
import AuthContext from '../../context/AuthContext';
import Button from '../../components/Button';
import DashboardCard from '../../components/DashboardCard';
import SupplierCard from '../SupplierDashboard/components/SupplierCard';
import addIcon from '../../styles/images/addEllipseIcon.svg';
import userManagementAPIs from '../../services/user-management.service';
import gatewayAPIs from '../../services/gateway.service';
import Upload from '../../features/Upload';
import WorknotesModal from '../../features/WorknotesModal';
import UserRoles from '../../features/Header/constants';
import rbac from '../../rbac';
import helperFunctions from '../../utils/helperFunctions';
import Toast from '../../components/Alerts/Toast/Toast';
import { callOffMechanisms, routeToMarket } from '../../config/constants';
import { cleanupDeletedDocument, mergeUploadedDocuments, persistUploadedDocuments } from '../../utils/uploadHelper';

const getStatus = (startDate, eventStatus) => {
    const current = format(new Date(), 'dd-MM-yyyy');
    if (eventStatus === 'Started') {
        return 'In-progress';
    }
    if (startDate < current) {
        return 'Overdue';
    }
    if (startDate >= current) {
        return 'Pending';
    }
    return 'Pending';
};

const taskEventContent = (data) => {
    const taskEventItems = data.map((taskEventItem, index) => <div className='cardContentContainer' key={`event${index}`}>
        <div className='eventItem'>
            <div className='leftColumn'><p className='caption-xSmall'>{taskEventItem.startDate}</p></div>
            <div className='middleColumn'>
                <div className='eventHeader'>
                    <p className='title-xSmall'>{taskEventItem.eventName}</p>
                </div>
                <div className='eventContent'>
                    <p className='caption-xSmall'>{taskEventItem.projectName}</p>
                </div>
            </div>
            <div className='rightColumn'><p className='eventStatus title-xSmall'>
                <div className='statusText' id={getStatus(taskEventItem.startDate, taskEventItem.eventStatus)}>
                    <p className='title-small'>{getStatus(taskEventItem.startDate, taskEventItem.eventStatus)}</p>
                </div>
            </p></div>
        </div>
    </div>);
    return taskEventItems;
};

const myProjectContentItem = (projectItem) => <>
    <div className='contentItem'>
        <p className='caption-small itemLabel'>Estimated value:</p>
        <p className='title-small itemValue'>{projectItem.estimatedValue ? helperFunctions.formatCurrency(projectItem.estimatedValue) : '-'}</p>
    </div>
    <div className='contentItem'>
        <p className='caption-small itemLabel'>Current Stage:</p>
        <p className='title-small itemValue'>{projectItem.currentStage ? projectItem.currentStage : '-'}</p>
    </div>
    <div className='contentItem'>
        <p className='caption-small itemLabel'>Stage Progress:</p>
        <p className='title-small itemValue'>{`${projectItem.stageProgress}`}</p>
    </div>
</>;


const myRequestContentItem = (requestItem) => <>
    <div className='contentItem'>
        <p className='caption-small itemLabel'>Status:</p>
        <p className='title-small itemValue'>{helperFunctions.camel2title(requestItem.projectStatus)}</p>
    </div>
    <div className='contentItem'>
        <p className='caption-small itemLabel'>Contract value:</p>
        <p className='title-small itemValue'>{requestItem.estimatedValue ? helperFunctions.formatCurrency(requestItem.estimatedValue) : '-'}</p>
    </div>
    <div className='contentItem'>
        <p className='caption-small itemLabel'>Raised by:</p>
        <p className='title-small itemValue'>{requestItem.username}</p>
    </div>
    <div className='contentItem'>
        <p className='caption-small itemLabel'>Submitted on:</p>
        <p className='title-small itemValue'>{helperFunctions.formatPrettyDate(requestItem.createdOn)}</p>
    </div>
</>;

const myContractContentItem = (contractItem) => <>
    <div className='contentItem'>
        <p className='caption-small itemLabel'>Value:</p>
        <p className='title-small itemValue'>{contractItem.awardValue ? helperFunctions.formatCurrency(contractItem.awardValue) : '-'}</p>
    </div>
    <div className='contentItem'>
        <p className='caption-small itemLabel'>Rigour Level:</p>
        <p className='title-small itemValue'>{contractItem.rigour || '-'}</p>
    </div>
    <div className='contentItem'>
        <p className='caption-small itemLabel'>Current Stage:</p>
        <p className='title-small itemValue'>{contractItem.currentStage ? contractItem.currentStage : '-'}</p>
    </div>
    <div className='contentItem'>
        <p className='caption-small itemLabel'>Stage Progress:</p>
        <p className='title-small itemValue'>{contractItem.stageProgress}</p>
    </div></>;

const getPrettyRTM = (rtm) => routeToMarket.filter((route) => route.id === rtm)[0]?.name;

const LocalAuthorityDashboard = () => {
    const authContext = useContext(AuthContext);
    const [projects, setProjects] = useState([]);
    const [contracts, setContracts] = useState([]);
    const [requests, setRequests] = useState([]);
    const [tasks, setTasks] = useState([]);
    const [showWorknotes, setShowWorkNote] = useState(false);
    const [workNoteMode, setWorkNoteMode] = useState('');
    const [workNotes, setWorkNotes] = useState([]);
    const [documents, setDocuments] = useState([]);
    const [callOff, setCallOff] = useState([]);
    const [prfAwaitingApproval, setPrfAwaitingApproval] = useState([]);
    const { accountType } = authContext.user;
    const history = useHistory();

    const myContractContent = (data) => {
        const contractItems = data.map((contractItem, index) => ((index < 3) ? <SupplierCard
            key={index}
            id={`contractId${index}`}
            header
            caption={`Contract # ${contractItem.contractReference ? contractItem.contractReference : '-'}`}
            headerTitle={contractItem.title}
            content={myContractContentItem(contractItem)}
            actionBtnText='View Contract'
            handlerActionBtn={() => history.push(`/contracts/management/${contractItem.contractID}`)}
        /> : null));
        return contractItems;
    };

    const submitProcrumentRequest = () => <>
        {prfAwaitingApproval?.length > 0
            ? <div className='cardContentContainer'>
                {prfAwaitingApproval.map((prf) => (
                    <div className='submitRequestItem' key={prf.projectID}>
                        <div><p className='title'>{prf.title}</p></div>
                        <div>
                            <Button
                                id='view'
                                variant='primary'
                                label={'View'}
                                handleClick={() => history.push(`/procurement-requests/overview/${prf.projectID}`)}
                            />
                        </div>
                    </div>
                ))}
            </div>
            : <div className='cardContentContainer'>
                <p className='title' id='noProcurementRequestsContent'>No procurement requests available.</p>
            </div>}
    </>;

    const myProjectContent = (data) => {
        const projectItems = data.map((projectItem, index) => {
            if (index < 3) {
                return <SupplierCard
                    key={index}
                    id={`projetcId${index}`}
                    header
                    headerTitle={projectItem.title}
                    caption={`Project # ${projectItem.projectReference ? projectItem.projectReference : '-'}`}
                    content={myProjectContentItem(projectItem)}
                    actionBtnText='View Project'
                    alertMessage={projectItem.projectStatus === 'cancelled' ? 'Cancelled' : null}
                    handlerActionBtn={() => history.push(`/projects/overview/${projectItem.projectID}`)}
                    rtm={projectItem.route ? getPrettyRTM(projectItem.route) : ''}
                />;
            }
            return null;
        });

        return projectItems;
    };

    const getPrettyCallOffMechanism = (callOffMechanism) => callOffMechanisms.filter(
        (eventType) => eventType.value === callOffMechanism,
    )[0]?.label;

    const myCallOffContentItem = (item) => <>
        <div className='contentItem'>
            <p className='caption-small itemLabel'>{item?.title ? item.title : '-'}</p>
        </div>
        <div className='contentItem'>
            <p className='caption-small itemLabel'>Current Stage:</p>
            <p className='title-small itemValue'>{item?.currentStage ? item.currentStage : '-'}</p>
        </div>
        <div className='contentItem'>
            <p className='caption-small itemLabel'>Award value:</p>
            <p className='title-small itemValue'>£{item?.awardValue ? item.awardValue : '-'}</p>
        </div>
        <div className='contentItem'>
            <p className='caption-small itemLabel'>Call off mechanism:</p>
            <p className='title-small itemValue'>{item?.callOffMechanism ? getPrettyCallOffMechanism(item.callOffMechanism) : '-'}</p>
        </div>
        <div className='contentItem'>
            <p className='caption-small itemLabel'>Original solution:</p>
            <p className='title-small itemValue'>{item?.originalSolution ? item.originalSolution : '-'}</p>
        </div>
        <div className='contentItem'>
            <p className='caption-small itemLabel'>Stage Progress:</p>
            <p className='title-small itemValue'>{item?.stageProgress ? item.stageProgress : '-'}</p>
        </div>
    </>;

    const callOffContent = () => {
        const callOffItems = callOff?.map((item, index) => (<SupplierCard
            key={index}
            id={`callOff${index}`}
            header
            headerTitle={`Call Off #${item.projectReference}`}
            content={myCallOffContentItem(item)}
            actionBtnText='View call-off'
            handlerActionBtn={() => history.push(`/calloff/overview/${item.projectID}`)}
        />));
        return callOffItems;
    };

    const myRequestContent = (data) => {
        const requestItems = data.map((requestItem, index) => (<SupplierCard
            key={index}
            id={`requestId${index}`}
            header
            caption={`Request # ${requestItem.projectReference ? requestItem.projectReference : '-'}`}
            headerTitle={requestItem.title}
            content={myRequestContentItem(requestItem)}
            actionBtnText='View Request'
            handlerActionBtn={() => history.push(`/procurement-requests/overview/${requestItem.projectID}`)}
        />));
        return requestItems;
    };

    const fetchData = async () => {
        try {
            const dashboardData = await gatewayAPIs.dashboard();
            setPrfAwaitingApproval(dashboardData.data.prfAwaitingApproval);
            setProjects(dashboardData.data.myProjects);
            setRequests(dashboardData.data.myPrfs);
            setCallOff(dashboardData.data.callOffs);
            setContracts(dashboardData.data.myContracts);
            setTasks(dashboardData?.data?.myTasksAndEvents || []);
        } catch {
            Toast.fire({
                icon: 'error',
                titleText: 'Unable to retrieve information.',
            });
        }
    };

    const fetchUser = async () => {
        try {
            const userData = await userManagementAPIs.getUserDetails(authContext.user.id);
            if (userData.status === 200) {
                setWorkNotes(userData?.data?.organisationDetails?.worknotes);
                setDocuments(userData?.data?.additionalDocuments);
            }
        } catch {
            Toast.fire({
                icon: 'error',
                titleText: 'Unable to retrieve information.',
            });
        }
    };

    const viewWorkNotes = () => {
        setWorkNoteMode('view');
        setShowWorkNote(true);
    };

    const addWorkNotes = () => {
        setWorkNoteMode('add');
        setShowWorkNote(true);
    };

    const handleAddWorkNotes = async (workNoteInput) => {
        const newListWorknotes = workNoteInput;
        const payLoad = {
            worknotes: newListWorknotes,
        };
        try {
            const response = await userManagementAPIs.updateAccountWorknotes(payLoad);
            if (response.status === 200) {
                Toast.fire({
                    icon: 'success',
                    titleText: 'Worknote added successfully.',
                });
                fetchUser();
            }
        } catch (err) {
            Toast.fire({
                icon: 'error',
                titleText: 'Unable to add worknote.',
            });
        }
    };

    const uploadDocuments = async (newDocs) => {
        const mergedDocs = mergeUploadedDocuments(documents, newDocs);

        const payload = {
            additionalDocuments: mergedDocs,
        };

        await persistUploadedDocuments(
            'upload',
            () => userManagementAPIs.updateAccountDocuments(payload),
            fetchUser, // Success
            fetchUser, // Fail
        );
    };

    const deleteDocument = async (doc) => {
        const mergedDocs = cleanupDeletedDocument(documents, doc);

        const payload = {
            additionalDocuments: mergedDocs,
        };

        await persistUploadedDocuments(
            'delete',
            () => userManagementAPIs.updateAccountDocuments(payload),
            fetchUser, // Success
            fetchUser, // Fail
        );
    };

    useEffect(() => {
        if (authContext.authenticated) {
            fetchData();
            fetchUser();
        }
    }, [authContext]);

    return <>
        <div className='dashboardContainer'>
            <DashboardCard
                id='taskEventCard'
                size='small'
                header={true}
                headerTitle='My Tasks and Events'
                actionBtn={false}
                actionBtnText='View All'
                content={(tasks && tasks.length > 0)
                    ? taskEventContent(tasks)
                    : <><p className='caption'>You don&apos;t have any assigned tasks and events in-progress or pending.</p></>}
                handlerActionBtn={() => { }}
                footer={tasks && tasks.length > 0}
                footerBtnText='View All'
                footerBtnHandler={() => history.push('/calendar')}
                customStyle = 'customStyle'
            />
            <DashboardCard
                id='myProjectsCard'
                size='medium'
                header={true}
                headerTitle='My Projects'
                icon
                iconSrc={addIcon}
                actionBtn={rbac.can(rbac.action.createProject,
                    [accountType.toLowerCase() + authContext.user.role])}
                actionBtnText='New Project'
                variant='secondary'
                additionalVariant='skinless'
                content={(projects && projects.length > 0)
                    ? <div className='dashboardCardMainRow'>{myProjectContent(projects)}</div>
                    : <><p className='caption'>No projects available.</p></>}
                handlerActionBtn={() => history.push('/project/create')}
                footer={true}
                footerBtnText='View All'
                footerBtnHandler={() => history.push('/projects')}
                customStyle = 'customStyle'
            />
        </div>
        {accountType.toLowerCase() === UserRoles.LOCAL_AUTHORITY_ROLE && <div className='dashboardContainer'>
            <DashboardCard
                id='submitProcurementRequests'
                size='small'
                header={true}
                headerTitle='Submitted Procurement Requests'
                caption='Procurement requests
                submitted by members of your organisation, pending your review.'
                actionBtn={false}
                content={submitProcrumentRequest()}
                handlerActionBtn={() => { }}
                footer={true}
                footerBtnText='View All'
                footerBtnHandler={() => history.push('/procurement-requests')}
                customStyle='customStyle'
            />
            <DashboardCard
                id='myProcurementRequestsCard'
                size='medium'
                header={true}
                headerTitle='My Procurement Requests'
                icon
                iconSrc={addIcon}
                actionBtn={true}
                actionBtnText='New Request'
                variant='secondary'
                additionalVariant='skinless addProject'
                content={(requests && requests.length > 0)
                    ? <div className='dashboardCardMainRow'>{myRequestContent(requests)}</div>
                    : <><p className='title'>No procurement request available.</p></>}
                handlerActionBtn={() => history.push('/pre-procurement-request')}
                footer={true}
                footerBtnText='View All'
                footerBtnHandler={() => history.push('/procurement-requests')}
                customStyle='customStyle'
            />
        </div>}
        <div className='dashboardContainer'>
            {/* <DashboardCard
                id='Stage1Approval'
                size='small'
                header={true}
                headerTitle='Stage 1 Approval'
                caption='Approval requests raised
                by the project manager for stage 1 of the tendering evaluation completed.'
                actionBtn={false}
                content={stage1ApprovalContent()}
                handlerActionBtn={() => { }}
                footer={false}
                footerBtnText='View All'
                footerBtnHandler={() => alert('View All button clicked')}
            /> */}
            <DashboardCard
                id='actions'
                size='small'
                header={true}
                headerTitle='Actions'
                caption={'Documents and Worknotes uploaded here are visible to everyone in your organisation'}
                content={<div id='dashboardActionContainer'>
                    <div id='dashboardActionWorknotes'>
                        <p className='title sectionLabel' id='worknotes'> Worknotes</p>
                        <p className='caption'> {`${workNotes ? workNotes?.length : 0} worknotes added`}</p>
                        <div className='buttonContainer'>
                            <Button id='actionBtnS'
                                size='small'
                                variant='secondary'
                                label='View'
                                handleClick={viewWorkNotes} />
                            <Button id='actionBtn'
                                size='small'
                                variant='primary'
                                label='Add'
                                handleClick={addWorkNotes} />
                        </div>
                    </div>
                    <Upload
                        title = {'Documents'}
                        allowMultiple = {true}
                        uploadIdentifier = {'Document'}
                        uploadCallback={uploadDocuments}
                        deleteCallback={deleteDocument}
                        questionnaire={false}
                        uploadedDocuments={documents}
                    />
                </div>}
                customStyle='customStyle'
            />
            <DashboardCard
                id='callOffs'
                size='medium'
                header={true}
                headerTitle='My Call-Offs'
                actionBtn={false}
                content={(callOff && callOff.length > 0)
                    ? callOffContent()
                    : <p className='title left'>No call-offs available.</p>}
                handlerActionBtn={() => { }}
                footer={true}
                footerBtnText='View All'
                footerBtnHandler={() => history.push('/callOffs')}
                customStyle='customStyle'
            />
        </div>

        <div className='dashboardContainer'>
            <DashboardCard
                id='myContractsCard'
                size='medium'
                header={true}
                headerTitle='My Contracts'
                icon
                iconSrc={addIcon}
                actionBtn={false}
                content={(contracts && contracts.length > 0)
                    ? <div className='dashboardCardMainRow'>{myContractContent(contracts)}</div>
                    : <><p className='caption'>No contracts available.</p></>}
                handlerActionBtn={() => {}}
                footer={true}
                footerBtnText='View All'
                footerBtnHandler={() => history.push('/contracts')}
                customStyle = 'customStyle'
            />
        </div>
        <div className='dashboardContainer'>
            {showWorknotes
            && <WorknotesModal
                closeModal={() => setShowWorkNote(false)}
                mode={workNoteMode}
                worknotesInput={workNotes}
                handleCallback={handleAddWorkNotes}
            />}
        </div>
    </>;
};

export default LocalAuthorityDashboard;
