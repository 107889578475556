import isSupplierRole from '../../utils/isSupplierRole';

const supplierIsAwardedInLot = (contractDetails, supplierAccountId, lotId) => {
    const allSuppliers = contractDetails?.awardedSuppliers
        ?.reduce((acc, lot) => [...acc, ...lot.suppliers], [])
        ?.map((supplier) => ({ supplierId: supplier.accountID, lotId: supplier.lotID }));
    return allSuppliers?.some((supplier) => supplier.supplierId === supplierAccountId
    && supplier.lotId === lotId);
};
const showAwardSummaryButton = (contractDetails, accountType, supplierAccountId,
    lotId) => contractDetails?.awardEventID
    && !isSupplierRole(accountType)
    && (!contractDetails?.isAwarded
        || (supplierAccountId && lotId
            && !supplierIsAwardedInLot(contractDetails, supplierAccountId, lotId)));

export default showAwardSummaryButton;
