import React, { useEffect, useState, useContext } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { merge } from 'lodash';
import LabelledRadioButton from '../../../components/LabelledRadioButton';
import AuthContext from '../../../context/AuthContext';
import LabelledTextarea from '../../../components/LabelledTextArea';
import Button from '../../../components/Button';
import Upload from '../../Upload';
import Toast from '../../../components/Alerts/Toast/Toast';
import projectManagementAPIs from '../../../services/project-management.service';
import projectService from '../../../services/project.service';
import helperFunctions from '../../../utils/helperFunctions';
import { cleanupDeletedDocument, mergeUploadedDocuments } from '../../../utils/uploadHelper';
import userManagementAPIs from '../../../services/user-management.service';
import ShowRecipientsModal from '../../ShowRecipientsModal';
import UserRoles from '../../Header/constants';
import Modal from '../../../components/Modal';
import LabelledInput, { LabelledInputTypeText } from '../../../components/LabelledInput';
import StatusLabel from '../../../components/StatusLabel';
import isSupplierRole from '../../../utils/isSupplierRole';
import {
    BUYER_STATUS,
    markAsCompleteBtnTitle,
    BUYER_STATUS_ACTIONS,
    messageWithLink1,
    messageWithLink2,
    topicIdMessage,
    topicURLMessage,
} from './constants';
import getThreadBuyerStatus from './utils';
import CorrespondenceRecipients from '../CorrespondenceRecipients';
import {
    registerInterestStatuses,
    CorrespondenceVisibility,
    CorrespondenceVisibilityLabel,
    CorrespondenceTypeName,
} from '../../../config/constants';

const ViewCorrespondence = () => {
    const authContext = useContext(AuthContext);
    const {
        resourceType, resourceId, topicId, responseType,
    } = useParams();
    const [topicDetails, setTopicDetails] = useState(null);
    const [publicCorrespondence, setPublicCorrespondence] = useState(null);
    const [recipients, setRecipients] = useState([]);
    const [expandMessage, setExpandMessage] = useState(false);
    const [threads, setThreads] = useState([]);
    const [shownThreads, setShownThreads] = useState({});
    const [newThread, setNewThread] = useState({});
    const [messageReplies, setMessageReplies] = useState({});
    const history = useHistory();
    const [showWorknotes, setShowWorkNote] = useState(false);
    const closeWorknoteModal = () => setShowWorkNote(false);
    const [showReplyAllModal, setShowReplyAllModal] = useState(false);
    const [values, setValues] = useState({});
    const [replyAllMessage, setReplyAllMessage] = useState();
    const [replyAllDocuments, setReplyAllDocuments] = useState([]);
    const [currentThreadId, setCurrentThreadId] = useState();
    const [registeredSuppliers, setRegisteredSuppliers] = useState([]);
    const [successfulSuppliers, setSuccessfulSuppliers] = useState([]);

    const RegExpLinkMessage = new RegExp(`(${messageWithLink1}) (${topicIdMessage})[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12} (${messageWithLink2})`);

    const fetchProjectSuppliers = async () => {
        if (resourceType === 'project') {
            const response = await projectManagementAPIs.getProject(resourceId);
            if (response.status === 200) {
                const project = response.data;

                const registeredSuppliersInCurrentPublishEvent = projectService
                    .getRegisteredSuppliersFromCurrentPublishEvent(project);

                setValues({
                    ...values,
                    correspondenceType: CorrespondenceVisibility.allRegisteredSuppliers,
                });

                setRegisteredSuppliers(registeredSuppliersInCurrentPublishEvent.map((supplier) => ({
                    name: supplier.account?.companyName,
                    value: supplier.accountID,
                })));

                setSuccessfulSuppliers(registeredSuppliersInCurrentPublishEvent
                    .filter(
                        (supplier) => supplier.status === registerInterestStatuses.approved,
                    ));
            }
        }
    };

    const fetchUnreadData = async () => {
        const response = await projectManagementAPIs.getUnreadCorrespondence(
            resourceId, resourceType,
        );
        if (response.status === 200) {
            return response.data;
        }
        return [];
    };


    const getRecipientTimestamp = (data, recipient) => (data?.readByAccounts?.find((item) => item.accountID === recipient?.accountID)?.timestamp || '');

    // readStaus set here
    const fetchRecipients = async (data) => {
        const suppliers = [];
        if (data?.recipientAccountIDs
                && data?.recipientAccountIDs?.length > 0) {
            const body = { accountIds: data?.recipientAccountIDs };
            const supplierDetails = await userManagementAPIs
                .findAccounts(body);
            if (supplierDetails.status === 200) {
                if (Array.isArray(supplierDetails.data)) {
                    supplierDetails.data.forEach((recipient) => {
                        const readStatus = data?.readByAccounts?.some(
                            (acc) => acc.accountID === recipient?.accountID,
                        ) || false;
                        suppliers.push({
                            companyName: recipient?.companyName,
                            accountID: recipient?.accountID,
                            readStatus,
                            timestamp: getRecipientTimestamp(data, recipient),
                        });
                    });
                }
            }
        } else if (data?.readBy && data?.readBy?.length > 0) {
            const body = { accountIds: data?.readBy };
            const supplierDetails = await userManagementAPIs
                .findAccounts(body);
            if (supplierDetails.status === 200) {
                if (Array.isArray(supplierDetails?.data)) {
                    supplierDetails.data.forEach((recipient) => {
                        if (recipient?.companyName !== authContext.user.accountName) {
                            suppliers.push({
                                companyName: recipient?.companyName,
                                accountID: recipient?.accountID,
                                readStatus: true,
                                timestamp: getRecipientTimestamp(data, recipient),
                            });
                        }
                    });
                }
            }
        }
        setRecipients(suppliers);
    };

    const fetchTopic = async () => {
        const response = await projectManagementAPIs.getTopic(topicId, resourceId, resourceType);


        if (response.status === 200) {
            await fetchRecipients(response.data);
            setTopicDetails(response.data);
            if (response?.data?.recipientAccountIDs?.length === 0) {
                setPublicCorrespondence(true);
            } else {
                setPublicCorrespondence(false);
            }
        } else {
            Toast.fire({
                icon: 'error',
                titleText: 'Unable to retrieve topic details.',
            });
        }
    };

    const fetchSupplierMessages = async (threadId, buyerStatus, action) => {
        const unreadDetails = await fetchUnreadData();
        const response = await projectManagementAPIs.browseThreadMessages(
            resourceId, resourceType, topicId, threadId,
        );

        if (response.status === 200) {
            const supplierThread = [];
            response.data.items = response.data.items.map((data) => ({
                ...data,
                newOrUnread: unreadDetails
                    .filter((unreadTopic) => unreadTopic.topicID === topicId)[0]?.unreadThreads
                    .filter((unreadThread) => unreadThread.threadID === threadId)[0]?.unreadMessages
                    .filter((unreadMessage) => unreadMessage === data?.messageID)
                    .length > 0,
                buyerStatus: action
                    ? getThreadBuyerStatus(action, buyerStatus)
                    : buyerStatus,
            }));
            supplierThread.push(response.data.items[0]);
            supplierThread[0].messages = response.data.items;
            setThreads(supplierThread);
        } else {
            Toast.fire({
                icon: 'error',
                titleText: 'Unable to retrieve information.',
            });
        }
    };

    const fetchThreads = async (canUpdateSingle) => {
        const unreadDetails = await fetchUnreadData();
        const response = await projectManagementAPIs.browseThreads(
            resourceId, resourceType, topicId,
        );

        if (response.status === 200) {
            if (responseType === 'single' && response.data.items.length > 0) {
                fetchSupplierMessages(
                    response.data.items[0].threadID,
                    response.data.items[0].buyerStatus,
                    canUpdateSingle ? BUYER_STATUS_ACTIONS.NEW_MESSAGE : undefined,
                );
            } else {
                response.data.items = response.data.items.map((data) => ({
                    ...data,
                    newOrUnread: unreadDetails
                        .filter((unreadTopic) => unreadTopic.topicID
                        === data.topicID)[0]?.unreadThreads
                        .filter((unreadThread) => unreadThread.threadID === data.threadID)
                        .length > 0,
                })).sort((a, b) => new Date(b.systemTimestamp) - new Date(a.systemTimestamp))
                    .sort((a, b) => Number(b.newOrUnread) - Number(a.newOrUnread));

                setThreads((prevState) => merge([...prevState], response.data.items));
            }
        } else {
            Toast.fire({
                icon: 'error',
                titleText: 'Unable to retrieve information.',
            });
        }
    };


    const fetchAllMessages = async (threadId, action) => {
        const unreadDetails = await fetchUnreadData();
        const response = await projectManagementAPIs.browseThreadMessages(
            resourceId, resourceType, topicId, threadId,
        );

        if (response.status === 200) {
            setThreads(threads.map((thread) => {
                if (thread.threadID === threadId) {
                    response.data.items = response.data.items.map((data) => ({
                        ...data,
                        newOrUnread: unreadDetails
                            .filter((unreadTopic) => unreadTopic.topicID
                            === topicId)[0]?.unreadThreads
                            .filter((unreadThread) => unreadThread.threadID
                            === threadId)[0]?.unreadMessages
                            .filter((unreadMessage) => unreadMessage === data?.messageID)
                            .length > 0,
                    }));
                    return {
                        ...thread,
                        buyerStatus: getThreadBuyerStatus(action, thread.buyerStatus),
                        newOrUnread: response.data.items
                            .filter((message) => message.newOrUnread).length > 0,
                        messages: response.data.items,
                    };
                }
                return {
                    ...thread,
                };
            }));
        } else {
            Toast.fire({
                icon: 'error',
                titleText: 'Unable to retrieve information.',
            });
        }
    };

    useEffect(() => {
        fetchTopic();
        fetchThreads();
        fetchProjectSuppliers();
    }, []);

    const updateThread = async (threadId, params) => {
        const payload = {
            params,
        };

        const response = await projectManagementAPIs.updateThread(
            resourceId, resourceType, topicId, threadId, payload,
        );

        if (response.status === 200) {
            Toast.fire({
                icon: 'success',
                titleText: 'Thread updated successfully',
            });
            fetchThreads(true);
            setNewThread({});
        } else {
            Toast.fire({
                icon: 'error',
                titleText: 'Error updating the Thread',
            });
        }
    };

    const createThread = async () => {
        const payload = {
            message: newThread.message,
            documents: newThread.documents,
        };

        const response = await projectManagementAPIs.createThread(
            resourceId, resourceType, topicId, payload,
        );

        if (response.status === 200) {
            Toast.fire({
                icon: 'success',
                titleText: 'Thread created successfully',
            });
            fetchThreads(true);
            setNewThread({});
        } else {
            Toast.fire({
                icon: 'error',
                titleText: 'Error creating the Thread',
            });
        }
    };

    const createThreadForCurrentTopicWhen0Threads = async (originalTopicID, newTopicID) => {
        const newMessageWithLink = `${messageWithLink1} ${topicIdMessage}${newTopicID} ${messageWithLink2}`;

        const payload = {
            message: newMessageWithLink,
        };

        const newThreadResponse = await projectManagementAPIs.createThread(
            resourceId, resourceType, originalTopicID, payload,
        );

        if (newThreadResponse.status === 200) {
            const { threadID } = newThreadResponse.data.thread;
            updateThread(threadID, {
                buyerStatus: BUYER_STATUS.COMPLETED.value,
            });
            Toast.fire({
                icon: 'success',
                titleText: 'Thread created successfully',
            });
            fetchThreads(true);
            setNewThread({});
        } else {
            Toast.fire({
                icon: 'error',
                titleText: 'Error creating the Thread',
            });
        }
    };


    const setAndShowReplyAllModal = async (showModal, threadId) => {
        setShowReplyAllModal(showModal);

        if (threadId) {
            setCurrentThreadId(threadId);
            setReplyAllMessage(messageReplies[`reply${threadId}`]);
            setReplyAllDocuments(messageReplies[`documents${threadId}`]);
        } else {
            setReplyAllMessage(newThread.message);
            setReplyAllDocuments(newThread.documents);
        }
    };

    const createThreadMessage = async (threadId, newMessageWithLink) => {
        const payload = {
            message: messageReplies[`reply${threadId}`] || newMessageWithLink,
            documents: messageReplies[`documents${threadId}`],
        };

        const response = await projectManagementAPIs.createThreadMessage(
            resourceId, resourceType, topicId, threadId, payload,
        );

        if (response.status === 200) {
            Toast.fire({
                icon: 'success',
                titleText: 'Message created successfully',
            });
            fetchAllMessages(threadId, BUYER_STATUS_ACTIONS.NEW_MESSAGE);
            setMessageReplies({
                ...messageReplies,
                [`reply${threadId}`]: '',
                [`documents${threadId}`]: [],
            });
        } else {
            Toast.fire({
                icon: 'error',
                titleText: 'Error replying to the thread',
            });
        }
    };

    const handleThreadDocumentUpload = (uploadedDocs) => {
        const mergedDocs = mergeUploadedDocuments(newThread.documents, uploadedDocs);

        setNewThread({
            ...newThread,
            documents: mergedDocs,
        });
    };

    const handleThreadDocumentDelete = (deletedDoc) => {
        const mergedDocs = cleanupDeletedDocument(newThread.documents, deletedDoc);

        setNewThread({
            ...newThread,
            documents: mergedDocs,
        });
    };

    const handleChange = (e) => {
        const { name, value } = e.target;

        setValues({
            ...values,
            [name]: value,
        });
    };

    const handleTopicDocumentUpload = (uploadedDocs) => {
        const mergedDocs = mergeUploadedDocuments(values.documents, uploadedDocs);

        setValues({
            ...values,
            documents: mergedDocs,
        });
    };

    const handleTopicDocumentDelete = (deletedDoc) => {
        const mergedDocs = cleanupDeletedDocument(values.documents, deletedDoc);

        setValues({
            ...values,
            documents: mergedDocs,
        });
    };

    const handleMessageDocumentUpload = (uploadedDocs, threadId) => {
        const mergedDocs = mergeUploadedDocuments(messageReplies[`documents${threadId}`], uploadedDocs);

        setMessageReplies({
            ...messageReplies,
            [`documents${threadId}`]: mergedDocs,
        });
    };

    const handleMessageDocumentDelete = (deletedDoc, threadId) => {
        const mergedDocs = cleanupDeletedDocument(messageReplies[`documents${threadId}`], deletedDoc);

        setMessageReplies({
            ...messageReplies,
            [`documents${threadId}`]: mergedDocs,
        });
    };

    const showThreadMessages = (threadID) => {
        fetchAllMessages(threadID, BUYER_STATUS_ACTIONS.VIEW_REPLIES);
        setShownThreads((prevState) => ({
            ...prevState,
            [threadID]: true,
        }));
    };

    const hideThreadMessages = (threadID) => {
        setShownThreads((prevState) => ({
            ...prevState,
            [threadID]: false,
        }));
    };

    const getAuthorName = (data) => {
        if (authContext.user.accountType !== 'supplier') {
            if (data?.senderAccountID !== authContext.user.accountId) {
                return `${data?.createdByUser?.contactDetails?.username} - ${data?.senderAccount?.companyName}`;
            }
            if (data?.createdByUser?.userID !== authContext.user.id) {
                return `${data?.createdByUser?.contactDetails?.username}`;
            }
            return 'You';
        }
        if (data?.senderAccountID !== authContext.user.accountId
            || data?.createdByUser?.userID !== authContext.user.id) {
            return `${data?.senderAccount?.companyName}`;
        }
        return 'You';
    };

    const getMessageAuthor = (data) => {
        if (authContext.user.accountType !== 'supplier') {
            return `${data.senderUser?.contactDetails?.username} - ${data.senderAccount?.companyName}`;
        }
        return `${data.senderAccount?.companyName}`;
    };

    const createCorrespondenceContent = () => <>
        { !isSupplierRole(authContext.user?.accountType) && registeredSuppliers.length > 0
                    && <LabelledRadioButton
                        id='correspondenceType'
                        label={CorrespondenceVisibilityLabel.title}
                        breakColumn={false}
                        onChange={(event) => handleChange(event)}
                        options={[
                            {
                                label: CorrespondenceVisibilityLabel.allRegisteredSuppliers,
                                value: CorrespondenceVisibility.allRegisteredSuppliers,
                                id: CorrespondenceVisibility.allRegisteredSuppliers,
                                name: CorrespondenceTypeName,
                                checked: values.correspondenceType
                                    === CorrespondenceVisibility.allRegisteredSuppliers,
                                required: true,
                            },
                            {
                                label: CorrespondenceVisibilityLabel.publicCorrespondence,
                                value: CorrespondenceVisibility.publicCorrespondence,
                                id: CorrespondenceVisibility.publicCorrespondence,
                                name: CorrespondenceTypeName,
                                checked: values.correspondenceType
                                    === CorrespondenceVisibility.publicCorrespondence,
                                required: true,
                            },
                        ]}
                        renderAsRow={true} />
        }
        {
            values.correspondenceType === CorrespondenceVisibility.allRegisteredSuppliers
            && <CorrespondenceRecipients recipients={successfulSuppliers} />
        }
        <LabelledInput id='title'
            type={LabelledInputTypeText}
            label='Subject'
            breakColumn={true}
            onChange={(e) => handleChange(e)}
            value={values.title || ''}
            required={true}
            placeholder='Enter subject' />
        <LabelledTextarea
            id='correspondenceText'
            type={LabelledInputTypeText}
            label='Message'
            breakColumn={true}
            onChange={(e) => handleChange(e)}
            value={values.correspondenceText || replyAllMessage}
            currentCharCount={values.correspondenceText ? values.correspondenceText.length : 0}
            placeholder='Enter message'
            required={true}
            maxCharCount={6000}
        />
        <div id='buttonContainer'>
            <p className='title' id='uploadTitle'>Attachments</p>
            <Upload
                title={''}
                allowMultiple={true}
                uploadCallback={(data) => handleTopicDocumentUpload(data)}
                deleteCallback={(data) => handleTopicDocumentDelete(data)}
                uploadedDocuments={values.documents || replyAllDocuments}
                readOnly={false}
            />
        </div>
    </>;

    const closeCreateModal = () => {
        setValues({
            correspondenceType: CorrespondenceVisibility.allRegisteredSuppliers,
        });
        setShowReplyAllModal(false);
    };

    const getRecipientAccountIds = (correspondenceType) => {
        const type = {
            publicCorrespondence: [],
            allRegisteredSuppliers: successfulSuppliers.map((item) => item.accountID),
        };

        return type[correspondenceType];
    };

    const handleSubmit = async () => {
        const payload = {
            subject: values.title,
            message: values.correspondenceText || replyAllMessage,
            recipientAccountIds: getRecipientAccountIds(values.correspondenceType),
            documents: values.documents || replyAllDocuments,
            originalTopicID: topicDetails.topicID,
        };

        const newTopicResponse = await projectManagementAPIs.createCorrespondenceTopic(
            resourceId, resourceType, payload,
        );

        if (newTopicResponse.status === 201) {
            if (!threads.length) {
                await createThreadForCurrentTopicWhen0Threads(
                    payload.originalTopicID, newTopicResponse.data.topicID,
                );
            } else {
                updateThread(currentThreadId, {
                    buyerStatus: BUYER_STATUS.COMPLETED.value,
                });
                const newMessageWithLink = `${messageWithLink1} ${topicIdMessage}${newTopicResponse.data.topicID} ${messageWithLink2}`;
                createThreadMessage(currentThreadId, newMessageWithLink);
            }

            Toast.fire({
                icon: 'success',
                titleText: 'Correspondence created successfully',
            });
            closeCreateModal();
            history.push(`/project/correspondence/${resourceId}`);
        } else {
            Toast.fire({
                icon: 'error',
                titleText: 'Error creating the Correspondence',
            });
        }
    };

    const showModal = () => {
        if (showReplyAllModal) {
            return (<Modal
                id='createNewCorrespondence'
                headerTitle='Create New Correspondence'
                open={showReplyAllModal}
                size='medium'
                body={createCorrespondenceContent()}
                footer={true}
                handleMainActionBtnClick = {() => handleSubmit()}
                mainActionButtonTxt={'Confirm'}
                secondActionButton={true}
                handleSecondaryActionBtnClick={closeCreateModal}
                secondActionButtonTxt={'Cancel'}
                helpOption={false}
                closeModal={closeCreateModal}
            />);
        }
        return (<></>);
    };

    const getTimeStampBySender = (message) => (message.readByAccounts
        .find((item) => message?.senderAccountID !== item.accountID)?.timestamp || false);

    const getMessage = (message) => {
        const hasLink = RegExpLinkMessage.test(message);

        if (!hasLink) {
            return message;
        }

        const splitMessage = message.split(topicIdMessage);
        const newTopicId = splitMessage[1].split(' ')[0];
        const finalMessage = splitMessage[1].split(newTopicId)[1];

        return (
            <>
                {splitMessage[0]}
                <a key={'newTopicLink'} className='caption' href={`/project/correspondence/overview/${resourceId}/${newTopicId}`}>
                    {topicURLMessage}
                </a>
                {finalMessage}
            </>
        );
    };

    // Is this only shown to the buyer?
    const buyerThreadContent = () => ((threads && threads.length > 0)
        ? <div className='headerThreads'>
            <p className='title-large bold'>Below are the threads/responses from Suppliers.</p>
            <div id='threadContanier'>
                {showModal()}
                {threads.map((thread, index) => <div className='threadContentItem' key={`thread-content-item-${index}`}>
                    <div key={`thread-${index}`} className='threadMainContent'>
                        <div className='threadHeader'>
                            <label className={'label projects body inlineTag'}>
                                {thread.newOrUnread && <p className='newOrUnread tag'> </p>}
                                <p className={`title ${thread.newOrUnread && 'newOrUnreadTitleMargin'}`}>{thread.senderUser?.contactDetails?.username} - {thread.senderAccount?.companyName}</p></label>
                            <div className='threadHeaderItems'>
                                {!isSupplierRole(authContext?.user?.accountType)
                                 && thread.buyerStatus && <StatusLabel
                                    id={`label-${thread.threadID}`}
                                    color={BUYER_STATUS[thread.buyerStatus].color}
                                    labelTxt={BUYER_STATUS[thread.buyerStatus].label}
                                /> }
                                <Button
                                    id={'viewReplybtn'}
                                    size={'small'}
                                    label={shownThreads[thread.threadID] && thread.messages?.length ? 'Hide replies' : 'View replies'}
                                    icon={false}
                                    variant={shownThreads[thread.threadID] ? 'primary' : 'secondary' }
                                    handleClick={
                                        shownThreads[thread.threadID]
                                    && thread.messages?.length
                                            ? () => hideThreadMessages(thread.threadID)
                                            : () => showThreadMessages(thread.threadID)
                                    }
                                />
                            </div>
                        </div>
                        <div id='correspondenceSubjectDetails'>
                            <p className={`caption-small ${thread.newOrUnread && 'newOrUnreadTimestampMargin'}`}>Posted on {helperFunctions.formatPrettyDateTime(thread.systemTimestamp)}</p>
                        </div>
                    </div>
                    <div className='threadMessageContent'>
                        {shownThreads[thread.threadID]}
                        {shownThreads[thread.threadID] && thread.messages?.length > 0 && <div className='threadMessages'>
                            {thread.messages.map((message, i) => <div className={`messageItem ${message.senderAccountID === authContext.user.accountId ? 'sender' : 'recipient'}`} key={`message-${i}`}>
                                <div className='messageHeader'>
                                    <label className={'label projects body inlineTag'}>
                                        {message.newOrUnread && <p className='newOrUnread tag'> </p>}
                                        <p className={`title ${message.newOrUnread && 'newOrUnreadTitleMargin'}`}>{getMessageAuthor(message)}</p></label>
                                    {!isSupplierRole(authContext?.user?.accountType)
                                     && message?.readByAccounts
                                    && getTimeStampBySender(message) && <StatusLabel
                                        id={`message_readStatus_${i}`}
                                        className='readStatus'
                                        color='green'
                                        labelTxt={`Read: ${helperFunctions.formatPrettyDateTime(getTimeStampBySender(message))}`}
                                    />}
                                    {i === thread.messages.length - 1 && (
                                        thread.buyerStatus === BUYER_STATUS.UNREAD.value
                                        || thread.buyerStatus === BUYER_STATUS.ACTION_NEEDED.value)
                                      && (<Button
                                          id={'markAsCompletebtn'}
                                          size={'large'}
                                          label={markAsCompleteBtnTitle}
                                          icon={false}
                                          variant='primary'
                                          handleClick={() => updateThread(thread.threadID, {
                                              buyerStatus: BUYER_STATUS.COMPLETED.value,
                                          })}
                                      />)}
                                </div>
                                <p className={`caption-small ${message.newOrUnread && 'newOrUnreadTimestampMargin'}`}>Responded on {helperFunctions.formatPrettyDateTime(message.systemTimestamp)}</p>
                                <div className='messageContent'>
                                    <p className='caption'>
                                        {getMessage(message.message)}
                                    </p>
                                </div>
                                {message.documents?.length > 0 && <div className='messageDocuments'>
                                    <Upload
                                        title={''}
                                        allowMultiple={true}
                                        uploadCallback={() => {}}
                                        deleteCallback={() => {}}
                                        uploadedDocuments={message.documents}
                                        readOnly={true}
                                    />
                                </div>}

                            </div>)}
                            <div className='threadResponse'>
                                <LabelledTextarea
                                    id={`messageReply-${index}`}
                                    label={''}
                                    subLabel={''}
                                    breakColumn={false}
                                    onChange={(event) => {
                                        setMessageReplies({
                                            ...messageReplies,
                                            [`reply${thread.threadID}`]: event.target.value,
                                        });
                                    }}
                                    value={messageReplies[`reply${thread.threadID}`] || ''}
                                    placeholder={'Write a message'}
                                    currentCharCount={messageReplies[`reply${thread.threadID}`]
                                        ? messageReplies[`reply${thread.threadID}`].length
                                        : 0}
                                    maxCharCount={3000}
                                    required={true}
                                    readonly={false}
                                    showCharCount={true}
                                />
                                <div className='upbuttonContainer'>
                                    <Upload
                                        title={''}
                                        allowMultiple={true}
                                        uploadCallback={(data) => handleMessageDocumentUpload(
                                            data, thread.threadID,
                                        )}
                                        deleteCallback={(data) => handleMessageDocumentDelete(
                                            data, thread.threadID,
                                        )}
                                        uploadedDocuments={messageReplies[`documents${thread.threadID}`] || []}
                                        readOnly={false}
                                    />
                                    <Button
                                        id={'reply-all-button'}
                                        label={'Reply All'}
                                        icon={false}
                                        handleClick={() => setAndShowReplyAllModal(true,
                                            thread.threadID)}
                                    />
                                    <Button
                                        id={'sendMessagebtn'}
                                        label={'Send'}
                                        icon={false}
                                        handleClick={() => createThreadMessage(thread.threadID)}
                                    />
                                </div>
                            </div>
                        </div>}
                    </div>
                </div>)}
            </div>
        </div>
        : <div id='threadContanier'>
            <div id='noThreads'>
                <div>
                    <p className='title'>
                        Currently there are no responses.
                        {(topicDetails?.senderAccountID === authContext.user.accountId)
                        && <>
                            <br/>
                            <span className='caption-small'>
                                Your account initiated this correspondence,
                                so you cannot respond to it.
                                Replies on this correspondence will be displayed here.
                            </span>
                        </>}
                    </p>
                </div>
            </div>
        </div>
    );

    const singleThreadContent = () => ((threads && threads.length > 0)
        ? <div id='threadContanier'>
            {showModal()}
            {threads.map((thread, index) => <div className='threadContentItem' key={`thread-content-item-${index}`}>
                <div className='threadMessageContent'>
                    <div className='threadMessages'>
                        {thread?.messages?.length > 0
                            && thread.messages.map((message, i) => <div className={`messageItem ${message.senderAccountID === authContext.user.accountId ? 'sender' : 'recipient'}`} key={`message-${i}`}>
                                <div className='messageHeader'>
                                    <label className={'label projects body inlineTag'}>
                                        {message.newOrUnread && <p className='newOrUnread tag'> </p>}
                                        <p className={`title ${message.newOrUnread && 'newOrUnreadTitleMargin'}`}>{getMessageAuthor(message)}</p></label>
                                    {!isSupplierRole(authContext?.user?.accountType)
                                    && i === thread.messages.length - 1
                                    && (
                                        thread.buyerStatus === BUYER_STATUS.UNREAD.value
                                        || thread.buyerStatus === BUYER_STATUS.ACTION_NEEDED.value)
                                    && (<Button
                                        id={'markAsCompletebtn'}
                                        size={'large'}
                                        label={markAsCompleteBtnTitle}
                                        icon={false}
                                        variant='primary'
                                        handleClick={() => updateThread(thread.threadID, {
                                            buyerStatus: BUYER_STATUS.COMPLETED.value,
                                        })}
                                    />)}
                                </div>
                                <p className={`caption-small ${message.newOrUnread && 'newOrUnreadTimestampMargin'}`}>Responded on {helperFunctions.formatPrettyDateTime(message.systemTimestamp)}</p>
                                <div className='messageContent'>
                                    <p className='caption'>
                                        {getMessage(message.message)}
                                    </p>
                                </div>
                                {message.documents?.length > 0 && <div className='messageDocuments'>
                                    <Upload
                                        title={''}
                                        allowMultiple={true}
                                        uploadCallback={() => {}}
                                        deleteCallback={() => {}}
                                        uploadedDocuments={message.documents}
                                        readOnly={true}
                                    />
                                </div>}
                            </div>)}
                        <div className='threadResponse'>
                            <LabelledTextarea
                                id={`messageReply-${index}`}
                                label={''}
                                subLabel={''}
                                breakColumn={false}
                                onChange={(event) => {
                                    setMessageReplies({
                                        ...messageReplies,
                                        [`reply${thread.threadID}`]: event.target.value,
                                    });
                                }}
                                value={messageReplies[`reply${thread.threadID}`] || ''}
                                placeholder={'Write a message'}
                                currentCharCount={messageReplies[`reply${thread.threadID}`]
                                    ? messageReplies[`reply${thread.threadID}`].length
                                    : 0}
                                maxCharCount={3000}
                                required={true}
                                readonly={false}
                                showCharCount={true}
                            />
                            <div className='upbuttonContainer'>
                                <Upload
                                    title={''}
                                    allowMultiple={true}
                                    uploadCallback={(data) => handleMessageDocumentUpload(
                                        data, thread.threadID,
                                    )}
                                    deleteCallback={(data) => handleMessageDocumentDelete(
                                        data, thread.threadID,
                                    )}
                                    uploadedDocuments={messageReplies[`documents${thread.threadID}`] || []}
                                    readOnly={false}
                                />
                                {authContext?.user?.accountType?.toLowerCase()
                                        !== UserRoles.SUPPLIER_ROLE && <Button
                                    id={'reply-all-button'}
                                    label={'Reply All'}
                                    icon={false}
                                    handleClick={() => setAndShowReplyAllModal(true,
                                        thread.threadID)}
                                />
                                }
                                <Button
                                    id={'sendMessagebtn'}
                                    label={'Send'}
                                    icon={false}
                                    handleClick={() => createThreadMessage(thread.threadID)}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>)}
        </div>
        : <div id='threadContanier'>
            {showModal()}
            {(topicDetails?.senderAccountID === authContext.user.accountId)
                ? <div id='noThreads'>
                    <div>
                        <p className='title'>
                    There is no response to this topic.
                            <br/>
                            <span className='caption-small'>
                    Your account initiated this correspondence,
                    so you cannot respond to it.
                    Replies to this correspondence will be displayed here.
                            </span>
                        </p>
                    </div>
                </div>
                : <div className='threadContentItem'>
                    <div className='threadResponse'>
                        <div className='threadResponseWarning'><p className='title'>You have not responded to this correspondence.</p></div>
                        <LabelledTextarea
                            id='threadMessage'
                            label={''}
                            subLabel={''}
                            breakColumn={false}
                            onChange={(event) => {
                                setNewThread({
                                    ...newThread,
                                    message: event.target.value,
                                });
                            }}
                            value={newThread.message || ''}
                            placeholder={'Write a message'}
                            currentCharCount={newThread.message
                                ? newThread.message.length
                                : 0}
                            maxCharCount={3000}
                            required={true}
                            readonly={false}
                            showCharCount={true}
                        />
                        <div className='upbuttonContainer'>
                            <Upload
                                title={''}
                                allowMultiple={true}
                                uploadCallback={(data) => handleThreadDocumentUpload(data)}
                                deleteCallback={(data) => handleThreadDocumentDelete(data)}
                                uploadedDocuments={newThread.documents || []}
                                readOnly={false}
                            />
                            {authContext?.user?.accountType?.toLowerCase()
                                        !== UserRoles.SUPPLIER_ROLE && <Button
                                id={'reply-all-button'}
                                label={'Reply All'}
                                icon={false}
                                handleClick={() => setAndShowReplyAllModal(true)}
                            />
                            }
                            <Button
                                id={'sendMessagebtn'}
                                label={'Send'}
                                icon={false}
                                handleClick={createThread}
                            />
                        </div>
                    </div>
                </div>}
        </div>
    );

    return (<>
        <div id='corespondenceSectionWrapper'>
            <section id='correspondenceSection'>
                <div id='correspondenceHeader'>
                    <div id='correspondenceSubjectBar'>
                        <p className='title-xLarge bold'>{topicDetails?.subject}</p>
                        <div className='vcButtonContainer'>
                            <Button
                                id={'backBtn'}
                                label={'Back'}
                                variant={'secondary'}
                                icon={false}
                                handleClick={() => history.goBack()}
                            />
                            {authContext?.user?.accountType?.toLowerCase()
                                        !== UserRoles.SUPPLIER_ROLE && <Button
                                id={'backBtn'}
                                label={'View Recipients'}
                                variant={'secondary'}
                                icon={false}
                                handleClick={() => setShowWorkNote(true)}
                            />}
                        </div>
                    </div>
                    <div id='correspondenceSubjectDetails'>
                        <p className='caption-small'>Posted on {helperFunctions.formatPrettyDateTime(
                            topicDetails?.systemTimestamp,
                        )} by {getAuthorName(topicDetails)}</p>
                    </div>
                </div>
                <div id='correspondenceSubjectContent'>
                    {topicDetails?.message?.length > 550
                        ? <>
                            {expandMessage === true
                                ? <p className='caption-small line-break'>{topicDetails?.message}</p>
                                : <p className='caption-small line-break'>{`${topicDetails?.message?.substring(0, 550)}...`}</p>
                            }
                        </>
                        : <p className='caption line-break'>{topicDetails?.message}</p>
                    }
                    <div id='correspondenceContentButton' className='line-break'>
                        {topicDetails?.message?.length > 550 && <Button
                            variant='secondary'
                            size='small'
                            label={expandMessage === true ? 'Hide' : 'Expand'}
                            handleClick={() => setExpandMessage(!expandMessage)}
                        />}
                    </div>
                    {topicDetails?.documents?.length > 0 && <div className='messageDocuments'>
                        <Upload
                            title={''}
                            allowMultiple={true}
                            uploadCallback={() => {}}
                            deleteCallback={() => {}}
                            uploadedDocuments={topicDetails?.documents}
                            readOnly={true}
                        />
                    </div>}
                </div>
                {responseType === 'single' ? singleThreadContent() : buyerThreadContent()}
            </section >
        </div>
        {showWorknotes
            && <ShowRecipientsModal
                closeModal={closeWorknoteModal}
                recipients={recipients}
                publicCorrespondence={publicCorrespondence}
            />}
    </>
    );
};

export default ViewCorrespondence;
