import UserRoles from '../../../features/Header/constants';
import { specialAccountPrivileges } from '../../../config/constants';
import PROFILE_LABELS from '../profileLabels';

const getPrettyspecialPrivilege = (privilege) => specialAccountPrivileges.filter(
    (sPrivilege) => sPrivilege.value === privilege,
)[0]?.name;

const generateOfficeSectionData = (title, data) => ({
    header: title,
    content: data?.map((temp, index) => {
        const values = {
            accordionId: index,
            accordionHeader: temp.accountName,
            accordionData: [
                {
                    header: '',
                    content: [{
                        label: 'Address:',
                        value: `${temp?.address?.addressLine1 ? `${temp?.address.addressLine1}, ` : ''} ${temp?.address?.addressLine2 ? `${temp?.address.addressLine2}, ` : ''} ${temp?.address?.city ? `${temp?.address.city}, ` : ''} ${temp?.address?.county ? `${temp?.address.county}, ` : ''} ${temp?.address?.postcode ? `${temp?.address.postcode} ` : ''}`,
                        masterDataAttribute: '',
                    }, {
                        label: 'Number of Employees:',
                        value: temp.numberOfEmployees || '',
                        masterDataAttribute: '',
                    }],
                },
            ],
        };

        const departments = {
            header: 'Departments',
            content: [{
                value: [],
                masterDataAttribute: '',
            }],
        };

        if (temp?.departments) {
            temp.departments.map((dept) => departments.content[0].value.push(dept.departmentName));
        }
        values.accordionData.push(departments);

        const industryCodes = [{
            header: 'Codes and Categories',
            content: [{
                value: [],
                masterDataAttribute: '',
            }],
        }];

        if (temp?.industryInfo && temp?.industryInfo.length > 0) {
            temp?.industryInfo?.forEach((code) => {
                industryCodes[0].content[0].value.push(`${code?.category} - ${code?.level}`);
            });
        }
        values.accordionData.push(industryCodes[0]);

        const deliveryArea = {
            header: 'Delivery Area',
            content: [{
                value: temp?.deliveryAreaInfo?.map((areas) => `${areas.code} - ${areas.description}`) || [],
                masterDataAttribute: '',
            }],
        };

        values.accordionData.push(deliveryArea);

        return values;
    }),

});

const generateUserData = (data) => {
    const values = {
        infoHeader: {
            editBtn: true,
        },
        data: [{
            header: 'Personal Information',
            content: [{
                label: 'First name:',
                value: `${data.userDetails?.contactDetails?.firstname || ''}`,
                masterDataAttribute: '',
            }, {
                label: 'Last name:',
                value: `${data.userDetails?.contactDetails?.surname || ''}`,
                masterDataAttribute: '',
            }, {
                label: 'Role:',
                value: `${data.userDetails?.userRole || ''}`,
                masterDataAttribute: '',
            }, {
                label: 'Privileges: ',
                value: data.privilege.length > 0 ? data.privilege.map((el) => getPrettyspecialPrivilege(el)).join(', ') : 'None',
                masterDataAttributes: '',
            }, {
                label: 'Job Title:',
                value: `${data.userDetails?.jobTitle || ''}`,
                masterDataAttribute: '',
            }, {
                label: 'Department:',
                value: `${data.userDetails?.department || ''}`,
            }, {
                label: 'Email:',
                value: `${data.userDetails?.email || ''}`,
            }, {
                label: 'Phone number:',
                value: `${data.userDetails.contactDetails?.countryCode || ''} ${data.userDetails.contactDetails?.mobile || ''}`,
                masterDataAttribute: '',
            },
            ],
        },
        ],
    };

    const departments = {
        header: 'Departments',
        content: [{
            value: [],
            masterDataAttribute: '',
        }],
    };

    if (data.organisationDetails?.departments) {
        data.organisationDetails.departments.forEach((dept) => {
            departments.content[0].value.push(dept.departmentName);
        });
    }
    values.data.push(departments);

    if (data.userDetails?.userRole?.toLowerCase() === 'admin') {
        const adminValues = {
            header: 'Organisation Information',
            content: [{
                label: 'Organisation Name',
                value: `${data.organisationDetails?.accountName || ''}`,
                masterDataAttribute: '',
            }, {
                label: 'Organisation Address',
                value: `${data.organisationDetails?.address?.addressLine1 ? `${data.organisationDetails?.address.addressLine1}, ` : ''} ${data.organisationDetails?.address?.addressLine2 ? `${data.organisationDetails?.address.addressLine2}, ` : ''} ${data.organisationDetails?.address?.city ? `${data.organisationDetails?.address.city}, ` : ''} ${data.organisationDetails?.address?.county ? `${data.organisationDetails?.address.county}, ` : ''} ${data.organisationDetails?.address?.postcode ? `${data.organisationDetails?.address.postcode} ` : ''}`,
                masterDataAttribute: '',
            },
            // {
            //     label: 'Organisation Contact Number',
            //     value: `(${data.organisationContact?.countryCode || ''})
            //     ${data.organisationContact?.contactNumber || ''}`,
            //     masterDataAttribute: '',
            // },
            ],
        };
        // These are not in the schema.
        // const specialAccountPrivileges = {
        //     header: 'Special Account Privileges',
        //     content: [{
        //         label: 'Organisational Directors:',
        //         value: `${data.specialAccountPrivileges?.filter((el) =>
        //         el.privilege === 'Organisation Directors').map((elem) =>
        //         elem.username).join(', ') || '-'}`,
        //         masterDataAttribute: '',
        //     }, {
        //         label: 'Organisational Rebate Officers:',
        //         value: `${data.specialAccountPrivileges?.filter((el) =>
        //         el.privilege === 'Organisation Rebate Officers').map((elem) =>
        //         elem.username).join(', ') || '-'}`,
        //         masterDataAttribute: '',
        //     }, {
        //         label: 'Organisational Management Approvers:',
        //         value: `${data.specialAccountPrivileges?.filter((el) =>
        //           el.privilege === 'Organisation Management Approvers').map((elem) =>
        //             elem.username).join(', ') || '-'}`,
        //         masterDataAttribute: '',
        //     }, {
        //         label: 'Organisation Communications Team:',
        //         value: `${data.specialAccountPrivileges?.filter((el) =>
        //         el.privilege === 'Organisation Communications Team').map((elem) =>
        //         elem.username).join(', ') || '-'}`,
        //         masterDataAttribute: '',
        //     }, {
        //         label: 'Organisation Pre-procurement reviewers:',
        //         value: `${data.specialAccountPrivileges?.filter((el) =>
        //         el.privilege === 'Organisation Pre-procurement Reviewers').map((elem) =>
        //         elem.username).join(', ') || '-'}`,
        //         masterDataAttribute: '',
        //     },
        //     ],
        // };
        values.data.push(adminValues);
        // values.data.push(specialAccountPrivileges);
    }

    // if (data.organisationDetails.isHQ !== undefined) {
    //     const officeLabel = data.organisationDetails.isHQ ? 'Regional Offices' : 'Headquarter';
    //     values.data.push(generateOfficeSectionData(officeLabel,
    //         data.organisationDetails.regionalOffices));
    // }

    return values;
};

const generateSupplierData = (data) => {
    const values = {
        infoHeader: {
            editBtn: true,
        },
        data: [{
            header: 'Personal Information',
            content: [{
                label: 'First name:',
                value: `${data.userDetails?.contactDetails?.firstname || ''}`,
                masterDataAttribute: '',
            }, {
                label: 'Last name:',
                value: `${data.userDetails?.contactDetails?.surname || ''}`,
                masterDataAttribute: '',
            }, {
                label: 'Role:',
                value: `${data.userDetails?.userRole || ''}`,
                masterDataAttribute: '',
            }, {
                label: 'Job Title:',
                value: `${data.userDetails?.jobTitle || ''}`,
                masterDataAttribute: '',
            }, {
                label: 'Department:',
                value: `${data.userDetails?.department || ''}`,
                masterDataAttribute: '',
            }, {
                label: 'Email:',
                value: `${data.userDetails?.email || ''}`,
            }, {
                label: 'Phone number:',
                value: `${data.userDetails.contactDetails?.countryCode || ''} ${data.userDetails.contactDetails?.mobile || ''}`,
                masterDataAttribute: '',
            },
            ],
        },
        ],
    };

    const departments = {
        header: 'Departments',
        content: [{
            value: [],
            masterDataAttribute: '',
        }],
    };

    if (data.organisationDetails?.departments) {
        data.organisationDetails.departments.forEach((dept) => {
            departments.content[0].value.push(dept.departmentName);
        });
    }
    values.data.push(departments);

    if (data.userDetails?.userRole?.toLowerCase() === 'admin') {
        const adminValues = {
            header: PROFILE_LABELS.organisationInformation,
            content: [{
                label: PROFILE_LABELS.businessStructure,
                value: `${data.organisationDetails?.businessStructure || '-'}`,
                masterDataAttribute: '',
            }, {
                label: PROFILE_LABELS.natureOfBusiness,
                value: `${data.organisationDetails?.natureOfBusiness || '-'}`,
                masterDataAttribute: '',
            }, {
                label: PROFILE_LABELS.vatNumber,
                value: `${data.organisationDetails?.vatNumber || '-'}`,
                masterDataAttribute: '',
            }, {
                label: PROFILE_LABELS.registrationNumber,
                value: `${data.organisationDetails?.registrationNumber || '-'}`,
                masterDataAttribute: '',
            }, {
                label: PROFILE_LABELS.address,
                value: `${data.organisationDetails?.address?.addressLine1 ? `${data.organisationDetails?.address.addressLine1}, ` : ''} ${data.organisationDetails?.address?.addressLine2 ? `${data.organisationDetails?.address.addressLine2}, ` : ''} ${data.organisationDetails?.address?.city ? `${data.organisationDetails?.address.city}, ` : ''} ${data.organisationDetails?.address?.county ? `${data.organisationDetails?.address.county}, ` : ''} ${data.organisationDetails?.address?.postcode ? `${data.organisationDetails?.address.postcode} ` : '-'}`,
                masterDataAttribute: '',
            }, {
                label: PROFILE_LABELS.noOfEmployees,
                value: `${data.organisationDetails?.noOfEmployees || '-'}`,
                masterDataAttribute: '',
            },
            // {
            //     // organisation contact number
            //     label: 'Phone number:',
            //     value: `${data.organisationContact?.countryCode || ''}
            //     ${data.organisationContact?.contactNumber || ''}`,
            //     masterDataAttribute: '',
                // }
            ],
        };

        values.data.push(adminValues);
    }

    const industryCodes = [{
        header: 'Codes and Categories',
        content: [{
            value: [],
            masterDataAttribute: '',
        }],
    }];

    if (data?.organisationDetails?.industryInfo
        && data?.organisationDetails?.industryInfo?.length > 0) {
        data.organisationDetails.industryInfo.forEach((code) => {
            industryCodes[0].content[0].value.push(`${code.category} - ${code.level}`);
        });
    }

    const regionsOfSupply = [{
        header: 'Delivery Area',
        content: [{
            value: [],
            masterDataAttribute: '',
        }],
    }];

    if (data?.organisationDetails?.deliveryAreaInfo
        && data?.organisationDetails?.deliveryAreaInfo?.length > 0) {
        data.organisationDetails.deliveryAreaInfo.forEach((code) => {
            regionsOfSupply[0].content[0].value.push(`${code.code} - ${code.description}`);
        });
    }

    values.data.push(industryCodes[0]);
    values.data.push(regionsOfSupply[0]);

    // if (OfficeData.isHQ !== undefined) {
    //     const officeLabel = OfficeData.isHQ ? 'Regional Offices' : 'Headquarter';
    //     values.data.push(generateOfficeSectionData(officeLabel, OfficeData.details));
    // }
    if (data.organisationDetails.isHQ !== undefined && data.organisationDetails?.regionalOffices) {
        const officeLabel = data.organisationDetails.isHQ ? 'Regional Offices' : 'Headquarter';
        values.data.push(generateOfficeSectionData(officeLabel,
            data.organisationDetails.regionalOffices));
    }
    return values;
};

const generateData = (data) => {
    let result;
    if (data.organisationDetails.accountType.toLowerCase() === UserRoles.SUPPLIER_ROLE) {
        result = generateSupplierData(data);
    } else {
        result = generateUserData(data);
    }
    return result;
};

export default generateData;
