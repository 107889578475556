/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-param-reassign */
import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import { format } from 'date-fns';
import AccountDetailsForm from '../../features/SupplierRegistration/components/AccountDetails';
import constants from '../../features/SupplierRegistration/constants';
import Form from '../../components/Form';
import actions from '../../features/SupplierRegistration/slice/supplier-reg.action';
import userManagementAPIs from '../../services/user-management.service';
import ProgressBar from '../../features/ProgressBar';
import JoinRequestConfirmed from '../../features/SupplierRegistration/components/JoinRequestConfirmed';
import ConfirmJoinRequest from '../../features/SupplierRegistration/components/ConfirmJoinRequest';
import Toast from '../../components/Alerts/Toast/Toast';
import Confirm from '../../components/Alerts/Confirm/Confirm';

const CreateAccount = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const {
        initFormData, currentStep, companyType, consentStatus, newRODetails, showROForm,
        companyDetailForm, accountExists, hiddenDetails, onlySelectedCodes, onlySelectedRegions,
    } = useSelector((state) => ({
        initFormData: state.SupplierRegReducer.accountDetails,
        currentStep: state.SupplierRegReducer.currentStep,
        companyType: state.SupplierRegReducer.companyType,
        newRODetails: state.SupplierRegReducer.newRODetails,
        showROForm: state.SupplierRegReducer.showROForm,
        consentStatus: state.SupplierRegReducer.consentStatus,
        companyDetailForm: state.SupplierRegReducer.companyDetails,
        hiddenDetails: state.SupplierRegReducer.hiddenDetails,
        accountExists: state.SupplierRegReducer.accountExists,
        onlySelectedCodes: state.CodesReducer.onlySelectedCodes,
        onlySelectedRegions: state.SupplyRegionsReducer.onlySelectedRegions,
    }));
    // const [step, setStep] = useState(currentStep);
    const [showConfirmJoinRequest, setShowConfirmJoinRequest] = useState(false);
    const [showJoinRequestConfirmed, setJoinRequestConfirmed] = useState(false);


    // useEffect(() => {
    //     if (currentStep !== 2) {
    //         dispatch(actions.setCurrentStep(2));
    //     }
    // }, []);

    // useEffect(() => {
    //     setStep(currentStep);
    // }, [currentStep]);

    useEffect(() => {
        if (companyDetailForm?.accountName === '') {
            // No Company Details Error
            history.push('/');
        }
    }, []);

    useEffect(() => {
        if (accountExists) {
            setJoinRequestConfirmed(true);
        }
    }, [accountExists]);

    const prepareFormState = () => {
        let preparedFormState = [];
        preparedFormState = constants.accountDetails.map((el) => ({
            ...el,
            value: initFormData[el.id],
        }));
        return preparedFormState;
    };

    const formState = prepareFormState();

    const prev = (event) => {
        event.preventDefault();
        dispatch(actions.setCurrentStep(currentStep - 1));
        history.goBack();
    };

    const consentStatusSet = (consentStatusData) => ({
        hasAgreedToTnC: consentStatusData?.tcpp || false,
        hasAgreedToRecieveComms: consentStatusData?.consentEmail || false,
        agreedOn: `${format(new Date(), 'dd-MM-yyyy')}T${new Date().toLocaleTimeString('en-GB')}`,

    });

    const prepareRequest = (obj) => {
        const finalConsentStatus = consentStatusSet(consentStatus);
        const submittedForm = {
            ...initFormData,
            ...companyDetailForm,
            companyType,
            ...finalConsentStatus,
            ...newRODetails,
        };
        Object.keys(obj).forEach((key) => {
            if (typeof obj[key] === 'object') {
                prepareRequest(obj[key]);
            } else {
                obj[key] = submittedForm[key];
            }
        });
        delete obj.companyType;
        return { ...obj };
    };
    const finalPayload = () => {
        const obj = showROForm ? { ...constants.requestS4 } : { ...constants.requestS1 };
        const requestPayload = prepareRequest(obj);
        const finalPayloadData = JSON.parse(JSON.stringify(requestPayload));
        let regionalOffice = {};
        if (showROForm) {
            regionalOffice = {
                accountName: requestPayload.organisationDetails.regionalOffice.roAccountName,
                address: {
                    addressLine1: requestPayload.organisationDetails
                        .regionalOffice.address.roAddressLine1,
                    addressLine2: requestPayload.organisationDetails
                        .regionalOffice.address.roAddressLine2,
                    city: requestPayload.organisationDetails.regionalOffice.address.roCity,
                    county: requestPayload.organisationDetails.regionalOffice.address.roCounty,
                    country: requestPayload.organisationDetails.regionalOffice.address.roCountry,
                    postcode: requestPayload.organisationDetails.regionalOffice.address.roPostcode,
                },
                businessStructure: requestPayload.organisationDetails.regionalOffice
                    .roBusinessStructure,
                natureOfBusiness: requestPayload.organisationDetails.regionalOffice
                    .roNatureOfBusiness,
                vatNumber: requestPayload.organisationDetails.regionalOffice
                    .roVatNumber,
                registrationNumber: requestPayload.organisationDetails.regionalOffice
                    .roRegistrationNumber,
                noOfEmployees: requestPayload.organisationDetails.regionalOffice
                    .roNoOfEmployees,
            };
            if (onlySelectedCodes.length > 0) {
                const industryInfo = onlySelectedCodes.map((code) => ({
                    classificationID: code.classificationID,
                    level: code.level,
                    category: code.category,
                }));
                regionalOffice.industryInfo = industryInfo;
            }

            if (onlySelectedRegions.length > 0) {
                const deliveryAreaInfo = onlySelectedRegions.map((region) => ({
                    code: region.code,
                    description: region.description,
                }));
                regionalOffice.deliveryAreaInfo = deliveryAreaInfo;
            }
            finalPayloadData.organisationDetails.regionalOffice = regionalOffice;
            // TO_DO - need to update accountID from company exist api
            finalPayloadData.organisationDetails.accountID = 'e7536037-f541-46aa-bcc2-47505ca5396b';
        }
        if (hiddenDetails.wishToJoin) {
            finalPayloadData.organisationDetails.accountID = hiddenDetails.accountID;
        }

        if (!hiddenDetails.wishToJoin) {
            if (onlySelectedCodes.length > 0) {
                const industryInfo = onlySelectedCodes.map((code) => ({
                    classificationID: code.classificationID,
                    level: code.level,
                    category: code.category,
                }));
                finalPayloadData.organisationDetails.industryInfo = industryInfo;
            }

            if (onlySelectedRegions.length > 0) {
                const deliveryAreaInfo = onlySelectedRegions.map((region) => ({
                    code: region.code,
                    description: region.description,
                }));
                finalPayloadData.organisationDetails.deliveryAreaInfo = deliveryAreaInfo;
            }
        }

        return finalPayloadData;
    };

    const confirmJoinReq = () => {
        setJoinRequestConfirmed(true);
    };

    const closeModal = () => {
        setShowConfirmJoinRequest(false);
        setJoinRequestConfirmed(false);
    };

    const handleSubmitAfterEmailConfirmation = async () => {
        // Company account does not exist
        const res = await userManagementAPIs.createAccount(
            finalPayload(),
        );
        if (res.status === 201) {
            // dispatch(actions.setOnlySelectedCodes([]));
            // dispatch(actions.setOnlySelectedRegions([]));
            dispatch(actions.initiateLogin(true));
            dispatch(actions.setCurrentStep(3));
        } else if (res.data && res.data.ErrorCode === '2000') {
            Toast.fire({
                icon: 'error',
                titleText: res?.data?.Message?.split('- ')[1] || 'Error while processing your request. Please try again later.',
                // An account with the given email already exists,
                // please login or register with a different email.',
            });
        } else {
            Toast.fire({
                icon: 'error',
                titleText: 'Unable to create an account.',
            });
        }
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        const attrs = {
            title: 'Please confirm your email?',
            subTitle: `Entered email: ${initFormData.email}`,
            confirmBtnText: 'Confirm',
            input: '',
            inputOptions: null,
            inputValidator: null,
        };
        Confirm(handleSubmitAfterEmailConfirmation, attrs);
    };
    return <>
        <ProgressBar options={constants.progressBar}
            activeStep={4}
            id='acc-reg-progress'
            context='LeftToRight' />
        <section className='nepo-container registration-form'>
            <div className='wrapper'>
                <Form id='account-details-form' onSubmit={(e) => handleSubmit(e)}>
                    <AccountDetailsForm formValues={formState} prev={prev}
                        consentStatus={consentStatus}
                        hiddenDetails= {hiddenDetails}/>
                </Form>
            </div>
        </section>
        {
            (showConfirmJoinRequest)
                && <ConfirmJoinRequest closeModal={closeModal} next={confirmJoinReq}/>
        }
        {
            (showJoinRequestConfirmed) && <JoinRequestConfirmed closeModal={closeModal}/>
        }
    </>;
};
export default CreateAccount;
