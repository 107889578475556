const filterTypes = {
    SELECT: 'select',
    RANGE: 'range',
    CODESANDCATEGORIES: 'codesandcategories',
    DELIVERYAREA: 'deliveryarea',
    LOCALITY: 'locality',
    INPUT: 'input',
    INPUT_RANGE: 'inputrange',
};

const filterLabels = {
    LOCALITY: 'Locality',
};

const filterFields = {
    STATUS: 'status',
    PROJECT_STATUS: 'projectstatus',
    RTM: 'rtm',
    WORKFLOWTYPE: 'workflowtype',
    EVENTTYPE: 'eventtype',
    STAGETYPE: 'stagetype',
    AUTHORITY: 'authority',
    DEPARTMENTS: 'departments',
    PRFS_STARTING_BETWEEN: 'prfsstartingbetween',
    CONTRACTS_STARTING_BETWEEN: 'contractsstartingbetween',
    CONTRACTS_ENDING_BETWEEN: 'contractsendingbetween',
    CREATED_BETWEEN: 'createdbetween',
    CODESANDCATEGORIES: 'codesandcategories',
    DELIVERYAREA: 'deliveryarea',
    LOCALITY: 'locality',
    QUESTIONNAIRETYPE: 'questionnairetype',
    QUESTIONNAIRE_STATUS: 'questionnairestatus',
    COMPANY_TYPE: 'companytype',
    EXPRESSION_WINDOW_BETWEEN: 'expressionbetween',
    SUBMISSION_WINDOW_BETWEEN: 'submissionbetween',
    RIGOUR_LEVEL: 'rigourlevel',
    CONTRACT_STATUS: 'contractstatus',
    COMPANY_SIZE_BETWEEN: 'companysizebetween',
    POSTCODE: 'postcode',
    ESTIMATED_VALUE_BETWEEN: 'estimatedvaluebetween',
};

const fieldsByContext = {
    opportunities: [
        filterFields.STATUS,
        filterFields.AUTHORITY,
        filterFields.RTM,
        filterFields.CODESANDCATEGORIES,
        filterFields.DELIVERYAREA,
        filterFields.EXPRESSION_WINDOW_BETWEEN,
        filterFields.SUBMISSION_WINDOW_BETWEEN,
    ],
    contractsregister: [
        filterFields.CONTRACT_STATUS,
        filterFields.CONTRACTS_STARTING_BETWEEN,
        filterFields.CONTRACTS_ENDING_BETWEEN,
        filterFields.AUTHORITY,
    ],
    suppliers: [
        filterFields.COMPANY_TYPE,
        filterFields.CODESANDCATEGORIES,
        filterFields.DELIVERYAREA,
        filterFields.LOCALITY,
        filterFields.COMPANY_SIZE_BETWEEN,
        filterFields.POSTCODE,
    ],
    projects: [
        filterFields.AUTHORITY,
        filterFields.PROJECT_STATUS,
        filterFields.RTM,
        filterFields.CONTRACTS_STARTING_BETWEEN,
        filterFields.CODESANDCATEGORIES,
    ],
    requests: [
        filterFields.CONTRACTS_STARTING_BETWEEN,
        filterFields.ESTIMATED_VALUE_BETWEEN,
        filterFields.AUTHORITY,
    ],
    workflows: [
        filterFields.STATUS,
        // filterFields.WORKFLOWTYPE,
        filterFields.RTM,
        filterFields.AUTHORITY,
        filterFields.DEPARTMENTS,
        filterFields.CREATED_BETWEEN,
        // filterFields.CODESANDCATEGORIES,
        // filterFields.DELIVERYAREA,
    ],
    solutions: [
        filterFields.AUTHORITY,
        filterFields.CONTRACT_STATUS,
        filterFields.CODESANDCATEGORIES,
        filterFields.CREATED_BETWEEN,
    ],
    spends: [],
    rebates: [],
    questionnairesselect: [
        filterFields.AUTHORITY,
        filterFields.CREATED_BETWEEN,
        filterFields.DEPARTMENTS,
    ],
    questionnaires: [
        filterFields.QUESTIONNAIRETYPE,
        filterFields.AUTHORITY,
        filterFields.CREATED_BETWEEN,
        filterFields.DEPARTMENTS,
        filterFields.QUESTIONNAIRE_STATUS,
    ],
    sections: [],
    contracts: [
        filterFields.CONTRACT_STATUS,
        filterFields.RIGOUR_LEVEL,
        filterFields.CONTRACTS_STARTING_BETWEEN,
        filterFields.CONTRACTS_ENDING_BETWEEN,
    ],
    contracts_for_supplier: [
        filterFields.CONTRACT_STATUS,
        filterFields.CONTRACTS_STARTING_BETWEEN,
        filterFields.CONTRACTS_ENDING_BETWEEN,
    ],
    events: [
        filterFields.DEPARTMENTS,
        filterFields.EVENTTYPE,
        filterFields.STAGETYPE,
    ],
    stages: [
        filterFields.DEPARTMENTS,
        filterFields.STAGETYPE,
    ],
};

export default {
    fieldsByContext,
    filterTypes,
    filterLabels,
    filterFields,
};
