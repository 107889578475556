import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

import AuthContext from '../../context/AuthContext';
import authenticationAPIs from '../../services/user-management.service';
import gatewayAuthAPIs from '../../services/gateway.service';

import actions from './slice/auth.actions';

const Auth = ({ children }) => {
    const [authState, setAuthState] = useState({
        authenticated: false,
        user: {},
        tokens: {},
    });
    const dispatch = useDispatch();

    const setContextSession = (authResult) => {
        const userData = {
            id: authResult.userId,
            firstName: authResult.firstName,
            lastName: authResult.lastName,
            email: authResult.email,
            role: authResult.role,
            accountName: authResult.accountName,
            accountType: authResult.accountType,
            accountId: authResult.accountId,
            username: authResult.username,
            details: authResult.details,
            privilege: authResult.privilege,
            defaultWorkflow: authResult.defaultWorkflow,
            accountVerified: authResult.accountVerified,
        };

        let isUserAuthenticated = false;
        isUserAuthenticated = !!authResult.tokens.accessToken;
        const userTokens = authResult.tokens;

        setAuthState({
            ...authState,
            authenticated: isUserAuthenticated,
            user: userData,
            tokens: userTokens,
        });
    };

    const updateToken = (accessToken) => {
        setAuthState({
            ...authState,
            tokens: {
                ...authState.tokens,
                accessToken,
            },
        });
    };

    const login = async (email, password) => {
        const auth = await authenticationAPIs.login(email, password);

        if (!auth.data) {
            return { networkError: 'network error' };
        }

        if (auth.data.accessToken || auth.data.Session) {
            // const details = await authenticationAPIs.getUserDetails(auth.data.userID);
            const tokens = auth.data.accessToken ? { accessToken: auth.data.accessToken }
                : { session: auth.data.Session };
            tokens.refreshToken = auth.data.refreshToken ? auth.data.refreshToken : '';

            setContextSession({
                userId: auth.data.userID,
                firstName: auth.data.userDetails?.contactDetails?.firstname,
                lastName: auth.data.userDetails?.contactDetails?.surname,
                email,
                role: auth.data.userDetails?.userRole,
                accountName: auth.data.organisationDetails?.accountName,
                accountType: auth.data.organisationDetails?.accountType,
                accountId: auth.data.organisationDetails?.accountID,
                username: auth.data.userDetails?.contactDetails?.firstname,
                tokens,
                details: auth.data.userDetails,
                privilege: auth.data.privilege,
                defaultWorkflow: {
                    dAWorkflow: auth.data.organisationDetails?.dAWorkflow,
                    fCWorkflow: auth.data.organisationDetails?.fCWorkflow,
                },
                accountVerified: auth.data.organisationDetails?.verified,
            });

            return { actionSuccess: true, ...auth };
        }

        return { actionSuccess: false, ...auth };
    };

    const refresh = async () => {
        const auth = await gatewayAuthAPIs.refresh(authState.tokens.refreshToken);

        if (!auth.data) {
            return { networkError: 'network error' };
        }

        if (auth.data?.AuthenticationResult) {
            updateToken(auth.data.AuthenticationResult.AccessToken);
        }

        return auth;
    };

    const createPassword = async (password) => {
        const username = authState.user.email;
        const sessionToken = authState.tokens.session;
        const auth = await authenticationAPIs.createPassword(username, password, sessionToken);
        const tokens = { accessToken: auth.data.accessToken };

        tokens.refreshToken = auth.data.refreshToken ? auth.data.refreshToken : '';

        if (auth.data.accessToken) {
            setContextSession({
                userId: auth.data.userDetails?.userID,
                firstName: auth.data.userDetails?.contactDetails?.firstname,
                lastName: auth.data.userDetails?.contactDetails?.surname,
                email: auth.data.userDetails.email,
                role: auth.data.userDetails?.userRole,
                accountName: auth.data.organisationDetails?.accountName,
                accountType: auth.data.organisationDetails?.accountType,
                accountId: auth.data.organisationDetails?.accountID,
                username: auth.data.userDetails?.contactDetails?.firstname,
                tokens,
                details: auth.data.userDetails,
                privilege: auth.data.privilege,
                defaultWorkflow: {
                    dAWorkflow: auth.data.organisationDetails?.dAWorkflow,
                    fCWorkflow: auth.data.organisationDetails?.fCWorkflow,
                },
                accountVerified: auth.data.organisationDetails?.verified,
            });

            return { actionSuccess: true, ...auth };
        }

        return { actionSuccess: false, ...auth };
    };

    const getVerificationCode = async (username) => {
        const auth = await authenticationAPIs.getVerificationCode(username);

        if (auth.status === 200) {
            return { actionSuccess: true, ...auth };
        }

        return { actionSuccess: false, ...auth };
    };

    const resetPassword = async (username, password, confirmationCode) => {
        const auth = await authenticationAPIs.resetPassword(username, password, confirmationCode);

        if (auth.status === 200) {
            return { actionSuccess: true, ...auth };
        }

        return { actionSuccess: false, ...auth };
    };

    const silentLogin = async (loginObj) => {
        setAuthState(loginObj);

        if (loginObj.accessToken || loginObj.Session) {
            const details = await authenticationAPIs.getUserDetails(loginObj.userID);
            const tokens = loginObj.accessToken ? { accessToken: loginObj.accessToken }
                : { session: loginObj.Session };
            tokens.refreshToken = loginObj.refreshToken ? loginObj.refreshToken : '';

            setContextSession({
                userId: details.data.userDetails?.userID,
                firstName: details.data.userDetails?.contactDetails?.firstname,
                lastName: details.data.userDetails?.contactDetails?.surname,
                email: details.data.userDetails.email,
                role: details.data.userDetails?.userRole,
                accountName: details.data.organisationDetails?.accountName,
                accountType: details.data.organisationDetails?.accountType,
                accountId: details.data.organisationDetails?.accountID,
                username: details.data.userDetails?.contactDetails?.firstname,
                tokens,
                details: details.data.userDetails,
                privilege: details.data.privilege,
                defaultWorkflow: {
                    dAWorkflow: details.data.organisationDetails?.dAWorkflow,
                    fCWorkflow: details.data.organisationDetails?.fCWorkflow,
                },
                accountVerified: details.data.organisationDetails?.verified,
            });

            return { actionSuccess: true, ...loginObj };
        }

        return { actionSuccess: false, ...loginObj };
    };

    const setRole = (role) => {
        setAuthState({
            ...authState,
            user: {
                ...authState.user,
                role,
            },
        });
    };

    const logout = async (logoutCb) => {
        const auth = await authenticationAPIs.logout(authState.tokens.accessToken);
        if (auth.status) {
            setContextSession({
                authenticated: false,
                userId: '',
                email: '',
                role: '',
                accountName: '',
                accountId: '',
                accountType: '',
                username: '',
                tokens: {},
                details: {},
                privilege: [],
            });

            dispatch(actions.logoutUser());

            if (logoutCb) logoutCb();

            return { actionSuccess: true, ...auth };
        }

        return { actionSuccess: false, ...auth };
    };

    const authActions = {
        login,
        refresh,
        createPassword,
        getVerificationCode,
        resetPassword,
        logout,
        silentLogin,
        setRole,
    };

    return (
        <AuthContext.Provider value={{
            ...authState,
            ...authActions,
        }}>
            {children}
        </AuthContext.Provider>
    );
};

Auth.propTypes = {
    children: PropTypes.object,
};

export default Auth;
