/* eslint-disable max-len */
import React, { useContext, useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import {
    formatDistanceToNowStrict,
    parseISO,
    isPast,
    isFuture,
} from 'date-fns';
import PropTypes from 'prop-types';
import Tag from '../../components/Tag';
import projectManagementAPIs from '../../services/project-management.service';
import helperFunctions from '../../utils/helperFunctions';
import Toast from '../../components/Alerts/Toast/Toast';
import CountdownTimer from '../CountdownTimer';
import DashboardCard from '../../components/DashboardCard';
import GuidanceBanner from '../../components/GuidanceBanner';
import Accordion from '../Accordion';
import LotCard from '../../screens/ProjectOverview/components/LotCard';
import Button from '../../components/Button';
import Modal from '../../components/Modal';
import Form from '../../components/Form';
import LabelledTextArea from '../../components/LabelledTextArea';
import { accountTypes } from '../../config/constants';
import AuthContext from '../../context/AuthContext';
import Constants from './constants';

const OpportunityOverview = ({ type }) => {
    const authContext = useContext(AuthContext);
    const { opportunityId } = useParams();
    const [opportunityDetails, setOpportunityDetails] = useState({});
    const [unreadCorrespondence, setUnreadCorrespondence] = useState(0);
    const [cancellationReasonModal, setCancellationReasonModal] = useState(false);
    const [rejectionReasonModal, setRejectionReasonModal] = useState(false);
    const [withdrawModal, setWithdrawModal] = useState(false);
    const [withdrawReason, setWithdrawReason] = useState('');
    const history = useHistory();

    const fetchUnreadData = async (resourceType, resourceId) => {
        let unreadCount = 0;
        const response = await projectManagementAPIs.getUnreadCorrespondence(
            resourceId, resourceType,
        );
        if (response.status === 200) {
            response.data.map((threads) => {
                response.data.unreadCount = 0;
                if (threads.unreadThreads.length > 0) {
                    threads.unreadThreads.map((messages) => {
                        if (messages.unreadMessages.length > 0) {
                            response.data.unreadCount += messages.unreadMessages.length;
                        } else {
                            response.data.unreadCount += 1;
                        }
                        return null;
                    });
                } else {
                    response.data.unreadCount += 1;
                }
                unreadCount += response.data.unreadCount;
                return null;
            });
            setUnreadCorrespondence(unreadCount);
        }
    };

    const fetchOpportunity = async () => {
        try {
            let opportunityResponse;
            if (type === 'public') {
                opportunityResponse = await projectManagementAPIs.getPublicOpportunity(opportunityId);
            } else {
                if (authContext.user.accountType !== 'supplier') {
                    Toast.fire({
                        icon: 'error',
                        titleText: 'You do not have permission to view this content.',
                    });
                    history.push('/dashboard');
                }
                opportunityResponse = await projectManagementAPIs.getOpportunity(opportunityId);
            }
            if (opportunityResponse.status === 200) {
                await fetchUnreadData('project', opportunityResponse.data.projectID);
                setOpportunityDetails({
                    ...opportunityResponse.data,
                });
            } else {
                Toast.fire({
                    icon: 'error',
                    titleText: 'Unable to retrieve information.',
                });
            }
        } catch (error) {
            Toast.fire({
                icon: 'error',
                titleText: 'Unable to retrieve information.',
            });
        }
    };

    const registerInterest = async () => {
        const response = await projectManagementAPIs.registerInterest(opportunityId);
        if (response.status === 200) {
            Toast.fire({
                icon: 'success',
                titleText: 'Interest registered successfully.',
            });
            fetchOpportunity();
        } else {
            Toast.fire({
                icon: 'error',
                titleText: 'Unable to register interest.',
            });
        }
    };

    const withdrawInterest = async () => {
        const payload = {
            reason: withdrawReason,
        };
        const response = await projectManagementAPIs.withdrawInterest(opportunityId, payload);
        if (response.status === 200) {
            Toast.fire({
                icon: 'success',
                titleText: 'Interest withdrawn successfully.',
            });
            setWithdrawModal(false);
            setWithdrawReason('');
            fetchOpportunity();
        } else {
            Toast.fire({
                icon: 'error',
                titleText: 'Unable to withdraw interest.',
            });
        }
    };

    const generateCodeTags = (item) => {
        const TagList = [];
        item?.forEach((tag, index) => {
            const TagElement = <Tag key={`${tag.classificationID}-${index}`}
                id={`row-${tag.classificationID}-${index}`}
                tagTxt={`${tag.category} - ${tag.level}`}
                isDeletable={false}
                size='large' />;
            TagList.push(TagElement);
        });
        if (TagList.length === 0) {
            return <p className={'caption'}>{'No codes and categories found.'}</p>;
        }
        return <div className='sectionContentTags'>
            {TagList}
        </div>;
    };

    const generateRegionTags = (item) => {
        const TagList = [];
        item?.forEach((tag, index) => {
            const TagElement = <Tag key={`${tag.code}-${index}`}
                id={`row-${tag.code}-${index}`}
                tagTxt={`${tag.code} - ${tag.description}`}
                isDeletable={false}
                size='large' />;
            TagList.push(TagElement);
        });
        if (TagList.length === 0) {
            return <p className={'caption'}>{'No region(s) of supply found.'}</p>;
        }
        return <div className='sectionContentTags'>
            {TagList}
        </div>;
    };

    const generateFaqContent = () => {
        if (opportunityDetails?.faq?.length > 0) {
            return <Accordion
                id='cwAccordion'
                data={opportunityDetails.faq.filter((faq) => faq.isActive).map((item, key) => ({
                    sectionId: `faq-${key}`,
                    header: <p className='title'>{item?.question}</p>,
                    content: <div className=''>
                        <div className=''>
                            <p className='label body faq_content'>{item?.answer}</p>
                        </div>
                    </div>,
                }))}
            />;
        }
        return <p className='caption-large' id='noFAQContent'>No FAQ available.</p>;
    };

    const generateCorrespondenceContent = () => <>
        <div id='correspondenceContent'>
            <p className='title sectionLabel'>Correspondence</p>
            <p className='caption'>
                {`${unreadCorrespondence} unread correspondence available`}
            </p>
            <div id='buttonContainer'>
                <Button id='actionViewCorrespondence'
                    size='small'
                    variant='secondary'
                    label='View'
                    handleClick={() => history.push(`/project/correspondence/${opportunityDetails.projectID}`)} />
            </div>
        </div>
    </>;

    const generatePastActionContent = () => <>
        {opportunityDetails?.pastAction?.length > 0
            ? <div id='pastActionContanier'>
                {opportunityDetails?.pastAction?.map((action, idx) => <div className='actionItem' key={`actionItem-${idx}`}>
                    <p className='title'>{action.title}</p>
                    <Button
                        id={`viewOutcome-${idx}`}
                        label={'View Outcome'}
                        handleClick={() => null}
                        size={'small'}
                    />
                </div>)}
            </div>
            : <div id='noLot'>
                <p className='caption' id='lotText'>No past actions on this opportunity.</p>
            </div>}
    </>;

    const generateInfoContent = (opportunityData) => (
        opportunityDetails ? <section className='itemBody'>
            <p className='title-large sectionHeader keyInfo'>Key Information</p>
            <div className='sectionContent'>
                <div className='sectionRow'>
                    <p className='title sectionLabel'>Description:</p>
                    <p className='caption sectionValue'>{helperFunctions.simpleFormat(opportunityData?.description ? opportunityData?.description : '-', 'p', 'caption', true)}</p>
                </div>
                <div className='sectionRow'>
                    <p className='title sectionLabel'>Estimated contract value:</p>
                    <p className='caption sectionValue'>{opportunityData?.contractValue ? helperFunctions.formatCurrency(opportunityData?.contractValue) : '-'}</p>
                </div>
                <div className='sectionRow'>
                    <p className='title sectionLabel'>Expression of interest window start on:</p>
                    <p className='caption sectionValue'>{helperFunctions.formatPrettyDateTime(opportunityData?.expressionInterestStartDate)}</p>
                </div>
                <div className='sectionRow'>
                    <p className='title sectionLabel'>Expression of interest window ends on:</p>
                    <p className='caption sectionValue'>{helperFunctions.formatPrettyDateTime(opportunityData?.expressionInterestEndDate)}</p>
                </div>
                <div className='sectionRow'>
                    <p className='title sectionLabel'>Estimated contract start date:</p>
                    <p className='caption sectionValue'>{opportunityData?.contractStartDate ? helperFunctions.formatPrettyDate(opportunityData?.contractStartDate) : '-'}</p>
                </div>
                <div className='sectionRow'>
                    <p className='title sectionLabel'>Estimated contract end date:</p>
                    <p className='caption sectionValue'>{opportunityData?.contractEndDate ? helperFunctions.formatPrettyDate(opportunityData?.contractEndDate) : '-'}</p>
                </div>
            </div>
            <p className='title-large sectionHeader'>Codes and Categories</p>
            <div className='sectionContent'>
                {generateCodeTags(opportunityData?.industryInfo)}
            </div>
            <p className='title-large sectionHeader'>Delivery Area</p>
            <div className='sectionContent'>
                {generateRegionTags(opportunityData?.deliveryAreaInfo)}
            </div>
            <p className='title-large sectionHeader'>Buyer Information</p>
            <div className='sectionContent'>
                <div className='sectionRow'>
                    <p className='title sectionLabel'>Name:</p>
                    <p className='caption sectionValue'>{opportunityData?.account?.companyName}</p>
                </div>
                <div className='sectionRow'>
                    <p className='title sectionLabel'>Address:</p>
                    <p className='caption sectionValue'>{opportunityData?.account?.address ? Object.keys(opportunityData?.account?.address).sort().map((k) => opportunityData?.account?.address[k]).join(', ') : ''}</p>
                </div>
                <div className='sectionRow'>
                    <p className='title sectionLabel'>Primary contact name:</p>
                    <p className='caption sectionValue'>{opportunityData?.user?.contactDetails?.username}</p>
                </div>
                <div className='sectionRow'>
                    <p className='title sectionLabel'>Primary contact email:</p>
                    <p className='caption sectionValue'>{opportunityData?.user?.email}</p>
                </div>
                <div className='sectionRow'>
                    <p className='title sectionLabel'>Primary contact phone number:</p>
                    <p className='caption sectionValue'>{opportunityData?.user?.contactDetails?.mobile}</p>
                </div>
            </div>
        </section> : <></>);

    const generateLotsContent = () => ((opportunityDetails?.lots?.length > 0)
        ? <div id='lotContainer'>
            {opportunityDetails.lots
                ?.sort((a, b) => a.lotOrderNo - b.lotOrderNo)
                ?.map((lot, idx) => {
                    const codesAndCategories = lot?.industryInfo?.map((code) => `${code.category} - ${code.level}`);
                    const deliveryAreas = lot?.deliveryAreaInfo?.map((deliveryArea) => `${deliveryArea.code} - ${deliveryArea.description}`);

                    return <LotCard
                        key={`lot-${idx}`}
                        id={`lots-id-${idx}`}
                        header
                        actionBtn={true}
                        caption={`Lot #${lot.lotOrderNo}`}
                        headerTitle={lot.title}
                        content={<>
                            <div className='contentItem'>
                                <>
                                    <p className='caption-small projectHeader'>Codes and Categories:</p>
                                    <p className='title-small projectTitle'>{codesAndCategories?.length > 0
                                        ? codesAndCategories.map((role, i) => {
                                            if (i + 1 === codesAndCategories.length) {
                                                return `${helperFunctions.camel2title(role)}`;
                                            }
                                            return `${helperFunctions.camel2title(role)}, `;
                                        })
                                        : '-'}</p>
                                </>
                                <>
                                    <p className='caption-small projectHeader'>Delivery Area:</p>
                                    <p className='title-small projectTitle'>{deliveryAreas?.length > 0
                                        ? deliveryAreas.map((role, i) => {
                                            if (i + 1 === deliveryAreas.length) {
                                                return `${helperFunctions.camel2title(role)}`;
                                            }
                                            return `${helperFunctions.camel2title(role)}, `;
                                        })
                                        : '-'}</p>
                                </>
                                <>
                                    <p className='caption-small projectHeader'>Estimated Value:</p>
                                    <p className='title-small projectTitle'>{lot.estimatedValue
                                        ? helperFunctions.formatCurrency(lot.estimatedValue) : '-'}</p>
                                </>
                            </div>
                        </>
                        }
                        actionBtnText='View Lot'
                        handlerActionBtn={() => { history.push(`/projects/overview/${opportunityDetails.projectID}/lot/view/${lot.lotID}`); }}
                    />;
                })}
        </div>
        : <div id='noLot'>
            <p className='caption' id='lotText'>No Lots added for this opportunity.</p>
        </div>);

    const handleAnswerSummaryButton = () => {
        const {
            projectID, eventID, isProjectDPS, lots,
        } = opportunityDetails;

        const url = `${Constants.answerSummaryUrl}${projectID}/${eventID}`;
        history.push(isProjectDPS && lots.length ? `${Constants.dpsUrl}${url}` : url);
    };

    const handleViewOutcomeButton = () => {
        history.push(`/opportunity/award/summary/${opportunityDetails.projectID}/${opportunityDetails.eventID}`);
    };

    const getShowOutcomeButtons = () => {
        const {
            isProjectDPS, publishLevel, lots, isSubmissionEvaluated,
        } = opportunityDetails;
        const buttons = [
            {
                id: 0, label: Constants.buttons.viewOutcome, size: 'regular', variant: 'primary', action: handleViewOutcomeButton, allowAction: true,
            },
        ];

        if (isProjectDPS && publishLevel === Constants.publishLotLevel && lots.length && isSubmissionEvaluated) {
            const newSubmissionButton = {
                id: 1, label: Constants.buttons.newSubmission, size: 'regular', variant: 'secondary', action: handleAnswerSummaryButton, allowAction: true,
            };
            buttons.push(newSubmissionButton);
        }

        return buttons;
    };

    const generateGuidanceBanner = () => {
        const expressionStartDate = opportunityDetails?.expressionInterestStartDate
            ? formatDistanceToNowStrict(
                new Date(
                    opportunityDetails?.expressionInterestStartDate,
                ),
            ) : '';
        const submissionStartDate = opportunityDetails?.submissionStartDate
            ? formatDistanceToNowStrict(
                new Date(
                    opportunityDetails?.submissionStartDate,
                ),
            ) : '';

        const headerText = `${opportunityDetails?.eventName}`;

        switch (true) {
            case (opportunityDetails?.showOutcome):
                return <GuidanceBanner
                    id={'guidanceBanner-showOutcome'}
                    headerText = 'Market Consultation'
                    bannerText1 = {'There is an update on your submission. Click on ‘View Outcome’ to learn more.'}
                    buttonArr={getShowOutcomeButtons()}
                />;
            case (opportunityDetails?.projectStatus?.toLowerCase() === 'cancelled'):
                return <GuidanceBanner
                    id={'guidanceBanner-cancelled'}
                    headerText = {headerText}
                    bannerText1 = {'This project has been cancelled.'}
                    bannerText2 = {'Click on \'View Reason\' to find out more'}
                    type={'red'}
                    buttonArr={
                        [
                            {
                                id: 0, label: 'View Reason', size: 'regular', variant: 'primary', action: () => setCancellationReasonModal(true), allowAction: true,
                            },
                        ]
                    }
                />;
            case (opportunityDetails.submissionEndDate
                && isPast(parseISO(opportunityDetails?.submissionEndDate))):
                return <GuidanceBanner
                    id={'guidanceBanner-postInterest'}
                    headerText = {headerText}
                    bannerText1 = {'The submission deadline for this project has passed.'}
                    buttonArr={authContext.authenticated === true && opportunityDetails.registerInterest && [
                        {
                            id: 1, label: 'View Response', size: 'regular', variant: 'primary', action: handleAnswerSummaryButton, allowAction: true,
                        },
                    ]}
                    // actionableItem={'POST-INTEREST'}
                />;
            case (opportunityDetails.submissionStartDate
                    && isFuture(parseISO(opportunityDetails?.submissionStartDate)))
                    && opportunityDetails?.registerInterest?.status?.toLowerCase() === 'approved':
                return <GuidanceBanner
                    id={'guidanceBanner-preInterest'}
                    headerText = {headerText}
                    bannerText1 = {`You should be able to interact with this opportunity in ${submissionStartDate}.`}
                    buttonArr={[]}
                    actionableItem={'PRE-INTEREST'}
                />;
            case (opportunityDetails.expressionInterestStartDate
                    && isFuture(parseISO(opportunityDetails?.expressionInterestStartDate))):
                return <GuidanceBanner
                    id={'guidanceBanner-preInterest'}
                    headerText = {headerText}
                    bannerText1 = {`You should be able to interact with this opportunity in ${expressionStartDate}.`}
                    buttonArr={[]}
                    actionableItem={'PRE-INTEREST'}
                />;
            case (opportunityDetails.expressionInterestEndDate
                    && isPast(parseISO(opportunityDetails?.expressionInterestEndDate))):
                return <GuidanceBanner
                    id={'guidanceBanner-postInterest'}
                    headerText = {headerText}
                    bannerText1 = {opportunityDetails?.registerInterest?.status?.toLowerCase() === 'approved' ? 'Congrats! You have successfully registered interest in this opportunity.' : 'This phase of the project has elapsed.'}
                    bannerText2 = {opportunityDetails?.registerInterest?.status?.toLowerCase() === 'approved' ? 'The buyer has a few questions, click on \'View\' to begin.' : ''}
                    buttonArr={[
                        {
                            id: 0, label: 'Remove Interest', size: 'regular', variant: 'primary', additionalVarient: 'red', action: () => { setWithdrawModal(true); }, allowAction: opportunityDetails?.registerInterest?.status?.toLowerCase() === 'approved',
                        }, {
                            id: 1, label: 'View', size: 'regular', variant: 'primary', action: handleAnswerSummaryButton, allowAction: opportunityDetails?.registerInterest?.status?.toLowerCase() === 'approved',
                        }]}
                    actionableItem={'POST-INTEREST'}
                />;
            case (opportunityDetails?.registerInterest?.status?.toLowerCase() === 'pending'):
                return <GuidanceBanner
                    id={'guidanceBanner-approval'}
                    headerText = {headerText}
                    bannerText1 = {'Awaiting approval from buyer.'}
                    buttonArr={
                        [
                            {
                                id: 0, label: 'Remove Interest', size: 'regular', variant: 'primary', additionalVarient: 'red', action: () => { setWithdrawModal(true); }, allowAction: true,
                            },
                        ]
                    }
                    showStatus={'Approval Pending'}
                    actionableItem={'APPROVAL'}
                />;
            case (opportunityDetails?.registerInterest?.status?.toLowerCase() === 'rejected'):
                return <GuidanceBanner
                    id={'guidanceBanner-rejected'}
                    headerText = {headerText}
                    bannerText1 = {'Your interest in this project has been rejected.'}
                    bannerText2 = {'Click on \'View Reason\' to find out more'}
                    type={'red'}
                    buttonArr={
                        [
                            {
                                id: 0, label: 'View Reason', size: 'regular', variant: 'primary', action: () => setRejectionReasonModal(true), allowAction: true,
                            },
                            {
                                id: 0, label: 'I\'m Interested', size: 'regular', variant: 'primary', action: registerInterest, allowAction: true,
                            },
                        ]
                    }
                />;

                // Need the actual response from backend
            // case (opportunityDetails?.submissionStatus?.toLowerCase() === 'answer'):
            //     return <GuidanceBanner
            //         id={'guidanceBanner-answer'}
            //         headerText = {headerText}
            //         bannerText1 = {'You\'ve started responing to this opportunity!'}
            //         bannerText2 = {
            //             'You can always get back to your response(s) by clicking the \'Answer\' button.'}
            //         buttonArr={
            //             [{
            //                 id: 0, label: 'Remove Interest', size: 'regular', variant: 'primary', additionalVariant: 'red', action: () => { setWithdrawModal(true); }, allowAction: true,
            //             }, {
            //                 id: 1, label: 'Answer', size: 'regular', variant: 'primary', action: handleAnswerSummaryButton, allowAction: true,
            //             }]
            //         }
            //     />;
            case (opportunityDetails?.registerInterest?.status?.toLowerCase() === 'approved' && authContext.user?.accountType?.toLowerCase() === accountTypes.supplier):
                return <GuidanceBanner
                    id={'guidanceBanner-approved'}
                    headerText = {headerText}
                    bannerText1 = {'Congrats! You have successfully registered interest in this opportunity.'}
                    bannerText2 = {'The buyer has a few questions, click on \'View\' to begin.'}
                    buttonArr={
                        [
                            {
                                id: 0, label: Constants.buttons.removeInterest, size: 'regular', variant: 'primary', additionalVariant: 'red', action: () => { setWithdrawModal(true); }, allowAction: true,
                            }, {
                                id: 1, label: Constants.buttons.view, size: 'regular', variant: 'primary', action: handleAnswerSummaryButton, allowAction: true,
                            },
                        ]
                    }
                />;
            case (opportunityDetails?.projectStatus?.toLowerCase() === 'created' && authContext.user?.accountType?.toLowerCase() === accountTypes.supplier):
                return <GuidanceBanner
                    id={'guidanceBanner-created'}
                    headerText = {headerText}
                    bannerText1 = {'This opportunity is publicly available.'}
                    bannerText2 = {'Click on \'I\'m Interested\' to find out more'}
                    buttonArr={
                        [
                            {
                                id: 0, label: 'I\'m Interested', size: 'regular', variant: 'primary', action: registerInterest, allowAction: true,
                            },
                        ]
                    }
                />;
            case (opportunityDetails?.projectStatus?.toLowerCase() === 'created' && authContext.authenticated === false):
                return <GuidanceBanner
                    id={'guidanceBanner-created'}
                    headerText = {headerText}
                    bannerText1 = {'This opportunity is publicly available.'}
                    bannerText2 = {'Login to find out more.'}
                />;
            default:
                break;
        }
        return [];
    };

    const generateActivityLogContent = () => ((opportunityDetails?.activityLog?.length > 0)
        ? <>
            {opportunityDetails.activityLog.map((log, index) => <div key={`log-${index}`} className={'logItem'}>
                <p className={'headerTitle title'}>{log.message}</p>
                <p className={'caption'}>{log.username}, {log.departmentName}</p>
                <p className={'caption small'}>{helperFunctions.formatPrettyDateTime(log.systemTimestamp)}</p>
            </div>)}
        </>
        : <div id='noActivityLog'>
            <p className='caption'>No logs.</p>
        </div>);

    useEffect(() => {
        fetchOpportunity();
    }, [opportunityId]);

    return <>
        <div className='opportunityOverviewContainer'>
            {generateGuidanceBanner()}
            {opportunityDetails.submissionEndDate
            && !opportunityDetails.showOutcome
            && opportunityDetails.projectStatus !== 'cancelled'
            && <div id='submissionDeadlineContainer'>
                <CountdownTimer timerEndDate={opportunityDetails.submissionEndDate} type='tiled'
                    enablerag={true}/>
            </div>}
            <DashboardCard
                id='opportunityDetails'
                size='large'
                header={true}
                headerTitle={opportunityDetails.title}
                caption={`Opportunity # ${opportunityDetails.referenceNumber ? opportunityDetails.referenceNumber : '-' }`}
                content={generateInfoContent(opportunityDetails)}
                footer={false}
            />
        </div>
        <div className='opportunityOverviewContainer'>
            <DashboardCard
                id='pastActions'
                size='small'
                header={true}
                headerTitle={'Past Actions'}
                content={generatePastActionContent()}
                footer={false}
            />
            <DashboardCard
                id='faqEvents'
                size='medium'
                header={true}
                headerTitle={'Frequently Asked Questions'}
                caption={''}
                content={generateFaqContent()}
                footer={false}
            />
        </div>
        <div className='opportunityOverviewContainer'>
            <DashboardCard
                id='lots'
                size='large'
                header={true}
                headerTitle={'Lots'}
                caption={''}
                content={generateLotsContent()}
                footer={false}
            />
        </div>
        <div className='opportunityOverviewContainer'>
            {opportunityDetails?.registerInterest?.status?.toLowerCase() === 'approved'
            && <DashboardCard
                id='correspondenceLog'
                size='small'
                header={true}
                headerTitle={'Correspondence'}
                content={generateCorrespondenceContent()}
                footer={false}
            />}
            <DashboardCard
                id='activityLog'
                size='small'
                header={true}
                headerTitle={'Activity Log'}
                content={generateActivityLogContent()}
                footer={false}
            />
        </div>
        {withdrawModal
        && <Form
            id={'withdrawIntresrtForm'}
            className={'withdrawIntresrtForm'}
            onSubmit={(event) => {
                event.preventDefault();
                withdrawInterest();
            }}>
            <Modal
                id='withdrawInterestModal'
                headerTitle='Withdraw Interest'
                open={withdrawModal}
                size='medium'
                body={<>
                    <LabelledTextArea
                        id='withdrawReason'
                        type={'LabelledInputTypeText'}
                        label='Resolved Reason'
                        breakColumn={true}
                        onChange={(e) => setWithdrawReason(e.target.value)}
                        value={withdrawReason || ''}
                        currentCharCount={withdrawReason ? withdrawReason.length : 0}
                        placeholder='Enter reason for withdrawing interest'
                        required={true}
                    />
                </>}
                footer={true}
                handleMainActionBtnClick = {() => {}}
                mainActionButtonTxt={'Confirm'}
                secondActionButton={true}
                handleSecondaryActionBtnClick={() => {
                    setWithdrawReason('');
                    setWithdrawModal(false);
                }}
                secondActionButtonTxt={'Cancel'}
                helpOption={false}
                closeModal={() => {
                    setWithdrawReason('');
                    setWithdrawModal(false);
                }}
            />
        </Form>
        }
        {cancellationReasonModal
        && <Modal
            id='showCancellationModal'
            open={cancellationReasonModal}
            size='small'
            headerTitle='Cancellation Reason'
            body={<>
                <div>
                    {opportunityDetails?.statusCancelledReasons?.map((reason) => <>
                        <p className='title'>{reason.externalReason}</p>
                    </>)}
                </div>
            </>}
            footer={true}
            handleMainActionBtnClick={() => setCancellationReasonModal(false)}
            mainActionButtonTxt={'Ok'}
            secondActionButton={false}
            closeModal={() => setCancellationReasonModal(false)}
            allowCloseButton = {true}
        />
        }
        {rejectionReasonModal
        && <Modal
            id='showRejectionModal'
            open={rejectionReasonModal}
            size='small'
            headerTitle='Reason'
            body={<>
                <div>
                    <p className='title'>{opportunityDetails?.registerInterest?.externalReason || ''}</p>
                </div>
            </>}
            footer={true}
            handleMainActionBtnClick={() => setRejectionReasonModal(false)}
            mainActionButtonTxt={'Ok'}
            secondActionButton={false}
            closeModal={() => setRejectionReasonModal(false)}
            allowCloseButton = {true}
        />
        }
    </>;
};

OpportunityOverview.propTypes = {
    type: PropTypes.string,
};

export default OpportunityOverview;
