import React, { useEffect, useState, useContext } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import {
    parseISO, isPast,
} from 'date-fns';

import rbac from '../../rbac';
import AuthContext from '../../context/AuthContext';
import Toast from '../../components/Alerts/Toast/Toast';
import Confirm from '../../components/Alerts/Confirm/Confirm';
import Button from '../../components/Button';
import GuidanceBanner from '../../components/GuidanceBanner';
import LabelledCheckBox from '../../components/LabelledCheckBox';
import Modal from '../../components/Modal';
import Accordion from '../../features/Accordion';
import projectManagementAPIs from '../../services/project-management.service';
import addIcon from '../../styles/images/addIcon.svg';
import helperFunctions from '../../utils/helperFunctions';
import StatusLabel from '../../components/StatusLabel';
import { getDescriptionBlock } from '../../features/DocumentBuilder/helpers';
import { RICH_TEXT /* evaluationPanel */ } from '../../config/constants';

const ManageQuestionnaires = () => {
    const history = useHistory();
    const authContext = useContext(AuthContext);
    const { projectId, eventId } = useParams();
    const [questionnaires, setQuestionnaires] = useState([]);
    const [projectDetails, setProjectDetails] = useState({});
    const [eventDetails, setEventDetails] = useState({});
    const [isOpportunityLive, setIsOpportunityLive] = useState(false);
    const [hasSubmissionEndDatePassed, setHasSubmissionEndDatePassed] = useState(false);

    const [lots, setLots] = useState([]);
    const [lotSelected, setLotSelected] = useState([]);
    const [lotTemplate, setLotTemplate] = useState();
    const [weightageModal, setWeightageModal] = useState(false);
    const [lotModal, setLotModal] = useState(false);

    const fetchProject = async (id) => {
        const response = await projectManagementAPIs.getProject(id);
        if (response.status === 200) {
            let publishEvent = {};
            response?.data?.workflow?.stages?.forEach((stage) => {
                stage?.events?.forEach((evnt) => {
                    if (evnt.eventID === eventId) {
                        publishEvent = {
                            ...evnt,
                        };
                        if (publishEvent.submissionStartDate && publishEvent.submissionEndDate) {
                            if (helperFunctions.isBetween(new Date(),
                                parseISO(publishEvent.submissionStartDate),
                                parseISO(publishEvent.submissionEndDate))) {
                                setIsOpportunityLive(true);
                            }
                            if (isPast(parseISO(publishEvent.submissionEndDate))) {
                                setHasSubmissionEndDatePassed(true);
                            }
                        }
                    }
                });
            });
            setProjectDetails(response.data);
            setEventDetails(publishEvent);
        } else {
            Toast.fire({
                icon: 'error',
                titleText: 'Unable to load project.',
            });
        }
        setLots(response.data?.lots || []);
    };

    const fetchData = async () => {
        const response = await projectManagementAPIs.getAssignedQuestionnaire(projectId, eventId);
        if (response.status === 200) {
            setQuestionnaires(response.data.responseSummary);
        } else {
            Toast.fire({
                icon: 'error',
                titleText: 'Unable to retrieve assigned questionnaires.',
            });
        }
    };

    useEffect(() => {
        fetchData();
        fetchProject(projectId);
    }, [projectId]);

    const removeAssignedItem = async (item) => {
        const response = await projectManagementAPIs.removeAssignedQuestionnaire(item.responseID);
        if (response.status === 200) {
            Toast.fire({
                icon: 'success',
                titleText: 'Questionnaire removed from event successfully.',
            });
        } else {
            Toast.fire({
                icon: 'error',
                titleText: 'Unable to remove questionnaire from event.',
            });
        }
        fetchData();
    };

    const confirmRemove = (item) => {
        Confirm(() => {
            removeAssignedItem(item);
        }, {
            subTitle: 'Are you sure you want to remove this questionniare?',
        });
    };

    const editAssignedItem = async (responseID, item) => {
        const response = await projectManagementAPIs.editAssignedQuestionnaireLots(
            responseID, item,
        );
        if (response.status === 200) {
            Toast.fire({
                icon: 'success',
                titleText: 'Questionnaire lots updated successfully',
            });
        } else {
            Toast.fire({
                icon: 'error',
                titleText: 'Unable to update questionnaire lots.',
            });
        }
        fetchData();
    };

    const onCheckBoxChange = (e, item) => {
        const lotArray = lotSelected || [];
        const newLot = {
            lotID: item.lotID,
            title: item.title,
            lotOrderNo: item.lotOrderNo,
        };
        let updatedLots;
        if (lotSelected && lotSelected.filter((lot) => lot.lotID === item.lotID).length > 0) {
            updatedLots = lotSelected.filter((lot) => lot.lotID !== item.lotID);
            setLotSelected(updatedLots);
        } else {
            lotArray.push(newLot);
            setLotSelected(lotArray);
        }

        setLots(lots.map((el) => {
            let preparedEl = {};
            if (`checkbox-${el.lotID}-checkBox-btn` === e.target.id) {
                preparedEl = {
                    ...el,
                    selected: !el.selected,
                };
            } else {
                preparedEl = {
                    ...el,
                };
            }
            return preparedEl;
        }));
    };

    const setAllLots = (state) => {
        const lotReset = [];
        lots.forEach((lot) => {
            const preparedLot = {
                ...lot,
                selected: state,
            };
            lotReset.push(preparedLot);
        });
        return lotReset;
    };

    const onAllLotsChange = () => {
        if (lotSelected.length === lots.length) {
            setLotSelected([]);
            setLots(setAllLots(false));
        } else {
            setLotSelected(lots);
            setLots(setAllLots(true));
        }
    };

    const handleLot = (data) => {
        const lotArray = [];
        lotSelected.forEach((lot) => {
            const lotObject = {
                lotID: lot.lotID,
                lotTitle: lot.title,
                lotOrderNo: lot.lotOrderNo,
            };
            lotArray.push(lotObject);
        });
        const templateObject = {
            questionnaireID: data.responseID,
            questionnaireName: data.templateName,
            lots: lotArray,
        };
        editAssignedItem(data.responseID, templateObject);
        setLotSelected([]);
        setLotTemplate();
        setLots(setAllLots(false));
        setLotModal(false);
    };

    const handleEditModal = (data) => {
        const lotReset = [];
        const selectedArray = [];
        lots.forEach((lot) => {
            if (data.lots.filter((dataLot) => dataLot.lotID === lot.lotID).length > 0) {
                const preparedLot = {
                    ...lot,
                    selected: true,
                };
                selectedArray.push(lot);
                lotReset.push(preparedLot);
            } else {
                const preparedLot = {
                    ...lot,
                };
                lotReset.push(preparedLot);
            }
        });
        setLotTemplate(data);
        setLots(lotReset);
        setLotSelected(selectedArray);
        setLotModal(true);
    };

    const handleCancelModal = () => {
        setLots(setAllLots(false));
        setLotModal(false);
    };

    const checkResponseWeightage = (response) => {
        let total = 0;
        response.sections.forEach((section) => {
            if (section.weightage) { total += section.weightage; }
        });
        if (total > 0 && response.weightage && response.weightage === total) return true;
        return false;
    };

    const generateResponseWeightageMessage = (response) => {
        let total = 0;
        response.sections.forEach((section) => {
            if (section.weightage) {
                total += section.weightage;
            }
        });
        if (response.weightage && response.weightage !== total) return 'Requires Revision';
        return 'Yet to be assigned';
    };


    const generateResponseWeightage = (response) => {
        let total = 0;
        total = response.weightage;
        return total;
    };

    const generateTotalWeightage = () => {
        let total = 0;
        questionnaires.forEach((response) => {
            response.sections.forEach((section) => {
                section.questions.forEach((question) => {
                    if (question.weightage) {
                        total += question.weightage;
                    }
                });
            });
        });
        return total;
    };

    const generateLotWeightage = (data) => {
        let total = 0;
        questionnaires.forEach((response) => {
            if (response.lots.filter((lot) => lot.lotID === data.lotID).length > 0) {
                response.sections.forEach((section) => {
                    section.questions.forEach((question) => {
                        if (question.weightage) {
                            total += question.weightage;
                        }
                    });
                });
            }
        });
        return total;
    };

    const isAssignWeightageApplicable = (questionnaire) => {
        let hasAtleastOneScoredQuestion = false;
        questionnaire?.sections.map((section) => {
            section.questions.map((question) => {
                if (question.scoringMechanism === 'scored') {
                    hasAtleastOneScoredQuestion = true;
                }
                return null;
            });
            return null;
        });
        return hasAtleastOneScoredQuestion;
    };

    const validateQuestionnaire = (data) => {
        const publishLevel = eventDetails?.publishProject === true ? 'project' : 'lot';
        if (data.publishLevel === publishLevel) {
            return true;
        }
        return false;
    };

    const generateAccordionSectionHeader = (data) => (
        <div className='mqAccordionHeader'>
            <div className='mqAccordionHeaderTitle'>
                <div><p className='title-large bold'>{data.templateName || 'N/A'}</p></div>
                <div className='mqAccordionHeaderStatusPills'>{validateQuestionnaire(data) === false && <StatusLabel
                    id={'invalidQuestionnaire'}
                    color={'red'}
                    labelTxt={'Invalid publish level'} />}
                {!checkResponseWeightage(data)
                && isAssignWeightageApplicable(data) && <StatusLabel
                    id={'intentDocsApproval'}
                    color={'amber'}
                    labelTxt={generateResponseWeightageMessage(data)} />}
                </div>
            </div>
            <div className='mqLots'>
                {data?.lots.length > 0 && <><p className='title bold'>Lots:&nbsp;</p>
                    {data.lots.sort((a, b) => a.lotOrderNo - b.lotOrderNo).map((lot, idx) => <p key={idx} className='title'>{`Lot ${lot?.lotOrderNo}:
                        ${lot.lotTitle}`}{idx + 1 !== data.lots.length && <>,</>}&nbsp;</p>)}
                </>}
            </div>
        </div>
    );


    const generateAccordionSectionContent = (data) => <>
        <div className='mqAccordionSectionContentRow'>
            <div className='mqAccordionSectionContentData'>
                <div className='mqDataSection'>
                    { getDescriptionBlock(data.templateDescription || 'N/A', RICH_TEXT.QUESTIONNAIRE_DESCRIPTION) }
                    {isAssignWeightageApplicable(data)
                        && <div className='mqWeightage'><p className='label title'>Weighting:&nbsp;</p>{generateResponseWeightage(data) > 0 ? <p className='label title'>{`${generateResponseWeightage(data)}`}</p> : <p className='title assigned'>Yet to be assigned</p>}</div>
                    }
                    {/* TODO enable EVALUATION PANEL after the release
                                        {data.evaluationPanel
                        && <p className='label title' >{evaluationPanel}:
                        {helperFunctions.getEvaluationPanelLabel(data.evaluationPanel)}</p>
                    } */}
                </div>
                <div className='mqDataSection'>
                    <p className='label title'>Created Date: {data.createdDate || 'N/A'}</p>
                </div>
            </div>
        </div>
        <div className='mqAccordionFooter'>
            <div className='mqlotButtons'>
                { projectDetails?.steeringInfo && rbac.can(
                    rbac.action.editAssignedQuestionnaire,
                    rbac.util.getSteeringGroupRole(authContext.user.id,
                        projectDetails?.steeringInfo),
                )
                    && <>
                        <Button
                            id={'mqButton'}
                            variant='primary'
                            additionalVariant='red'
                            size='small'
                            disabled={isOpportunityLive || hasSubmissionEndDatePassed}
                            label={'Delete'}
                            handleClick={() => confirmRemove(data)} />
                        {data.templateType !== 'defaultSelectionQuestionnaire' && <Button
                            id={'mqButton'}
                            variant='secondary'
                            size='small'
                            disabled={hasSubmissionEndDatePassed
                                || validateQuestionnaire(data) === false}
                            label={'Edit'}
                            handleClick={() => history.push(`/questionnaires/edit/${data.templateID}/${projectId}/${eventId}/_/${data.responseID}`)} />}
                    </>
                }
                <Button
                    id={'mqButton'}
                    variant='secondary'
                    size='small'
                    label={'View'}
                    handleClick={() => history.push(`/questionnaires/view/${data.templateID}/${data.responseID}`, {
                        deadlinePassed: hasSubmissionEndDatePassed,
                    })}
                />
            </div>
            <div className='mqWeightageButtons'>
                {lots && lots.length > 0
                    && !eventDetails?.publishProject
                    && projectDetails?.steeringInfo && rbac.can(
                    rbac.action.editAssignedQuestionnaire,
                    rbac.util.getSteeringGroupRole(authContext.user.id,
                        projectDetails?.steeringInfo),
                )
                    && <Button
                        id={'mqButton'}
                        variant='secondary'
                        size='small'
                        disabled={isOpportunityLive
                            || hasSubmissionEndDatePassed || validateQuestionnaire(data) === false}
                        label={'Edit Lots'}
                        handleClick={() => handleEditModal(data)} />
                }
                {
                    projectDetails?.steeringInfo && rbac.can(
                        rbac.action.editAssignedQuestionnaire,
                        rbac.util.getSteeringGroupRole(authContext.user.id,
                            projectDetails?.steeringInfo),
                    ) && isAssignWeightageApplicable(data)
                    && <Button
                        id={'mqButton-wide'}
                        variant='primary'
                        size='small'
                        label={data.weightage ? 'Edit Weighting' : 'Assign Weighting'}
                        disabled={hasSubmissionEndDatePassed
                            || validateQuestionnaire(data) === false
                        }
                        handleClick={() => history.push(`/questionnaires/weightage/${eventId}/${data.responseID}`)} />}
            </div>
        </div>
    </>;

    const generateAccordionData = () => {
        const elements = [];

        if (questionnaires) {
            questionnaires.forEach((data, idx) => {
                elements.push({
                    sectionId: `mqAccordionSection-id-${idx}`,
                    header: generateAccordionSectionHeader(data),
                    content: generateAccordionSectionContent(data),
                });
            });
        }

        return elements;
    };

    const generateLotModalBody = () => {
        const allLots = <div key={'0'} className='bqLotModal'><LabelledCheckBox
            id={'checkbox-all'}
            renderAsRow={false}
            breakColumn={false}
            options={[{
                id: 'checkbox-all',
                value: 'all',
                label: 'Select All',
                checked: lotSelected.length === lots.length || false,
            }]}
            onChange={() => onAllLotsChange()}
            required={false}
        /></div>;
        const lotBody = lots?.sort((a, b) => a.lotOrderNo - b.lotOrderNo)?.map((item, idx) => <div key={idx} className='bqLotModal'>
            <LabelledCheckBox
                id={`checkbox-${idx}`}
                renderAsRow={false}
                breakColumn={false}
                options={[{
                    id: `checkbox-${item.lotID}`,
                    value: item.lotID,
                    label: `Lot ${item.lotOrderNo}: ${item.title}`,
                    checked: item.selected || false,
                }]}
                onChange={(e) => onCheckBoxChange(e, item)}
                required={false}
            />
        </div>);
        const result = [allLots, ...lotBody];
        return result;
    };

    const generateModalBody = (data) => <div>
        <GuidanceBanner
            id='mqGuidanceBanner'
            type='small'
            headerText='Questionnnaire Weighting'
            bannerText2='Please ensure the total questionnaire weighting for each lot is 100.'
            buttonArr={[]}
        />
        <div className='mqBannerContainer'>
            <div className='mqBannerRow'><p className='body bold'>Lots</p><p className='body bold'>Weighting</p></div>
            {data.map((lot) => <div key='key' className='mqBannerRow'><p className='body'>{lot.lotName || lot.title}</p><p className='body'>{generateLotWeightage(lot)}</p></div>)}
        </div>
    </div>;

    const validateProject = () => {
        const publishLevel = eventDetails?.publishProject === true ? 'project' : 'lot';
        if (publishLevel === 'lot' && lots.length === 0) return false;
        return true;
    };

    const validateAllQuestionnaires = () => {
        const publishLevel = eventDetails?.publishProject === true ? 'project' : 'lot';
        const invalidQuestionnaires = [];
        questionnaires.forEach((template) => {
            if (template?.publishLevel !== publishLevel) {
                invalidQuestionnaires.push('true');
            }
        });

        if (invalidQuestionnaires.length > 0) {
            return false;
        }

        return true;
    };

    const getBannerText = () => {
        if (validateProject() === false) return 'Warning. You are publishing at lot level, but not created any lots for this project. Please add at least one lot before continuing.';
        if (validateAllQuestionnaires() === false) return `Warning. You are publishing at a ${eventDetails?.publishProject === true ? 'project' : 'lot'} level, but questionnaires assigned to this event do not match this publish level. To fix this, please delete these questionnaires. You can then re-add them.`;
        if (isOpportunityLive) return 'This Opportunity is currently live. While you can still make some changes, these will impact suppliers.';
        if (hasSubmissionEndDatePassed) return 'This Opportunity is has expired. You cannot make changes to these questionnaires.';
        return 'This Opportunity is yet to be published';
    };

    const generateWeightingIndicator = () => {
        const weightingCheck = eventDetails?.questionnairesType?.filter((template) => template !== 'selection');
        if (weightingCheck?.length > 0) {
            return (projectDetails
                && projectDetails.lots
                && projectDetails.lots.length > 0
                && !eventDetails?.publishProject
                ? <Button
                    id={'mqButton'}
                    variant='secondary'
                    additionalVariant='skinless'
                    label={'Lot Weighting'}
                    handleClick={() => setWeightageModal(true)} />
                : <div>
                    <p className='title'>Total Weighting: {generateTotalWeightage()}/100</p>
                </div>);
        }
        return (<></>);
    };

    return (
        <>
            <GuidanceBanner
                id='mqGuidanceBanner'
                headerText={eventDetails.eventName}
                type = {validateProject() === false || validateAllQuestionnaires() === false ? 'red' : ''}
                bannerText1="You're selecting questionnaires for this opportunity!"
                bannerText2={getBannerText()}
                buttonArr={[{
                    size: 'small',
                    allowAction: true,
                    variant: 'secondary',
                    label: 'Back',
                    action: () => history.goBack(),
                }]}
            />
            <div className='mqContentSectionWrapper'>
                <div className='mqSummary'>
                    <p className='title-xLarge'>Selection Summary</p>
                    <div className='mqButtonContainer'>
                        {projectDetails?.steeringInfo && rbac.can(
                            rbac.action.editAssignedQuestionnaire,
                            rbac.util.getSteeringGroupRole(authContext.user.id,
                                projectDetails?.steeringInfo),
                        )
                        && <>
                            {generateWeightingIndicator()}
                            {validateProject() === true
                            && <Button
                                id={'mqButton'}
                                variant='secondary'
                                additionalVariant='skinless'
                                icon={true}
                                disabled={isOpportunityLive || hasSubmissionEndDatePassed}
                                iconSrc={addIcon}
                                label={'Select Questionnaire'}
                                handleClick={() => history.push(`/questionnaires/select/itt/${projectId}/${eventId}?type=${eventDetails.questionnairesType?.join(',')}`)}/>
                            }
                        </>

                        }
                    </div>

                </div>
                {questionnaires.length > 0
                    ? <Accordion id='mqAccordion' data={generateAccordionData()} open={true} />
                    : <div className='mqContentNone'>
                        {projectDetails?.steeringInfo && rbac.can(
                            rbac.action.editAssignedQuestionnaire,
                            rbac.util.getSteeringGroupRole(authContext.user.id,
                                projectDetails?.steeringInfo),
                        )
                        && <>
                            {validateProject() === true ? <><p className='title body'>No questionnaires selected for this event.</p>
                                <Button
                                    id={'mqButton'}
                                    variant='primary'
                                    label={'Select Questionnaires'}
                                    handleClick={() => history.push(`/questionnaires/select/itt/${projectId}/${eventId}?type=${eventDetails.questionnairesType?.join(',')}`)}
                                /></>
                                : <><p className='title body'>Please add at least one lot before adding questionnaires.</p></>
                            }
                        </>}
                    </div>
                }
            </div>
            {weightageModal && <Modal
                open={true}
                size='small-long'
                headerTitle='Lot Weighting'
                body={generateModalBody(lots)}
                closeModal={() => setWeightageModal(false)}
                helpOption={false}
                footer={false}
            />}
            {lotModal && <Modal
                open={true}
                closeModal={() => handleCancelModal()}
                size={'medium'}
                headerTitle={'Select Lot(s)'}
                secondActionButton={true}
                secondActionButtonTxt='Cancel'
                handleSecondaryActionBtnClick={() => handleCancelModal()}
                handleMainActionBtnClick={() => handleLot(lotTemplate)}
                mainActionButtonTxt='Continue'
                closeButton={true}
                body={generateLotModalBody()}
            />}
        </>
    );
};

export default ManageQuestionnaires;
