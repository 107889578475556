import React, { useEffect, useState } from 'react';

import { useParams, useHistory } from 'react-router-dom';
import GuidanceBanner from '../../components/GuidanceBanner';
import Button from '../../components/Button';
import StatusLabel from '../../components/StatusLabel';
import projectManagementAPIs from '../../services/project-management.service';
import Toast from '../../components/Alerts/Toast/Toast';
import DashboardCard from '../../components/DashboardCard';
import {
    awardStatus, publishAtLevel, documentTypes, awardSummaryLabels, attatchedToTypes,
} from '../../config/constants';
import { handleDownload, handleMultipleDownload } from '../../utils/uploadHelper';
import Constants from './constants';
import TreeViewer from '../../components/TreeViewer/TreeViewer';
import preparePreviousSubmissionsTree from '../../services/previous-submissions.service';
import {
    prepareCurrentSubmissionsTree, getTreeStructure,
} from '../../services/current-submissions.service';
import {
    getResponsesMenu, exportFunction, getBanner,
    getPreviousResponsesMenu, getPreviousLotsMenu,
    getCurrenSubmissionTitle,
} from './helpers';

import Accordion from '../../features/Accordion';
import shareIcon from '../../styles/images/share-icon.png';
import statusCodes from '../../utils/statusCodes';

const OpportunityAwardSummary = () => {
    const history = useHistory();
    const [data, setData] = useState({});
    const [currentResponses, setCurrentResponses] = useState([]);
    const [oldResponses, setOldResponses] = useState([]);
    const { projectId, eventId } = useParams();

    const getAwardSummaryResponse = async () => {
        try {
            const response = await projectManagementAPIs.getAwardSummary(projectId, eventId);
            if (response.status === 200) {
                setData(response.data);
                const awardSummary = response.data;
                if (awardSummary?.isProjectDPS
                    && awardSummary?.publishLevel === publishAtLevel.lot
                    && Array.isArray(awardSummary?.responses)) {
                    const treeData = getTreeStructure(awardSummary);
                    setCurrentResponses(Object.keys(treeData).length > 0
                        ? prepareCurrentSubmissionsTree(treeData, getResponsesMenu) : undefined);
                } else {
                    setCurrentResponses(undefined);
                }
            } else {
                Toast.fire({
                    icon: Constants.errorIcon,
                    titleText: Constants.retrieveInfoError,
                });
            }
        } catch (err) {
            Toast.fire({
                icon: Constants.errorIcon,
                titleText: Constants.retrieveInfoError,
            });
        }
    };

    const getResponsesHistory = async () => {
        try {
            const response = await projectManagementAPIs.getResponsesHistory(projectId, eventId);
            if (response.status === statusCodes.SUCCESS) {
                setOldResponses(
                    Object.keys(response.data).length
                        ? preparePreviousSubmissionsTree(response.data,
                            getPreviousResponsesMenu, getPreviousLotsMenu)
                        : undefined,
                );
            } else if (response.status !== statusCodes.METHOD_NOT_ALLOWED) {
                Toast.fire({
                    icon: Constants.errorIcon,
                    titleText: Constants.retrieveInfoError,
                });
            }
        } catch (err) {
            Toast.fire({
                icon: Constants.errorIcon,
                titleText: Constants.retrieveInfoError,
            });
        }
    };

    useEffect(() => {
        getAwardSummaryResponse();
        getResponsesHistory();
    }, []);

    const getFileNames = (type) => data?.docs?.filter((doc) => doc.documentType === type)
        .map((doc) => ({
            documentID: doc.documentID,
            filename: doc.documentName,
        }));

    const handleIntentAwardDocumentDownload = async (type, attachedToId, attachedToType) => {
        const documents = getFileNames(type);
        if (documents.length === 1) {
            await handleDownload(documents[0], attachedToId, attachedToType);
        } else {
            await handleMultipleDownload(documents, attachedToId, attachedToType);
        }
    };

    const getPrettyResponseStatus = (status) => awardStatus.filter(
        (rStatus) => rStatus.value === status,
    )[0]?.label;

    const handleNewSubmission = () => {
        history.push(`${Constants.answerSummaryDPSUrl}/${projectId}/${eventId}`);
    };

    const generateEvaluation = () => (data
        ? <>
            <div id={'evaluationItem'}>
                <div className='evalationContent'>
                    <p className={'title'}>Evaluation of you tender submission(s) is completed.
                        View each tender document in the Tendering Documents section to see the
                        scores given for each document.</p>
                </div>
                <div id={'awardBtnContainer'}>
                    {data?.docs
                        ?.some((awardDoc) => awardDoc.documentType === documentTypes.AWARD)
                    && <Button
                        id={'tBtn-downloadAwardLetter'}
                        label={awardSummaryLabels.AWARD_LETTER_DOWNLOAD}
                        size='small'
                        additionalVariant='btnFullWidth'
                        handleClick={() => handleIntentAwardDocumentDownload(
                            documentTypes.AWARD, projectId, attatchedToTypes.PROJECT,
                        ) }
                    />}
                    {data?.docs
                        ?.some((awardDoc) => awardDoc.documentType === documentTypes.INTENT)
                    && <Button
                        id={'tBtn-downloadIntentLetter'}
                        label={awardSummaryLabels.INTENT_LETTER_DOWNLOAD}
                        size='small'
                        additionalVariant='btnFullWidth'
                        handleClick={() => handleIntentAwardDocumentDownload(
                            documentTypes.INTENT, projectId, attatchedToTypes.PROJECT,
                        )}
                    />}
                </div>
            </div>
        </> : '');

    const generateContract = () => (data
        ? <>
            <div id={'contractItem'}>
                <div className='contractContent'>
                    <p className={'title'}>Click on the button below to view the
                        contract entry for this opportunity.</p>
                </div>
                <div id={'contractBtnContainer'}>
                    <Button
                        id={'tBtn-viewContract'}
                        label='View Contract'
                        size='small'
                        additionalVariant='btnFullWidth'
                        handleClick={() => history.push(`/contracts/overview/${data?.awardInfo?.contractID}`) }
                    />
                </div>
            </div>
        </> : '');

    return <div id='awardSummaryContainer'>
        <div id='awardSummaryBanner'>
            <GuidanceBanner
                id={'viewOutcomeGuidance'}
                headerText={`Your submission outcome is ${data && !data.recordFound ? 'awaiting' : 'available'}!`}
                bannerText2={data && !data.recordFound
                    ? <></>
                    : <>Please find the tender evaluation results and documents in
                        the section below.</>
                }
            />;
        </div>
        {data.publishLevel === 'project' && data?.responses?.questionnaires?.length > 0
        && <div className='awardSummaryContentContainer'>
            <div className='awardSummaryContent'>
                <div id='buttonDiv'>
                    <Button
                        id='primary-action-btn'
                        type='button'
                        size='regular'
                        variant='primary'
                        additionalVariant='additionalexportbtn'
                        label='Export All'
                        handleClick={() => exportFunction(projectId)}
                    />
                </div>
            </div>
            <div className='awardSummaryContent'>
                <div className='awardSummaryContentSection'>
                    {data.responses.questionnaires.map((questionnaire, idx) => <div className='awardSummaryContentBody' key={questionnaire.responseID}>
                        <div className='awardSummaryQuestionTitle'><span className='title'>{questionnaire.responseName}</span></div>
                        <div className='awardSummaryBtnGroup'>
                            {questionnaire.weightage ? <span className='title'>Total Score : {questionnaire.score}/{questionnaire.weightage}</span>
                                : <span className='title'></span>}
                            <StatusLabel
                                id='questionStatus'
                                color= {data.responses.intentStatus === 'successful' ? 'green' : 'red'}
                                labelTxt={getPrettyResponseStatus(data.responses.intentStatus)}
                            />
                            <Button
                                id={`primary-action-btn-${idx}`}
                                type='button'
                                size='regular'
                                variant='primary'
                                additionalVariant='btnSize'
                                label='View'
                                handleClick={() => history.push(`/questionnaires/answer/view/${questionnaire.responseID}`, {
                                    deadlinePassed: true,
                                })
                                }
                            />
                        </div>
                    </div>)}
                </div>
            </div>
        </div>}

        {!data.isProjectDPS && data.publishLevel === publishAtLevel.lot
        && data?.responses?.length > 0
        && <div className='awardSummaryContentContainer' >
            <div className='awardSummaryContent'>
                <div id='buttonDiv'>
                    {/* {data.recordFound && data.isProjectDPS && (
                        <Button
                            id='primary-action-btn'
                            type='button'
                            size='x-large'
                            variant='secondary'
                            additionalVariant='additionalnewsubmissionbtn'
                            label={Constants.newSubmissionBtn}
                            handleClick={handleNewSubmission}
                        />
                    )} */}
                    <Button
                        id='primary-action-btn'
                        type='button'
                        size='regular'
                        variant='primary'
                        additionalVariant='additionalexportbtn'
                        label='Export All'
                        handleClick={() => exportFunction(projectId)}
                    />
                </div>
            </div>
            <Accordion
                id='lotAccordion'
                data={data.responses.map((lots, index) => ({
                    sectionId: `lot-${index}`,
                    header: <div className='awardSummaryQuestionTitle'>
                        <p className='title'>{`Lot${index + 1} : ${lots.lotTitle}`}</p>
                        <div className='awardSummaryQuestionScore'>
                            <span className='title'> {lots?.score && lots.weightage
                            && `Total Score : ${lots.score}/${lots.weightage}`}</span>
                            <StatusLabel
                                id='questionStatus'
                                color= {lots.intentStatus === 'successful' ? 'green' : 'red'}
                                labelTxt={getPrettyResponseStatus(lots.intentStatus)}
                            />
                        </div>
                    </div>,
                    content: <>
                        {lots.questionnaires.map((question, idx) => <div id='accordionContentBody' key={`question${idx}`}>
                            <div className='awardSummaryQuestionTitle'>
                                <p className='title'>{question.sharedQuestion && <img src={shareIcon} alt={'shared'} id='share-icon'></img>}{question.responseName}</p>
                            </div>
                            <div className='awardSummaryBtnGroup'>
                                {question.weightage ? <span className='title'>Total Score : {question.score}/{question.weightage}</span>
                                    : <span className='title'></span>}
                                <Button
                                    id={`primary-action-btn-${idx}`}
                                    type='button'
                                    size='regular'
                                    variant='primary'
                                    additionalVariant='btnSize'
                                    label={'View'}
                                    handleClick={() => history.push(`/questionnaires/answer/view/${question.responseID}`, {
                                        deadlinePassed: true,
                                    })}
                                />
                            </div>
                        </div>)}
                    </>,
                }))}
                open={true}/>
        </div>}

        {(currentResponses?.length > 0 || oldResponses?.length > 0)
            && <>
                {currentResponses?.length
                    && <>
                        {getBanner(data, handleNewSubmission, projectId)}
                        {getCurrenSubmissionTitle(data.tenderSubmittedAt)}
                        <TreeViewer classAdded='current-responses-tree' data={currentResponses} hasSearch={false}/>
                    </>
                }
                {oldResponses?.length
                    && <TreeViewer classAdded='previous-responses-tree' data={oldResponses} hasSearch={false}/>
                }
            </>
        }
        <div className='awardSummaryOutcome'>
            {data?.docs?.length > 0
            && <DashboardCard
                id='evaluationOutcome'
                size='small'
                header={true}
                headerTitle={'Evaluation Outcome'}
                content={generateEvaluation()}
                footer={false}
            />}
            {data?.awardInfo?.contractID
            && <DashboardCard
                id='contractOutcome'
                size='small'
                header={true}
                headerTitle={'Contract Overview'}
                content={generateContract()}
                footer={false}
            />}
        </div>
    </div>;
};

export default OpportunityAwardSummary;
