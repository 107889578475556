import React from 'react';
import PropTypes from 'prop-types';
import { sanitizeHtml, isHTML, base64ToHtml } from '../../services/html.service';
import helperFunctions from '../../utils/helperFunctions';
import Constants from './constants';
import LabelledRadioButton from '../../components/LabelledRadioButton';
import {
    evaluationPanelTypes, evaluationPanel, responseStatus, VIEW_ANSWER_MODE,
} from '../../config/constants';
import { handleDownload, handleMultipleDownload } from '../../utils/uploadHelper';

const getVersionsStructure = (options) => options?.map((option) => ({
    ...option,
    ...!option.type && { type: Constants.PREVIOUS_VERSIONS_QUESTIONNAIRE_TYPE },
}));

const haveVersions = (versions, type) => {
    const structuredVersions = getVersionsStructure(versions);
    return structuredVersions?.some((version) => version.type === type);
};

const getDescriptionBlock = (description, className = '') => {
    const decodedHtml = base64ToHtml(description);

    return <>
        { isHTML(decodedHtml)
            ? <div className={`caption formatted-html ${className}`} dangerouslySetInnerHTML={sanitizeHtml(decodedHtml)}></div>
            : <p className='body justify'>{helperFunctions.simpleFormat(description || '-', 'p', 'caption', true)}</p>
        }
    </>;
};
const downloadAttachedFiles = async (event, files, responseId) => {
    event.preventDefault();
    const { response } = Constants.attachedToType;
    if (!files) return;
    if (files.length > 1) {
        handleMultipleDownload(files, responseId, response);
    } else {
        handleDownload(files[0], responseId, response);
    }
};
const EvaluationPanelRadioButtonGroup = ({
    questionnaireDetails,
    handleChange,
}) => <LabelledRadioButton
    id='evaluationPanel'
    label={evaluationPanel}
    breakColumn={false}
    onChange={handleChange}
    required={true}
    options={[{
        label: evaluationPanelTypes.individual.label,
        value: evaluationPanelTypes.individual.value,
        id: evaluationPanelTypes.individual.id,
        name: 'evaluationPanel',
        required: true,
        checked: questionnaireDetails.evaluationPanel
            ? questionnaireDetails.evaluationPanel === evaluationPanelTypes.individual.value
            : true,
    }, {
        label: evaluationPanelTypes.consensus.label,
        value: evaluationPanelTypes.consensus.value,
        id: evaluationPanelTypes.consensus.id,
        name: 'evaluationPanel',
        required: true,
        checked: questionnaireDetails.evaluationPanel
            ? questionnaireDetails.evaluationPanel === evaluationPanelTypes.consensus.value
            : false,
    }]}
    renderAsRow={true} />;

EvaluationPanelRadioButtonGroup.propTypes = {
    questionnaireDetails: PropTypes.object.isRequired,
    handleChange: PropTypes.func.isRequired,
};

const isBidSubmitted = (documentMode, documentState) => documentMode === VIEW_ANSWER_MODE
    && documentState?.bidStatus === responseStatus.bidSubmitted;

export {
    getVersionsStructure,
    haveVersions,
    getDescriptionBlock,
    downloadAttachedFiles,
    EvaluationPanelRadioButtonGroup,
    isBidSubmitted,
};
