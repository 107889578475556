import { format } from 'date-fns';
import { saveAs } from 'file-saver';
// import instance from '../config/axios.instance';

import { isEmpty } from 'lodash';
import Toast from '../components/Alerts/Toast/Toast';

import { maxUploadSize, multipleDownloadsFilename, noDocumentsToDownload } from '../config/constants';

import gatewayAPIs from '../services/gateway.service';
import documentManagementAPIs from '../services/document-management.service';

import fileReader from './fileReader';
import ToastConstants from '../components/Alerts/Toast/constants';

const defaultUploadOptions = {
    accessGroups: [],
    uploadCallback: () => {},
    successCallback: () => {},
    failCallback: () => {},
    validationCallback: () => true,
    questionnaire: false,
    sectionNumber: undefined,
    questionNumber: undefined,
    documentInfo: 'N/A',
    contract: false,
};

const defaultDeleteOptions = {
    questionnaire: false,
    sectionNumber: undefined,
    questionNumber: undefined,
    deleteCallback: () => {},
};

const validateFileSize = (file) => file.size <= maxUploadSize;

const handleDocumentSubmit = async (e, document, authContext, opts) => {
    const options = {
        ...defaultUploadOptions,
        ...opts,
    };

    if (!document.fileUpload) {
        alert('Please upload a file');
        return;
    }

    e.preventDefault();

    const { fileUpload } = document;
    const files = Array.from(fileUpload);

    const metadata = [];
    const formdata = new FormData();

    try {
        files.forEach((file) => {
            if (!validateFileSize(file)) {
                throw new Error(`File '${file.name}' was larger than ${maxUploadSize / 1000 / 1000}MB`);
            }

            formdata.append('files', file);

            metadata.push({
                filename: file.name,
                description: document.description || '',
                documentName: file.name,
                documentInfo: options.documentInfo, // e.g. SPEND / N/A
                accessGroups: options.accessGroups,
                expiryDate: document.expiryDate ? `${format(document.expiryDate, 'dd-MM-yyyy')}` : '',
                isLegacy: false,

                // These fields are now redundant
                // documentDescription: document.description || 'TODO',
                // accountID: authContext.user.accountId,
                // uploadedBy: authContext.user.id,
                // userName: `${authContext.user.firstName } ${authContext.user.lastName}`,
                // filename: newFilename,
            });
        });
    } catch (error) {
        Toast.fire({
            icon: 'error',
            titleText: `Unable to upload document. ${error.message}`,
        });

        if (options.failCallback) {
            options.failCallback(files, metadata);
        }

        return;
    }


    formdata.append('metadata', JSON.stringify(metadata));

    try {
        if (await options.validationCallback(metadata)) {
            if (authContext.user.id) {
                const uploadRequest = await gatewayAPIs.upload(formdata);

                if (uploadRequest.status === 200) {
                    if (options.uploadCallback) {
                        if (options.questionnaire) {
                            options.uploadCallback(
                                [
                                    ...uploadRequest.data,
                                ],
                                options.sectionNumber,
                                options.questionNumber,
                            );
                        } else if (options.contract) {
                            options.uploadCallback([...uploadRequest.data], options.contract);
                        } else {
                            options.uploadCallback([
                                ...uploadRequest.data,
                            ]);
                        }
                    }

                    if (options.successCallback) {
                        options.successCallback([
                            ...uploadRequest.data,
                        ], files, metadata);
                    }
                } else {
                    Toast.fire({
                        icon: 'error',
                        titleText: 'Unable to upload document. Upload failed.',
                    });

                    if (options.failCallback) {
                        options.failCallback(files, metadata);
                    }
                }
            } else {
                Toast.fire({
                    icon: 'error',
                    titleText: 'Unable to upload document. User not found.',
                });

                if (options.failCallback) {
                    options.failCallback(files, metadata);
                }
            }
        }
    } catch (error) {
        Toast.fire({
            icon: 'error',
            titleText: `Unable to upload document. ${error}`,
        });

        if (options.failCallback) {
            options.failCallback(files, metadata);
        }
    }
};

const handleRemoveDocument = (documents, document, setDocuments, opts = {}) => {
    const options = {
        ...defaultDeleteOptions,
        ...opts,
    };

    setDocuments(documents.filter((e) => (e) !== document));

    if (options.deleteCallback && !options.questionnaire) {
        options.deleteCallback(document);
    }

    if (options.deleteCallback && options.questionnaire) {
        options.deleteCallback(document, options.sectionNumber, options.questionNumber);
    }
};

function click(node) {
    try {
        node.dispatchEvent(new MouseEvent('click'));
    } catch (e) {
        const evt = document.createEvent('MouseEvents');
        // monitor initMoustEvent, may need to use new MouseEvent in future if support stops
        evt.initMouseEvent('click', true, true, window, 0, 0, 0, 80,
            20, false, false, false, false, 0, null);
        node.dispatchEvent(evt);
    }
}

const downloadUrl = async (url, filename) => {
    try {
        const element = document.createElement('a');
        element.setAttribute('type', 'hidden');
        element.href = url;
        element.download = filename;

        // tabnabbing:
        // I think the risk of not having the rel attribute
        // is mitigated by removing the target='_blank' attribute
        // element.rel = 'noopener noreferrer';
        // element.target = '_blank';

        document.body.appendChild(element);
        click(element);
        element.parentNode?.removeChild(element);
    } catch (err) {
        throw new Error();
    }
};

/**
 * Handler for when user downloads a single file
 * @param {Object} document should contain at least documentID and filename
 * @param {string} attachedToId ID of the element this document is related to
 * @param {string} attachedToType determines what the previously provided id is, for the
 *      backend to process accordingly. check attachedToType in DocumentBuilder/constants.js
 */
const handleDownload = async (document, attachedToId, attachedToType) => {
    try {
        const { documentID, filename } = document;
        const response = await documentManagementAPIs.getSignedUrl(
            documentID, attachedToId, attachedToType,
        );
        // const response = await documentManagementAPIs.getByDocumentID(documentID);
        if (response.status === 200) {
            try {
                // const blob = new Blob([response.data], {
                //     type: response.headers['content-type'] || 'application/octet-stream',
                // });

                await downloadUrl(response.data, filename);
            } catch (error) {
                Toast.fire({
                    icon: 'error',
                    titleText: 'Unable to download document.',
                });
            }
        } else {
            const { data } = response;
            const file = await fileReader(data);
            const { ErrorCode } = JSON.parse(file);
            if (ErrorCode === '8000') {
                Toast.fire({
                    icon: 'error',
                    titleText: 'Uploaded document is in process, please try after sometime.',
                });
            } else {
                Toast.fire({
                    icon: 'error',
                    titleText: 'Unable to download document.',
                });
            }
        }
    } catch (error) {
        Toast.fire({
            icon: 'error',
            titleText: 'Unable to download document.',
        });
    }
};

const handleEvaluationMultipleDownload = async (documentsIds) => {
    try {
        if (isEmpty(documentsIds.noLots) && isEmpty(documentsIds.withLots)) {
            Toast.fire({
                icon: ToastConstants.error,
                titleText: noDocumentsToDownload,
            });
            return;
        }

        const response = await documentManagementAPIs.getAllByDocumentIDInSubfolders(documentsIds);

        if (response.status === 200) {
            try {
                const blob = new Blob([response.data], {
                    type: response.headers['content-type'] || 'application/octet-stream',
                });
                saveAs(blob, multipleDownloadsFilename);
            } catch (error) {
                Toast.fire({
                    icon: 'error',
                    titleText: 'Unable to download documents.',
                });
            }
        } else {
            const { data: resData } = response;
            const file = await fileReader(resData);
            const { ErrorCode } = JSON.parse(file);
            if (ErrorCode === '8000') {
                Toast.fire({
                    icon: 'error',
                    titleText: 'Uploaded document is in process, please try after sometime.',
                });
            } else {
                Toast.fire({
                    icon: 'error',
                    titleText: 'Unable to download document.',
                });
            }
        }
    } catch (error) {
        Toast.fire({
            icon: 'error',
            titleText: 'Unable to download documents.',
        });
    }
};

/**
 * Handler for when user downloads a batch of files
 * @param {Object[]} documents each document should contain at least documentID
 * @param {string} attachedToId ID of the element these documents are related to
 * @param {string} attachedToType determines what the previously provided id is, for the
 *      backend to process accordingly. check attachedToType in DocumentBuilder/constants.js
 */
const handleMultipleDownload = async (documents, attachedToId, attachedToType) => {
    try {
        const docs = [];
        documents.forEach((document) => {
            docs.push(document.documentID);
        });
        // getAllByDocumentID
        const response = await documentManagementAPIs.getAllByDocumentID(
            docs, attachedToId, attachedToType,
        );

        if (response.status === 200) {
            try {
                const blob = new Blob([response.data], {
                    type: response.headers['content-type'] || 'application/octet-stream',
                });
                saveAs(blob, multipleDownloadsFilename);
            } catch (error) {
                Toast.fire({
                    icon: 'error',
                    titleText: 'Unable to download documents.',
                });
            }
        } else {
            const { data: resData } = response;
            const file = await fileReader(resData);
            const { ErrorCode } = JSON.parse(file);
            if (ErrorCode === '8000') {
                Toast.fire({
                    icon: 'error',
                    titleText: 'Uploaded document is in process, please try after sometime.',
                });
            } else {
                Toast.fire({
                    icon: 'error',
                    titleText: 'Unable to download document.',
                });
            }
        }
    } catch (error) {
        Toast.fire({
            icon: 'error',
            titleText: 'Unable to download documents.',
        });
    }
};

const mergeUploadedDocuments = (originalDocs, newDocs, extraAttributes = {}) => {
    const mergedDocs = [];

    // Check for array type and length
    if (Array.isArray(originalDocs)) {
        originalDocs.forEach((doc) => {
            mergedDocs.push({
                documentID: doc.documentID,
                filename: doc.filename,
            });
        });
    }

    // Iterate over new incoming docs, append any new documents by documentID
    newDocs?.forEach((doc) => {
        const isExistingDoc = mergedDocs.some(
            (existingDoc) => existingDoc.documentID === doc.documentID,
        );

        if (!isExistingDoc) {
            mergedDocs.push({
                documentID: doc.documentID,
                filename: doc.filename, // to be removed when Warren updates the hydration logic
                ...extraAttributes,
            });
        }
    });

    // Return the merged document array
    return mergedDocs;
};

const cleanupDeletedDocument = (originalDocs, deletedDoc) => {
    let mergedDocs = [];

    if (Array.isArray(originalDocs)) {
        mergedDocs = originalDocs.filter((doc) => doc.documentID !== deletedDoc.documentID);
    }

    return mergedDocs;
};

const persistUploadedDocuments = async (verb, handler, successCallback, failCallback) => {
    try {
        const response = await handler();

        let pastTense = `${verb}ed`;

        switch (verb) {
            case 'upload': {
                pastTense = 'uploaded';
                break;
            }
            case 'delete': {
                pastTense = 'deleted';
                break;
            }
            default: {
                pastTense = `${verb}ed`;
                break;
            }
        }

        if ((response.status && response.status === 200) || response === true) {
            Toast.fire({
                icon: 'success',
                titleText: `Document ${pastTense} successfully.`,
            });

            if (successCallback) {
                return successCallback(response);
            }
        } else {
            Toast.fire({
                icon: 'error',
                titleText: `Unable to ${verb} document.`,
            });

            if (failCallback) {
                return failCallback(response);
            }
        }
    } catch (error) {
        Toast.fire({
            icon: 'error',
            titleText: `Unable to ${verb} document.`,
        });

        if (failCallback) {
            return failCallback(error);
        }
    }

    return undefined;
};

const deleteDocument = async (documentID, successCallback, failCallback) => {
    const deleteFile = await documentManagementAPIs
        .deleteDocument(documentID);

    if (deleteFile.status === 200) {
        if (successCallback) {
            return successCallback();
        }
        Toast.fire({
            icon: 'success',
            title: 'Document deleted successfully.',
        });
    } else {
        Toast.fire({
            icon: 'error',
            title: 'Unable to delete document.',
        });
        if (failCallback) {
            return failCallback();
        }
    }

    return undefined;
};

export {
    mergeUploadedDocuments,
    cleanupDeletedDocument,
    persistUploadedDocuments,
    deleteDocument,
    handleDocumentSubmit,
    handleDownload,
    handleMultipleDownload,
    handleRemoveDocument,
    handleEvaluationMultipleDownload,
};
