import {
    isValid, parse, parseISO, format, isEqual, isBefore, isAfter,
} from 'date-fns';
import { saveAs } from 'file-saver';
import { pluralize } from 'inflected';
import * as parseHtml from 'html-react-parser';
import { v4 } from 'uuid';
import Constants from '../features/DocumentBuilder/constants';
import { evaluationStatuses, evaluationPanelTypes } from '../config/constants';

const relativeTime = (date, refDate, dateFormats, timeUnits) => {
    const readableDateFormats = dateFormats || {
        past: [
            { ceiling: 60, text: '$seconds seconds ago' },
            { ceiling: 3600, text: '$minutes minutes ago' },
            { ceiling: 86400, text: '$hours hours ago' },
            { ceiling: 2629744, text: '$days days ago' },
            { ceiling: 31556926, text: '$months months ago' },
            { ceiling: null, text: '$years years ago' },
        ],
        future: [
            { ceiling: 60, text: 'in $seconds seconds' },
            { ceiling: 3600, text: 'in $minutes minutes' },
            { ceiling: 86400, text: 'in $hours hours' },
            { ceiling: 2629744, text: 'in $days days' },
            { ceiling: 31556926, text: 'in $months months' },
            { ceiling: null, text: 'in $years years' },
        ],
    };

    const readableTimeUnits = timeUnits || [
        [31556926, 'years'],
        [2629744, 'months'],
        [86400, 'days'],
        [3600, 'hours'],
        [60, 'minutes'],
        [1, 'seconds'],
    ];

    const previousDate = new Date(date);
    const referranceDate = refDate ? new Date(refDate) : new Date();
    let secondsDifference = (referranceDate - previousDate) / 1000;

    let tense = 'past';
    if (secondsDifference < 0) {
        tense = 'future';
        secondsDifference = 0 - secondsDifference;
    }

    const getFormat = () => {
        for (let i = 0; i < readableDateFormats[tense].length; i++) {
            if (readableDateFormats[tense][i]
                .ceiling == null || secondsDifference <= readableDateFormats[tense][i].ceiling) {
                return readableDateFormats[tense][i];
            }
        }
        return null;
    };

    const getTimeBreakdown = () => {
        let seconds = secondsDifference;
        const breakdown = {};
        for (let i = 0; i < readableTimeUnits.length; i++) {
            const occurencesOfUnit = Math.floor(seconds / readableTimeUnits[i][0]);
            seconds -= (readableTimeUnits[i][0] * occurencesOfUnit);
            breakdown[readableTimeUnits[i][1]] = occurencesOfUnit;
        }
        return breakdown;
    };

    const depluralizeTimeAgoText = (timeAgoText, ...args) => {
        let timeAgo = timeAgoText;
        const obj = { ...args };
        Object.keys(obj).forEach((i) => {
            if (i === 1) {
                const regexp = new RegExp(`\\b${i}\\b`);
                timeAgo = timeAgoText.replace(regexp, () => args[0].replace(/s\b/g, ''));
            }
        });
        return timeAgo;
    };

    const renderDate = (dateFormat) => {
        const breakdown = getTimeBreakdown();
        const timeAgoText = dateFormat.text.replace(/\$(\w+)/g, (...args) => breakdown[args[1]]);
        return depluralizeTimeAgoText(timeAgoText, breakdown);
    };

    return renderDate(getFormat());
};

const formatDate = (dateString) => {
    const allDate = dateString.split('T');
    const thisDate = allDate[0].split('-');
    const thisTime = allDate[1];
    const newDate = [thisDate[2], thisDate[1], thisDate[0]].join('/');
    const newdateformat = `${newDate} ${thisTime}`;
    return relativeTime(newdateformat);
};

/**
 * Function to formate data in a user-friendly format.
 * e.g 3 hours ago, 1 day ago, 2 months ago etc.
 * @param {*} dateTime datatime to format
 */
const getRelativeTime = (dateTime) => formatDate(dateTime);

/**
 * Function to split array into chunks as needed by the Pagination feature
 * @param {array} dataSet - Array to split into array chunks
 * @param {number} numberPerPage - Number of elements required in a single page
 */
const splitDataSetForPagination = (dataSet, numberPerPage) => []
    .concat(dataSet
        .map((_elem, i) => (i % numberPerPage === 0 ? [dataSet.slice(i, i + numberPerPage)] : []))
        .filter((data) => data.length > 0));

/**
 * Function to convert array of objects into array as needed to create select dropdown options
 * @param {array} dataToProcess - Array of objects to process
 * @param {string} attributeLvl1 - Attribute whose value the function should capture
 * @param {string || null} attributeLvl2 - Sub-attribute whose value the function should capture.
 * Useful for when the attribute whose value is needed is within an object
 */
const constructDropdownData = (dataToProcess, attributeLvl1, attributeLvl2) => {
    const dropdownData = [];
    dataToProcess?.forEach((data) => {
        if (attributeLvl2) {
            if (data[attributeLvl1] && data[attributeLvl1][attributeLvl2]) {
                dropdownData.push({
                    label: data[attributeLvl1][attributeLvl2],
                    value: data[attributeLvl1][attributeLvl2],
                });
            }
        } else if (data[attributeLvl1]) {
            dropdownData.push({
                label: data[attributeLvl1],
                value: data[attributeLvl1],
            });
        }
    });
    return [null, ...new Map(dropdownData.map((data) => [data.label, data])).values()];
};

const groupByKey = (xs, key) => xs?.reduce((rv, x) => {
    // eslint-disable-next-line no-param-reassign
    (rv[x[key]] = rv[x[key]] || []).push(x);
    return rv;
}, {});

/**
 *
 * @param {Array} dataList -array of data which need to group
 * @param {string} key - by this key array is grouped
 * @returns grouped dropdown data
 * dataList must contain the item value in the field name and value
 */
const constructGroupedDropdownData = (dataList, key, customKey) => {
    const groupedData = groupByKey(dataList, key) || [];
    const dataKeys = Object.keys(groupedData);
    const dataValues = Object.values(groupedData);
    const optionList = [];
    if (dataKeys && dataKeys.length > 0) {
        for (let i = 0; i < dataKeys.length; i++) {
            const itemList = [];
            for (let j = 0; j < dataValues[i].length; j++) {
                itemList.push({
                    value: dataValues[i][j].value,
                    name: dataValues[i][j].name,
                    accountID: dataValues[i][j].accountID,
                    departmentID: dataValues[i][j].departmentID,
                });
            }
            optionList.push({
                name: dataValues[i][0][customKey || key],
                type: 'group',
                items: itemList?.sort((a, b) => a.name.localeCompare(b.name)),

            });
        }
    }
    return optionList;
};

const backendDataMapper = (dataSchema, backendResponseData) => {
    let returnData = {};
    if (dataSchema.infoHeader) {
        returnData = {
            infoHeader: {
                ...dataSchema.infoHeader,
                itemInfo: backendResponseData[dataSchema.infoHeader.itemInfoMasterDataAttribute],
                referenceNumber: backendResponseData[dataSchema.infoHeader
                    .referenceNumberMasterDataAttribute],
            },
            panelData: {
                ...dataSchema.panelData,
                document: backendResponseData[dataSchema.panelData.documentMasterDataAttribute],
                faq: backendResponseData[dataSchema.panelData.faqMasterDataAttribute],
            },
            data: [],
        };

        // if (!backendResponseData[dataSchema.infoHeader.referenceNumberMasterDataAttribute]) {
        //     returnData.infoHeader.referenceNumber = dataSchema.infoHeader.referenceNumber;
        //     returnData.infoHeader.itemInfo = dataSchema.infoHeader.itemInfo;
        // }
    } else {
        returnData = {
            data: [],
        };
    }

    dataSchema.data.forEach((dataItem, dataKey) => {
        const contentData = [];
        if (!contentData.header) {
            contentData.header = dataItem.header;
        }
        const backendData = [];
        dataItem.content.forEach((item, key) => {
            if (dataItem.content[key].masterDataAttribute.constructor === ({}).constructor) {
                let instance = {};
                try {
                    const objectToProcess = backendResponseData[Object
                        .keys(dataItem.content[key].masterDataAttribute)];
                    const valueToMap = objectToProcess[Object
                        .values(dataItem.content[key].masterDataAttribute)];
                    instance = ({
                        label: dataSchema.data[dataKey].content[key].label,
                        value: valueToMap,
                    });
                } catch {
                    instance = ({
                        label: dataSchema.data[dataKey].content[key].label,
                        value: dataSchema.data[dataKey].content[key].value,
                    });
                }
                backendData.push(instance);
            } else {
                let instance = {};
                if (dataItem.content[key].masterDataAttribute) {
                    instance = ({
                        label: dataSchema.data[dataKey].content[key].label,
                        value: backendResponseData[
                            dataSchema.data[dataKey].content[key].masterDataAttribute],
                    });
                    if (!backendResponseData[
                        dataSchema.data[dataKey].content[key].masterDataAttribute]) {
                        instance.value = dataSchema.data[dataKey].content[key].value;
                    }
                    backendData.push(instance);
                } else {
                    instance = ({
                        label: dataSchema.data[dataKey].header[key],
                        value: dataSchema.data[dataKey].content[key].value,
                    });
                    backendData.push(instance);
                }
            }
        });
        contentData.content = backendData;
        returnData.data.push(contentData);
    });
    return { returnData };
};

const procurementRequestOverviewBackendDataMapper = (dataSchema, backendResponseData) => {
    const contentData = dataSchema.data[0].content;
    const backendData = [];
    contentData.forEach((item, key) => {
        let instance = {};
        if (contentData[key].masterDataAttribute) {
            instance = ({
                label: dataSchema.data[0].content[key].label,
                value: backendResponseData[dataSchema.data[0].content[key].masterDataAttribute],
            });
            if (!backendResponseData[dataSchema.data[0].content[key].masterDataAttribute]) {
                instance.value = dataSchema.data[0].content[key].value;
            }

            backendData.push(instance);
        } else {
            instance = ({
                label: dataSchema.data[0].content[key].label,
                value: dataSchema.data[0].content[key].value,
            });
            backendData.push(instance);
        }
    });
    const returnData = {
        infoHeader: {
            ...dataSchema.infoHeader,
            itemInfo: backendResponseData[dataSchema.infoHeader.itemInfoMasterDataAttribute],
            referenceNumber: backendResponseData[dataSchema.infoHeader
                .referenceNumberMasterDataAttribute],
        },
        data: [{
            header: 'Key Information',
            content: [
            ],

        }],
    };

    if (!backendResponseData[dataSchema.infoHeader.referenceNumberMasterDataAttribute]) {
        returnData.infoHeader.referenceNumber = dataSchema.infoHeader.referenceNumber;
        returnData.infoHeader.itemInfo = dataSchema.infoHeader.itemInfo;
    }
    returnData.data[0].content = backendData;
    return { returnData };
};

const sortNotificationsData = (dataToSort) => {
    const dataToProcess = [...dataToSort];
    if (dataToProcess.length > 0) {
        const sortedDataSet = dataToProcess.sort((a, b) => {
            if (isValid(parse(a.timeSent, 'dd-MM-yyyy\'T\'HH:mm:ss', new Date()))
                && isValid(parse(b.timeSent, 'dd-MM-yyyy\'T\'HH:mm:ss', new Date()))) {
                const dateA = parse(a.timeSent, 'dd-MM-yyyy\'T\'HH:mm:ss', new Date());
                const dateB = parse(b.timeSent, 'dd-MM-yyyy\'T\'HH:mm:ss', new Date());
                if (Date.parse(dateA) && Date.parse(dateB)) {
                    return new Date(dateB) - new Date(dateA);
                }
            }
            return [];
        });
        return sortedDataSet;
    }
    return [];
};

const getSubmissionStatus = (data) => {
    const sealed = data.information.filter((i) => i.submissionStatus.replace(/\s/g, '').toLowerCase() === 'sealed');
    const sealopened = data.information.filter((i) => i.submissionStatus.replace(/\s/g, '').toLowerCase() === 'sealopened');
    const released = data.information.filter((i) => i.submissionStatus.replace(/\s/g, '').toLowerCase() === 'released');
    const underevaluation = data.information.filter((i) => i.submissionStatus.replace(/\s/g, '').toLowerCase() === 'underevaluation');
    const stage1approvalpending = data.information.filter((i) => i.submissionStatus.replace(/\s/g, '').toLowerCase() === 'stage1approvalpending');
    const evaluationcomplete = data.information.filter((i) => i.submissionStatus.replace(/\s/g, '').toLowerCase() === 'evaluationcomplete');
    const evaluationrejected = data.information.filter((i) => i.submissionStatus.replace(/\s/g, '').toLowerCase() === 'evaluationrejected');

    if (sealed.length) {
        return 'Sealed';
    }
    if (sealopened.length) {
        return 'Seal Opened';
    }
    if (released.length) {
        return 'Released';
    }
    if (underevaluation.length) {
        return 'Under Evaluation';
    }
    if (stage1approvalpending.length) {
        return 'Stage 1 Approval Pending';
    }
    if (evaluationcomplete.length) {
        return 'Evaluation Complete';
    }
    if (evaluationrejected.length) {
        return 'Evaluation Rejected';
    }
    return '';
};

const prepareDocumentBuilderState = (context, serviceRes, templateId) => {
    const prepareState = {
        sections: [],
    };

    switch (context.toLowerCase()) {
        case 'questionnaire':
            prepareState.templateId = templateId;
            if (serviceRes.projectID) prepareState.projectId = serviceRes.projectID;
            if (serviceRes.responseID) prepareState.responseId = serviceRes.responseID;
            if (serviceRes.eventID) prepareState.eventId = serviceRes.eventID;
            if (serviceRes.versions) prepareState.versions = serviceRes.versions;
            if (serviceRes.versionOf) prepareState.versionOf = serviceRes.versionOf;
            if (serviceRes.responseversions) {
                prepareState.responseversions = serviceRes.responseversions;
            }
            if (serviceRes.versionsNo) prepareState.versionsNo = serviceRes.versionsNo;
            if (serviceRes.versionReason) prepareState.versionReason = serviceRes.versionReason;
            if (serviceRes.supplierInfo) prepareState.supplierInfo = serviceRes.supplierInfo;
            if (serviceRes.projectInfo) prepareState.projectInfo = serviceRes.projectInfo;
            prepareState.isPreviousSubmission = (typeof serviceRes?.supplierSubmissionNumber === 'number');
            prepareState.bidStatus = serviceRes.bidStatus;

            prepareState.details = {
                templateName: serviceRes.templateName,
                supplierUserID: serviceRes.supplierUserID,
                projectID: serviceRes.projectID,
                description: serviceRes.description || serviceRes.templateDescription,
                isPublic: serviceRes.isPublic,
                isDisabled: serviceRes.isDisabled,
                templateType: serviceRes.templateType,
                maxScore: serviceRes.maxScore ? parseInt(serviceRes.maxScore, 10) : 0,
                minScore: serviceRes.minScore ? parseInt(serviceRes.minScore, 10) : 0,
                sectionsWeighted: serviceRes.sectionsWeighted,
                showWeightings: serviceRes.showWeightings,
                templateStatus: serviceRes.templateStatus,
                responseStatus: serviceRes.responseStatus || undefined,
                shareQuestionnaire: (serviceRes.isPublic || serviceRes.sharedWith?.length > 0) ? 'true' : 'false',
                shareQuestionnaireWithEveryone: serviceRes.isPublic ? 'true' : 'false',
                sharedWith: serviceRes.sharedWith?.map((el) => el.accountID),
                responseType: serviceRes.responseType,
                sharedWithName: serviceRes.sharedWith?.map((el) => el.accountName),
                sharedBy: serviceRes.sharedBy,
                weightage: serviceRes.weightage || undefined,
                accountIDs: serviceRes.accountID || serviceRes.account,
                previousVersions: serviceRes.previousVersions,
                evaluationPanel: serviceRes.evaluationPanel
                || evaluationPanelTypes.individual.value,
            };
            // if (serviceRes.responseID) prepareState.details.weightage = serviceRes.weightage;
            prepareState.sections = serviceRes.sections?.map((section) => {
                const prepareSection = {};
                prepareSection.id = section.id;
                prepareSection.title = section.title;
                prepareSection.description = section.description;
                prepareSection.weightage = section.weightage || undefined;
                prepareSection.sectionItems = section.questions.map((question) => {
                    const prepareQuestion = {
                        ...question,
                        attachments: question.attachments ? question.attachments : [],
                        responseType: question.responseType,
                    };
                    return prepareQuestion;
                });
                return prepareSection;
            });
            break;
        case 'section':
            prepareState.sectionID = templateId;
            prepareState.details = {
                name: '',
                templateType: serviceRes.templateType,
            };
            prepareState.sections = [{
                title: serviceRes.title,
                description: serviceRes.description,
            }];
            prepareState.sections[0].sectionItems = serviceRes.questions.map((question) => {
                const prepareQuestion = {
                    ...question,
                    attachments: question.attachments ? question.attachments : [],
                };
                return prepareQuestion;
            });
            break;
        case 'workflow':
            prepareState.templateId = templateId;
            prepareState.decision = serviceRes.decision;
            prepareState.details = {
                name: serviceRes.workflowName,
                description: serviceRes.workflowDescription,
                type: serviceRes.workflowType,
                sharedWith: serviceRes.sharedWith?.map((el) => el.accountID),
                sharedWithName: serviceRes.sharedWith?.map((el) => el.accountName),
                shareWorkflow: (serviceRes.isPublic || serviceRes.sharedWith?.length > 0) ? 'true' : 'false',
                shareWorkflowWithEveryone: serviceRes.isPublic ? 'true' : 'false',
                route: serviceRes.route || '',
                freezeRoute: serviceRes.freezeRoute,
                accountIDs: serviceRes.accountID || serviceRes.account,
            };
            prepareState.sections = serviceRes.stages?.map((stage) => {
                const prepareStage = {};
                prepareStage.name = stage.stageName;
                prepareStage.category = stage.category;
                prepareStage.sectionItems = stage.events.sort((a, b) => a.orderNo - b.orderNo)
                    .map((event) => {
                        const prepareEvent = {
                            ...event,
                        };
                        prepareEvent.eventDuration = parseInt(event.eventDuration, 10);
                        prepareEvent.startDateReminder = event.startDateReminderFlag
                            ? parseInt(event.startDateReminder, 10)
                            : undefined;
                        prepareEvent.endDateReminder = event.endDateReminderFlag
                            ? parseInt(event.endDateReminder, 10)
                            : undefined;
                        return prepareEvent;
                    });
                return prepareStage;
            });
            break;
        case 'response':
            prepareState.responseID = templateId;
            prepareState.details = {
                previousVersions: serviceRes.previousVersions,
            };
            prepareState.sections = serviceRes.sections.map((section) => {
                const prepareSection = {};
                prepareSection.title = section.title;
                prepareSection.id = section.id;
                prepareSection.sectionItems = section.questions.map((question) => {
                    const prepareQuestion = {
                        title: question.questionTitle,
                        questionAnswer: question.questionAnswer,
                        supportingAnswerDocuments: question?.supportingAnswerDocuments || [],
                        responseType: question.responseType,
                        id: question.id,
                    };
                    return prepareQuestion;
                });
                return prepareSection;
            });
            prepareState.versionReason = serviceRes.versionReason;
            break;
        default:
            break;
    }
    return prepareState;
};

const camel2title = (camelCase) => camelCase
    ?.replace(/([A-Z])/g, (match) => ` ${match}`)
    ?.replace(/^./, (match) => match.toUpperCase())
    ?.trim();

// For text seperated by '/'
const slashCamel2title = (camelCase) => camelCase
    ?.replace(/^./, (match) => match.toUpperCase())
    ?.trim();

const formatCurrency = (value) => {
    if (!Number.isNaN(value)) {
        return new Intl.NumberFormat('en-GB', { style: 'currency', currency: 'GBP' }).format(value);
    }
    return '';
};

const formatPrettyDate = (date) => {
    try {
        const parsedISO = parseISO(date);
        if (parsedISO !== 'Invalid Date') {
            return `${format(parsedISO, 'dd-MM-yyyy')}`;
        }
    } catch (e) {
        return date;
    }
    return date;
};

const formatPrettyDateTime = (date) => {
    try {
        const parsedISO = parseISO(date);
        if (parsedISO !== 'Invalid Date') {
            return `${format(parsedISO, 'dd-MM-yyyy HH:mm')}`;
        }
    } catch (e) {
        return date;
    }

    return null;
};

const formatDateTimeFromUTCToUK = (date) => {
    if (!date) return '';

    const convertedDate = new Date((typeof date === 'string' ? new Date(date) : date).toLocaleString(Constants.ENGLISH_LANGUAGE_TYPE.EN, { timeZone: Constants.UK_TIMEZONE }));
    return format(convertedDate, 'dd-MM-yyyy HH:mm');
};

// This date comes in systemTimeStamp format and return a formatted Date String.
const formatPrettyUKDateTime = (datetimeString) => datetimeString.replace(/T/, ' ').replace(/\..+/, '')
    .slice(0, -3);

const pluralizeText = (count, singular, options = {}) => (
    count !== 1 ? (options.plural || pluralize(singular)) : singular
);

const simpleFormat = (str, wrapperElem = 'p', wrapperClass = '', multiNewLines = false) => {
    let newStr = str.replace(/\r\n?/, '\n');
    newStr = newStr.trim();
    if (newStr.length > 0) {
        if (!multiNewLines) newStr = newStr.replace(/\n\n+/g, `</${wrapperElem}><${wrapperElem} class='${wrapperClass}'>`);
        newStr = newStr.replace(/\n/g, '<br />');
        newStr = `<${wrapperElem} class='${wrapperClass}'>${newStr}</${wrapperElem}>`;
    }

    return parseHtml.default(newStr);
};

const createUniqueId = () => {
    let text = '';
    const possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    for (let i = 0; i < 5; i++) {
        text += possible.charAt(Math.floor(Math.random() * possible.length));
    }
    return text;
};

const isBetween = (date, from, to, inclusivity = '()') => {
    if (!['()', '[]', '(]', '[)'].includes(inclusivity)) {
        throw new Error('Inclusivity parameter must be one of (), [], (], [)');
    }

    const isBeforeEqual = inclusivity[0] === '[';
    const isAfterEqual = inclusivity[1] === ']';

    return (isBeforeEqual ? (isEqual(from, date) || isBefore(from, date)) : isBefore(from, date))
        && (isAfterEqual ? (isEqual(to, date) || isAfter(to, date)) : isAfter(to, date));
};

const handleFilter = (items) => (searchValue) => {
    if (searchValue.length === 0) {
        return items;
    }
    const updatedItems = items.map((list) => {
        const newItems = list.items.filter((item) => item
            .name.toLowerCase().includes(searchValue.toLowerCase()));
        return { ...list, items: newItems };
    });
    return updatedItems;
};


const exportFunction = async (data) => {
    let success = false;
    const convertedData = atob(data);
    try {
        const blob = new Blob([convertedData], { type: 'application/octet-stream;base64' });
        const objectURL = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = objectURL;
        a.download = 'Export.csv';
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        success = false;
    } catch (error) {
        success = false;
    }
    return success;
};

const generateGuid = () => v4();

const exportFunctionCSV = async (response) => {
    const blob = new Blob([response.data], { type: response.headers['content-type'] });
    const filename = response.headers['content-disposition']?.split('"')[1] || 'export.csv';
    saveAs(blob, filename);
};

const getYesNoElement = (parent, val) => {
    if (!parent || val === undefined) {
        return undefined;
    }
    if (typeof (val) === 'string') {
        return val === 'true' ? Constants.YES : Constants.NO;
    }
    return (val === true ? Constants.YES : Constants.NO);
};

const getNumElements = (parent, val) => {
    if (!parent) {
        return undefined;
    }
    return (val?.length || '0');
};

const getScore = (parent, val) => {
    if (!parent) {
        return undefined;
    }
    return (val || '0');
};

const getVersionItem = (title, old, current) => ({
    title,
    old,
    current,
});
const getQuestionnaireTypeLabel = (type) => (
    Constants.questionnaireTypes.find(
        (qType) => qType.value === type,
    )?.label || ''
);
const getShareQuestionnaireWith = (shareQuestionnaireWithEveryone, sharedWith, sharedWithName) => {
    if (shareQuestionnaireWithEveryone === 'true') {
        return Constants.PUBLIC;
    }
    return (sharedWith
        ? sharedWithName.join(', ') : Constants.NOT_SHARED_EXTERNALLY);
};

const getIds = (collection) => collection?.map((item) => item.id) || [];

const getUniqueIds = (oldCollection, currentCollection) => [...new Set(
    [...getIds(oldCollection),
        ...getIds(currentCollection)],
)];

const getResponseOptions = (responseOptions) => responseOptions?.join(', ');

const getFilenamesDocuments = (documents) => documents
    ?.map((doc) => doc.filename)
    .join(', ');

const getQuestions = (oldQuestions, currentQuestions) => {
    const questionsIds = getUniqueIds(oldQuestions, currentQuestions);
    const questions = questionsIds?.map((questionId) => {
        const old = oldQuestions?.find((item) => item.id === questionId);
        const current = currentQuestions?.find((item) => item.id === questionId);

        const oldDocuments = getFilenamesDocuments(old?.attachments);
        const currentDocuments = getFilenamesDocuments(current?.attachments);
        return {
            title: `${current?.questionTitle ? current.questionTitle : old?.questionTitle }`,
            details: [
                getVersionItem(Constants.TITLE,
                    old?.questionTitle,
                    current?.questionTitle),
                getVersionItem(Constants.DESCRIPTION,
                    old?.description,
                    current?.description),
                getVersionItem(Constants.SCORING_MECHANISM,
                    slashCamel2title(old?.scoringMechanism),
                    slashCamel2title(current?.scoringMechanism)),
                ...old?.weightage || current?.weightage
                    ? [getVersionItem(Constants.QUESTION_WEIGHTING, old?.weightage || '', current?.weightage || '')] : [],
                ...old?.responseType === Constants.FREETEXT
                || current?.responseType === Constants.FREETEXT
                    ? [getVersionItem(Constants.CHARACTER_LIMIT, old?.freeTextLength || '', current?.freeTextLength || '')] : [],
                getVersionItem(Constants.RESPONSE_TYPE,
                    slashCamel2title(old?.responseType),
                    slashCamel2title(current?.responseType)),
                ...old?.responseType === Constants.CHECKBOX
                 || current?.responseType === Constants.CHECKBOX
                 || old?.responseType === Constants.RADIOBUTTON
                 || current?.responseType === Constants.RADIOBUTTON
                    ? [getVersionItem(Constants.RESPONSE_OPTIONS,
                        getResponseOptions(old?.responseOptions),
                        getResponseOptions(current?.responseOptions))] : [],
                getVersionItem(Constants.UPLOADS_NUMBER,
                    getNumElements(old, old?.attachments),
                    getNumElements(current, current?.attachments)),
                ...old?.attachments.length > 0 || current?.attachments.length > 0
                    ? [getVersionItem(Constants.UPLOADS,
                        oldDocuments, currentDocuments)] : [],
                getVersionItem(Constants.SUPPORTING_DOCUMENTS_WITH_RESPONSE,
                    getYesNoElement(old, old?.supportingDocuments),
                    getYesNoElement(current, current?.supportingDocuments)),
                getVersionItem(Constants.OPTIONAL_QUESTION,
                    getYesNoElement(old, old?.responseOptional),
                    getYesNoElement(current, current?.responseOptional)),
            ],

        };
    });
    return questions;
};

const getSections = (versionToCompare, oldSections, currentSections) => {
    const sectionsIds = getUniqueIds(oldSections, currentSections);
    const sections = sectionsIds?.map((sectionId, index) => {
        const old = oldSections?.find((section) => section.id === sectionId);
        const current = currentSections?.find((section) => section.id === sectionId);

        return {
            title: `${index + 1} - ${current?.title ? current.title : old?.title }`,
            leftTitle: formatPrettyDateTime(versionToCompare),
            rightTitle: Constants.CURRENT_VERSION,
            details: [
                getVersionItem(Constants.TITLE, old?.title, current?.title),
                getVersionItem(Constants.DESCRIPTION,
                    old?.description, current?.description),
                ...old?.weightage || current?.weightage
                    ? [getVersionItem(Constants.SECTION_WEIGHTING, old?.weightage || '', current?.weightage || '')] : [],
            ],
            children: getQuestions(old?.sectionItems, current?.sectionItems),
        };
    });
    return sections;
};

const getResponseAnswer = (old, current) => {
    if (current?.responseType === Constants.YES_NO_RESPONSE_TYPE) {
        return getVersionItem(Constants.QUESTION_ANSWER,
            getYesNoElement(old, old?.questionAnswer),
            getYesNoElement(current, current?.questionAnswer));
    }

    return getVersionItem(Constants.QUESTION_ANSWER,
        old?.questionAnswer,
        current?.questionAnswer);
};

const getResponseQuestions = (versionToCompare, oldQuestions, currentQuestions) => {
    const questionsIds = getUniqueIds(oldQuestions, currentQuestions);
    return questionsIds.reduce((acc, questionID) => {
        const old = oldQuestions.find((questionItem) => questionItem.id === questionID);
        const current = currentQuestions.find((questionItem) => questionItem.id === questionID);
        const oldDocuments = getFilenamesDocuments(old?.supportingAnswerDocuments);
        const currentDocuments = getFilenamesDocuments(current?.supportingAnswerDocuments);

        return [
            ...acc,
            {
                title: `${current?.title || old?.title }`,
                leftTitle: formatPrettyDateTime(versionToCompare),
                rightTitle: Constants.CURRENT_VERSION,
                details: [
                    getResponseAnswer(old, current),
                    getVersionItem(Constants.UPLOADS_NUMBER,
                        getNumElements(old, old?.supportingAnswerDocuments),
                        getNumElements(current, current?.supportingAnswerDocuments)),
                    getVersionItem(Constants.UPLOADS,
                        oldDocuments, currentDocuments),
                ],
            },
        ];
    }, []);
};

const getResponseSections = (versionToCompare, oldSections, currentSections) => {
    const sectionsIds = getUniqueIds(oldSections, currentSections);
    return sectionsIds?.map((sectionId, index) => {
        const old = oldSections?.find((item) => item.id === sectionId);
        const current = currentSections?.find((item) => item.id === sectionId);

        return {
            title: `${index + 1} - ${current?.title ?? old?.title }`,
            children: getResponseQuestions(versionToCompare,
                old?.sectionItems, current?.sectionItems),
        };
    });
};

function prepareVersionsToCompare(context, old, current) {
    const id = context.toLowerCase() === Constants.QUESTIONNAIRE ? old.templateId : old.responseID;
    const versionToCompare = current?.details.previousVersions
        ?.find((version) => version.versionTemplateId === id);
    const versionToCompareDate = versionToCompare?.localTimestamp
    || versionToCompare?.systemTimestamp;
    let structure;
    if (context.toLowerCase() === Constants.QUESTIONNAIRE) {
        let sectionsWeigthedDetails = [];

        const details = [
            getVersionItem(Constants.TITLE,
                old?.details?.templateName,
                current?.details?.templateName),
            getVersionItem(Constants.DESCRIPTION,
                old?.details?.description
                || old?.details?.templateDescription,
                current?.details?.description || current?.details?.templateDescription),
            getVersionItem(Constants.TYPE,
                getQuestionnaireTypeLabel(old?.details?.templateType),
                getQuestionnaireTypeLabel(current?.details?.templateType)),
            getVersionItem(Constants.QUESTIONNAIRE_SHARED,
                getYesNoElement(old, old?.details?.shareQuestionnaire),
                getYesNoElement(current, current?.details?.shareQuestionnaire)),
            getVersionItem(Constants.QUESTIONNAIRE_SHARED_WITH,
                getShareQuestionnaireWith(old?.details?.shareQuestionnaireWithEveryone,
                    old?.details?.sharedWith, old?.details?.sharedWithName),
                getShareQuestionnaireWith(current?.details?.shareQuestionnaireWithEveryone,
                    current?.details?.sharedWith, current?.details?.sharedWithName)),
            getVersionItem(Constants.QUESTIONNAIRE_SHARED_BY,
                old?.details?.sharedBy,
                current?.details?.sharedBy),
            getVersionItem(Constants.ARE_SECTIONS_WEIGHTED,
                getYesNoElement(old, old?.details?.sectionsWeighted),
                getYesNoElement(current, current?.details?.sectionsWeighted)),
        ];
        if (old?.details?.sectionsWeighted || current?.details?.sectionsWeighted) {
            sectionsWeigthedDetails = [
                getVersionItem(Constants.MIN_SCORING_VALUE,
                    getScore(old?.details?.sectionsWeighted, old?.details?.minScore),
                    getScore(current?.details?.sectionsWeighted, current?.details?.minScore)),
                getVersionItem(Constants.MAX_SCORING_VALUE,
                    getScore(old?.details?.sectionsWeighted, old?.details?.maxScore),
                    getScore(current?.details?.sectionsWeighted, current?.details?.maxScore)),
                getVersionItem(Constants.SHOW_SECTION_WEIGHTINGS,
                    getYesNoElement(old, old?.details?.showWeightings),
                    getYesNoElement(current, current?.details?.showWeightings)),
            ];
        }

        const versionReason = {
            additionalTextInfo: old?.versionReason,
        };

        structure = {
            versionToCompareDate,
            title: Constants.ADDITIONAL_DETAILS,
            childrenTitle: Constants.SECTIONS,
            leftTitle: formatPrettyDateTime(versionToCompareDate),
            rightTitle: Constants.CURRENT_VERSION,
            details: sectionsWeigthedDetails.length
                ? [...details, ...sectionsWeigthedDetails] : [...details],
            children: getSections(versionToCompareDate, old.sections, current.sections),
            additionalTextInfoTitle: Constants.CHANGES_DESCRIPTION,
            ...old?.versionReason && versionReason,
        };
    }
    if (context.toLowerCase() === Constants.RESPONSE) {
        structure = {
            versionToCompareDate,
            childrenTitle: Constants.SECTIONS,
            leftTitle: formatPrettyDateTime(versionToCompareDate),
            rightTitle: Constants.CURRENT_VERSION,
            children: getResponseSections(versionToCompareDate, old.sections, current.sections),
        };
    }
    return structure;
}

const isSubmitForApprovalAvailable = (responses) => responses
    ?.every((response) => response.evaluationStatus
    === evaluationStatuses.evaluationComplete);

const stringWithParams = (template, ...args) => template.replace(
    /{([0-9]+)}/g,
    (match, index) => (args?.[index] ?? match),
);

const getEvaluationPanelLabel = (evaluationPanel) => evaluationPanelTypes
    ?.[evaluationPanel].label;


const helperFunctions = {
    getRelativeTime,
    splitDataSetForPagination,
    constructDropdownData,
    constructGroupedDropdownData,
    backendDataMapper,
    procurementRequestOverviewBackendDataMapper,
    sortNotificationsData,
    camel2title,
    slashCamel2title,
    getSubmissionStatus,
    prepareDocumentBuilderState,
    formatCurrency,
    formatPrettyDate,
    formatPrettyDateTime,
    formatPrettyUKDateTime,
    pluralizeText,
    simpleFormat,
    createUniqueId,
    isBetween,
    handleFilter,
    exportFunction,
    exportFunctionCSV,
    prepareVersionsToCompare,
    generateGuid,
    isSubmitForApprovalAvailable,
    stringWithParams,
    formatDateTimeFromUTCToUK,
    getEvaluationPanelLabel,
};

export default helperFunctions;
